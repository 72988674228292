import get from 'lodash/get'

export const initialState = {
  step: 0,
  prev: null,
  loading: false,
  error: null,
  infoData: null,
  addressData: null,
  creditCardData: null,
  customer: null,
  order: null,
  transaction: null
}

const SET_LOADING = 'SET_LOADING'
const SET_ERROR = 'SET_ERROR'
const SET_INFO = 'SET_INFO'
const SET_ADDRESS = 'SET_ADDRESS'
const SET_CREDIT_CARD = 'SET_CREDIT_CARD'
const SET_CUSTOMER = 'SET_CUSTOMER'
const SET_ORDER = 'SET_ORDER'
const SET_TRANSACTION = 'SET_TRANSACTION'
const SET_ALL_DATA = 'SET_ALL_DATA'
const BACK = 'BACK'

const goBack = () => ({ type: BACK })
const setLoading = () => ({ type: SET_LOADING })
const setError = (payload) => ({ type: SET_ERROR, payload })
const setInfoData = (payload) => ({ type: SET_INFO, payload })
const setAddressData = (payload) => ({ type: SET_ADDRESS, payload })
const setCreditCardData = (payload) => ({ type: SET_CREDIT_CARD, payload })
const setCustomer = (payload) => ({ type: SET_CUSTOMER, payload })
const setOrder = (payload) => ({ type: SET_ORDER, payload })
const setTransaction = (payload) => ({ type: SET_TRANSACTION, payload })
export const setAllData = (payload) => ({ type: SET_ALL_DATA, payload })

export const checkoutReducer = (state=initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING: return { ...state, loading: true, error: '' } 
    case SET_ERROR: return { ...state, loading: false, error: payload } 
    case SET_INFO: return { ...state, loading: false, prev: 0, step: 1, infoData: payload }
    case SET_ADDRESS: return { ...state, loading: false, prev: 1, step: 2, addressData: payload }
    case SET_CREDIT_CARD: return { ...state, loading: false, prev: 2, step: 3, creditCardData: payload }
    case SET_CUSTOMER: return { ...state, loading: false, customer: payload }
    case SET_ORDER: return { ...state, loading: false, order: payload }
    case SET_TRANSACTION: return { ...state, loading: false, transaction: payload }
    case SET_ALL_DATA: return { ...state, ...payload, prev: typeof payload.step === 'number' ? state.step : state.prev }
    case BACK: return { ...state, step: Math.max(0, state.step - 1), prev: state.step }
    default: return state
  }
}

export const toCheckoutData = (state) => ({
  checkoutData: {
    contact: { phone: '', ...get(state, 'infoData') },
    shippingAddress: get(state, 'addressData.shipping'),
    billingAddress: get(state, 'addressData.billing'),
    items: get(state, 'items'),
    transaction: {
      id: get(state, 'transaction.data.checkout.transaction.id'),
      total: Number(get(state, 'transaction.data.checkout.transaction.amount'))
    },
  }
})

export const checkoutActions = {
  setLoading,
  setError,
  setInfoData,
  setAddressData,
  setCreditCardData,
  setCustomer,
  setOrder,
  setTransaction,
  setAllData,
  goBack,
}
import React from 'react'
import Mobile from './mobile'
import Desktop from './desktop'

export const View = ({ children }) => {
  return (
    <div className="pt-2 pb-40vh">
      {children}
      <div className="mt-5 py-5">
        <FooterLinks />
      </div>
    </div>
  )
}

export const FooterLinks = () => (
  <div className="text-center">
    <div className="text-muted mb-3">COIN - © 2021 All Rights Reserved</div>
    <div className="text-white">
      <FooterLink href="//support.coinapp.co/hc">Support</FooterLink>
      <span> | </span>
      <FooterLink href="//coinapp.co/legal/privacy">Privacy Policy</FooterLink>
      <span> | </span>
      <FooterLink href="//coinapp.co/legal/terms">Terms of Service</FooterLink>
      <span> | </span>
      <FooterLink href="//coinapp.co/legal/sales-terms">
        Terms of Sale
      </FooterLink>
      <span> | </span>
      <FooterLink href="//coinapp.co/legal/subscription-terms">
        Subscription Terms
      </FooterLink>
      <span> | </span>
      <FooterLink href="//coinapp.co/legal/sweepstakes-terms">
        Sweepstake Terms
      </FooterLink>
    </div>
  </div>
)

const FooterLink = ({ children, href }) => {
  return (
    <a
      href={href}
      className="text-white px-1"
      target="_blank"
      rel="nofollow noreferrer"
    >
      {children}
    </a>
  )
}

export const FooterOfficeLocation = () => {
  return (
    <>
      <h4 className="text-primary text-uppercase">
        <i className="fa fa-building mr-2" style={{ fontSize: 20 }} />
        Office Location
      </h4>
      <p className="mb-0 text-light">XY &amp; COIN Headquarters:</p>
      <p className="mb-0 text-light">1405 30th St</p>
      <p className="mb-0 text-light">Suite A</p>
      <p className="mb-0 text-light">San Diego, CA 92154</p>
      <p className="mb-0 text-light">
        <a href="tel:1-855-346-3160">(855)346-3160</a>
      </p>
    </>
  )
}

const Footer = ({ hiker }) => {
  return (
    <div className="footer" id="footer">
      <Mobile
        hiker={hiker}
        className="d-lg-none w-100"
        style={{ transform: `translate3d(0, 30%, 0)`, paddingTop: 100 }}
      />
      <Desktop
        hiker={hiker}
        className="d-none d-lg-block w-100 hill-drop-shadow"
        style={{ paddingTop: 100 }}
      />
    </div>
  )
}

export default Footer

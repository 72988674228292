import { cloneElement, FC, useEffect } from 'react'
import { render } from 'react-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import { primaryMain } from '../../context/page'
import CircularProgress from '@material-ui/core/CircularProgress'
import Main from '../../Main'
import swal from 'sweetalert2'

const swalProps = {
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
}

export const swalError = (text) => {
  return swal.fire({ type: 'error', text, ...swalProps })
}

export const swalWarning = (title, html) => {
  return swal.fire({ type: 'warning', title, html, ...swalProps })
}

export const swalLoader = (text) => {
  return swal.fire({
    showConfirmButton: false,
    allowOutsideClick: false,
    onClose: () => {
      const container = document.querySelector('.swal2-container')
      if (container) container.remove()
    },
    onBeforeOpen: (element) => {
      render(
        <Main type="light">
          <div className="text-center text-dark">
            {text}
            <div className="text-primary">
              <CircularProgress color="inherit" />
            </div>
          </div>
        </Main>,
        element,
      )
    },
  })
}

export const swalSuccess = (text) => {
  return swal.fire({ type: 'success', text, ...swalProps })
}

export const swalConfirm = (
  text = '',
  title = 'Are you sure?',
  type = 'warning',
  {
    confirmButtonText = 'Yes!',
    cancelButtonText = 'Cancel',
    html = '',
    ...options
  } = {},
) => {
  return swal.fire({
    title,
    text,
    type,
    html,
    reverseButtons: true,
    showCancelButton: true,
    confirmButtonText,
    cancelButtonText,
    buttonsStyling: false,
    cancelButtonClass: 'btn btn-danger mr-1',
    confirmButtonClass: 'btn btn-info text-white',
    ...options,
  })
}

export const swalConfirmAppLink = async ({
  link = '',
  text = '',
  title = 'Continue In App',
  type = 'info',
  confirmButtonText = 'Continue',
  cancelButtonText = 'Cancel',
  ...options
} = {}) => {
  const { value } = await swalConfirm(text, title, type, {
    confirmButtonText,
    cancelButtonText,
    ...options,
  })
  if (value) {
    window.open(`https://my.coinapp.co/appLink${link}`)
  }
}

export const swalRender = (Component, options) => {
  return swal.fire({
    ...options,
    onClose: () => {
      const container = document.querySelector('.swal2-container')
      if (container) container.remove()
    },
    onBeforeOpen: (element) => {
      render(
        <Main type="light">
          {cloneElement(Component, { onClose: () => swal.close() })}
        </Main>,
        element,
      )
    },
  })
}

export const swalSelect = (Component, options) => {
  return new Promise((res, rej) => {
    swal.fire({
      ...options,
      onClose: () => {
        res(null)
        const container = document.querySelector('.swal2-container')
        if (container) container.remove()
      },
      onBeforeOpen: (element) => {
        render(
          <Main type="light">
            {cloneElement(Component, {
              onChange: (v) => {
                res(v)
                swal.close()
              },
              onClose: () => {
                swal.close()
              },
            })}
          </Main>,
          element,
        )
      },
    })
  })
}

export const swalClose = () => {
  swal.close()
}

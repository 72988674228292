import { ReactNode } from 'react'
import { DangerBtn } from '../Buttons'
import { confirmPaymentPrompt } from '../Upgrade/ConfirmUpgrade'
import { trackPayEvent, trackWarning } from '../../utils/events'
import { swalLoader, swalSuccess, swalError } from '../Alert'
import {
  useTransactionMutation,
  useInvoiceQuery,
  PaymentCard,
  TransactionDocument,
  Invoice,
} from '@xyo-network/api-checkout-apollo'
import { redeemSubscription, checkoutClient } from '../../context/checkout'
import { parseError } from '../../utils/error'
import { useUser, refetchAccounts } from '../../context/user'
import { MY_PAST_DUE_INVOICES } from '../Invoice'
import get from 'lodash/get'

interface IOuter {
  invoiceId?: string | null
  invoice?: Invoice
  cards?: (PaymentCard | null)[] | null
  children: ReactNode
}

export const usePayInvoice = (cards: PaymentCard[]) => {
  const { user } = useUser()
  const pay = async (invoice: Invoice) => {
    const invoiceId = get(invoice, 'id')
    const email = get(user, 'email')
    if (!invoiceId || !email) return null
    const card = await confirmPaymentPrompt(
      Promise.resolve(invoice),
      Promise.resolve(cards),
      'Pay Invoice',
    )
    if (!card || !card.paymentCardId) {
      trackPayEvent('canceled', {})
      return null
    }
    try {
      trackPayEvent('confirmed', {})
      swalLoader(<p>Paying Invoice...</p>)
      const result = await checkoutClient.mutate({
        mutation: TransactionDocument,
        variables: { invoiceId, paymentCardId: card.paymentCardId },
      })
      console.log('Redeem subscription', await redeemSubscription(email))
      console.log(
        'Refetch account',
        await Promise.all([
          checkoutClient.query({
            query: MY_PAST_DUE_INVOICES,
            fetchPolicy: 'network-only',
          }),
          refetchAccounts(),
        ]),
      )
      swalSuccess('Transaction successful!')
      return result?.data?.createTransaction
    } catch (e) {
      trackWarning('Pay invoice error', e.message)
      trackPayEvent('error', { value: e.message })
      swalError(parseError(e.message))
      return null
    }
  }
  return pay
}

export default ({ invoiceId, children, cards, ...props }: IOuter) => {
  const [payInvoice] = useTransactionMutation()
  const invoiceQuery = useInvoiceQuery({
    variables: { id: invoiceId || '' },
    skip: !invoiceId || Boolean(props.invoice),
  })
  const invoice = props.invoice ?? invoiceQuery.data?.invoice
  const { user } = useUser()
  const pay = async (ev: any) => {
    ev.stopPropagation()
    const email = get(user, 'email')
    if (!invoiceId || !payInvoice || !email) return
    const card = await confirmPaymentPrompt(
      Promise.resolve(invoice),
      Promise.resolve(cards),
    )
    if (!card || !card.paymentCardId) return trackPayEvent('canceled', {})
    try {
      trackPayEvent('confirmed', {})
      swalLoader(<p>Paying Invoice...</p>)
      await payInvoice({
        variables: { invoiceId, paymentCardId: card.paymentCardId },
      })
      await redeemSubscription(email)
      await refetchAccounts()
      swalSuccess('Transaction successful!')
    } catch (e) {
      trackWarning('Pay invoice error', e.message)
      trackPayEvent('error', { value: e.message })
      swalError(parseError(e.message))
    }
  }
  return invoiceId ? <DangerBtn onClick={pay}>{children}</DangerBtn> : <></>
}

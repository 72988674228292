import React from 'react'
import NavLogo from '../Logo/NavLogo'
import MenuIcon from '@material-ui/icons/Menu'
import { Link } from 'react-router-dom'
import { BuyNowButton } from '../Checkout'
import { HistoryLinks } from '../Sidebar'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { lightTheme } from '../../context/page'

export default ({ toggleDrawer }) => (
  <div className="navbar navbar-expand-lg px-1 px-sm-2" style={{ zIndex: 1 }}>
    <Link className="navbar-brand" to="/">
      <NavLogo />
    </Link>
    <div className="ml-auto d-none d-md-block">
      <Link to="/account" className="btn btn-dark mr-1 px-0 px-sm-2">
        <span className="text-danger">
          <i className="d-none d-md-inline fa fa-user" /> Account
        </span>
      </Link>
      <Link to="/redeem" className="btn btn-dark mr-1 px-0 px-sm-2">
        <span className="text-danger">
          <i className="d-none d-md-inline fa fa-envelope-open-text" /> Redeem
        </span>
      </Link>
      <Link to="/verify" className="btn btn-dark mr-1 px-0 px-sm-2">
        <span className="text-danger">
          <i className="d-none d-md-inline fa fa-wallet" /> Verify
        </span>
      </Link>
      <BuyNowButton
        renderPopover={() => (
          <MuiThemeProvider theme={lightTheme}>
            <HistoryLinks className="text-dark" />
          </MuiThemeProvider>
        )}
      >
        {() => (
          <button className="btn btn-dark mr-1 px-0 px-sm-2">
            <span className="text-danger">
              <i className="d-none d-md-inline fa fa-scroll" /> History{' '}
              <small>&#x25bc;</small>
            </span>
          </button>
        )}
      </BuyNowButton>
    </div>
    <button onClick={toggleDrawer} className="btn btn-outline-danger">
      <MenuIcon color="inherit" />
    </button>
  </div>
)

export const LoginNavbar = ({ redirect }) => (
  <div className="navbar navbar-expand-lg px-1 px-sm-2" style={{ zIndex: 1 }}>
    <Link className="navbar-brand" to="/">
      <NavLogo />
    </Link>
    <div className="ml-auto">
      <Link
        to={redirect ? `/auth?redirect=${redirect}` : '/auth'}
        className="btn btn-dark mr-1 px-0 px-sm-2"
      >
        <span className="text-danger">
          <i className="d-inline fa fa-user" /> Login
        </span>
      </Link>
    </div>
  </div>
)

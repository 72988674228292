import { useEffect, useState, useMemo, useCallback } from 'react'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Form, { FormField } from '../Form'
import { swalRender } from '../Alert'
import { BossProvider } from '../../context/bossapi'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'

const required = (v) => (v ? '' : 'Required')
const requiredIfNotSame = (v, vs) => (vs.same ? '' : v ? '' : 'Required')

const addressInfoValidator = {
  'shipping.address': required,
  'shipping.city': required,
  'shipping.country': required,
  'shipping.region': required,
  'shipping.postalCode': required,
  'billing.address': requiredIfNotSame,
  'billing.city': requiredIfNotSame,
  'billing.country': requiredIfNotSame,
  'billing.region': requiredIfNotSame,
  'billing.postalCode': requiredIfNotSame,
}

export const showEditAddress = ({ userInfo }) => {
  swalRender(<EditAddressForm userInfo={userInfo} />)
}

const EditAddressForm = ({ userInfo, onClose }) => {
  const initial = useMemo(
    () => ({
      ...userInfo,
      shipping: {
        address: userInfo.shipStreetAddress,
        address2: userInfo.shipStreetAddress2,
        city: userInfo.shipCity,
        region: userInfo.shipState,
        postalCode: userInfo.shipZipcode,
        country: userInfo.shipCountry,
      },
      same: true,
    }),
    [userInfo],
  )
  return (
    <BossProvider>
      <Mutation mutation={updateShippingAddress} update={onClose}>
        {(mutate, { loading }) => (
          <Form
            id={'edit-shipping-address'}
            onSubmit={({ shipFullName, shipping }) => {
              mutate({
                variables: {
                  shipFullName,
                  shipStreetAddress: shipping.address,
                  shipStreetAddress2: shipping.address2,
                  shipCity: shipping.city,
                  shipState: shipping.region,
                  shipZipcode: shipping.postalCode,
                  shipCountry: shipping.country,
                },
              })
            }}
            initial={initial}
            validator={addressInfoValidator}
          >
            {({ values }) => (
              <>
                <FormField
                  id="shipFullName"
                  name="shipFullName"
                  component={TextField}
                  autoFocus
                  label="Full Name"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  component={TextField}
                />
                <AddressFields type="shipping" values={values.shipping} />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="mt-2 ml-auto"
                  disabled={loading}
                >
                  Submit
                </Button>
              </>
            )}
          </Form>
        )}
      </Mutation>
    </BossProvider>
  )
}

const AddressForm = ({ onSubmit, initial, id }) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initial={initial}
      validator={addressInfoValidator}
    >
      {({ values }) => (
        <>
          <h4>Shipping Address</h4>
          <AddressFields type="shipping" values={values.shipping} />
          <div className="d-flex align-items-center mb-3">
            <FormField
              id="same"
              name="same"
              type="checkbox"
              component={Checkbox}
            />
            <label htmlFor="same" className="mb-0 text-dark">
              Same as billing address
            </label>
          </div>
          {values.same === true ? (
            <></>
          ) : (
            <>
              <h4>Billing Address</h4>
              <AddressFields type="billing" values={values.billing} />
            </>
          )}
        </>
      )}
    </Form>
  )
}

const AddressFields = ({ type, values }) => {
  const [countries, setCountries] = useState([])
  const [regions, setRegions] = useState([])

  useEffect(() => {
    import(/* webpackChunkName: "country-region-data" */ 'country-region-data')
      .then((data) => {
        const us = (data.default || []).find(
          ({ countryShortCode }) => countryShortCode === 'US',
        )
        setCountries([us].concat(data.default))
      })
      .catch((e) =>
        setCountries([
          { countryName: 'United States', countryShortCode: 'US', regions: [] },
        ]),
      )
  }, [])

  useEffect(() => {
    if (countries && values && values.country) {
      const country = countries.find(
        ({ countryShortCode }) => values.country === countryShortCode,
      )
      if (country) setRegions(country.regions)
    }
  }, [countries, values && values.country])

  return (
    <>
      <FormField
        id="address"
        name={`${type}.address`}
        component={TextField}
        autoComplete={`${type} address-line1`}
        autoFocus
        label="Address"
        variant="outlined"
        margin="normal"
        fullWidth
      />
      <FormField
        id="address2"
        name={`${type}.address2`}
        autoComplete={`${type} address-line2`}
        component={TextField}
        label="Address Line 2"
        variant="outlined"
        margin="normal"
        fullWidth
      />
      <div className="row">
        <div className="col">
          <FormField
            id="country"
            name={`${type}.country`}
            autoComplete={`${type} country-name`}
            className="col"
            component={SelectCountry}
            variant="outlined"
            label="Country"
            margin="normal"
            countries={countries}
          />
        </div>
        <div className="col">
          <FormField
            id="region"
            name={`${type}.region`}
            autoComplete={`${type} region`}
            className="col"
            component={SelectRegion}
            regions={regions}
            label="State"
            variant="outlined"
            margin="normal"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <FormField
            id="city"
            name={`${type}.city`}
            autoComplete={`${type} locality`}
            className="col"
            component={TextField}
            label="City"
            variant="outlined"
            margin="normal"
          />
        </div>
        <div className="col">
          <FormField
            id="postalCode"
            name={`${type}.postalCode`}
            autoComplete={`${type} postal-code`}
            className="col"
            component={TextField}
            label="Zip"
            variant="outlined"
            margin="normal"
          />
        </div>
      </div>
    </>
  )
}

const SelectCountry = ({ countries, ...props }) => {
  const handleChange = (ev, element) => {
    props.onChange(ev, element.props.value)
  }

  return (
    <TextField select {...props} onChange={handleChange}>
      <MenuItem value="">Choose...</MenuItem>
      {countries.map(({ countryName, countryShortCode }, i) => (
        <MenuItem key={`${countryShortCode}_${i}`} value={countryShortCode}>
          {countryName}
        </MenuItem>
      ))}
    </TextField>
  )
}

const SelectRegion = ({ regions, ...props }) => {
  const handleChange = (ev, element) => {
    props.onChange(ev, element.props.value)
  }

  return (
    <TextField select {...props} onChange={handleChange}>
      <MenuItem value="">Choose...</MenuItem>
      {regions.map(({ name, shortCode }) => (
        <MenuItem key={shortCode} value={shortCode}>
          {name}
        </MenuItem>
      ))}
    </TextField>
  )
}

const updateShippingAddress = gql`
  mutation UpdateShippingAddress(
    $shipFullName: String
    $shipStreetAddress: String
    $shipStreetAddress2: String
    $shipCity: String
    $shipState: String
    $shipZipcode: String
    $shipCountry: String
    $shipPhoneNumber: String
  ) {
    updateShippingAddress(
      shipFullName: $shipFullName
      shipStreetAddress: $shipStreetAddress
      shipStreetAddress2: $shipStreetAddress2
      shipCity: $shipCity
      shipState: $shipState
      shipZipcode: $shipZipcode
      shipCountry: $shipCountry
      shipPhoneNumber: $shipPhoneNumber
    ) {
      id
      uid
      type
    }
  }
`

export default AddressForm

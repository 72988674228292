import { createContext, useContext, useState, useEffect } from 'react'
import { useUserInfo } from './user'

export const defaultValue = {
  invalid: false,
  lastKnownAddress: '',
  isVerified: false,
  wallet: '',
  loading: false,
  setLoading: (loading) => {
    defaultValue.loading = loading
  },
  onSubmit: (wallet) => {},
  setWallet: (wallet) => {
    defaultValue.wallet = wallet
  },
}

export const AddressContext = createContext(defaultValue)

export const useAddress = () => useContext(AddressContext)

export const AddressProvider = ({ children }) => {
  const { userInfo } = useUserInfo()
  const [invalid, setInvalid] = useState('')
  const [wallet, setWalletValue] = useState('')
  const [isVerified, setWalletVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lastKnownAddress, setLastKnownAddress] = useState('')
  const setWallet = (value, verified = false) => {
    setWalletValue(value)
    setWalletVerified(verified)
  }
  useEffect(() => {
    if (userInfo && userInfo.lastKnownAddress) {
      setWallet(userInfo.lastKnownAddress)
      setLastKnownAddress(userInfo.lastKnownAddress)
    }
  }, [userInfo])
  return (
    <AddressContext.Provider
      value={{
        ...defaultValue,
        loading,
        setLoading,
        invalid,
        wallet,
        isVerified,
        setWallet,
        setInvalid,
        lastKnownAddress,
      }}
    >
      {children}
    </AddressContext.Provider>
  )
}

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import firebase from 'firebase/app'
import { useState } from 'react'
import 'firebase/auth'

export const PasswordRequiredDialog = ({
  email,
  open,
  onClose,
  onConfirmed,
  title,
  content,
}) => {
  const [confirming, setConfirming] = useState(false)
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const onConfirm = async (ev) => {
    if (ev) ev.preventDefault()
    try {
      setError('')
      setConfirming(true)
      if (!password) {
        throw new Error('Password is required.')
      } else {
        try {
          await firebase.auth().signInWithEmailAndPassword(email, password)
        } catch (e) {
          throw new Error('Password is invalid.')
        }
      }
      if (typeof onConfirmed === 'function') {
        onConfirmed()
      }
      setConfirming(false)
    } catch (e) {
      setError(e.message)
      setConfirming(false)
    }
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        <form onSubmit={onConfirm}>
          <TextField
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
            type="password"
            variant="outlined"
            label="Enter Password"
            helperText={error}
            error={Boolean(error)}
            margin="normal"
            fullWidth
          />
        </form>
      </DialogContent>
      <DialogActions style={{ alignItems: 'flex-end' }}>
        <Box mr={1}>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </Box>
        <Button
          disabled={confirming}
          onClick={() => onConfirm()}
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

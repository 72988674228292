import { useEffect } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { trackWarning } from '../utils/events'

export const getInviteRedirectUri = (id) => {
  return (process.env.REACT_APP_INVITE_REDIRECT || '').replace('[id]', id)
}

export default withRouter(({ match }) => {
  const { id } = match.params

  useEffect(() => {
    // window.location.href = getInviteRedirectUri(id)
    setTimeout(() => {
      window.location.href = getInviteRedirectUri(id)
    }, 1000)
  }, [id])

  return (
    <div className="container pt-4 pb-40vh text-center text-white">
      <h4
        href={getInviteRedirectUri(id)}
        className="d-flex align-items-center justify-content-center"
      >
        Redirecting <span className="spinner-border spinner-border-sm ml-2" />
      </h4>
      <h4>
        <small>
          If you are not redirected automatically please{' '}
          <a href={getInviteRedirectUri(id)}>click here</a>.
        </small>
      </h4>
    </div>
  )
})

import { useState, useMemo, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { CheckoutProvider } from '../context/checkout'
import { BossProvider } from '../context/bossapi'
import {
  InvoiceFragmentDoc,
  CardFragmentDoc,
} from '@xyo-network/api-checkout-apollo'
import Select from '@material-ui/core/Select'
import Skeleton from '@material-ui/lab/Skeleton'
import MenuItem from '@material-ui/core/MenuItem'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from '../components/Table/Pagination'
import times from 'lodash/times'
import { Query, Mutation } from 'react-apollo'
import cx from 'classnames'
import lowerCase from 'lodash/lowerCase'
import first from 'lodash/first'
import capitalize from 'lodash/capitalize'
import mapValues from 'lodash/mapValues'
import ButtonBase from '@material-ui/core/ButtonBase'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { lightTheme } from '../context/page'
import { useQueryString, queryValue } from '../utils/search'
import ListLayout from '../components/Layouts/List'
import { InvoiceList } from '../components/Invoice/List'
import { MY_PAST_DUE_INVOICES } from '../components/Invoice'
import {
  usePageQueryParams,
  InvoiceStatusFilter,
} from '../components/Invoice/Filter'
import { redeemHistoryQuery, redeemRetryMutation } from './withdraw'
import keyBy from 'lodash/keyBy'
import find from 'lodash/find'
import { LoadingButton } from '../components/Buttons'
import { swalSuccess, swalError } from '../components/Alert'
import { parseError } from '../utils/error'
import { useUserInfo } from '../context/user'

const Invoices = withRouter(({ history, onAllInvoicesPaid }) => {
  const [invoice, setInvoice] = useState(null)
  const {
    query,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePageQueryParams(history)
  return (
    <ListLayout
      title={
        <>
          <Typography variant="h4">Invoices</Typography>
          <Typography variant="subtitle2">
            Past due invoices must be paid to continue subscription and redeem
            rewards
          </Typography>
        </>
      }
    >
      <Query
        query={MY_PAST_DUE_INVOICES}
        variables={{
          pagination: { page: query.page, perPage: query.perPage },
        }}
      >
        {({ data, loading }) => {
          const customer = data?.me
          const invoices = customer?.invoicesPastDue?.data
          const total = customer?.invoicesPastDue?.total
          const cards = customer?.paymentCards?.data
          return (
            <div>
              <InvoiceList
                perPage={query.perPage}
                loading={loading}
                invoices={invoices}
                onAllInvoicesPaid={onAllInvoicesPaid}
                cards={cards}
              />
              {total ? (
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10, 25]}
                  count={total}
                  rowsPerPage={query.perPage}
                  page={query.page - 1}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                ></TablePagination>
              ) : null}
            </div>
          )
        }}
      </Query>
    </ListLayout>
  )
})

const RedeemDenied = ({ open, onClose }) => {
  const { userInfo } = useUserInfo()
  return (
    <MuiThemeProvider theme={lightTheme}>
      <BossProvider>
        <Query
          query={redeemHistoryQuery}
          variables={{ sort: { field: 'date', order: -1 } }}
        >
          {({ data, loading }) => {
            if (loading) return null
            const redeems = data?.myRedeemHistory?.data ?? []
            const redeem = first(redeems)
            if (!redeem) return null
            if (redeem.state !== 'denied' || redeem.status !== 'denied') {
              console.log('last redeem not denied')
              return null
            }
            if (!userInfo || userInfo.xyoCollected < redeem.xyoCost) {
              console.log('not enough collected to re request')
              return null
            }
            const options = data?.redeemOptions ?? []
            const optionsById = keyBy(options, 'id')
            const reference = redeem?.reference
            const option = optionsById[redeem?.itemId]
            const imageUrl = option?.imageUrl ?? ''
            const title = option?.title ?? ''
            return (
              <Mutation
                mutation={redeemRetryMutation}
                onError={(e) => {
                  onClose()
                  swalError(parseError(e.message))
                }}
                onCompleted={(data) => {
                  onClose()
                  swalSuccess(`Your request for ${title} has been resubmitted.`)
                }}
              >
                {(retryRedeem, { loading: updating }) => (
                  <RetryRedeemDialog
                    title={title}
                    imageUrl={imageUrl}
                    loading={updating}
                    onClose={onClose}
                    onConfirm={() =>
                      retryRedeem({
                        variables: { reference },
                      })
                    }
                    open={open}
                  />
                )}
              </Mutation>
            )
          }}
        </Query>
      </BossProvider>
    </MuiThemeProvider>
  )
}

const RetryRedeemDialog = ({
  open,
  onClose,
  title,
  imageUrl,
  onConfirm,
  loading,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <div className="d-flex align-items-center">
          {imageUrl ? (
            <Avatar
              src={imageUrl}
              className="mr-2"
              style={{ borderRadius: 0 }}
              imgProps={{ style: { objectFit: 'contain' } }}
            />
          ) : null}
          {title}
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your previously denied request for {title} can now be retried.
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ alignItems: 'flex-end' }}>
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={onConfirm}
          loading={loading}
        >
          Request
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default () => {
  const [retryRedeemOpen, setRetryRedeemOpen] = useState(false)
  return (
    <CheckoutProvider>
      <Invoices onAllInvoicesPaid={() => setRetryRedeemOpen(true)} />
      <RedeemDenied
        open={retryRedeemOpen}
        onClose={() => setRetryRedeemOpen(false)}
      />
    </CheckoutProvider>
  )
}

import { useState } from 'react'
import { Link } from 'react-router-dom'
import Fab from '@material-ui/core/Fab'
import dropsImage from '../../assets/drops.png'
import claimsImage from '../../assets/geoclaim.png'
import axeImage from '../../assets/pickaxe.png'

export default () => {
  return (
    <div
      className="position-absolute w-100 pb-4 pb-md-5"
      style={{ bottom: 0, zIndex: 1 }}
    >
      <div className="d-flex align-items-center justify-content-center">
        <Link to="/geodrops">
          <Fab
            style={{
              width: 64,
              height: 64,
              padding: 14,
              background: '#fff',
              border: '3px solid #eee',
            }}
          >
            <img className="w-100" src={dropsImage} alt="Geodrops" />
          </Fab>
        </Link>
        <Link to="/geomines">
          <Fab
            style={{
              width: 94,
              height: 94,
              padding: 26,
              margin: '0 40px',
              background: '#fff',
              border: '4px solid #eee',
            }}
          >
            <img className="w-100" src={axeImage} alt="Geomines" />
          </Fab>
        </Link>
        <Link to="/geoclaims">
          <Fab
            style={{
              width: 64,
              height: 64,
              padding: 14,
              background: '#fff',
              border: '3px solid #eee',
            }}
          >
            <img className="w-100" src={claimsImage} alt="Geoclaims" />
          </Fab>
        </Link>
      </div>
    </div>
  )
}

import { useEffect } from 'react'
import Badge from '@material-ui/core/Badge'
import Tooltip from '@material-ui/core/Tooltip'
import Shield from '@material-ui/icons/VerifiedUser'
import { useUser, useUserInfo } from '../../context/user'
import { ProfileImage } from './ProfileImage'
import { showEditAddress } from './AddressForm'
import { EditInline, EditInApp } from '../Edit'
import cx from 'classnames'
import { PlanIcon } from '../Plans'
import { swalConfirmAppLink } from '../Alert'
import { useMySubscription } from '../Subscriptions/mySubscription'
import { ResetPassword } from './ResetPassword'

export const LogoutButton = ({ className }) => {
  const { onLogout, user } = useUser()
  return user ? (
    <button
      onClick={onLogout}
      className={cx('btn btn-outline-danger', className)}
    >
      <span className="d-none d-md-inline">Logout</span>{' '}
      <i className="fa fa-sign-out-alt" />
    </button>
  ) : (
    <></>
  )
}

export const ActiveSubscriptionIcon = () => {
  const { userInfo } = useUserInfo()
  return (
    <PlanIcon
      className="ml-1"
      plan={userInfo?.activeSubscription ?? 'Basic'}
      style={{ height: 18, transform: `translateY(-3px)` }}
    />
  )
}

export const UserInfoDisplay = ({ children, resetPassword }) => {
  const { user } = useUser()
  const { userInfo, activeSubscription, isFHR, inTrial } = useMySubscription()
  const onSaveDisplayName = async (displayName) => {
    await user.updateProfile({ displayName })
  }
  const onSaveEmail = async (email) => {
    await user.updateEmail(email)
  }
  return user ? (
    <div className="offset-md-4">
      <div className="d-flex">
        <Badge
          invisible={!userInfo || !userInfo.kycComplete}
          color="secondary"
          style={{ alignSelf: 'flex-start' }}
          classes={{
            badge: 'profile-badge',
          }}
          badgeContent={
            <Tooltip title="Identity verified">
              <Shield />
            </Tooltip>
          }
        >
          <ProfileImage />
        </Badge>
        <div className="d-flex flex-column justify-content-center flex-grow-1 ml-4">
          <h3 className="mt-1 mb-0">
            <EditInApp
              className="text-white"
              initial={userInfo?.username || ''}
              placeholder={'User Name'}
              onEdit={() => {
                swalConfirmAppLink({
                  link: '/account',
                  text: `In the app, tap the "Username" field to update it.`,
                })
              }}
            />
          </h3>
          <div className="mb-2">
            <EditInApp
              className="text-white"
              initial={user.email || ''}
              placeholder="Email"
              onEdit={() => {
                swalConfirmAppLink({
                  link: '/account',
                  text: `In the app, tap the "Email" field to update it.`,
                })
              }}
            />
          </div>
          {resetPassword ? <ResetPassword /> : null}
          <div>
            {userInfo ? (
              <>
                {/* <p className="m-0">
                  <EditInApp
                    className="text-white"
                    initial={userInfo?.shipStreetAddress?.slice(0, 20) || ''}
                    placeholder={<strong>Address:</strong>}
                    onEdit={() => {
                      swalConfirmAppLink({
                        link: '/account',
                        html: `<ol class="text-left">
                        <li>In the app, tap the "Account Details" button
                        <li>Then tap on the Shipping Address input field to update it.</li>
                        </ol>
                        `,
                      })
                    }}
                  /> */}
                {/* <span
                    onClick={() => showEditAddress({ userInfo })}
                    className="text-underline-hover cursor-pointer"
                  >
                    Edit
                  </span> */}
                {/* </p> */}
                {/* <p className="m-0">{userInfo.shipStreetAddress}</p>
                <p className="m-0">{userInfo.shipStreetAddress2}</p>
                <p className="m-0">
                  {userInfo.shipCity
                    ? `${(
                        userInfo.shipCity || ''
                      ).trim()}, ${userInfo.shipState ||
                        ''} ${userInfo.shipZipcode || ''}`
                    : ''}
                </p>
                <p className="m-0">{userInfo.shipCountry}</p>
                <p className="m-0">{userInfo.shipPhoneNumber}</p> */}
              </>
            ) : null}
          </div>
          <div>
            {userInfo ? (
              <>
                <strong>Plan:</strong> {activeSubscription || 'Basic'}{' '}
                {isFHR ? 'FHR ' : ' '} {inTrial ? ' (Trial)' : ''}
                <ActiveSubscriptionIcon />
              </>
            ) : (
              ''
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  )
}

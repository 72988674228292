import { useMemo, useEffect } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { lightTheme } from '../../context/page'
import Box from '@material-ui/core/Box'
import UserInfoForm from '../User/InfoForm'
import AddressForm from '../User/AddressForm'
import CreditCardForm from './CreditCardForm'
import { PaymentTokenProvider, useCheckout } from './context'
import Footer from './Footer'

export { BuyNowButton } from './BuyNow'

export default ({
  onDisplay,
  onCustomerInfoComplete,
  onShippingInfoComplete,
  onPaymentInfoComplete,
  onSuccess,
  onCancel,
  children,
}) => {
  useEffect(() => {
    if (onDisplay) {
      onDisplay()
    }
  }, [])
  return (
    <MuiThemeProvider theme={lightTheme}>
      <Box color="#000">
        <PaymentTokenProvider
          onSuccess={onSuccess}
          onCustomerInfoComplete={onCustomerInfoComplete}
          onShippingInfoComplete={onShippingInfoComplete}
          onPaymentInfoComplete={onPaymentInfoComplete}
        >
          <UserInfoStep label="Personal Info" onCancel={onCancel} />
          <AddressStep label="Address Info" />
          <CreditCardStep label="Billing Info">{children}</CreditCardStep>
        </PaymentTokenProvider>
      </Box>
    </MuiThemeProvider>
  )
}

const UserInfoStep = ({ onCancel }) => {
  const { setInfoData, infoData } = useCheckout()
  const id = 'info-form'
  return (
    <>
      <UserInfoForm id={id} onSubmit={setInfoData} initial={infoData} />
      <Footer id={id} onCancel={onCancel} />
    </>
  )
}

const AddressStep = () => {
  const { setAddressData, addressData, goBack } = useCheckout()
  const initial = useMemo(() => addressData || { same: true }, [addressData])
  const id = 'address-form'
  return (
    <>
      <AddressForm id={id} onSubmit={setAddressData} initial={initial} />
      <Footer id={id} onCancel={goBack} />
    </>
  )
}

const CreditCardStep = ({ children }) => {
  const { setCreditCardData, creditCardData, goBack } = useCheckout()
  const initial = useMemo(
    () => ({ method: 'payment-card', ...creditCardData }),
    [creditCardData],
  )
  const id = 'credit-card-form'
  return (
    <>
      <CreditCardForm id={id} onSubmit={setCreditCardData} initial={initial}>
        {children}
      </CreditCardForm>
      <Footer id={id} onCancel={goBack} />
    </>
  )
}

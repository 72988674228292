import { FC } from 'react'
import { setContext } from 'apollo-link-context'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  concat,
  HttpLink,
} from '@apollo/client'
import { coinSurveysUri } from '@coin-microservices/coin-surveys-react-hooks'
import firebase from 'firebase/app'
import 'firebase/auth'

const authLink: any = setContext(async (op, { headers }) => {
  const user = firebase.auth().currentUser
  const token = user ? await user.getIdToken() : ''
  return {
    headers: {
      ...headers,
      'X-Auth-Token': token,
    },
  }
})

const surveysClient = new ApolloClient({
  link: concat(authLink, new HttpLink({ uri: coinSurveysUri })),
  cache: new InMemoryCache(),
})

export const SurveysProvider: FC = ({ children }) => {
  return <ApolloProvider client={surveysClient}>{children}</ApolloProvider>
}

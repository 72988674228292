import React from 'react'
import { trackReactivateEvent, trackWarning } from '../../utils/events'
import {
  swalConfirm,
  swalLoader,
  swalSuccess,
  swalError,
} from '../../components/Alert'
import {
  useReactivateSubscriptionMutation,
  RebillySubscription,
  AccountDocument,
} from '@xyo-network/api-checkout-apollo'
import { parseError } from '../../utils/error'
import { DangerBtn } from '../Buttons'

export default ({
  subscription,
}: {
  subscription: RebillySubscription | null
}) => {
  const [mutate] = useReactivateSubscriptionMutation({
    variables: { subscriptionId: subscription ? subscription.id : '' },
    refetchQueries: [{ query: AccountDocument }],
  })
  const reactivateSubscription = async () => {
    if (!mutate || !subscription) return
    const nextBillingDate = subscription.renewalTime
      ? new Date(subscription.renewalTime).toLocaleString()
      : ''
    const { value } = await swalConfirm(
      nextBillingDate ? `You will be charged on ${nextBillingDate}.` : '',
    )
    if (!value) return trackReactivateEvent('canceled', {})
    try {
      trackReactivateEvent('confirmed', {})
      swalLoader(<p>Reactivating Subscription...</p>)
      await mutate()
      swalSuccess('Glad to see you came back!')
      trackReactivateEvent('success', {})
    } catch (e) {
      trackWarning('Reactivate subscription error', e.message)
      trackReactivateEvent('error', { value: e.message })
      swalError(parseError(e.message))
    }
  }

  return (
    subscription && (
      <DangerBtn onClick={reactivateSubscription}>
        Reactivate Subscription
      </DangerBtn>
    )
  )
}

import { useEffect, useState, useMemo, useRef } from 'react'
import {
  featureCollection,
  quadkeyType,
  collectionPopupHtml,
  claimPopupHtml,
  dropPopupHtml,
} from '../../utils/map'
import { origin } from 'quadkeytools'
import staticService from '@mapbox/mapbox-sdk/services/static'

const mapboxClient = staticService({
  accessToken: process.env.REACT_APP_MAPBOX,
})

export const QuadkeyMap = ({ quadkey, style }) => {
  const imgSrc = useMemo(() => {
    const { lng, lat } = origin(quadkey)
    const geoJson = featureCollection(quadkeyType)([
      { quadkey, 'fill-opacity': 0.7, fill: '#5D3A7B' },
    ])
    return `${mapboxClient
      .getStaticImage({
        ownerId: 'mapbox',
        styleId: 'dark-v10',
        width: 300,
        height: 300,
        position: {
          zoom: quadkey.length - 2,
          coordinates: [lng, lat],
        },
        overlays: [{ geoJson }],
      })
      .url()}`
  }, [quadkey])
  return (
    <div
      style={{
        ...style,
        backgroundImage: `url("${imgSrc}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    ></div>
  )
}

export const BoundsMap = ({ coordinates, style }) => {
  const imgSrc = useMemo(() => {
    const geoJson = featureCollection(({ coordinates, ...properties }) => ({
      type: 'Feature',
      properties,
      geometry: {
        type: 'Polygon',
        coordinates,
      },
    }))([{ coordinates, 'fill-opacity': 0.7, fill: '#5D3A7B' }])

    return `${mapboxClient
      .getStaticImage({
        ownerId: 'mapbox',
        styleId: 'dark-v10',
        width: 300,
        height: 300,
        position: 'auto',
        overlays: [{ geoJson }],
      })
      .url()}`
  }, [coordinates])
  return (
    <div
      style={{
        ...style,
        backgroundImage: `url("${imgSrc}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    ></div>
  )
}

export const GlobalMap = ({ style }) => {
  const imgSrc = useMemo(() => {
    return `${mapboxClient
      .getStaticImage({
        ownerId: 'mapbox',
        styleId: 'dark-v10',
        width: 300,
        height: 300,
        position: {
          zoom: 0.02,
          coordinates: [-9, -9],
        },
      })
      .url()}`
  }, [])
  return (
    <div
      style={{
        ...style,
        backgroundImage: `url("${imgSrc}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    ></div>
  )
}

export function mapBounds(coords, bounds) {
  return coords.reduce((acc, c) => {
    if (!acc) return new window.mapboxgl.LngLatBounds(c, c)
    return acc.extend(c)
  }, bounds)
}

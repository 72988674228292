import firebase from 'firebase/app'
import { utmParams } from './search'
import { GTagEcommerce, gtagCategoricalEvent, gTag } from './gtag'
import partial from 'lodash/partial'
import fetch from 'isomorphic-fetch'
import 'firebase/auth'

const ua = navigator.userAgent.toLowerCase()
const isAndroid = ua.indexOf('android') !== -1

export const trackEvent = (event, attributes) => {
  try {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event,
      ...attributes,
    })
  } catch (e) {}
}

export const trackError = (...m) => {
  try {
    window.Rollbar.error(...m)
    console.error(...m)
  } catch (e) {}
}

export const trackWarning = (...m) => {
  try {
    window.Rollbar.warning(...m)
    console.warn(...m)
  } catch (e) {}
}

export const trackInfo = (...m) => {
  try {
    console.info(...m)
    window.Rollbar.info(...m)
  } catch (e) {}
}

export const configureRollbar = (user) => {
  if (!user || !user.uid) return
  try {
    const code_version = process.env.REACT_APP_GIT_SHA
    console.log({ code_version })
    window.Rollbar.configure({
      payload: {
        person: {
          id: user.uid,
          email: user.email,
        },
        client: {
          javascript: {
            code_version,
            source_map_enabled: true,
            guess_uncaught_frames: true,
          },
        },
      },
    })
  } catch (e) {
    console.log(e)
  }
}

export const trackSurveyEvent = gtagCategoricalEvent('survey')
export const trackSurveyDialogOpenEvent = trackSurveyEvent('dialog-open')
export const trackSurveyDialogCloseEvent = trackSurveyEvent('dialog-close')
export const trackSurveyPrecisionEvent = trackSurveyEvent('precision')
export const trackSurveyPollfishEvent = trackSurveyEvent('pollfish')
export const trackSubscriptionEvent = gtagCategoricalEvent('subscription')
export const trackCancelEvent = trackSubscriptionEvent('cancel')
export const trackReactivateEvent = trackSubscriptionEvent('reactivate')
export const trackChangeEvent = trackSubscriptionEvent('change')
export const trackPayEvent = trackSubscriptionEvent('pay')

export async function appsFlyerEvent(eventName, eventValue = {}) {
  try {
    const user = firebase.auth().currentUser
    const customer_user_id = user ? user.uid : null
    if (!customer_user_id) return
    await fetch(
      `${process.env.REACT_APP_COIN_SUBSCRIPTIONS_API}/coin_subscriptions/appsflyer`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          appId: isAndroid ? 'network.xyo.coin' : 'id1450443351',
          eventName,
          customer_user_id,
          eventValue: {
            ...utmParams,
            ...eventValue,
          },
        }),
      },
    )
  } catch (e) {}
}

export function purchaseTracker(plan) {
  const value = plan.planId
  const products = [
    { id: plan.planId, name: plan.planId, price: plan.amount, quantity: 1 },
  ]
  const gtagEcommerce = new GTagEcommerce(products)
  return {
    view: () => {
      gtagEcommerce.view()
      appsFlyerEvent('product-view', {})
      gTag('event', 'transaction-view', { value })
    },
    click: () => {
      gtagEcommerce.click()
      appsFlyerEvent('product-click', {})
    },
    step: (step, option, value) => {
      gtagEcommerce.step(step, option, value)
      switch (step) {
        case 1:
          appsFlyerEvent('checkout-personal-info-complete', {})
          break
        case 2:
          appsFlyerEvent('checkout-contact-info-complete', {})
          break
        case 3:
          appsFlyerEvent('checkout-payment-info-complete', {})
          break
        default:
      }
    },
    purchase: (id, revenue) => {
      gtagEcommerce.purchase(id, revenue)
      appsFlyerEvent('product-purchase', {
        af_revenue: revenue,
      })
    },
  }
}

if (!Array.prototype.fill) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.fill = function () {
    trackWarning('Using fill prototype')
    return this
  }
}

if (!Object.entries) {
  Object.entries = function (obj) {
    return Object.keys(obj).map((k) => [k, obj[k]])
  }
}

import { useQuery, gql } from '@apollo/client'
import { checkoutClient } from '../../context/checkout'

export const useAchievementsCollected = () => {
  return useQuery(UserAchievementsCollected, {
    client: checkoutClient,
  })
}

const UserAchievementsCollected = gql`
  query UserAchievementsCollected {
    account {
      userInfo {
        ownerUID
        achievementsCollected {
          id
          achievement
          info {
            id
            achievement
            type
            rank
            group
            title
            description
            image
            icon
            imageUrl
            iconUrl
          }
          date
        }
      }
    }
  }
`

import { SheetsRegistry } from 'jss'
import {
  createMuiTheme,
  createGenerateClassName,
} from '@material-ui/core/styles'

export const backgroundColor = '#262525'
export const primaryMain = '#ee7268'
export const secondaryMain = '#34C588'
const fontFamily =
  "'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif"

export const createTheme = (type = 'dark') =>
  createMuiTheme({
    typography: {
      fontFamily,
    },
    palette: {
      type,
      background: {
        default: backgroundColor,
      },
      primary: { main: primaryMain, contrastText: '#ffffff' },
      secondary: { main: secondaryMain, contrastText: '#ffffff' },
    },
  })

export const theme = createTheme('dark')
export const lightTheme = createTheme('light')

function createPageContext() {
  return {
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry(),
    // The standard class name generator.
    generateClassName: createGenerateClassName(),
  }
}

let pageContext

export function getPageContext() {
  if (pageContext) return pageContext
  pageContext = createPageContext()
  return pageContext
}

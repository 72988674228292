import { FooterLinks } from '../Footer'
import { SuspendImage } from '../Loader/Image'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { Link as ReactLink } from 'react-router-dom'
import {
  SweepstakesEntryTermsAgreed,
  useSweepstakesOptionsLoading,
  sweepstakesEvent,
} from './context'

import page1 from './terms/page-v5-1.png'
import page2 from './terms/page-v5-2.png'
import page3 from './terms/page-v5-3.png'
import page4 from './terms/page-v5-4.png'
import page5 from './terms/page-v5-5.png'

export const SweepstakesDisclaimer = () => {
  return (
    <Typography
      style={{
        color: 'inherit',
        opacity: 0.8,
        fontWeight: 'bold',
        fontSize: 10,
        textTransform: 'uppercase',
      }}
    >
      No purchase is necessary to enter or win
      {/* COIN used for Sweepstakes entries is non-refundable */}
    </Typography>
  )
}

export const SweepstakesTermsAgreement = () => {
  const loading = useSweepstakesOptionsLoading()
  const [agreed, setAgreed] = SweepstakesEntryTermsAgreed.useContext()
  const handleChange = (ev: any) => {
    const checked = ev.target.checked
    sweepstakesEvent('agreeToTerms', 'toggle', { value: checked })
    setAgreed(checked)
  }
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <FormControlLabel
        disabled={loading}
        classes={{ root: 'mb-0', label: 'line-height-1' }}
        control={
          <Checkbox
            checked={agreed}
            onChange={handleChange}
            name="terms_agreement"
            color="secondary"
          />
        }
        label={
          <Typography variant="caption" style={{ fontSize: 10, lineHeight: 1 }}>
            I Agree to the{' '}
            <ReactLink
              to="/sweepstakes-terms"
              style={{ textDecoration: 'underline', color: 'inherit' }}
            >
              Terms and Conditions
            </ReactLink>
          </Typography>
        }
      />
    </Box>
  )
}

export const SweepstakesTerms = () => {
  return (
    <>
      <div className="text-white pb-40vh">
        <SuspendImage src={page1} className="w-100" />
        <SuspendImage src={page2} className="w-100" />
        <SuspendImage src={page3} className="w-100" />
        <SuspendImage src={page4} className="w-100" />
        <SuspendImage src={page5} className="w-100" />
        <Box py={4}>
          <FooterLinks />
        </Box>
      </div>
    </>
  )
}

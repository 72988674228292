import { createContext, useContext, useReducer, useEffect, useRef } from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import {
  initialState,
  checkoutReducer,
  checkoutActions,
  toCheckoutData,
} from './reducer'
import { SlideInLeft, SlideInRight } from '../../components/Animate'
import {
  checkout,
  createPaymentToken,
  addPaymentCard,
} from '../../context/checkout'
import { toFirstAndLastName } from '../../utils/name'
import { localJSONStore } from '../../utils/localStore'
import { bindActionCreators } from 'redux'
import { swalError, swalConfirm, swalLoader } from '../Alert'
import {
  // trackEvent,
  trackWarning,
  trackError,
  appsFlyerEvent,
} from '../../utils/events'
// import { gtag } from '../../utils/gtag'
import { parseError } from '../../utils/error'
import StepForm from '../Form/Steps'
import swal from 'sweetalert2'
import invoke from 'lodash/invoke'
import get from 'lodash/get'
import pick from 'lodash/pick'
import firebase from 'firebase/app'
import 'firebase/auth'

export const CheckoutContext = createContext(initialState)
const infoStore = localJSONStore('customerData')
const addressStore = localJSONStore('addressData')
const creditCardStore = localJSONStore('creditCardData')

export const useCheckout = () => useContext(CheckoutContext)

export const PaymentTokenProvider = ({ onSuccess, children, ...props }) => {
  const [state, dispatch] = useReducer(checkoutReducer, initialState)
  const dispatcher = useRef(dispatch)
  const actions = bindActionCreators(checkoutActions, dispatcher.current)

  const setInfoData = async (infoData) => {
    try {
      actions.setLoading()
      infoStore.to(infoData)
      invoke(props, 'onCustomerInfoComplete', { infoData })
      actions.setAllData({ infoData, step: 1, loading: false })
    } catch (e) {
      actions.setError(e.message)
    }
  }

  const setAddressData = async (addressData) => {
    try {
      actions.setLoading()
      addressStore.to(addressData)
      const { infoData } = state
      const { same, shipping } = addressData
      const billing = same ? shipping : addressData.billing
      actions.setAllData({ addressData, step: 2, loading: false })
      invoke(props, 'onShippingInfoComplete', {
        infoData,
        billing,
        shipping,
      })
    } catch (e) {
      actions.setError(e.message)
    }
  }

  const setCreditCardData = async (creditCardData) => {
    try {
      actions.setLoading()
      creditCardStore.to(
        pick(creditCardData, ['method', 'ccNumber', 'ccExpMonth', 'ccExpYear']),
      )
      const { addressData, infoData } = state
      const { same, shipping } = addressData
      const billing = same ? shipping : addressData.billing
      const emails = [
        {
          label: 'main',
          value: infoData.email,
          primary: true,
        },
      ]
      const phoneNumbers = infoData.phone
        ? [
            {
              label: 'main',
              value: infoData.phone,
              primary: true,
            },
          ]
        : []
      invoke(props, 'onPaymentInfoComplete', {
        infoData,
        shipping,
        billing,
        creditCardData,
      })
      // trackEvent(
      //   'order-data-complete',
      //   toCheckoutData({ infoData, addressData, creditCardData }),
      // )
      const tokenData = await createPaymentToken(creditCardData, {
        ...billing,
        ...infoData,
        emails,
        phoneNumbers,
      })
      if (!tokenData) throw new Error('Error creating payment token')
      const paymentCardId = await addPaymentCard(tokenData.id, infoData)
      if (!paymentCardId) throw new Error('Error adding payment')
      await onSuccess(paymentCardId, {
        creditCardData,
        infoData,
        shipping,
        billing,
      })
    } catch (e) {
      trackError('setCreditCardData error', e)
      actions.setError(parseError(e.message))
    }
  }

  useEffect(() => {
    try {
      const user = firebase.auth().currentUser
      const info = infoStore.from()
      const infoData = user
        ? {
            email: user.email || '',
            phone: info ? info.phone : '',
            ...toFirstAndLastName(user.displayName),
          }
        : {}
      const addressData = addressStore.from()
      const creditCardData = creditCardStore.from()
      actions.setAllData({ infoData, addressData, creditCardData })
    } catch (e) {}
  }, [])

  useEffect(() => {
    return () => {
      dispatcher.current = () => {
        console.warn('Tried to dispatch after dismount')
      }
    }
  }, [])

  return (
    <CheckoutContext.Provider
      value={{
        ...state,
        ...actions,
        setInfoData,
        setAddressData,
        setCreditCardData,
      }}
    >
      <StepForm prev={state.prev} step={state.step}>
        {children}
      </StepForm>
    </CheckoutContext.Provider>
  )
}

import { Component, createContext, Fragment, cloneElement } from 'react'
import { createPortal } from 'react-dom'
import cx from 'classnames'

const context = createContext('modal-container')

export const Provider = context.Provider
export const Consumer = context.Consumer

export const ModalContent = ({
  title,
  children,
  footer,
  onClose,
  className,
}) => (
  <div className={cx('modal-content', className)}>
    {title && (
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        {onClose && (
          <button
            onClick={onClose}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>
    )}
    {children}
    {footer && <div className="modal-footer">{footer}</div>}
  </div>
)

export default class ModalPortal extends Component {
  stopPropagation = (ev) => {
    ev.stopPropagation()
  }

  touchedDialog = (ev) => {
    ev.stopPropagation()
    this.touched = false
  }

  touchedModal = (ev) => {
    ev.stopPropagation()
    this.touched = true
  }

  depressed = (ev) => {
    ev.stopPropagation()
    if (this.touched && this.props.onClose) {
      this.props.onClose()
    }
  }

  renderModal = () => {
    const { open, children, onClose, isScrolling } = this.props
    return (
      <Fragment>
        <div
          onMouseDown={this.touchedModal}
          onMouseUp={this.depressed}
          className={cx('modal fade', {
            show: open,
            'scroll-y': isScrolling,
          })}
          style={{ display: open ? 'block' : '' }}
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            onClick={this.stopPropagation}
            onMouseDown={this.touchedDialog}
            onTouchStart={this.touchedDialog}
          >
            {cloneElement(children, { onClose })}
          </div>
        </div>
        {open && (
          <div
            className={cx('modal-backdrop', { show: open })}
            onClick={onClose}
          />
        )}
      </Fragment>
    )
  }

  renderPortal = (id) => {
    const node = document.getElementById(id)
    if (!node) {
      throw new Error(`No element found with id: ${id}`)
    } else {
      return createPortal(this.renderModal(), node)
    }
  }

  render() {
    return <Consumer>{this.renderPortal}</Consumer>
  }
}

import Dialog from '@material-ui/core/Dialog'
import React from 'react'
import {
  SweepstakesOptionsContext,
  SweepstakesSize,
  SweepstakesThemeProvider,
  SweepstakesEntryDialogOpen,
  SweepstakesEntrySelected,
} from './context'
import { SweepstakesEntryForm } from './Form'

export const EntryDialog = () => {
  const [open, setOpen] = SweepstakesEntryDialogOpen.useContext()
  const [selected] = SweepstakesEntrySelected.useContext()

  return (
    <SweepstakesThemeProvider>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xs"
        classes={{ paper: 'w-100' }}
        keepMounted
      >
        <SweepstakesSize.Provider initial="">
          <SweepstakesOptionsContext.Provider value={selected}>
            <SweepstakesEntryForm />
          </SweepstakesOptionsContext.Provider>
        </SweepstakesSize.Provider>
      </Dialog>
    </SweepstakesThemeProvider>
  )
}

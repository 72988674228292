// import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Skeleton from '@material-ui/lab/Skeleton'
import Slider from '@material-ui/core/Slider'
import { useEffect, useState } from 'react'
import { TokenBalanceRow } from './PositionItem'
import Typography from '@material-ui/core/Typography'
import { formatName, formatSymbol, getLogo } from './utils'
import { Position, PositionPrice, Token } from './types'

export const UniswapV3PositionDetails = ({
  position,
}: {
  position?: Position | null
}) => {
  return (
    <Grid container>
      <Box p={2} pr={1}>
        <PositionImage tokenURI={position?.tokenURI} width="100%" />
      </Box>
      <Box flexGrow={1} display="flex" flexDirection="column" p={2}>
        <Box p={1}>
          <Paper elevation={0} style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}>
            <Box p={1}>
              <Typography>
                <strong>Liquidity</strong>
              </Typography>
              <TokenBalanceRow
                amount={position?.amount0}
                token={position?.token0}
              />
              <TokenBalanceRow
                amount={position?.amount1}
                token={position?.token1}
              />
            </Box>
          </Paper>
        </Box>
        <Box p={1}>
          <Paper elevation={0} style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}>
            <Box p={1}>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography>
                  <strong>Price range</strong>
                </Typography>
                <Box flexGrow={1} />
                <TokenAvatar token={position?.token0} />
                <TokenSymbol token={position?.token0} />/
                <TokenSymbol token={position?.token1} />
              </Box>
              <PositionPriceRange price={position?.price0} />
            </Box>
          </Paper>
        </Box>
        <Box p={1}>
          <Paper elevation={0} style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}>
            <Box p={1}>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography>
                  <strong>Price range</strong>
                </Typography>
                <Box flexGrow={1} />
                <TokenAvatar token={position?.token1} />
                <TokenSymbol token={position?.token1} />/
                <TokenSymbol token={position?.token0} />
              </Box>
              <PositionPriceRange price={position?.price1} />
            </Box>
          </Paper>
        </Box>
        <Box flexGrow={1} />
        {/* <Box p={1}>
          <Button fullWidth variant="contained" color="primary">
            <strong>Pool Info</strong>
          </Button>
        </Box> */}
        <Box p={1}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            // color="secondary"
            component="a"
            target="_blank"
            rel="noopener norefferer"
            href={`https://app.uniswap.org/#/pool/${position?.tokenId}`}
          >
            <strong>Manage</strong>
          </Button>
        </Box>
      </Box>
    </Grid>
  )
}

const STARTS_WITH = 'data:application/json;base64,'

const PositionImage = ({
  tokenURI,
  height,
  width,
}: {
  tokenURI?: string | null
  height?: string | number
  width?: string | number
}) => {
  const [src, setSrc] = useState('')
  useEffect(() => {
    if (tokenURI) {
      const result = JSON.parse(atob(tokenURI.slice(STARTS_WITH.length)))
      setSrc(result?.image)
    }
  }, [tokenURI])
  return src ? (
    <img src={src} style={{ width, height, objectFit: 'contain' }} alt="" />
  ) : (
    <Skeleton width={width} height={height} />
  )
}

const TokenAvatar = ({ token }: { amount?: string; token?: Token }) => {
  return token ? (
    <img
      style={{ height: '1em', marginRight: 8 }}
      alt={formatName(token)}
      src={getLogo(token)}
    />
  ) : (
    <Skeleton
      variant="circle"
      height={12}
      width={12}
      style={{ marginRight: 8 }}
    />
  )
}

const PositionSlider = ({ price }: { price?: PositionPrice | null }) => {
  const marks = [Number(price?.lower), Number(price?.upper)]
  const value = Number(price?.current)
  // const diff = Math.max(marks[0], Math.abs(marks[1] - marks[0]))
  return (
    <Slider
      disabled
      valueLabelDisplay="auto"
      min={marks[0] - marks[0] / 1.5}
      max={marks[1] + marks[0] / 1.5}
      value={value}
      // getAriaValueText={valuetext}
      aria-labelledby="discrete-slider-always"
      marks={marks.map((value) => ({ value, label: `${value}` }))}
    />
  )
}

const PositionPriceRange = ({ price }: { price?: PositionPrice }) => {
  return (
    <Box>
      <PositionSlider price={price} />
      {/* <Box display="flex" mb={1}>
        <Box mr={0.5} flex={1}>
          <PriceBox amount={price?.lower} label="Min" />
        </Box>
        <Box ml={0.5} flex={1}>
          <PriceBox amount={price?.upper} label="Max" />
        </Box>
      </Box> */}
      <Box flex={1}>
        <PriceBox amount={price?.current} label="Current" />
      </Box>
    </Box>
  )
}

// const TokenName = ({ token }: { amount?: string; token?: Token }) => {
//   return (
//     <Typography>
//       {token ? formatName(token) : <Skeleton width={120} />}
//     </Typography>
//   )
// }

const TokenSymbol = ({ token }: { token?: Token }) => {
  return (
    <Typography>
      {token ? formatSymbol(token) : <Skeleton width={120} />}
    </Typography>
  )
}

const PriceBox = ({ amount, label }: { amount?: string; label?: string }) => {
  return (
    <Box
      p={1}
      flex={1}
      style={{ backgroundColor: 'rgba(0,0,0,0.2)', textAlign: 'center' }}
    >
      {label}: {amount ? amount : <Skeleton width={60} />}
    </Box>
  )
}

import { useState } from 'react'
import Popover from '@material-ui/core/Popover'
import over from 'lodash/over'

export const BuyNowButton = ({
  children,
  renderPopover,
  onClick,
  onRenderPopover,
}) => {
  const [open, setOpen] = useState(null)
  const handlePopoverOpen = (ev) => {
    ev.stopPropagation()
    setOpen(ev.currentTarget)
    if (onRenderPopover) onRenderPopover()
  }
  const handlePopoverClose = () => {
    setOpen(null)
  }
  return (
    <div className="d-inline-block position-relative">
      <div className="d-inline-block" onClick={handlePopoverOpen}>
        {children(onClick)}
      </div>
      {renderPopover && (
        <Popover
          open={!!open}
          anchorEl={open}
          modifiers={{ flip: { enabled: false } }}
          classes={{ paper: 'bg-light mt-2 mb-5 p-2 rounded z-1' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={handlePopoverClose}
          onClick={handlePopoverClose}
        >
          {renderPopover(onClick)}
        </Popover>
      )}
    </div>
  )
}

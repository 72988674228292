import gql from 'graphql-tag'

export const UPGRADE_TYPE_QUERY = gql`
query UpgradeType {
  upgradeType @client
}
`

export const UPGRADE_TYPE_MUTATION = gql`
mutation SetUpgradeType($upgradeType: String!) {
  setUpgradeType(upgradeType: $upgradeType) @client
}
`

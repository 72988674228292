import Box from '@material-ui/core/Box'
import TablePagination from '@material-ui/core/TablePagination'
import IconButton from '@material-ui/core/IconButton'
import RefreshIcon from '@material-ui/icons/Refresh'
import PollIcon from '@material-ui/icons/Poll'
import times from 'lodash/times'
import { useMemo, useState, useEffect } from 'react'
import { LoadingButton } from '../components/Buttons'
import { useScrollContext } from '../components/Scroll'
import { TableCursorActions } from '../components/Table/Pagination'
import { View } from '../components/Footer'
import Skeleton from '@material-ui/lab/Skeleton'
import { numberFormat } from '../utils/number'
import { usePollFish } from '../context/pollfish'
import { PlanIcon } from '../components/Plans'
import lowerCase from 'lodash/lowerCase'
import capitalize from 'lodash/capitalize'
import { localJSONStore } from '../utils/localStore'
import { swalWarning } from '../components/Alert'
import { SurveysProvider } from '../context/surveys'
import { useListSurveysQuery } from '@coin-microservices/coin-surveys-react-hooks'
import { useSurveyProvider, useDisplaySuccess } from '../components/SurveyNav'
import { trackSurveyDialogOpenEvent } from '../utils/events'

const surveyDisclaimer = localJSONStore('survey-disclaimer')

const PollHistoryList = () => {
  const pollFish = usePollFish()
  const scrollElement = useScrollContext()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [cursorByPage, setCusorByPage] = useState({ 1: {} })
  const variables = useMemo(
    () => ({ ...cursorByPage[page], limit: perPage, nonce: pollFish.nonce }),
    [page, perPage, pollFish.nonce, cursorByPage],
  )
  const { data, loading, refetch } = useListSurveysQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  })
  const { remoteRowCount, rowData } = useMemo(() => {
    const remoteRowCount = data?.listSurveys?.total ?? 0
    const rowData = data?.listSurveys?.data ?? []
    return { remoteRowCount, rowData }
  }, [data])

  console.log(pollFish, remoteRowCount, rowData)

  const changePage = (p) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    const cursorNext = data?.listSurveys?.cursorNext
    if (!cursorByPage[p] && cursorNext) {
      setCusorByPage((cursor) => ({ ...cursor, [p]: { cursorNext } }))
    }
    setPage(p)
  }
  const handleChangePage = (event, newPage) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }
  const showDisclaimer = async () => {
    surveyDisclaimer.to({ time: Date.now() })
    await swalWarning(
      'Warning',
      'Be sure to answer the surveys truthfully. If the Pollfish system detects you are randomly answering the questions, you may not get rewarded for your time or may even be rejected from their system and not be allowed to take any further surveys.',
    )
  }

  useEffect(() => {
    const seen = surveyDisclaimer.from()
    if (seen && seen.time) {
      // pollFish.initialize()
      return
    }
    showDisclaimer()
      // .then(() => pollFish.initialize())
      .catch((e) => console.log(e))
  }, [])

  useDisplaySuccess(refetch)

  return (
    <View>
      <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8 px-0">
        <div className="d-flex align-items-center text-white px-2 px-md-0">
          <h1>Surveys Attempted</h1>
          <div className="flex-grow-1" />
          <div className="d-none d-md-block">
            {(rowData || []).length ? <PollFishButton /> : null}
          </div>
          <IconButton
            disabled={loading}
            onClick={() => refetch({ variables })}
            className="ml-1"
          >
            <RefreshIcon />
          </IconButton>
        </div>
        <div className="position-relative list-group text-dark">
          {loading ? (
            times(perPage).map((a, i) => (
              <SurveyCompleted key={i} loading={true} />
            ))
          ) : (rowData || []).length ? (
            (rowData || []).map((survey, i) => (
              <SurveyCompleted key={survey.id} {...survey} />
            ))
          ) : (
            <NoSurveysCompleted />
          )}
          {remoteRowCount && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25]}
              count={remoteRowCount}
              rowsPerPage={perPage}
              page={page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TableCursorActions}
            ></TablePagination>
          )}
        </div>
      </div>
    </View>
  )
}

const SurveyCompleted = ({
  plan,
  ug,
  panelist_id,
  created,
  date,
  reward_name,
  reward_value,
  length_of_interview,
  reward,
  rewardType,
  status,
  duplicate,
  reason,
  loading,
}) => {
  return (
    <div className="list-group-item d-flex align-items-center justify-content-between rounded-0-sm">
      {loading ? (
        <Skeleton
          variant="rect"
          width={80}
          height={80}
          className="skeleton"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
        />
      ) : plan ? (
        <Avatar name={plan}>
          <PlanIcon plan={plan || 'basic'} height={28} />
        </Avatar>
      ) : ug ? (
        <Avatar name="precision">
          <PollIcon />
        </Avatar>
      ) : panelist_id ? (
        <Avatar name="feedback">
          <PollIcon />
        </Avatar>
      ) : null}
      <div />
      <div className="ml-4 text-right">
        {loading ? (
          <>
            <Skeleton
              variant="text"
              width={80}
              className="skeleton"
              classes={{ root: 'ml-auto' }}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
            />
            <Skeleton
              variant="text"
              width={60}
              className="skeleton"
              classes={{ root: 'ml-auto' }}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
            <Skeleton
              variant="text"
              width={60}
              className="skeleton"
              classes={{ root: 'ml-auto' }}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
          </>
        ) : (
          <>
            <h4 className="mb-0">
              Earned:{' '}
              {length_of_interview
                ? numberFormat(requestReward({ status, length_of_interview }))
                : reward
                ? numberFormat(reward)
                : duplicate || status !== 'eligible'
                ? numberFormat(0)
                : numberFormat(reward_value || 0)}
            </h4>
            {reward_name ? <p className="mb-0">{reward_name}</p> : null}
            {reason ? (
              <p className="mb-0 text-danger">
                {capitalize(lowerCase(reason))}
              </p>
            ) : (
              ''
            )}
            {rewardType ? (
              <>
                <p className="mb-0">COIN</p>
                <p className="mb-0">
                  {capitalize(
                    lowerCase(rewardType.replace('profiler', 'profile')),
                  )}
                </p>
              </>
            ) : (
              ''
            )}
            <p className="mb-0 text-muted">
              <small>{new Date(created || date).toLocaleDateString()}</small>
            </p>
          </>
        )}
      </div>
    </div>
  )
}

const NoSurveysCompleted = () => {
  return (
    <div className="list-group-item text-center text-dark py-5 rounded-0-sm">
      <h4 className="mb-2">No Previous Surveys Found</h4>
      <PollFishButton />
      <p>
        Read more about surveys{' '}
        <a
          href="https://support.coinapp.co/hc/en-us/articles/360042771634"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>
    </div>
  )
}

const PollFishButton = () => {
  const pollFish = usePollFish()
  const setOpen = useSurveyProvider()[1]
  const setDialogOpen = () => {
    setOpen(true)
    trackSurveyDialogOpenEvent('button', {})
  }
  return (
    <Box display="flex" justifyContent="center">
      <LoadingButton
        onClick={setDialogOpen}
        // pollFish.initialize().then(() => pollFish.showFullSurvey())
        variant="contained"
        color="primary"
        loading={pollFish.initializing}
      >
        Earn COIN Taking Surveys
      </LoadingButton>
    </Box>
  )
}

const PollFishPage = () => {
  return (
    <SurveysProvider>
      <PollHistoryList />
    </SurveysProvider>
  )
}

const COIN = 0.0005
const SPLIT = 0.5

function requestReward(request) {
  return rewardRevenue(request) / (COIN / SPLIT)
}

function loiRevenue(loi) {
  if (loi > 10) return 1.5
  if (loi > 5) return 1
  if (loi > 0) return 0.6
  return 0
}

function rewardRevenue(request) {
  switch (request.status) {
    case 'complete':
      return loiRevenue(request.length_of_interview)
    case 'terminate':
      return 0.05
    default:
      return 0
  }
}

const Avatar = ({ children, name }) => (
  <div
    className="bg-info rounded overflow-hidden text-center text-white d-flex align-items-center flex-column"
    style={{ height: 80, width: 80, position: 'relative' }}
  >
    <div className="d-flex flex-grow-1 align-items-center justify-content-center">
      {children}
    </div>
    <p
      className="mb-0 w-100 text-uppercase"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}
    >
      {name || 'basic'}
    </p>
  </div>
)

export default PollFishPage

import { useEffect, useState } from 'react'
import { EditIconButton, SaveIconButton, CancelIconButton } from '../Buttons'
import CircularProgress from '@material-ui/core/CircularProgress'
import debounce from 'lodash/debounce'
import { swalError } from '../Alert'
import cx from 'classnames'

export const EditInApp = ({ placeholder, initial, onEdit, className }) => {
  return (
    <>
      <span onClick={() => onEdit()}>
        {initial || <span className="text-muted">{placeholder}</span> || ''}
      </span>
      <EditIconButton
        className={cx('p-0 ml-1', className)}
        onClick={() => onEdit()}
        sm={true}
      />
    </>
  )
}

export default ({ placeholder, initial, onSave, className }) => {
  const [value, setValue] = useState(initial || '')
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  useEffect(() => {
    setValue(initial)
  }, [initial])
  const handleChange = (ev) => {
    if (!saving) {
      setValue(ev.target.value)
    }
  }
  const handleEnter = (ev) => {
    if (!saving && ev.key === 'Enter') {
      handleSave()
    }
  }
  const handleSave = async () => {
    try {
      setSaving(true)
      await onSave(value)
      setSaving(false)
    } catch (e) {
      setValue(initial)
      setSaving(false)
      swalError(e.message)
    }
  }
  const handleCancel = () => {
    setEditing(false)
    setValue(initial || '')
  }
  return editing ? (
    <>
      <input
        className={cx(
          'form-control form-control-sm d-inline w-inherit bg-transparent border-0 outline-0',
          className,
        )}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyPress={handleEnter}
        onBlur={debounce(() => setEditing(false), 200)}
        autoFocus={true}
      />
      {saving ? (
        <span className="spinner-border ml-1" />
      ) : (
        <>
          <CancelIconButton
            className="btn btn-sm btn-outline-danger ml-1"
            onClick={handleCancel}
          />
          <SaveIconButton
            className="btn btn-sm btn-outline-success ml-1"
            onClick={handleSave}
          />
        </>
      )}
    </>
  ) : (
    <>
      <span onClick={() => setEditing(true)}>
        {value || <span className="text-muted">{placeholder}</span> || ''}
      </span>
      <EditIconButton
        className={cx('p-0 ml-1', className)}
        onClick={() => setEditing(true)}
        sm={true}
      />
    </>
  )
}

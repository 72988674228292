import { useState } from 'react'
import { Link } from 'react-router-dom'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import { useUser } from '../../context/user'
import AxeIcon from '../Logo/Axe'
import DropIcon from '../Logo/Drop'
import ClaimIcon from '../Logo/Claim'
import dropsImage from '../../assets/drops.png'
import claimsImage from '../../assets/geoclaim.png'
import axeImage from '../../assets/pickaxe.png'
import adPlay from '../../assets/adPlay.png'
import bigWinStars from '../../assets/bigWinStars@3x.png'
import survey from '../Rewards/surveyGreen.png'
import visits from '../Rewards/visitsGreen.png'
import scans from '../Rewards/scansGreen.png'
import verifications from '../../assets/verificationsFull.png'
import sweepstakes from '../../assets/sweepstakesIcon.png'
import botImage from '../../assets/bot.png'
import { useSupportCategories } from '../../context/support'
import { ScrollY } from '../Scroll'
import cx from 'classnames'

export default () => {
  const { user, onLogout } = useUser()
  const [open, setOpen] = useState(false)

  function handleClick(e) {
    e.stopPropagation()
    setOpen(!open)
  }

  return (
    <>
      <List>
        <ListItem
          button
          to="/account"
          component={Link}
          className="no-decoration text-white"
        >
          <i className="text-white fa fa-user mr-3" />
          <ListItemText primary="Account" className="no-decoration" />
        </ListItem>
        <ListItem
          button
          to="/redeem"
          component={Link}
          className="no-decoration text-white"
        >
          <i className="text-white fa fa-envelope-open-text mr-3" />
          <ListItemText primary="Redeem" />
        </ListItem>
        <ListItem
          button
          to="/verify"
          component={Link}
          className="no-decoration text-white"
        >
          <i className="text-white fa fa-wallet mr-3" />
          <ListItemText primary="Verify" />
        </ListItem>
        <ListItem button onClick={handleClick}>
          <i className="text-white fa fa-history mr-3" />
          <ListItemText primary="History" />
          <span className="text-white">
            {open ? <ExpandLess /> : <ExpandMore />}
          </span>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className="pl-4">
            <HistoryLinks className="text-white" />
          </div>
        </Collapse>
        <ListItem
          button
          to="/referrals"
          component={Link}
          className="no-decoration text-white"
        >
          <i className="text-white fa fa-users mr-3" />
          <ListItemText primary="Referrals" />
        </ListItem>
        <ListItem
          button
          to="/badges"
          component={Link}
          className="no-decoration text-white"
        >
          <i className="text-white fa fa-shield-alt mr-3" />
          <ListItemText primary="Badges" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          to="/faq"
          component={Link}
          className="no-decoration text-white"
        >
          <i className="text-white fa fa-user mr-3" />
          <ListItemText primary="FAQ" />
        </ListItem>
        {user && (
          <ListItem
            button
            to="/settings"
            component={Link}
            className="no-decoration text-white"
          >
            <i className="text-white fa fa-cog mr-3" />
            <ListItemText primary="Settings" />
          </ListItem>
        )}
        {user && (
          <ListItem
            button
            onClick={onLogout}
            className="no-decoration text-white"
          >
            <i className="text-white fa fa-sign-out-alt mr-3" />
            <ListItemText primary="Logout" />
          </ListItem>
        )}
      </List>
    </>
  )
}

export const HistoryLinks = ({ className }) => {
  return (
    <List disablePadding component="div" className={className}>
      <ListItem
        button
        component={Link}
        to="/geoclaims"
        className={cx('no-decoration', className)}
      >
        <img
          src={claimsImage}
          alt="Geoclaims"
          style={{ height: 18 }}
          className="mr-3"
        />
        <ListItemText primary="Geoclaims" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/geomines"
        className={cx('no-decoration', className)}
      >
        <img
          src={axeImage}
          alt="Geomines"
          style={{ height: 18 }}
          className="mr-3"
        />
        <ListItemText primary="Geomines" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/geodrops"
        className={cx('no-decoration', className)}
      >
        <img
          src={dropsImage}
          alt="Geodrops"
          style={{ height: 18 }}
          className="mr-3"
        />
        <ListItemText primary="Geodrops" color="primary" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/background"
        className={cx('no-decoration', className)}
      >
        <img
          src={botImage}
          alt="Background Rewards"
          style={{ height: 18 }}
          className="mr-3"
        />
        <ListItemText primary="Background Rewards" color="primary" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/videoads"
        className={cx('no-decoration', className)}
      >
        <img
          src={adPlay}
          alt="Ad Plays"
          style={{ height: 16 }}
          className="mr-3"
        />
        <ListItemText primary="Ad Rewards" color="primary" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/bigwins"
        className={cx('no-decoration', className)}
      >
        <img
          src={bigWinStars}
          alt="Big Geomines"
          style={{ height: 18 }}
          className="mr-3"
        />
        <ListItemText primary="Big Geomines" color="primary" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/surveys"
        className={cx('no-decoration', className)}
      >
        <img
          src={survey}
          alt="Surveys"
          style={{ height: 18 }}
          className="mr-3"
        />
        <ListItemText primary="Surveys" color="primary" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/rewarded/visits"
        className={cx('no-decoration', className)}
      >
        <img
          src={visits}
          alt="Rewarded Visits"
          style={{ height: 18 }}
          className="mr-3"
        />
        <ListItemText primary="Rewarded Visits" color="primary" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/rewarded/scans"
        className={cx('no-decoration', className)}
      >
        <img
          src={scans}
          alt="Rewarded Scans"
          style={{ height: 18 }}
          className="mr-3"
        />
        <ListItemText primary="Rewarded Scans" color="primary" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/sweepstakes/history"
        className={cx('no-decoration', className)}
      >
        <img
          src={sweepstakes}
          alt="Sweepstakes History"
          style={{ height: 18 }}
          className="mr-3"
        />
        <ListItemText primary="Sweepstakes History" color="primary" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/rewarded/verifications"
        className={cx('no-decoration', className)}
      >
        <img
          src={verifications}
          alt="Rewarded Verifications"
          style={{ height: 18 }}
          className="mr-3"
        />
        <ListItemText primary="Rewarded Verifications" color="primary" />
      </ListItem>
    </List>
  )
}

export const HelpLinks = ({ categoryId, onClick }) => {
  const { data, loading } = useSupportCategories()

  if (loading)
    return (
      <div className="text-center py-5" style={{ minWidth: 200 }}>
        <div className="spinner-border text-danger"></div>
      </div>
    )

  return (
    <ScrollY style={{ maxHeight: 340, paddingBottom: 40 }}>
      <List disablePadding component="div">
        {data.map(({ id, name, icon }) => (
          <ListItem
            button
            key={id}
            onClick={() => onClick && onClick(id)}
            selected={categoryId === id}
            className="text-dark"
          >
            <i className={`${icon} text-danger mr-3`} />
            <ListItemText primary={name} />
          </ListItem>
        ))}
      </List>
    </ScrollY>
  )
}

import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  useAccountQuery,
  findMainSubscription,
} from '@xyo-network/api-checkout-apollo'
import { useUser } from '../../context/user'
import { checkoutClient } from '../../context/checkout'
import toPairs from 'lodash/toPairs'
import get from 'lodash/get'
import moment from 'moment'

export const useMySubscription = () => {
  const user = useUser()
  const accountQuery = useAccountQuery({ client: checkoutClient })
  const { userInfo, subscription } = useMemo(() => {
    const userInfo = accountQuery.data?.account?.userInfo
    const subscriptions =
      accountQuery.data?.account?.customer?.subscriptions || []
    const subscription = findMainSubscription(subscriptions)
    return { userInfo, subscription }
  }, [accountQuery.data])
  const { activeSubscription, subscriptions, isFHR, inTrial } = useMemo(() => {
    const activeSubscription = userInfo?.activeSubscription ?? 'basic'
    const subscriptions = toPairs(userInfo?.subscriptionInfo)
      .map(([id, value]: any) => ({ id, ...value }))
      .sort((a, b) => get(b, 'startDate.seconds') - get(a, 'startDate.seconds'))
    const isFHR = subscriptions.some(
      ({ id, isActive }) => isActive && id.indexOf('fhr') === 0,
    )
    const inTrial = subscriptions.some(
      ({ isActive, inTrial }) => isActive && inTrial,
    )
    return { activeSubscription, subscriptions, isFHR, inTrial }
  }, [userInfo?.activeSubscription])
  return {
    ...accountQuery,
    ...user,
    userInfo,
    subscription,
    subscriptions,
    activeSubscription,
    isFHR,
    inTrial,
  }
}

export const useMySubscriptionMaster = () => {
  const history = useHistory()
  const mySubs = useMySubscription()
  const threeMonthsAgo = moment().subtract(3, 'months')
  const isMasterRoute = history.location.search.indexOf('master_ok') !== -1
  const startTime = moment(mySubs?.subscription?.startTime ?? '')
  const availableTime = moment(startTime).add(3, 'months')
  const isImpossibleReason =
    [
      'XYO-COIN-PRO-34-95',
      'XYO-COIN-PREMIUM-24-95',
      'XYO-COIN-PREMIUM-12-95',
      'XYO-COIN-PRO-3D-TRIAL-34-95',
      'XYO-COIN-PRO-7D-TRIAL-34-95',
    ].indexOf(mySubs?.subscription?.planId ?? '') === -1
      ? 'Current plan is not allowed to upgrade to COIN Master'
      : ''
  const isUnavailableReason = threeMonthsAgo.isBefore(startTime)
    ? `Available for upgrade ${availableTime.fromNow()}`
    : ''
  const isMasterOk = mySubs?.userInfo?.masterOk
  return {
    ...mySubs,
    isMasterOk: isMasterOk,
    isMasterRoute: isMasterRoute,
    isImpossibleReason: isImpossibleReason,
    isUnavailableReason: isUnavailableReason,
    availableTime: availableTime.fromNow(),
  }
}

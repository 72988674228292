import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import AlertTitle from '@material-ui/lab/AlertTitle'
import firebase from 'firebase/app'
import 'firebase/auth'
import { useState } from 'react'
import { parseError } from '../../utils/error'
import { useUser } from '../../context/user'
import { useSnackbar } from '../Snackables'

export const ResetPassword = () => {
  const { user } = useUser()
  const email = user?.['email'] ?? ''
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { setSnackbar } = useSnackbar()[1]
  const handleReset = async () => {
    setLoading(true)
    try {
      if (!email) throw new Error('Must have email')
      await firebase.auth().sendPasswordResetEmail(email)
      setSnackbar({
        severity: 'success',
        message: (
          <>
            <AlertTitle>Password Reset</AlertTitle> Instructions sent to <strong>{email}</strong>
          </>
        ),
        autoHideDuration: 10000,
      })
      setOpen(false)
    } catch (err) {
      setSnackbar({
        severity: 'error',
        message: parseError(err),
        autoHideDuration: 6000,
      })
    }
    setLoading(false)
  }
  return (
    <>
      <Typography>
        <Link style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>Reset Password</Link>
      </Typography>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <DialogContentText>
            Password reset instructions will be sent to <strong>{email}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button disabled={loading} onClick={handleReset} variant="contained" color="primary">Send</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

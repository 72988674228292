import { Token } from './types'
import ethLogo from '../../assets/ethereum.svg'
import uniLogo from '../../assets/uniswap.png'
import xyoLogo from '../../assets/xyo.svg'

export function formatName(token: Token) {
  return (token?.name ?? '').replace('Wrapped ', '')
}

export function formatSymbol(token: Token) {
  return (token?.symbol ?? '').replace('WETH', 'ETH')
}

export function getLogo(token: Token) {
  if (token.symbol.indexOf('ETH') !== -1) return ethLogo
  if (token.symbol.indexOf('UNI') !== -1) return uniLogo
  return xyoLogo
}

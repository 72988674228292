import {
  Profiler,
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
  ComponentType,
  ProfilerOnRenderCallback,
} from 'react'

export function makeContext<V>(ctxInitial: V, name: string) {
  type IContext = [V, Dispatch<SetStateAction<V>>]
  const setInitial = () => console.warn(`Provider for ${name} not supplied`)
  const Context = createContext<IContext>([ctxInitial, setInitial])
  const Provider: FC<{ initial?: V }> = ({
    children,
    initial = ctxInitial,
  }) => {
    const value = useState(initial)
    return <Context.Provider value={value}>{children}</Context.Provider>
  }
  // const Provider =
  //   process.env.NODE_ENV === 'development'
  //     ? makeProfiler(`${name} Provider`, _Provider)
  //     : _Provider
  return {
    Context,
    Provider,
    useContext: () => useContext(Context),
  }
}

export function makeProfiler<P>(id: string, Component: ComponentType<P>) {
  // https://reactjs.org/docs/profiler.html

  const totals = {
    baseDuration: 0,
    actualDuration: 0,
    interactions: 0,
  }
  const onRender: ProfilerOnRenderCallback = (
    id, // the "id" prop of the Profiler tree that has just committed
    phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
    actualDuration, // time spent rendering the committed update
    baseDuration, // estimated time to render the entire subtree without memoization
    // startTime, // when React began rendering this update
    // commitTime, // when React committed this update
    interactions, // the Set of interactions belonging to this update
  ) => {
    totals.baseDuration += baseDuration
    totals.actualDuration += actualDuration
    console.log(`${phase} callback`, id)
    console.log(`${id} duration`)
    console.log(`actual       ${actualDuration}`)
    console.log(`base         ${baseDuration}`)
    console.log(`acts         ${interactions}`)
    console.log(`total actual ${actualDuration}`)
    console.log(`total base   ${baseDuration}`)
    console.log(`total acts   ${baseDuration}`)
    console.log('')
  }

  return (props: P) => {
    return (
      <Profiler id={id} onRender={onRender}>
        <Component {...props} />
      </Profiler>
    )
  }
}

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import BackIcon from '@material-ui/icons/ArrowLeft'
import React from 'react'
import { Link as ReactLink } from 'react-router-dom'
import { useUser } from '../../context/user'
import {
  SweepstakesEntryCount,
  SweepstakesEntryStep,
  useSweepstakesOptionsAmountPerEntry,
  useSweepstakesOptionsLoading,
  sweepstakesEvent,
  pluralEntries,
} from './context'

export const SweepstakesBackButton = () => {
  const loading = useSweepstakesOptionsLoading()
  const [step, setStep] = SweepstakesEntryStep.useContext()
  const onBack = () => {
    sweepstakesEvent('back', 'pressed', { value: step - 1 })
    setStep(step - 1)
  }
  return (
    <Button
      variant="outlined"
      size="small"
      disabled={loading}
      style={{ height: '100%', minWidth: 'initial', maxWidth: 30 }}
      onClick={onBack}
    >
      <BackIcon />
    </Button>
  )
}

export const SweepstakesEntryButtons = () => {
  const { user, initialized } = useUser()
  const loading = useSweepstakesOptionsLoading()
  const amountPerEntry = useSweepstakesOptionsAmountPerEntry()
  const setEntryCount = SweepstakesEntryCount.useContext()[1]
  const [step, setStep] = SweepstakesEntryStep.useContext()
  const handleSetEntryCount = (n: number) => () => {
    sweepstakesEvent('setEntryCount', 'pressed', { value: n })
    setEntryCount(n)
    setStep(step + 1)
  }
  if (!initialized) return null
  if (!user) return <SweepstakesLoginButton />

  return (
    <Box display="flex">
      <Box p={1} flexGrow={1}>
        <SweepstakesEntryButton
          entryCount={1}
          disabled={loading}
          amountPerEntry={amountPerEntry}
          onClick={handleSetEntryCount(1)}
        />
      </Box>
      <Box p={1} flexGrow={1}>
        <SweepstakesEntryButton
          entryCount={10}
          disabled={loading}
          amountPerEntry={amountPerEntry}
          onClick={handleSetEntryCount(10)}
        />
      </Box>
      <Box p={1} flexGrow={1}>
        <SweepstakesEntryButton
          entryCount={100}
          disabled={loading}
          amountPerEntry={amountPerEntry}
          onClick={handleSetEntryCount(100)}
        />
      </Box>
    </Box>
  )
}

export const SweepstakesEntryButton = ({
  disabled,
  amountPerEntry,
  entryCount,
  onClick,
}: {
  disabled: boolean
  amountPerEntry: number
  entryCount: number
  onClick: () => any
}) => {
  return (
    <SuccessButton
      fullWidth
      variant="contained"
      color="secondary"
      size="small"
      onClick={onClick}
      style={{ position: 'relative', overflow: 'hidden', minHeight: 48 }}
      disabled={disabled}
      disableElevation
    >
      <FontBox style={{ paddingBottom: 14 }}>
        {amountPerEntry * entryCount}{' '}
        <span style={{ fontWeight: 'normal' }}>COIN</span>
      </FontBox>
      <EntryCountLabel entryCount={entryCount} />
    </SuccessButton>
  )
}

export const SweepstakesLoginButton = () => {
  return (
    <Box p={1}>
      <SuccessButton
        fullWidth
        variant="contained"
        color="secondary"
        size="small"
        disableElevation
        onClick={() => sweepstakesEvent('goToLogin', 'pressed', {})}
        {...{ to: 'auth', component: ReactLink }}
      >
        Login for entries with COIN
      </SuccessButton>
    </Box>
  )
}

export const EntryCountLabel = ({
  entryCount,
  hideCount,
}: {
  entryCount: number
  hideCount?: boolean
}) => {
  return (
    <Box
      position="absolute"
      bottom={0}
      left={0}
      right={0}
      textAlign="center"
      fontSize={10}
      style={{
        padding: '2px 0',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        textTransform: 'uppercase',
        lineHeight: 1,
      }}
    >
      {hideCount ? '' : entryCount} {pluralEntries(entryCount)}
    </Box>
  )
}

const FontBox = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
    fontSize: 14,
    lineHeight: 1,
  },
}))(Box)

export const LowercaseButton = withStyles({
  root: {
    textTransform: 'initial',
    fontWeight: 'bold',
    // fontSize: 16,
  },
})(Button)

export const SuccessButton = withStyles({
  root: {
    color: '#fff',
    '&:disabled': {
      backgroundColor: '#98d4a6!important',
      color: '#fff',
    },
  },
})(LowercaseButton)

import { ReactNode } from 'react'
import {
  useChangeSubscriptionMutation,
  useChangeSubscriptionPreviewMutation,
  RebillySubscription,
  PaymentCard,
} from '@xyo-network/api-checkout-apollo'
import { swalLoader, swalSuccess, swalError } from '../Alert'
import { confirmPaymentPrompt } from '../Upgrade/ConfirmUpgrade'
import { redeemSubscription } from '../../context/checkout'
import { trackChangeEvent, trackWarning } from '../../utils/events'
import { parseError } from '../../utils/error'
import { DangerBtn } from '../Buttons'
import { useUser, refetchAccounts } from '../../context/user'
import get from 'lodash/get'

interface IOuter {
  planId: string
  cards?: (PaymentCard | null)[] | null
  subscription: RebillySubscription | null
  children: ReactNode
}

export default ({ cards, subscription, children, planId }: IOuter) => {
  const { user } = useUser()
  const [change] = useChangeSubscriptionMutation({
    variables: {
      planId,
      paymentCardId: '',
      subscriptionId: subscription ? subscription.id : '',
    },
  })
  const [changePreview] = useChangeSubscriptionPreviewMutation({
    variables: {
      planId,
      subscriptionId: subscription ? subscription.id : '',
    },
  })
  const handleClick = async () => {
    const email = get(user, 'email')
    if (!change || !changePreview || !subscription || !email) return
    const subscriptionId = subscription.id
    trackChangeEvent('view', {})
    const preview = changePreview().then((res) =>
      get(res, 'data.changeSubscriptionPreview'),
    )
    const card = await confirmPaymentPrompt(
      preview,
      Promise.resolve((cards || []).filter((v) => v)),
    )
    if (!card || !card.paymentCardId) return trackChangeEvent('canceled', {})
    await preview
    try {
      trackChangeEvent('confirmed', {})
      swalLoader(<p>Changing Subscription...</p>)
      await change({
        variables: {
          paymentCardId: card.paymentCardId,
          subscriptionId,
          planId,
        },
      })
      await redeemSubscription(email)
      refetchAccounts()
      swalSuccess('Your subscription has been changed successfully!')
      trackChangeEvent('success', {})
    } catch (e) {
      trackWarning('Change subscription error', e.message)
      trackChangeEvent('error', { value: e.message })
      swalError(parseError(e.message))
    }
  }

  return subscription && <DangerBtn onClick={handleClick}>{children}</DangerBtn>
}

import { createContext, FC, useContext, useMemo, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'

type ICustomTokenValue = {
  isUsingCustomToken: string
}

type ICustomTokenActions = {
  set: (token: string) => void
  rm: (token: string) => void
}

type ICustomTokenContext = [ICustomTokenValue, ICustomTokenActions]

const CustomTokenContext = createContext<null | ICustomTokenContext>(null)
const customTokenStore = {
  key: 'CUSTOM_TOKEN',
  expires: 'CUSTOM_TOKEN_EXPIRES',
  expiresMinutes: 20,
  get: () => {
    const email = localStorage.getItem(customTokenStore.key) ?? ''
    if (!email) return ''

    const expires = localStorage.getItem(customTokenStore.expires) ?? ''

    if (!expires || new Date(expires).valueOf() <= Date.now()) {
      firebase.auth().signOut()
      customTokenStore.rm()
      return ''
    }

    return email
  },
  set: (v: string) => {
    const expires = new Date()
    expires.setMinutes(expires.getMinutes() + customTokenStore.expiresMinutes)
    localStorage.setItem(customTokenStore.key, v)
    localStorage.setItem(customTokenStore.expires, expires.toISOString())
  },
  rm: () => {
    localStorage.removeItem(customTokenStore.key)
    localStorage.removeItem(customTokenStore.expires)
  },
}

export const useCustomTokenContext = () => {
  const value = useContext(CustomTokenContext)
  if (!value) throw new Error('CustomTokenProvider component not found')
  return value
}

export const CustomTokenProvider: FC = ({ children }) => {
  const [value, setValue] = useState<ICustomTokenValue>({
    isUsingCustomToken: customTokenStore.get(),
  })
  const context = useMemo(() => {
    const actions: ICustomTokenActions = {
      set: (v) => {
        customTokenStore.set(v)
        setValue({ isUsingCustomToken: v })
      },
      rm: () => {
        customTokenStore.rm()
        setValue({ isUsingCustomToken: '' })
      },
    }
    return [value, actions] as ICustomTokenContext
  }, [value])

  return (
    <CustomTokenContext.Provider value={context}>
      {children}
    </CustomTokenContext.Provider>
  )
}

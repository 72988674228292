import { useState, useMemo } from 'react'
import { bossClient } from '../context/bossapi'
import {
  dropFormSchema,
  DropFormProvider,
  DropForm,
  DropFormHeader,
} from '@xyo-network/coin-geo-drops/build/lib/components/DropForm'
// import { ApolloProvider } from '@apollo/react-hooks'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'
import { useUser, useUserInfo } from '../context/user'
import { LinearProgress } from '@material-ui/core'
import firebase from 'firebase/app'
import 'firebase/storage'

const accessToken = process.env.REACT_APP_MAPBOX
const uploadImage = (uid) => async (base64Image) => {
  const match = base64Image.match(/data:(.*);base64,(.*)/)
  if (!match) throw new Error('Image upload unsuccessful')
  const fileName = new Date().toISOString()
  const path = `coin/images/${uid}/${fileName}`
  const contentType = match[1]
  const base64 = match[2]
  const ref = firebase.storage().ref().child(path)
  const task = await ref.putString(base64, 'base64', { contentType })
  const url = await ref.getDownloadURL()
  return url
}

export const CreateDrop = ({ uid, drop, open, onClose }) => {
  return (
    // <ApolloProvider client={bossClient}>
    <DropFormProvider drop={drop}>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        fullWidth
        style={{ overflow: 'hidden' }}
      >
        <DropForm
          onCancel={onClose}
          onSuccess={onClose}
          uploadImage={uploadImage(uid)}
          accessToken={accessToken}
          header={<DropFormHeader value={drop.maxAvailable} />}
        />
      </Dialog>
    </DropFormProvider>
    // </ApolloProvider>
  )
}

export default withRouter(({ history }) => {
  const { user } = useUser()
  const { userInfo } = useUserInfo()
  const initial = useMemo(() => {
    if (!userInfo) return {}
    const from = 'xyoCollected' // 'bossBalance'
    const maxAmounts = {}
    if (userInfo.webDropsAmountMax)
      maxAmounts.maxAmount = userInfo.webDropsAmountMax
    if (userInfo.webDropsQuantityMax)
      maxAmounts.maxQuantity = userInfo.webDropsQuantityMax
    maxAmounts.maxAvailable = userInfo && userInfo[from]
    const initial = { ...dropFormSchema.default(), ...maxAmounts, from }
    return initial
  }, [userInfo])
  if (!userInfo) return <LinearProgress />
  return (
    <CreateDrop
      uid={user.uid}
      open={true}
      drop={initial}
      onClose={() => history.push('/geodrops')}
    />
  )
})

import { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { CheckoutProvider } from '../context/checkout'
import { FadeIn } from '../components/Animate'
import { PasswordRequiredDialog } from '../components/Alert/PasswordRequired'
import { UserInfoDisplay } from '../components/User'
import {
  useRefundRequestQuery,
  useProcessRefundRequestMutation,
  QueryResult,
  RefundRequestQuery,
  RefundRequestQueryVariables,
} from '@xyo-network/api-checkout-apollo'
import CheckIcon from '@material-ui/icons/Check'
import InfoIcon from '@material-ui/icons/Info'
import ClearRoundedIcon from '@material-ui/icons/ClearRounded'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import Divider from '@material-ui/core/Divider'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'

export default withRouter(({ match }) => {
  const { id } = match.params
  return (
    <CheckoutProvider>
      <RefundView id={id} />
    </CheckoutProvider>
  )
})

const RefundView = ({ id }) => {
  const [confirmEmail, setConfirmEmail] = useState('')
  const { loading, error, data } = useRefundRequestQuery({ variables: { id } })
  const [
    processRefund,
    { loading: refunding, error: refundError },
  ] = useProcessRefundRequestMutation({ variables: { id } })
  return (
    <div className="container text-white pt-2 pb-40vh">
      <FadeIn>
        <div className="mb-5">
          <UserInfoDisplay />
        </div>
      </FadeIn>
      <FadeIn timer={400} style={{ zIndex: 2 }}>
        <Container maxWidth="sm">
          <h1 className="mb-4">Request Refund</h1>
          <Refund
            error={error || refundError}
            loading={loading}
            refunding={refunding}
            refund={data && data.refundRequest}
            handleRefund={() =>
              setConfirmEmail(data?.refundRequest?.email ?? '')
            }
          />
        </Container>
      </FadeIn>
      <PasswordRequiredDialog
        title="Confirm Refund Request"
        content={
          <>
            <Typography>
              Enter account password to accept terms and refund
            </Typography>
            <Typography>
              You will be refunded $
              {num(
                data?.refundRequest?.eligibleMonths *
                  data?.refundRequest?.eligibleAmount,
              )}
            </Typography>
            {typeof resetXyoCollectedAmount !== 'number' ||
            data?.refundRequest?.xyoCollected ===
              data?.refundRequest?.resetXyoCollectedAmount ? (
              <Typography gutterBottom>
                Your COIN balance will be remain{' '}
                {num(data?.refundRequest?.xyoCollected)}
              </Typography>
            ) : (
              <Typography gutterBottom>
                Your COIN balance will be adjusted from{' '}
                {num(data?.refundRequest?.xyoCollected)} to{' '}
                {num(data?.refundRequest?.resetXyoCollectedAmount)}
              </Typography>
            )}
          </>
        }
        open={Boolean(confirmEmail)}
        onClose={() => setConfirmEmail('')}
        email={confirmEmail}
        onConfirmed={() => {
          setConfirmEmail('')
          processRefund()
        }}
      />
    </div>
  )
}

const Refund = ({ refund, loading, error, refunding, handleRefund }) => {
  return loading ? (
    <LinearProgress />
  ) : error && error.message ? (
    <ErrorCard>
      <Typography className="text-danger">{error.message}</Typography>
    </ErrorCard>
  ) : refund && refund.refunded ? (
    <SuccessCard>
      <Typography>Refund Success</Typography>
    </SuccessCard>
  ) : refund && refund.accepted ? (
    <SuccessCard>
      <Typography>Refund Accepted</Typography>
    </SuccessCard>
  ) : refund && refund.refunding ? (
    <SuccessCard>
      <Typography>Refund In Progress</Typography>
    </SuccessCard>
  ) : refund && !refund.active ? (
    <ErrorCard>
      <Typography className="text-danger">Refund no longer active</Typography>
    </ErrorCard>
  ) : refund && refund.expireAt && Date.now() > refund.expireAt ? (
    <ErrorCard>
      <Typography className="text-danger">Refund is expired</Typography>
    </ErrorCard>
  ) : refund ? (
    <>
      <Box mb={2}>
        <RefundCard
          refund={refund}
          refunding={refunding}
          handleRefund={handleRefund}
        />
      </Box>
      <Typography
        component="p"
        variant="subtitle"
        style={{ textAlign: 'center' }}
      >
        By accepting this refund, you are agreeing to request no further refunds
        or charge backs.
      </Typography>
    </>
  ) : (
    <ErrorCard>
      <Typography className="text-danger">No Refund Found</Typography>
    </ErrorCard>
  )
}

const RefundCard = ({ refund, refunding, handleRefund }) => {
  const [checked, setChecked] = useState(false)
  return (
    <Card>
      <Slide in={true} direction="up">
        <div>
          <Box px={2} pt={1}>
            <TextRow bold title="Start Time" my={1}>
              {date(refund.startTime)}
            </TextRow>
            <Divider />
            <TextRow
              bold
              title="Eligible Months"
              info="Eligibility is based on several factors including but not limited to usage, duration of subscription, geodrops, and redemption."
              my={1}
            >
              {refund.eligibleMonths}
            </TextRow>
            <Divider />
            <TextRow bold title="Eligible Amount" my={1}>
              ${num(refund.eligibleMonths * refund.eligibleAmount)}
            </TextRow>
            <Divider />
            {typeof refund.resetXyoCollectedAmount === 'number' ? (
              <TextRow
                bold
                alignItems="flex-start"
                flexDirection="column"
                title="Adjusted COIN Balance"
                info="In order to process the refund, it is necessary to adjust your COIN balance to represent what you would have without the refunded subscription."
                my={1}
              >
                <span>
                  <TextRow title="Current Balance:" mt={1}>
                    {num(refund.xyoCollected)}
                  </TextRow>
                  <TextRow title="Adjusted Balance:">
                    {num(refund.resetXyoCollectedAmount)}
                  </TextRow>
                </span>
              </TextRow>
            ) : null}
            {/* <Box flex={1} display="flex" justifyContent="flex-end">
              <FormControlLabel
                label="Accept COIN Reset"
                labelPlacement="start"
                value={checked}
                onChange={(ev, checked) => setChecked(checked)}
                control={<Checkbox color="primary" />}
              />
            </Box> */}
          </Box>
          <Box px={2} pb={2} textAlign="right">
            {refund.active ? (
              <Button
                color="primary"
                variant="contained"
                position="relative"
                disabled={refunding}
                component={Button}
                onClick={handleRefund}
              >
                {refunding ? (
                  <Box
                    component={LinearProgress}
                    variant="indeterminate"
                    position="absolute"
                    width="100%"
                    top={0}
                    left={0}
                  />
                ) : null}
                Accept Refund
              </Button>
            ) : null}
          </Box>
        </div>
      </Slide>
    </Card>
  )
}

const TextRow = ({
  bold,
  title,
  value,
  info,
  children,
  alignItems = 'center',
  flexDirection = 'row',
  justifyContent = 'space-between',
  ...props
}) => {
  const main = (
    <Typography>{bold ? <strong>{title}</strong> : title}</Typography>
  )
  return (
    <Box
      display="flex"
      flexDirection={flexDirection}
      alignItems={alignItems}
      justifyContent={justifyContent}
      {...props}
    >
      {info ? (
        <Tooltip title={<Typography>{info}</Typography>}>
          <Box display="flex" flexDirection="row" alignItems="center">
            {main}{' '}
            <Box ml={1}>
              <InfoIcon fontSize="small" />
            </Box>
          </Box>
        </Tooltip>
      ) : (
        main
      )}
      <Box component={Typography} ml={1} style={{ textAlign: 'right' }}>
        {value || children}
      </Box>
    </Box>
  )
}

const ErrorCard = ({ children }) => {
  return (
    <Card>
      <CardContent>
        <Slide in={true} direction="up">
          <div>
            <ErrorContent>{children}</ErrorContent>
          </div>
        </Slide>
      </CardContent>
    </Card>
  )
}

const SuccessCard = ({ children }) => {
  return (
    <Card>
      <CardContent>
        <Slide in={true} direction="up">
          <div>
            <SuccessContent>{children}</SuccessContent>
          </div>
        </Slide>
      </CardContent>
    </Card>
  )
}

const SuccessContent = ({ children }) => {
  return (
    <div className="text-center">
      <div className="d-inline-block bg-success mt-4 mb-2 rounded-circle">
        <CheckIcon className="p-2" style={{ height: 120, width: 120 }} />
      </div>
      {children}
    </div>
  )
}

const ErrorContent = ({ children }) => {
  return (
    <div className="text-center">
      <div className="d-inline-block bg-danger mt-4 mb-2 rounded-circle">
        <ClearRoundedIcon className="p-2" style={{ height: 120, width: 120 }} />
      </div>
      {children}
    </div>
  )
}

function date(v) {
  if (!v) return ''
  return new Date(v).toLocaleString()
}

function num(v) {
  const n = Number(v)
  if (isNaN(n)) return 0
  return n.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

function withExpired(v) {
  if (!v) return v
  return { ...v, expireAt: Date.now() - 100 }
}

function withInactive(v) {
  if (!v) return v
  return { ...v, active: false }
}

function withRefunded(v, refunded) {
  if (!v) return v
  return { ...v, refunded }
}

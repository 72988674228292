import { sweepstakesUri } from '@coin-microservices/sweepstakes-react-hooks'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { FC, Suspense, useEffect } from 'react'
import { makeApolloAuthProvider } from '../../context/apollo'
import { lightTheme } from '../../context/page'
import { parseError } from '../../utils/error'
import { ErrorBoundary } from '../Error'
import SplashScreen from '../Logo/SplashScreen'
import { RequiredEmail } from '../Private'
import { useSnackbar } from '../Snackables'
import {
  SweepstakesCompletedDialog,
  SweepstakesCompletedDialogType,
} from './Completed'
import { SweepstakesEntrySelected } from './selected'
import { makeContext } from './makeContext'
import {
  SweepstakesDownloadDialog,
  SweepstakesDownloadDialogOpen,
} from './Download'
import {
  SweepstakesFreeEntryDialog,
  SweepstakesFreeEntryDialogOpen,
} from './FreeEntry'
export { SweepstakesEntrySelected } from './selected'
export { SweepstakesCompletedDialogType } from './Completed'
export { SweepstakesDownloadDialogOpen } from './Download'
export { SweepstakesFreeEntryDialogOpen } from './FreeEntry'
export * from './options'
export * from './event'

const uri = sweepstakesUri || 'http://localhost:4000'

export const SweepstakesApolloProvider = makeApolloAuthProvider(uri)

export const SweepstakesEntryStep = makeContext(0, 'entry_step')

export const SweepstakesSize = makeContext('', 'size')

export const SweepstakesEntryCount = makeContext(0, 'entry_count')

export const SweepstakesEntryTermsAgreed = makeContext(
  false,
  'entry_terms_agreed',
)

export const SweepstakesEntryConfirm = makeContext(false, 'entry_confirm')

export const SweepstakesEntryDialogOpen = makeContext(
  false,
  'entry_dialog_open',
)

export const SweepstakesHistoryOpen = makeContext(false, 'history_open')

export const SweepstakesThemeProvider: FC = ({ children }) => {
  return <MuiThemeProvider theme={lightTheme}>{children}</MuiThemeProvider>
}

const emailWhitelist = [
  'jordan.trouw@xy.company',
  'rphansen91@gmail.com',
  'johnarana@gmail.com',
  'tyleremerson07@gmail.com',
]

export const SweepstakesWhitelistProvider: FC = ({ children }) => {
  return <RequiredEmail oneOf={emailWhitelist}>{children}</RequiredEmail>
}

export const SweepstakesProvider: FC = ({ children }) => {
  return (
    <Suspense fallback={<SplashScreen error="" message="" />}>
      <ErrorBoundary
        fallback={(error: Error) => <DisplayError error={error} />}
      >
        <SweepstakesApolloProvider>
          <SweepstakesEntryCount.Provider>
            <SweepstakesEntryConfirm.Provider>
              <SweepstakesHistoryOpen.Provider>
                <SweepstakesEntryTermsAgreed.Provider>
                  <SweepstakesEntryStep.Provider>
                    <SweepstakesEntryDialogOpen.Provider>
                      <SweepstakesDownloadDialogOpen.Provider>
                        <SweepstakesEntrySelected.Provider>
                          <SweepstakesCompletedDialogType.Provider>
                            <SweepstakesFreeEntryDialogOpen.Provider>
                              <SweepstakesSize.Provider>
                                {children}
                                <SweepstakesThemeProvider>
                                  <SweepstakesCompletedDialog />
                                  <SweepstakesDownloadDialog />
                                  <SweepstakesFreeEntryDialog />
                                </SweepstakesThemeProvider>
                              </SweepstakesSize.Provider>
                            </SweepstakesFreeEntryDialogOpen.Provider>
                          </SweepstakesCompletedDialogType.Provider>
                        </SweepstakesEntrySelected.Provider>
                      </SweepstakesDownloadDialogOpen.Provider>
                    </SweepstakesEntryDialogOpen.Provider>
                  </SweepstakesEntryStep.Provider>
                </SweepstakesEntryTermsAgreed.Provider>
              </SweepstakesHistoryOpen.Provider>
            </SweepstakesEntryConfirm.Provider>
          </SweepstakesEntryCount.Provider>
        </SweepstakesApolloProvider>
      </ErrorBoundary>
    </Suspense>
  )
}

function DisplayError({ error }: any) {
  const { setSnackbar } = useSnackbar()[1] as any
  useEffect(() => {
    setSnackbar({
      severity: 'error',
      message: parseError(error),
      autoHideDuration: 6000,
    })
  }, [error])
  return null
}

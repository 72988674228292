import { FC, Children } from 'react'
import Slide from '@material-ui/core/Slide'
import Box from '@material-ui/core/Box'

export const Stepper: FC<{ step: number; height: number }> = ({
  children,
  step,
  height,
}) => {
  return (
    <Box position="relative" width="100%" overflow="hidden">
      {Children.map(children, (child, i) => (
        <Slide key={i} in={step === i} direction={step >= i ? 'right' : 'left'}>
          <Box position="absolute" width="100%">
            {child}
          </Box>
        </Slide>
      ))}
      <Box
        style={{
          height,
          transition: 'all 0.2s ease-in',
        }}
      />
    </Box>
  )
}

import React from 'react'
import Box from '@material-ui/core/Box'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useUserInfo, refetchAccounts } from '../../context/user'
import { numberFormat } from '../../utils/number'
import { parseError } from '../../utils/error'
import { useSnackbar } from '../Snackables'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { SuccessButton, EntryCountLabel } from './Buttons'
import { useUpsertSweepstakeEntriesMutation } from '@coin-microservices/sweepstakes-react-hooks'
import {
  SweepstakesEntryCount,
  SweepstakesEntryStep,
  useSweepstakesOptionsAmountPerEntry,
  SweepstakesEntryDialogOpen,
  useSweepstakesOptions,
  sweepstakesEvent,
} from './context'

export const SweepstakesConfirmDetails = () => {
  const classes = useStyles()
  const amountPerEntry = useSweepstakesOptionsAmountPerEntry()
  const { userInfo } = useUserInfo()
  const [entryCount] = SweepstakesEntryCount.useContext()
  const totalAmount = amountPerEntry * entryCount

  return (
    <Box display="flex" flexGrow={1} className={classes.confirmDetails}>
      <Box flexGrow={1}>
        <Box>
          <Typography variant="caption">Balance</Typography>
        </Box>
        <Box>
          <Typography variant="caption">Amount</Typography>
        </Box>
      </Box>
      <Box textAlign="right">
        <Box>
          <Typography variant="caption" noWrap>
            <strong>
              {numberFormat(Math.max(0, userInfo?.xyoCollected ?? 0))} COIN
            </strong>
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" noWrap>
            <strong>- {numberFormat(totalAmount)} COIN</strong>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export const SweepstakesConfirmButton = () => {
  const options = useSweepstakesOptions()
  const { userInfo } = useUserInfo()
  const amountPerEntry = useSweepstakesOptionsAmountPerEntry()
  const [entryCount] = SweepstakesEntryCount.useContext()
  const totalAmount = amountPerEntry * entryCount
  const remainingBalance = previewBalance(userInfo, totalAmount)
  const [step, setStep] = SweepstakesEntryStep.useContext()
  const [confirm, { loading }] = useUpsertSweepstakeEntriesMutation()
  const setDialogOpen = SweepstakesEntryDialogOpen.useContext()[1]
  const { setSnackbar } = useSnackbar()[1] as any

  const handleConfirm = async () => {
    try {
      sweepstakesEvent('confirm', 'pressed', { value: entryCount })
      await confirm({
        variables: {
          id: options?.id ?? '',
          entryCount,
          source: 'web',
        },
      })
      sweepstakesEvent('confirm', 'success', { value: entryCount })
      setSnackbar({
        severity: 'success',
        message: (
          <>
            <AlertTitle>Success</AlertTitle>
            Purchase of {entryCount} {entryCount === 1 ? 'entry' : 'entries'}{' '}
            completed.
          </>
        ),
        autoHideDuration: 6000,
      })
      setDialogOpen(false)
      setStep(step - 1)
      await refetchAccounts()
    } catch (error) {
      const message = parseError(error)
      sweepstakesEvent('confirm', 'error', { value: message })
      setSnackbar({
        message,
        severity: 'error',
        autoHideDuration: 6000,
      })
    }
  }

  return (
    <Box p={1}>
      <SuccessButton
        variant="contained"
        color="secondary"
        size="small"
        disabled={loading || remainingBalance < 0}
        style={{ height: '100%', position: 'relative', overflow: 'hidden' }}
        onClick={handleConfirm}
        disableElevation
      >
        {loading ? (
          <LinearProgress
            color="secondary"
            style={{ position: 'absolute', top: 0, left: 0, right: 0 }}
          />
        ) : null}
        <Box style={{ paddingBottom: 10 }}>Confirm</Box>
        <EntryCountLabel entryCount={entryCount} />
      </SuccessButton>
    </Box>
  )
}

function previewBalance(
  userInfo: { xyoCollected?: number | null } | undefined | null,
  spent: number,
) {
  return (userInfo?.xyoCollected ?? 0) - spent
}

const useStyles = makeStyles(theme =>
  createStyles({
    confirmDetails: {
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
    },
  }),
)

import React from 'react'
import Button from '@material-ui/core/Button'
import ReactStrap from '@xyo-network/tool-storybook-react/dist/lib/ReactStrap'
import cx from 'classnames'
export * from './Icon'

export const DangerBtn = ReactStrap('btn text-danger', <button />)

export const LoadingButton = ({
  children,
  disabled,
  loading,
  className,
  ...props
}) => {
  return (
    <Button
      color="primary"
      disabled={disabled || loading}
      className={cx('d-flex align-items-center', className)}
      {...props}
    >
      {children}{' '}
      {loading && <span className="spinner-border spinner-border-sm ml-2" />}
    </Button>
  )
}

import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { verifyAddressClient } from '@xyo-network/api-address-verifier-apollo/bindings'
import getRequestContext from './getRequestContext'

export const SIGNING_DATA = 'I <3 XYO'

export const verifyClient = verifyAddressClient('production', getRequestContext)

export const VerifyProvider = ({ children }) => {
  return <ApolloProvider client={verifyClient}>{children}</ApolloProvider>
}

import { useMemo } from 'react'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks'
import ApolloClient from 'apollo-boost'
import getRequestContext from './getRequestContext'
import { trackError, trackWarning } from '../utils/events'
import fetch from 'isomorphic-fetch'
import { parse } from 'querystring'
import gql from 'graphql-tag'

const DEVELOPMENT_URI = 'http://localhost:8083'
const PRODUCTION_URI = 'https://bossapi.coinapp.co'

const coinBossClient = (stage, getRequestContext) => {
  const uri = stage === 'development' ? DEVELOPMENT_URI : PRODUCTION_URI
  return new ApolloClient({
    uri,
    fetch,
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        trackError('Boss api gql error', graphQLErrors)
      }
      if (networkError) {
        trackWarning('Boss api net error', networkError)
      }
    },
    request: async (op) => {
      const context = getRequestContext ? await getRequestContext() : {}
      op.setContext(context)
    },
  })
}

export const bossClient = coinBossClient(
  process.env.NODE_ENV,
  getRequestContext,
)

export const BossProvider = ({ children }) => {
  return (
    <ApolloProvider client={bossClient}>
      <ApolloHooksProvider client={bossClient}>{children}</ApolloHooksProvider>
    </ApolloProvider>
  )
}

const NfcRefistrationDocument = gql`
  query NfcRegistration($nfcId: String!) {
    nfcRegistration(nfcId: $nfcId) {
      inviteUrl
    }
  }
`
export const getNfcRefistrationInviteUrl = async () => {
  const { uid } = parse(window.location.search.replace('?', ''))
  const [nfcId] = (uid ?? '').split('x')
  const response = await bossClient.query({
    query: NfcRefistrationDocument,
    variables: { nfcId },
  })
  const inviteUrl = response?.data?.nfcRegistration?.inviteUrl
  if (!inviteUrl) {
    throw new Error('Registration not found')
  }
  return inviteUrl
}

const VerifyRedeemDocument = gql`
  mutation verifyRedeemRequest($code: String!) {
    verifyRedeemRequest(code: $code) {
      reference
      state
    }
  }
`

export const getVerificationCode = () => {
  const { code, verification_code } = parse(
    window.location.search.replace('?', ''),
  )
  return code || verification_code
}

export const verifyRedeem = async () => {
  const code = getVerificationCode()
  if (!code) throw new Error('Code is required')
  const response = await bossClient.mutate({
    mutation: VerifyRedeemDocument,
    variables: { code },
  })
  const redeem = response?.data?.verifyRedeemRequest
  return redeem
}

const VerifyAddressHistoryDocument = gql`
  mutation verifyAddressHistoryRequest($code: String!) {
    verifyAddressHistoryRequest(code: $code) {
      type
      state
    }
  }
`

export const verifyAddressHistory = async (code) => {
  const response = await bossClient.mutate({
    mutation: VerifyAddressHistoryDocument,
    variables: { code },
  })
  const redeem = response?.data?.verifyAddressHistoryRequest
  return redeem
}

const VerifyDeviceDocument = gql`
  mutation verifyDeviceRequest($code: String!) {
    verifyDeviceRequest(code: $code) {
      type
      state
    }
  }
`

export const verifyDevice = async (code) => {
  const response = await bossClient.mutate({
    mutation: VerifyDeviceDocument,
    variables: { code },
  })
  const redeem = response?.data?.verifyDeviceRequest
  return redeem
}

import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useCheckout } from './context'
import ErrorAlert from '../Error'

export default ({ id, onCancel }) => {
  const { loading, error } = useCheckout()
  return (
    <>
      <ErrorAlert error={error} />
      <div className='d-flex align-items-center mt-3'>
        <span className='flex-grow-1' />
        <button
          type='button'
          className='btn btn-danger mr-1'
          onClick={onCancel}
        >
          Back
        </button>
        <button
          type='submit'
          className='btn btn-success d-flex align-items-center'
          disabled={loading}
          form={id}
        >
          Next{' '}{loading && <CircularProgress className='ml-1' size='1em' color='inherit' />}
        </button>
      </div>
    </>
  )
}
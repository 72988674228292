import { FC } from 'react'
import { setContext } from 'apollo-link-context'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  concat,
  HttpLink,
} from '@apollo/client'
import firebase from 'firebase/app'
import 'firebase/auth'

const authLink: any = setContext(async (op, { headers }) => {
  const user = firebase.auth().currentUser
  const token = user ? await user.getIdToken() : ''
  return {
    headers: {
      ...headers,
      'X-Auth-Token': token,
    },
  }
})

export const getClient = (uri: string) =>
  new ApolloClient({
    link: concat(authLink, new HttpLink({ uri })),
    cache: new InMemoryCache(),
  })

export const makeApolloAuthProvider = (uri: string) => {
  const client = getClient(uri)
  const Provider: FC = ({ children }) => {
    return <ApolloProvider client={client}>{children}</ApolloProvider>
  }
  return Provider
}

import { useState, useEffect } from 'react'
import { Link as ReactLink } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Skeleton from '@material-ui/lab/Skeleton'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import Paper from '@material-ui/core/Paper'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import times from 'lodash/times'
import getRequestContext from '../../context/getRequestContext'
import { useUser } from '../../context/user'
import { numberFormat } from '../../utils/number'
import { localJSONStore } from '../../utils/localStore'
import { toYYYYMMDD } from '../../utils/date'
export * from './Totals'

const { LineChart } = require('react-chartkick')
require('chart.js')

type Datum = {
  date: string
  collected: number
  count: number
}
type DashboardData = {
  name: string
  total: number
  data: Datum[]
}

type AsyncState<V> = {
  loading: boolean
  data: null | V
  error: string
}

const dashboardData: { [key: string]: DashboardData[] } = {}
const dashboardDataStore = localJSONStore('dashboard-data-store')
const dashboardDataUri =
  'https://coin-service-coin-user-dashboard-server-zatxacztoa-uc.a.run.app/dashboard'

async function loadDashboardData(uid: string) {
  const date = toYYYYMMDD(new Date())
  const key = `${uid}_${date}`
  if (dashboardData[key]) return dashboardData[key]

  const savedData = dashboardDataStore.from()
  if (savedData?.[key]) {
    dashboardData[key] = savedData?.[key]
    return savedData?.[key]
  }

  const { headers } = await getRequestContext()
  const res = await fetch(dashboardDataUri, {
    headers,
  })
  const data = (await res.json())?.result
  dashboardData[key] = data
  dashboardDataStore.to({ [key]: data })
  return data
}

export function useUserDashboardData() {
  const { user } = useUser()
  const uid = (user as any)?.uid
  const [state, setState] = useState<AsyncState<DashboardData[]>>({
    loading: false,
    data: null,
    error: '',
  })

  async function load() {
    try {
      setState({ loading: true, data: null, error: '' })
      const data = await loadDashboardData(uid)
      setState({ loading: false, data, error: '' })
    } catch (e) {
      setState({ loading: false, data: null, error: e.message })
    }
  }

  useEffect(() => {
    if (uid) {
      if (dashboardData[uid]) {
        setState({ loading: false, data: dashboardData[uid], error: '' })
      } else {
        load()
      }
    }
  }, [uid])

  return state
}

export const UserDashboard = () => {
  const { loading, data } = useUserDashboardData()
  return (
    <>
      <Box mb={8}>
        <UserDashboardContent
          name="Geomines"
          data={data?.[0]?.data ?? ([] as DashboardData['data'])}
          total={data?.[0]?.total ?? 0}
          columns={['Date', 'Geomines', 'Collected']}
          loading={loading}
          renderRow={(datum, i) => (
            <TableRow key={i}>
              <TableCell>
                {localeDate(datum?.date)}
                &nbsp;
              </TableCell>
              <TableCell align="right">{datum?.count}&nbsp;</TableCell>
              <TableCell align="right">
                {datum ? numberFormat(datum?.collected) : ''}&nbsp;
              </TableCell>
            </TableRow>
          )}
          to="/geomines"
        />
      </Box>
      <Box mb={8}>
        <UserDashboardContent
          name="Geodrops"
          data={data?.[1]?.data ?? ([] as DashboardData['data'])}
          total={data?.[1]?.total ?? 0}
          columns={['Date', 'Geodrops', 'Collected']}
          renderRow={(datum, i) => (
            <TableRow key={i}>
              <TableCell>
                {localeDate(datum?.date)}
                &nbsp;
              </TableCell>
              <TableCell align="right">{datum?.count}&nbsp;</TableCell>
              <TableCell align="right">
                {datum ? numberFormat(datum?.collected) : ''}&nbsp;
              </TableCell>
            </TableRow>
          )}
          loading={loading}
          to="/geodrops"
        />
      </Box>
      <Box mb={8}>
        <UserDashboardContent
          name="Geoclaims"
          data={data?.[2]?.data ?? ([] as DashboardData['data'])}
          total={data?.[2]?.total ?? 0}
          columns={['Date', 'Geoclaims', 'Collected']}
          renderRow={(datum, i) => (
            <TableRow key={i}>
              <TableCell>
                {localeDate(datum?.date)}
                &nbsp;
              </TableCell>
              <TableCell align="right">{datum?.count}&nbsp;</TableCell>
              <TableCell align="right">
                {datum ? numberFormat(datum?.collected) : ''}&nbsp;
              </TableCell>
            </TableRow>
          )}
          loading={loading}
          to="/geoclaims"
        />
      </Box>
    </>
  )
}

export const UserDashboardContent = ({
  name,
  data,
  total,
  columns,
  loading,
  renderRow,
  to,
}: DashboardData & {
  columns: string[]
  to: string
  loading: boolean
  renderRow: (datum: Datum, i: number) => any
}) => {
  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between">
        <Typography paragraph>
          Total {name} (Last 7 Days): {total}
        </Typography>
        <Typography paragraph>
          <Link component={ReactLink} to={to}>
            See Full History
          </Link>
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid xs={12} md={8} item>
          <Box height={268}>
            <Paper
              style={{ position: 'relative', height: '100%', paddingTop: 4 }}
              elevation={6}
            >
              {loading ? (
                <Skeleton
                  style={{ position: 'absolute', top: 0, left: 0 }}
                  variant="rect"
                  height="100%"
                  width="100%"
                />
              ) : null}
              <LineChart
                {...chartOptions}
                height={260}
                data={[
                  {
                    name: 'Collected',
                    data: data.map(({ date, collected }) => [
                      date.split('T')[0],
                      collected,
                    ]),
                  },
                ]}
              />
            </Paper>
          </Box>
        </Grid>
        <Grid xs={12} md={4} item>
          <Box height={268} overflow="auto">
            <Paper style={{ position: 'relative' }} elevation={6}>
              {loading ? (
                <Skeleton
                  style={{ position: 'absolute', top: 0, left: 0 }}
                  variant="rect"
                  height="100%"
                  width="100%"
                />
              ) : null}
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell key={column} align={i ? 'right' : 'left'}>
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {times(7).map((_, i) => renderRow(data?.[6 - i], i))}
                </TableBody>
              </Table>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

function localeDate(dateStr?: string | null) {
  if (!dateStr) return ''
  const date = new Date()
  const [y, m, d] = dateStr.split('T')[0].split('-')
  date.setFullYear(Number(y))
  date.setMonth(Number(m) - 1)
  date.setDate(Number(d))
  return date.toLocaleDateString()
}

const chartOptions = {
  curve: true,
  legend: false,
  colors: ['#ee7268', '#09baa6'],
  library: {
    backgroundColor: '#262525',
    timeline: {
      colorByRowLabel: false,
      showBarLabels: false,
      rowLabelStyle: { color: '#fff' },
      barLabelStyle: { color: '#fff' },
    },
  },
}

import React from 'react'
import Box from '@material-ui/core/Box'
import { SweepstakesEntryStep } from './context'
import { SweepstakesDetailsHeader, SweepstakesPrizeDetails } from './Details'
import {
  SweepstakesRequiredCountryDetails,
  EnterRequireCountryActions,
} from './Resident'
import {
  EnterStepActions,
  EntryCountStepActions,
  EntryConfirmStepActions,
} from './Actions'
import { Stepper } from './Stepper'

export const SweepstakesEntryForm = () => {
  const [step] = SweepstakesEntryStep.useContext()
  return (
    <Box>
      <Stepper step={Math.min(step, 1)} height={step === 0 ? 152 : 230}>
        <SweepstakesEntryRequiredCountry />
        <SweepstakesEntryFormDetails />
      </Stepper>
      <Stepper step={step} height={step < 2 ? 60 : 120}>
        <EnterRequireCountryActions />
        <EnterStepActions />
        <EntryCountStepActions />
        <EntryConfirmStepActions />
      </Stepper>
    </Box>
  )
}

const SweepstakesEntryRequiredCountry = () => {
  return (
    <>
      <SweepstakesDetailsHeader />
      <SweepstakesRequiredCountryDetails />
    </>
  )
}

const SweepstakesEntryFormDetails = () => {
  return (
    <>
      <SweepstakesDetailsHeader />
      <Box px={2} pb={1}>
        <SweepstakesPrizeDetails />
      </Box>
    </>
  )
}

import React, { useState } from 'react'
// import { useUser } from '../../context/user'
import { useAchievementsCollected } from './controls'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import times from 'lodash/times'
import Skeleton from '@material-ui/lab/Skeleton'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

const supportUri =
  'https://support.coinapp.co/hc/en-us/articles/360048287714-Badges-in-COIN'

export const BadgeCards = () => {
  const { data, loading } = useAchievementsCollected()
  const ranked = rankAcheivements(
    data?.account?.userInfo?.achievementsCollected,
  )

  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const items = loading ? times(6).map(() => null) : ranked
  const cols = times(isMdUp ? 3 : isSmUp ? 2 : 1).map((v) => [])
  items.forEach((item, i) => {
    cols[i % cols.length].push(item)
  })

  return (
    <Grid container spacing={2}>
      {items.length ? (
        cols.map((achievements, i) => (
          <Grid item sm={6} md={4} key={i}>
            {achievements.map((achievement, j) => (
              <Box mb={2} key={j}>
                <BadgeCard achievement={achievement} />
              </Box>
            ))}
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <NoBadges />
        </Grid>
      )}
    </Grid>
  )
}

const BadgeCard = ({ achievement }) => {
  const [open, setOpen] = useState(false)
  const toggle = (ev) => {
    ev.stopPropagation()
    setOpen(!open)
  }
  return (
    <Card>
      <Collapse in={open}>
        <CardContent
          style={{ backgroundColor: 'rgba(0,0,0,0.2)', position: 'relative' }}
        >
          <CardMedia
            style={{
              height: 0,
              paddingTop: '56.25%', // 16:9
              backgroundSize: 'contain',
            }}
            image={achievement?.info?.imageUrl}
            alt={achievement?.info?.title}
          />
          {achievement ? null : (
            <Skeleton
              variant="rect"
              height="100%"
              width="100%"
              style={{ position: 'absolute', top: 0, left: 0 }}
            />
          )}
        </CardContent>
        <CardContent style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}>
          <Typography>
            <strong>{achievement?.info?.title ?? ''}</strong>
          </Typography>
          <Typography>{achievement?.info?.description ?? ''}</Typography>
        </CardContent>
        <Divider />
      </Collapse>
      <CardHeader
        onClick={toggle}
        avatar={
          achievement ? (
            <Avatar
              variant="square"
              alt={achievement?.achievement}
              src={achievement?.info?.iconUrl}
            >
              {achievement?.info?.type}
            </Avatar>
          ) : (
            <Skeleton variant="rect" width={40} height={40} />
          )
        }
        action={
          <IconButton onClick={toggle}>
            <ExpandMoreIcon
              style={{
                opacity: 0.4,
                transform: `rotate3d(${open ? 180 : 0}, 0, 0)`,
                transition: 'all 0.3s ease-in',
              }}
            />
          </IconButton>
        }
        title={
          achievement ? (
            achievement?.info?.title ?? ''
          ) : (
            <Skeleton variant="rect" style={{ marginBottom: 4 }} />
          )
        }
        subheader={
          achievement ? (
            achievement.date ? (
              new Date(Number(achievement?.date))?.toLocaleString()
            ) : (
              ''
            )
          ) : (
            <Skeleton variant="rect" width="80%" />
          )
        }
        titleTypographyProps={{ noWrap: true }}
        subheaderTypographyProps={{ noWrap: true }}
      />
    </Card>
  )
}

function rankAcheivements(achievements) {
  const achievementsByGroup = groupBy(achievements, 'info.group')

  return Object.keys(achievementsByGroup).reduce((acc, group) => {
    const achievement = achievementsByGroup[group][0]
    if (achievement?.info?.rank) {
      return acc.concat(
        orderBy(achievementsByGroup[group], 'info.rank', 'desc')[0],
      )
    }
    return acc.concat(achievementsByGroup[group])
  }, [])
}

const NoBadges = () => {
  return (
    <Paper>
      <Box p={2} py={4} textAlign="center">
        <h4 className="mb-2">No Badges Found</h4>
        <p className="mb-0">
          Read more about badges{' '}
          <a href={supportUri} target="_blank" rel="noopener noreferrer">
            here
          </a>
          .
        </p>
      </Box>
    </Paper>
  )
}

import { useMemo } from 'react'
import { UPGRADE_TYPE_QUERY, UPGRADE_TYPE_MUTATION } from './gql'
import { useMutation, useQuery } from 'react-apollo-hooks'

export const useUpgrade = () => {
  const { data } = useQuery(UPGRADE_TYPE_QUERY)
  const [mutate] = useMutation(UPGRADE_TYPE_MUTATION)
  const ca = (upgradeType) => {
    const action = () => mutate({ variables: { upgradeType } })
    action.type = upgradeType
    return action
  }
  const upgradePlusFHR = ca('UPGRADE_PLUS_FHR')
  const upgradeProFHR = ca('UPGRADE_PRO_FHR')
  const buyPlusFHR = ca('BUY_PLUS_FHR')
  const confirmPlusFHR = ca('confirmPlusFHR')
  const confirmProFHR = ca('confirmProFHR')
  const closeUpgrade = ca('')

  return [
    data?.upgradeType || null,
    {
      confirmPlusFHR,
      confirmProFHR,
      upgradePlusFHR,
      upgradeProFHR,
      buyPlusFHR,
      closeUpgrade,
    },
  ]
}

import {
  authChangeEmailUri,
  VerifyEmailResetDocument,
} from '@coin-microservices/auth-change-email-react-hooks'
import { makeApolloAuthProvider, getClient } from './apollo'

// const uri =
//   process.env.NODE_ENV === 'production'
//     ? 'http://localhost:4000'
//     : authChangeEmailUri

export const ChangeEmailProvider = makeApolloAuthProvider(authChangeEmailUri)

export const verifyRequest = async (code: string) => {
  const client = getClient(authChangeEmailUri)
  if (!code) throw new Error('Code is required')
  const response = await client.query({
    query: VerifyEmailResetDocument,
    variables: { id: code },
  })
  const request = response?.data?.verifyEmailReset
  return request
}

import { useMemo } from 'react'
import MaskedInput, { conformToMask } from 'react-text-mask'
import TextField from '@material-ui/core/TextField'
import Form, { FormField } from '../Form'

const accountInfoValidator = {
  email: (v) => (v ? '' : 'Please enter email'),
  firstName: (v) => (v ? '' : 'Please enter first name'),
  lastName: (v) => (v ? '' : 'Please enter last name'),
}

export default ({ onSubmit, initial, id }) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initial={initial}
      validator={accountInfoValidator}
    >
      <FormField
        name="email"
        component={TextField}
        label="Email"
        autoComplete="email"
        InputProps={{ type: 'email' }}
        variant="outlined"
        margin="normal"
        fullWidth
      />
      <FormField
        name="phone"
        component={PhoneNumberMaskField}
        label="Phone Number"
        helperText="(Optional)"
        autoComplete="phone"
        InputProps={{ type: 'tel' }}
        variant="outlined"
        margin="normal"
        fullWidth
      />
      <FormField
        style={{ color: '#000' }}
        name="firstName"
        component={TextField}
        autoComplete="given-name"
        label="First Name"
        variant="outlined"
        margin="normal"
        fullWidth
      />
      <FormField
        name="lastName"
        component={TextField}
        autoComplete="family-name"
        label="Last Name"
        variant="outlined"
        margin="normal"
        fullWidth
      />
    </Form>
  )
}

const phoneMask = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

const PhoneNumberMaskField = ({ onChange, ...props }) => {
  const value = useMemo(
    () => conformToMask(props.value, phoneMask, { guide: true }).conformedValue,
    [props.value],
  )
  const handleChange = (ev) => {
    const value = ev.target.value.replace(/\D/g, '')
    onChange(ev, value)
  }
  return (
    <TextField
      {...props}
      value={value}
      onChange={handleChange}
      InputProps={{ inputComponent: PhoneNumberMask }}
    />
  )
}

const PhoneNumberMask = ({ inputRef, ...props }) => {
  return (
    <MaskedInput
      {...props}
      ref={(ref) => inputRef(ref ? ref.inputElement : null)}
      mask={phoneMask}
      showMask
    />
  )
}

import { useEffect } from 'react'
import coinVertical from '../assets/CoinLogoWithText2.png'
import {
  QuestionsProvider,
  QuestionsList,
  QuestionsFilter,
} from '../components/Faq'
import AccordionItem from '../components/Faq/Accordion'
import { FooterLinks, FooterOfficeLocation } from '../components/Footer'
import { useScrollTopEffect } from '../components/Scroll'

const SupportLink = () => (
  <a className="support-link" href={process.env.REACT_APP_SUPPORT_URI}>
    For additional help, please contact support.
  </a>
)

const Faq = () => {
  useScrollTopEffect()
  return (
    <>
      <div className="container pb-40vh">
        <div className="row">
          <div className="offset-md-2 col-md-8 col">
            <div
              className="d-none d-md-block text-center mb-4 pt-md-5"
              style={{ cursor: 'pointer' }}
            >
              <img src={coinVertical} style={{ height: 90 }} alt="Coin logo" />
            </div>
            <QuestionsProvider>
              <h4 className="text-center text-white">How Can We Help?</h4>
              <QuestionsFilter />
              <QuestionsList className="accordion mb-5">
                <AccordionItem />
              </QuestionsList>
              <div className="text-center mb-4">
                <FooterOfficeLocation />
              </div>
              <div className="text-center mb-4">
                <SupportLink />
              </div>
            </QuestionsProvider>
            <div className="text-center mb-4">
              <FooterLinks />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function Script({ id, src }) {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = src
    script.id = id
    document.body.appendChild(script)
  }, [])
  return <></>
}

export default Faq

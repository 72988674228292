import React from 'react'
import { BossProvider } from '../context/bossapi'
import { UserInfoDisplay } from '../components/User'
import { useQuery } from 'react-apollo-hooks'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import get from 'lodash/get'
import gql from 'graphql-tag'

export default () => {
  return (
    <BossProvider>
      <ReferralsPage />
    </BossProvider>
  )
}

const ReferralsPage = () => {
  const { data, loading, error } = useQuery(REFERRALS)

  return (
    <div className="container pt-2 pb-40vh text-white text-center">
      <h4 className="mb-5">Referral Rewards</h4>
      {loading ? (
        <CircularProgress />
      ) : (
        <div className="row pt-1">
          <div className="col-12 offset-sm-2 col-sm-8 offset-md-4 col-md-4">
            <Tooltip
              enterDelay={200}
              title="Earn Referral Rewards For every new user who installs COIN using your special invite link, you'll receive 10% of the rewards they geomine - for life!"
            >
              <TextField
                variant="outlined"
                label={
                  <div className="d-flex align-items-center justify-content-center">
                    Installs <InfoIcon className="ml-2" />
                  </div>
                }
                value={get(data, 'referrals.installs') || 0}
                className="mb-4"
                InputProps={{
                  disabled: true,
                }}
                fullWidth
              ></TextField>
            </Tooltip>
            <Tooltip
              enterDelay={200}
              title="Help us grow COIN! Each person that installs COIN using your special invite link will receive 1000 for Free (Android Only)"
            >
              <TextField
                variant="outlined"
                label={
                  <div className="d-flex align-items-center justify-content-center">
                    Earnings <InfoIcon className="ml-2" />
                  </div>
                }
                value={`${get(data, 'referrals.earned') || 0}`}
                className="mb-4"
                InputProps={{
                  disabled: true,
                }}
                fullWidth
              ></TextField>
            </Tooltip>
            <Button
              variant="outlined"
              component="a"
              href="https://support.coinapp.co/hc/en-us/articles/360034676253"
              target="_blank"
              fullWidth
            >
              More Info
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

const REFERRALS = gql`
  query Referrals {
    referrals {
      installs
      earned
    }
  }
`

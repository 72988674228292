import { Suspense, useState, useMemo } from 'react'
import { BossProvider } from '../context/bossapi'
import { useQuery } from 'react-apollo-hooks'
import { BoundsMap } from '../components/Map'
import { numberFormat } from '../utils/number'
import { toYYYYMMDD, subTime, fromYYYYMMDD } from '../utils/date'
import { useScrollContext } from '../components/Scroll'
import Skeleton from '@material-ui/lab/Skeleton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from '../components/Table/Pagination'
import { AWSError } from '../components/Error'
import { View } from '../components/Footer'
import keyBy from 'lodash/keyBy'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import times from 'lodash/times'
import gql from 'graphql-tag'

export default () => (
  <BossProvider>
    <GeominesPage />
  </BossProvider>
)

const GeominesPage = () => {
  const scrollElement = useScrollContext()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const variables = useMemo(() => ({ page, perPage }), [page, perPage])
  const { data, loading, error } = useQuery(COLLECTIONS_DAILY_STATS, {
    variables,
  })
  const remoteRowCount = get(data, 'collectionsDailyStats.total')
  const mineCount = get(data, 'myUserInfo.mineCount')
  const rowData = get(data, 'collectionsDailyStats.data')

  const changePage = (p) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    setPage(p)
  }
  const handleChangePage = (event, newPage) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }

  return (
    <View>
      <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8 px-0">
        <div className="d-flex align-items-end justify-content-between text-white px-2">
          <h1 style={{ lineHeight: 1 }}>Geomines</h1>
          {mineCount ? (
            <p className="mb-2">
              Count:{' '}
              {mineCount.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}
            </p>
          ) : null}
        </div>
        <div className="position-relative list-group text-dark">
          {loading ? (
            times(perPage).map((a, i) => (
              <CollectionByDay key={i} loading={true} />
            ))
          ) : (rowData || []).length ? (
            (rowData || []).map((collection, i) => (
              <CollectionByDay
                loading={loading}
                key={collection.yyyymmdd}
                day={fromYYYYMMDD(collection.yyyymmdd)}
                {...collection}
              />
            ))
          ) : error ? (
            <AWSError error={error} />
          ) : (
            <NoGeoMines />
          )}
          {remoteRowCount && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25]}
              count={remoteRowCount}
              rowsPerPage={variables.perPage}
              page={variables.page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            ></TablePagination>
          )}
        </div>
      </div>
    </View>
  )
}

const CollectionByDay = ({
  day,
  count,
  collected,
  xyoCollected,
  coordinates,
  loading,
}) => {
  return (
    <div className="list-group-item d-flex align-items-center justify-content-between rounded-0-sm">
      {loading ? (
        <Skeleton
          variant="rect"
          height={140}
          width={140}
          className="skeleton"
        />
      ) : count ? (
        <div
          className="quadkey-map bg-dark mb-md-0 rounded overflow-hidden"
          style={{ height: 140, width: 140, position: 'relative' }}
        >
          <BoundsMap
            coordinates={coordinates}
            style={{ height: '100%', width: '100%' }}
          />
        </div>
      ) : null}
      <div />
      <div className="ml-4 text-right">
        {loading ? (
          <>
            <Skeleton
              variant="text"
              width={80}
              classes={{ animate: 'skeleton', root: 'ml-auto' }}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
            />
            <Skeleton
              variant="text"
              width={60}
              classes={{ animate: 'skeleton', root: 'ml-auto' }}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
            <Skeleton
              variant="text"
              width={60}
              classes={{ animate: 'skeleton', root: 'ml-auto' }}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
          </>
        ) : (
          <>
            <h4 className="mb-0">Geomined: {numberFormat(collected || 0)}</h4>
            {xyoCollected && collected ? (
              <>
                <p className="mb-0">
                  Start: {numberFormat(xyoCollected - collected)}
                </p>
                <p className="mb-0">End: {numberFormat(xyoCollected)}</p>
              </>
            ) : null}
            {/* {xyoCollected && (
              <p className="mb-0">
                Balance: <span>{numberFormat(xyoCollected || 0)}</span>
              </p>
            )} */}
            <p className="mb-0">
              Count: <span>{numberFormat(count || 0, 0)}</span>
            </p>
            <p className="mb-0 text-muted">
              <small>{day.toLocaleDateString()}</small>
            </p>
          </>
        )}
      </div>
    </div>
  )
}

const NoGeoMines = () => {
  return (
    <div className="list-group-item text-center py-5 rounded-0-sm">
      <h4>No Mines Found</h4>
      <p>
        Read more about geomines{' '}
        <a
          href="https://support.coinapp.co/hc/en-us/articles/360033279773-COIN-User-Guide#EarnAssetsID"
          target="_blank"
        >
          here
        </a>
        .
      </p>
    </div>
  )
}

const COLLECTIONS_BY_DAY = gql`
  query CollectionsByDay($start: Int!, $end: Int) {
    collectionsByDay(start: $start, end: $end) {
      id
      yyyymmdd
      uid
      count
      collected
      coordinates
    }
  }
`

const COLLECTIONS_DAILY_STATS = gql`
  query CollectionsDailyStats($page: Int, $perPage: Int) {
    myUserInfo {
      mineCount
    }
    collectionsDailyStats(pagination: { page: $page, perPage: $perPage }) {
      total
      page
      data {
        id
        yyyymmdd
        uid
        count
        collected
        xyoCollected
        coordinates
      }
    }
  }
`

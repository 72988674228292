import LinearProgress from '@material-ui/core/LinearProgress'
import Snackbar from '@material-ui/core/Snackbar'
import Alert, { AlertProps } from '@material-ui/lab/Alert'
import { createContext, FC, ReactNode, useContext, useEffect, useRef, useState } from 'react'


type SnackbarOptions = {
  severity?: AlertProps['severity']
  loading?: boolean
  autoHideDuration?: number
  message?: ReactNode
  action?: ReactNode
}

const defaultValue = {
  message: null,
  action: null,
  loading: false
}

const defaultActions = {
  setSnackbar: (v: SnackbarOptions) => console.warn('Snackbar provider not initialized'),
  closeSnackbar: () => console.warn('Snackbar provider not initialized'),
}

const SnackbarContext = createContext<[SnackbarOptions, typeof defaultActions]>([
  defaultValue,
  defaultActions
])

export const SnackbarProvider: FC = ({ children }) => {
  const [value, setValue] = useState(defaultValue)
  const ref = useRef(defaultValue)
  const closeSnackbar = () => {
    ref.current = defaultValue
    setValue(defaultValue)
  }
  const setSnackbar = (v: any) => {
    setValue(v)
    ref.current = v
    return () => {
      if (ref.current === v) {
        setValue(defaultValue)
      }
    }
  }
  return (
    <SnackbarContext.Provider value={[value, { setSnackbar, closeSnackbar }]}>
      {children}
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = () => {
  return useContext(SnackbarContext)
}

const Snack = () => {
  const [snackbar, { closeSnackbar }] = useSnackbar()
  const [progress, setProgress] = useState(0)
  const intervalId = useRef<NodeJS.Timeout>()
  const open = Boolean(snackbar.message)
  useEffect(() => {
    if (open && snackbar.autoHideDuration) {
      setProgress(0)
      intervalId.current = setInterval(() => {
        setProgress((v) => v + 10)
      }, snackbar.autoHideDuration / 10)
    }
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current)
      }
    }
  }, [open, snackbar.autoHideDuration])
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      color="danger"
      autoHideDuration={snackbar.autoHideDuration}
      onClose={closeSnackbar}
    >
      <Alert
        style={{ position: 'relative' }}
        severity={snackbar.severity}
        onClose={snackbar.loading ? () => { } : closeSnackbar}
      >
        {snackbar.loading ? (
          <LinearProgress
            style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
          />
        ) : null}
        {snackbar.autoHideDuration ? (
          <LinearProgress
            style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
            value={progress}
            variant="determinate"
          />
        ) : null}
        {snackbar.message}
      </Alert>
    </Snackbar>
  )
}

export default Snack

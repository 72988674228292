import React, { useEffect, useState } from 'react'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import { usePollFish } from '../../context/pollfish'
import { useSetAlertError } from '@xyo-network/coin-forms/build/lib/components/Alert'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import AlertTitle from '@material-ui/lab/AlertTitle'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import PollIcon from '@material-ui/icons/Poll'
import CloseIcon from '@material-ui/icons/Close'
import pollfishLogo from '../../assets/pollfish.png'
import { useUser } from '../../context/user'
import { useLocation } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeContext } from '../Sweepstakes/makeContext'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { useSnackbar } from '../Snackables'
import {
  trackSurveyDialogOpenEvent,
  trackSurveyDialogCloseEvent,
  trackSurveyPollfishEvent,
  trackSurveyPrecisionEvent,
} from '../../utils/events'

const useStyles = makeStyles((theme) =>
  createStyles({
    speedDialIcon: {
      transform: 'translateY(-1.5px)',
    },
    speedDial: {
      border: '3px solid #eee',
      boxSizing: 'border-box',
    },
  }),
)

const SurveyNav = () => {
  const { pathname } = useLocation()
  const classes = useStyles()
  const setAlertError = useSetAlertError()
  const setPrecisionOpen = usePrecisionSample()[1]
  const pollFish = usePollFish()
  const [modalOpen, setModalOpen] = useSurveyProvider()
  const [open, setOpen] = useState(false)
  const handleDialogOpen = () => {
    setModalOpen(true)
    trackSurveyDialogOpenEvent('fab', {})
  }
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handlePrecision = (ev) => {
    if (ev) ev.stopPropagation()
    setPrecisionOpen(true)
    trackSurveyPrecisionEvent('fab', {})
  }
  const handlePollfish = (ev) => {
    if (ev) ev.stopPropagation()
    pollFish
      .initialize()
      .then(() => pollFish.showFullSurvey())
      .then(handleClose)
    trackSurveyPollfishEvent('fab', {})
  }
  useEffect(() => {
    setPrecisionOpen(pathname.includes('precision'))
  }, [])
  return (
    <div
      className="position-absolute p-4 p-md-5"
      style={{ bottom: 0, right: 0, zIndex: 1 }}
    >
      <SpeedDial
        ariaLabel="Survey providers"
        classes={{ fab: classes.speedDial }}
        // hidden={hidden}
        icon={<SpeedDialIcon className={classes.speedDialIcon} />}
        onClose={handleClose}
        onOpen={handleOpen}
        onClick={handleDialogOpen}
        open={open}
        direction="up"
      >
        <SpeedDialAction
          icon={<PollIcon />}
          tooltipTitle="Precision"
          onClick={handlePrecision}
        />
        <SpeedDialAction
          icon={
            <img
              src={pollfishLogo}
              alt="Pollfish"
              style={{ height: 16, opacity: 0.8, transform: 'translateY(2px)' }}
            />
          }
          tooltipTitle="Pollfish"
          onClick={handlePollfish}
        />
      </SpeedDial>
    </div>
  )
}

export const useDisplaySuccess = (refetch) => {
  const { setSnackbar } = useSnackbar()[1]
  useEffect(() => {
    window.displaySurvey = (opts) => {
      const { severity, message } = getMessageOpts(opts)
      if (severity && message) {
        setSnackbar({
          severity,
          message,
          autoHideDuration: 6000,
        })
        refetch()
      }
    }
  }, [])
}

export const {
  Provider: SurveyProviderProvider,
  useContext: useSurveyProvider,
} = makeContext(false, 'SurveyProviderContext')

const SurveyProviderDialog = () => {
  const [open, setOpen] = useSurveyProvider()
  const setPrecisionOpen = usePrecisionSample()[1]
  const pollFish = usePollFish()
  const close = () => {
    setOpen(false)
    trackSurveyDialogCloseEvent('', {})
  }
  const handlePrecision = () => {
    setOpen(false)
    setPrecisionOpen(true)
    trackSurveyPrecisionEvent('dialog', {})
  }
  const handlePollfish = () => {
    setOpen(false)
    pollFish.initialize().then(() => pollFish.showFullSurvey())
    trackSurveyPollfishEvent('dialog', {})
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={close}>
      <CardHeader
        title="Survey Providers"
        action={
          <Tooltip title={<Typography>Close</Typography>}>
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <Box p={2}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handlePollfish}
          size="large"
        >
          <img
            src={pollfishLogo}
            alt="Pollfish"
            style={{ height: 16, marginRight: 4, transform: 'translateY(2px)' }}
          />{' '}
          Pollfish
        </Button>
      </Box>
      <Box p={2}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handlePrecision}
          size="large"
        >
          <PollIcon style={{ marginRight: 4 }} /> Precision
        </Button>
      </Box>
    </Dialog>
  )
}

export const {
  Provider: PrecisionSampleProvider,
  useContext: usePrecisionSample,
} = makeContext(false, 'PrecisionSampleContext')

const PrecisionSampleDialog = () => {
  const { user } = useUser()
  const [open, setOpen] = usePrecisionSample()
  const close = () => setOpen(false)
  return (
    <Dialog fullScreen open={open} onClose={close}>
      <CardHeader
        title="Precision Sample"
        action={
          <Tooltip title={<Typography>Return to COIN</Typography>}>
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        }
      />
      {user?.uid ? (
        <iframe
          src={`https://www.opinionetwork.com/reg/signup?rid=20895&ct=usa&extmid=${user?.uid}`}
          title="Precision Sample"
          height="100%"
          width="100%"
          frameBorder="0"
        />
      ) : null}
    </Dialog>
  )
}

export const SurveyProvider = ({ children }) => {
  return (
    <SurveyProviderProvider>
      <PrecisionSampleProvider>
        {children}
        <PrecisionSampleDialog />
        <SurveyProviderDialog />
      </PrecisionSampleProvider>
    </SurveyProviderProvider>
  )
}

export default SurveyNav

function getMessageOpts(opts = {}) {
  const provider = (opts.provider || '').toLowerCase()
  switch (provider) {
    case 'precision':
      return getPrecisionMessageOpts(opts)
    default:
      return {}
  }
}
function getPrecisionMessageOpts(opts = {}) {
  const type = (opts.type || '').toLowerCase()
  const severity = type === 'success' ? 'success' : 'error'
  const message = getPrecisionMessage(opts)
  return { severity, message }
}

function getPrecisionMessage(opts = {}) {
  const type = (opts.type || '').toLowerCase()
  const title = decodeURIComponent(opts.title)
  // const { reward, date } = opts
  switch ((type || '').toLowerCase()) {
    case 'success':
      return (
        <>
          <AlertTitle>{title}</AlertTitle>
          Survey successful
        </>
      )
    case 'term':
      return (
        <>
          <AlertTitle>{title}</AlertTitle>
          Survey terminated
        </>
      )
    case 'fraud':
      return (
        <>
          <AlertTitle>{title}</AlertTitle>
          Survey fraudulent
        </>
      )
    default:
      return null
  }
}

import { useRef, Children } from 'react'
import { useChain, useSpring, useTransition, animated } from 'react-spring'
export { config } from 'react-spring'

const runOnEnter = (from, to) => ({ delay, config, children, style = {} }) => {
  const springStyle = useSpring({ to, delay, config, from })
  return (
    <animated.div style={{ ...style, ...springStyle }}>{children}</animated.div>
  )
}

export const FadeIn = runOnEnter({ opacity: 0 }, { opacity: 1 })
export const SlideInUp = runOnEnter(
  { opacity: 0, transform: 'translate3d(0, 100%, 0)' },
  { opacity: 1, transform: 'translate3d(0, 0, 0)' },
)
export const SlideInDown = runOnEnter(
  { opacity: 0, transform: 'translate3d(0, -100%, 0)' },
  { opacity: 1, transform: 'translate3d(0, 0, 0)' },
)
export const SlideInLeft = runOnEnter(
  { opacity: 0, transform: 'translate3d(100%, 0, 0)' },
  { opacity: 1, transform: 'translate3d(0, 0, 0)' },
)
export const SlideInRight = runOnEnter(
  { opacity: 0, transform: 'translate3d(-100%, 0, 0)' },
  { opacity: 1, transform: 'translate3d(0, 0, 0)' },
)

export const SlideAllUp = ({ children, className, rowClassName }) => {
  const items = Children.toArray(children)
  const transitionRef = useRef()
  const transitions = useTransition(items, ({ props }) => props.id, {
    ref: transitionRef,
    unique: true,
    trail: 400,
    from: { opacity: 0, transform: 'translateY(100%)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(100%)' },
  })

  useChain([transitionRef])

  return (
    <div className={className}>
      {transitions.map(({ key, props, item }) => (
        <animated.div key={key} style={props} className={rowClassName}>
          {item}
        </animated.div>
      ))}
    </div>
  )
}

import React, { useMemo, useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Divider from '@material-ui/core/Divider'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import { useLocation } from 'react-router-dom'
import { parse } from 'querystring'

export const ThankYou = () => {
  const location = useLocation()
  const [config, setConfig] = useState(null)

  // const { text, btnText, btnUrl, email, tag } = useMemo(() => {
  //   window.history.pushState({})
  //   return { text, btnText, btnUrl, email, tag }
  // }, [location.search])

  useEffect(() => {
    if (!config) {
      const {
        text = 'Thank you for confirming!',
        btnText = 'Continue',
        btnUrl = '',
        email = '',
        tag = '',
      } = parse(location.search.slice(1))
      if (email && tag) {
        tagSubscriber(email, tag)
      }
      setConfig({ text, btnText, btnUrl })
      window.history.pushState({ path: '/thank-you' }, '', '/thank-you')
    }
  }, [])

  return (
    <div className="container pb-40vh pt-5 flex-grow align-items-center">
      <div className="row">
        <div className="offset-md-4 col-md-4 mb-5">
          <Card>
            <CardContent>
              <Slide in={true} direction="up">
                <div className="text-center">
                  <Typography paragraph variant="h4">
                    {config?.text}
                  </Typography>
                  <div>
                    <div className="d-inline-block bg-success my-4 rounded-circle">
                      <CheckIcon
                        className="p-2"
                        style={{ height: 120, width: 120 }}
                      />
                    </div>
                  </div>
                </div>
              </Slide>
            </CardContent>
            {config?.btnUrl ? (
              <>
                <Divider />
                <CardActions style={{ justifyContent: 'flex-end' }}>
                  <Button
                    component="a"
                    href={config?.btnUrl}
                    rel="noreferrer nofollow"
                    target="_blank"
                    variant="contained"
                    color="secondary"
                  >
                    {config?.btnText}
                  </Button>
                </CardActions>
              </>
            ) : null}
          </Card>
        </div>
      </div>
    </div>
  )
}

async function tagSubscriber(email, tag) {
  await fetch(
    `https://coin-service-users-server-zatxacztoa-uc.a.run.app/drip/subscriber/tag`,
    {
      method: 'POST',
      body: JSON.stringify({ email, tag }),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export default ThankYou

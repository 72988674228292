import React from 'react'
import cx from 'classnames'

const ccMatch = {
  visa: isVisa,
  mastercard: isMasterCard,
  discover: isDiscover,
  amex: isAmex,
}
const ccTypes = Object.keys(ccMatch)

const CCIcon = ({ type, active }) => (
  <i
    style={{ opacity: active ? 1 : 0.3 }}
    className={cx('text-dark mb-1 mr-1 fab fa-2x', `fa-cc-${type}`)}
  />
)

export default ({ ccNumber }) => {
  return (
    <div className="d-flex">
      {ccTypes.map((type) => (
        <CCIcon key={type} type={type} active={ccMatch[type](ccNumber)} />
      ))}
    </div>
  )
}

export function isValidCreditCard(ccNumber) {
  return Object.keys(ccMatch).some((type) => ccMatch[type](ccNumber))
}

export function isVisa(ccNumber) {
  return /^4[0-9]{15}$/.test(ccNumber)
}

export function isMasterCard(ccNumber) {
  return /^5[1-5][0-9]{14}$/.test(ccNumber)
}

export function isDiscover(ccNumber) {
  return /^(?:6011\d{12})|(?:65\d{14})$/.test(ccNumber)
}

export function isAmex(ccNumber) {
  return /^3[47][0-9]{13,14}$/.test(ccNumber)
}

import React from 'react'
import { trackCancelEvent, trackWarning } from '../../utils/events'
import {
  swalConfirm,
  swalLoader,
  swalSuccess,
  swalError,
} from '../../components/Alert'
import {
  useCancelSubscriptionMutation,
  RebillySubscription,
  AccountDocument,
} from '@xyo-network/api-checkout-apollo'
import { parseError } from '../../utils/error'
import { DangerBtn } from '../Buttons'

export const CancelSubscription = ({
  subscription,
}: {
  subscription?: RebillySubscription | null
}) => {
  const [mutate] = useCancelSubscriptionMutation({
    variables: { subscriptionId: subscription ? subscription.id : '' },
    refetchQueries: [{ query: AccountDocument }],
  })
  const cancelSubscription = async () => {
    if (!mutate || !subscription) return
    const nextBillingDate = subscription.renewalTime
      ? new Date(subscription.renewalTime).toLocaleString()
      : ''
    const { value } = await swalConfirm(
      nextBillingDate
        ? `You can still use your current plan until ${nextBillingDate}.`
        : '',
    )
    if (!value) return trackCancelEvent('canceled', {})
    try {
      trackCancelEvent('confirmed', {})
      swalLoader(<p>Cancelling Subscription...</p>)
      await mutate()
      swalSuccess(
        'Sorry to see you go... You can reactivate the subscription at anytime.',
      )
      trackCancelEvent('success', {})
    } catch (e) {
      trackWarning('Cancel subscription error', e.message)
      trackCancelEvent('error', { value: e.message })
      swalError(parseError(e.message))
    }
  }

  return subscription ? (
    <DangerBtn onClick={cancelSubscription}>Cancel Subscription</DangerBtn>
  ) : null
}

export default CancelSubscription

import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import times from 'lodash/times'
import React from 'react'
import { useUser } from '../../context/user'
import { SuccessButton } from './Buttons'
import {
  getWinner,
  SweepstakesCompletedDialogType,
  SweepstakesEntryDialogOpen,
  SweepstakesEntrySelected,
  SweepstakesEntryStep,
  SweepstakesEntryTermsAgreed,
  sweepstakesEvent,
  SweepstakesOptionsContext,
  SweepstakesSize,

  // SweepstakesProvider,
  SweepstakesWhitelistProvider,
  useSweepstakesOptions,
  useSweepstakesOptionsData,
  useSweepstakesOptionsEnded,
  useSweepstakesOptionsLoading,
} from './context'
import {
  SweepstakesDetailsAvatar,
  SweepstakesDetailsLargeListItemText,
  SweepstakesDetailsListItemText,
} from './Details'
import { EntryDialog } from './Dialog'
import { SweepstakesDownloadDialogOpen } from './Download'
import { emojis } from './emojis'
import { popParticles } from './particle'
import { residentStorage, useResidentsOfUsMessage } from './Resident'

export const CurrentSweepstakesListItems = () => {
  const options = useSweepstakesOptionsData()
  const count = options?.length ?? 4

  return (
    <>
      {times(count).map((_, i) => (
        <SweepstakesOptionsContext.Provider
          value={options?.[i] ?? null}
          key={options?.[i]?.id ?? i}
        >
          <SweepstakesListItem />
        </SweepstakesOptionsContext.Provider>
      ))}

      <EntryDialog />
    </>
  )
}

export const useOnOpenSweestakes = () => {
  const { user } = useUser()
  const [selected, setSelected] = SweepstakesEntrySelected.useContext()
  const [step, setStep] = SweepstakesEntryStep.useContext()
  const setCompleteDialog = SweepstakesCompletedDialogType.useContext()[1]
  const setEntryTermsAgreed = SweepstakesEntryTermsAgreed.useContext()[1]
  const setDownloadOpen = SweepstakesDownloadDialogOpen.useContext()[1]
  const setOpen = SweepstakesEntryDialogOpen.useContext()[1]
  const ended = useSweepstakesOptionsEnded()
  const options = useSweepstakesOptions()
  const displayResidentsOfUsMessage = useResidentsOfUsMessage()

  const onOpen = async (ev: any) => {
    if (ended) {
      await popParticles(
        emojis?.data?.emojiOptions?.reduce(
          (acc, { id, icon }) => ({
            ...acc,
            [icon]: options?.userEntry?.reactions?.[id] ?? 0,
          }),
          {},
        ),
        ev.clientX,
        ev.clientY,
      ).catch((e) => console.warn('Animation failed'))
      setSelected(options)
      setCompleteDialog('ended')
      return
    }

    if (options?.inactive) {
      setCompleteDialog('inactive')
      return
    }

    if (!user) {
      sweepstakesEvent('download', 'opened', {})
      setDownloadOpen(true)
      return
    }

    const resident = residentStorage.get()
    if (resident === 'no') {
      displayResidentsOfUsMessage()
      sweepstakesEvent('dialog', 'non-resident-of-us', {})
    } else {
      sweepstakesEvent('dialog', 'opened', {})
      setSelected(options)
      setOpen(true)

      const nextStep = selected?.id !== options?.id ? 0 : step
      if (selected?.id !== options?.id) {
        setEntryTermsAgreed(false)
      }

      if (nextStep === 0 && resident === 'yes') {
        setStep(1)
      } else {
        setStep(nextStep)
      }
    }
  }
  return onOpen
}

const SweepstakesListItem = () => {
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const [size] = SweepstakesSize.useContext()

  const options = useSweepstakesOptions()
  const loading = useSweepstakesOptionsLoading()
  const classes = useStyles({ size })
  const onOpen = useOnOpenSweestakes()

  const item = (
    <ListItem
      button
      className={classes.listItem}
      disabled={loading}
      onClick={onOpen}
    >
      <ListItemAvatar style={{ minWidth: size === 'lg' ? 84 : 56 }}>
        <SweepstakesDetailsAvatar size={size === 'lg' ? 64 : 40} />
      </ListItemAvatar>
      {size === 'lg' && isSmUp ? (
        <>
          <SweepstakesDetailsLargeListItemText />
          <ListItemSecondaryAction>
            <SweepstakesEnterButton onClick={onOpen} />
          </ListItemSecondaryAction>
        </>
      ) : size === 'lg' ? (
        <>
          <SweepstakesDetailsLargeListItemText
            action={<SweepstakesEnterButton onClick={onOpen} size="small" />}
          />
        </>
      ) : (
        <>
          <SweepstakesDetailsListItemText />
          <ListItemSecondaryAction>
            <SweepstakesEnterButton onClick={onOpen} />
          </ListItemSecondaryAction>
        </>
      )}
    </ListItem>
  )

  if (options?.test) {
    return <SweepstakesWhitelistProvider>{item}</SweepstakesWhitelistProvider>
  }

  return item
}

export const SweepstakesEnterButton = ({
  size,
  onClick,
  ...props
}: {
  size?: string
  onClick?: (ev: any) => any
  loading?: boolean
}) => {
  const loading = useSweepstakesOptionsLoading() || props.loading
  const options = useSweepstakesOptions()
  const ended = useSweepstakesOptionsEnded()
  const winner = getWinner(options)
  const backgroundColor = winner?.confirmed ? '#EE7268' : ended ? '#8B8B8B' : ''

  return (
    <SuccessButton
      onClick={onClick}
      variant="contained"
      color={'secondary'}
      disableElevation
      disabled={loading}
      size={size === 'small' ? 'small' : 'medium'}
      style={
        size === 'small'
          ? { padding: 0, minWidth: 50, backgroundColor }
          : { backgroundColor }
      }
    >
      {winner?.confirmed ? 'Results' : ended ? 'Ended' : 'Enter'}
    </SuccessButton>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    listItem: {
      paddingRight: (props: any) => (props.size === 'lg' ? 16 : 102),
    },
  }),
)

import { FC } from 'react'
import fetch from 'isomorphic-fetch'
import { ApolloProvider } from '@apollo/client'
import {
  CheckoutDocument,
  AddPaymentDocument,
  CheckoutMutationVariables,
  AddPaymentMutationVariables,
  Address,
  CustomerInput,
  AddressInput,
  AccountDocument,
  getCheckoutClient,
} from '@xyo-network/api-checkout-apollo'
import { trackWarning } from '../utils/events'
import { utmParams } from '../utils/search'
import capitalize from 'lodash/capitalize'
import first from 'lodash/first'
// import omit from 'lodash/omit'
import get from 'lodash/get'
import firebase from 'firebase/app'
import 'firebase/auth'

type Stage = 'production' | 'development' | 'local'
const CHECKOUT_API = process.env.REACT_APP_CHECKOUT_API as Stage
const REBILLY_URL = process.env.REACT_APP_REBILLY_URL
const REBILLY_KEY = process.env.REACT_APP_REBILLY_KEY

export const checkoutClient = getCheckoutClient(CHECKOUT_API, async () => {
  const user = firebase.auth().currentUser
  const token = user ? await user.getIdToken() : ''
  return {
    headers: {
      'X-Stage':
        CHECKOUT_API === 'local' || CHECKOUT_API === 'development'
          ? 'sandbox'
          : 'live',
      'X-Auth-Token': token,
    },
  }
})

export const CheckoutProvider: FC = ({ children }) => {
  return <ApolloProvider client={checkoutClient}>{children}</ApolloProvider>
}

interface PaymentInfo {
  method: string
  ccNumber: string
  ccExpMonth: string
  ccExpYear: string
  ccCvv: string
}

export const redeemSubscription = async (email: string): Promise<any> => {
  const res = await fetch(
    `${
      process.env.REACT_APP_COIN_SUBSCRIPTIONS_API
    }/coin_subscriptions/redeem/${email}${
      process.env.NODE_ENV === 'development' ? '?mode=sandbox' : ''
    }`,
  )
  const data = await res.json()
  await checkoutClient.query({
    query: AccountDocument,
    fetchPolicy: 'network-only',
  })
  return data
}

export const redeemInitialSuccess = async (
  planType: string,
  coinUserId: string,
  rebillyCustomerId: string,
): Promise<any> => {
  const res = await fetch(
    `${process.env.REACT_APP_COIN_SUBSCRIPTIONS_API}/coin_subscriptions/redeem/initial-success`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        planType,
        coinUserId,
        rebillyCustomerId,
      }),
    },
  )
  const data = await res.json()
  return data
}

export const createPaymentToken = async (
  { method, ccNumber, ccExpMonth, ccExpYear, ccCvv }: PaymentInfo,
  billingAddress: Address,
) => {
  const leadSource = getLeadSource()
  const payloadData = {
    method: method || '',
    billingAddress,
    leadSource,
    paymentInstrument: {
      pan: ccNumber,
      expMonth: ccExpMonth,
      expYear: ccExpYear,
      cvv: ccCvv,
    },
  }
  const response = await fetch(
    `${REBILLY_URL}/tokens`,
    postOptions(payloadData, { Authorization: REBILLY_KEY }),
  )
  const data = await response.json()
  const errorDetails = capitalize(first(get(data, 'details')))
  const error = get(data, 'error')
  if (errorDetails) {
    throw new Error(errorDetails)
  }
  if (error) {
    throw new Error(error)
  }
  return data
}

export const checkout = async (
  planId: string,
  paymentCardId: string,
  customer: CustomerInput,
  billing: AddressInput,
  shipping: AddressInput,
) => {
  const variables: CheckoutMutationVariables = {
    planId,
    paymentCardId,
    customer,
    billing, //: omit(billing, ['email', 'firstName', 'lastName']),
    shipping, //: omit(shipping, ['email', 'firstName', 'lastName']),
  }
  let result
  try {
    result = await checkoutClient.mutate({
      mutation: CheckoutDocument,
      variables,
      refetchQueries: [{ query: AccountDocument }],
    })
  } catch (e) {
    trackWarning('Checkout Error', e.message)
    throw e
  }
  try {
    await redeemSubscription(customer.email)
    await checkoutClient.query({
      query: AccountDocument,
      fetchPolicy: 'network-only',
    })
  } catch (e) {
    trackWarning('Redeem Error', e.message)
    throw e
  }
  return result
}

export const addPaymentCard = async (
  paymentToken: string,
  customer: CustomerInput,
) => {
  const variables: AddPaymentMutationVariables = {
    paymentToken,
    customer,
  }
  const result = await checkoutClient.mutate({
    mutation: AddPaymentDocument,
    variables,
  })
  const paymentCardId = get(
    result,
    'data.addPayment.defaultPaymentInstrument.paymentCardId',
  )
  return paymentCardId
}

function postOptions(body: any, headers: any): any {
  return {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  }
}

function getLeadSource() {
  return {
    medium: get(utmParams, 'utm_medium') || '',
    source: get(utmParams, 'utm_source') || '',
    campaign: get(utmParams, 'utm_campaign') || '',
    term: '{{placement}}',
    content: '{{campaign.name}}',
    affiliate: '{{ad.name}}',
    subAffiliate: 'paid',
    salesAgent: 'virtual',
    clickId: 'sight',
    path: 'my.coinapp.co',
    currency: '',
    amount: '',
  }
}

import React from 'react'
import WarningOutlined from '@material-ui/icons/WarningOutlined'
import Check from '@material-ui/icons/Check'
import Cancel from '@material-ui/icons/Cancel'
import Remove from '@material-ui/icons/Remove'

export const invoiceStatusColor = status => {
  switch (status) {
    case 'past-due':
      return 'danger'
    case 'paid':
      return 'success'
    case 'unpaid':
      return ''
    case 'canceled':
      return 'danger'
    case 'refunded':
      return ''
  }
}

export const InvoiceStatusIcon = ({ status, ...props }) => {
  switch (status) {
    case 'past-due':
      return <WarningOutlined {...props} />
    case 'paid':
      return <Check {...props} />
    case 'unpaid':
      return <WarningOutlined {...props} />
    case 'canceled':
      return <Cancel {...props} />
    case 'refunded':
      return <Remove {...props} />
  }
  return null
}

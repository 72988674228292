import {
  SweepstakeOptions,
  useAllCurrentSweepstakeOptionsQuery,
} from '@coin-microservices/sweepstakes-react-hooks'
import { useMemo, createContext, useContext } from 'react'

export const infoUri =
  'https://support.coinapp.co/hc/en-us/articles/360058411214'

export const SweepstakesOptionsContext = createContext<SweepstakeOptions | null>(
  null,
)

export const useSweepstakesOptionsError = () => {
  const { error } = useAllCurrentSweepstakeOptionsQuery()
  return error
}

export const useSweepstakesOptionsData = () => {
  const { data } = useAllCurrentSweepstakeOptionsQuery()
  return data?.allCurrentSweepstakeOptions
}

export const useSweepstakesOptionsLoading = () => {
  const { loading } = useAllCurrentSweepstakeOptionsQuery()
  return loading
}

export const useSweepstakesOptions = () => {
  const options = useContext(SweepstakesOptionsContext)
  return options
}

export const getWinner = (options?: SweepstakeOptions | null) => {
  return options?.winners?.[0]
}

export const useSweepstakesOptionsAmountPerEntry = () => {
  return useSweepstakesOptions()?.amountPerEntry ?? 0
}

export const useSweepstakesOptionsEnded = () => {
  const endDate = useSweepstakesOptions()?.endDate
  return useMemo(() => isPast(endDate), [endDate])
}

export const isPast = (endDate: string) => {
  if (!endDate) return false
  return new Date(endDate).valueOf() < Date.now()
}

export function pluralEntries(entryCount: number) {
  return entryCount === 1 ? 'entry' : 'entries'
}

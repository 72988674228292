import React from 'react'
import { FooterLinks } from '../components/Footer'
import { useScrollTopEffect } from '../components/Scroll'

const Privacy = () => {
  useScrollTopEffect()
  return (
    <div className="text-white pt-5 pb-40vh container">
      <h1>Privacy Policy</h1>
      <p>
        <strong>Last Updated: January 25, 2019</strong>
      </p>
      <p>
        Your privacy is important to us. This Privacy Policy explains the
        personal information that XY – the Persistent Company (“XY”) processes,
        how we process it, and for what purposes.
      </p>
      <p>
        XY offers various products, services, apps and devices designed to help
        businesses and consumers leverage location technology, including this
        mobile application, location beacons, and the XY Oracle Network (“XYO
        Network”), all of which are referred to as XY “services” in this Policy.
      </p>
      <p>
        Please read this Policy carefully. This Policy applies to XY services
        identified herein, as well as other XY services that display this
        Policy.
      </p>
      <h2>Personal Information We Collect</h2>
      <p>
        Personal information is information about you or your use of XY services
        that can be used to identify you. When you interact with our services,
        XY may collect some of your personal information. You provide some of
        this data directly, such as providing your name and email when creating
        an account. We get some of it by collecting data about your use of the
        services. The data we collect depends on the nature of your interactions
        with us and the choices you make, including your privacy settings for
        certain services and the features you use. We may also obtain data about
        you from third parties.
      </p>
      <p>
        We rely on a variety of legal bases to process personal information—such
        as your consent, necessity to enter into or perform contracts,
        compliance with legal obligations, or a balancing of legitimate
        interests—for a variety of purposes described below.
      </p>
      <p>
        You have choices when it comes to the services you use and the data you
        share. When we ask you to provide personal data, you may decline. In
        many instances, we require some of your personal data in order to
        provide the service. If you choose not to provide data necessary to
        provide you with a particular service, you cannot use that service.
      </p>
      <p>The data we may collect can include the following:</p>
      <p>
        <strong>Name and contact data.</strong> Your first and last name, email
        address, postal address, phone number, and other similar contact data.
      </p>
      <p>
        <strong>Credentials.</strong> Passwords, password hints, and similar
        security information used for authentication and account access.
      </p>
      <p>
        <strong>Demographic data.</strong> Data about you such as your age,
        gender, country, and preferred language.
      </p>
      <p>
        <strong>Payment data.</strong> Data to process payments, such as your
        account number (a credit card number, for example) and the security code
        associated with the account.
      </p>
      <p>
        <strong>Token issuance data.</strong> In the event that you are issued
        tokens to use on the XYO Network, we will collect data sufficient to
        validate your eligibility, verify your identity and comply with XY’s
        internal procedures and applicable law, including, for example, a
        government issued identification number such as a tax number, drivers’
        license number, or personal identification number.
      </p>
      <p>
        <strong>Mobile application data.</strong> If you install an XY mobile
        application on your device, you will be asked to allow us access to
        certain information on your device, including your location data and
        other system tools.
      </p>
      <p>
        <strong>Interactions.</strong> Data about your use of XY services. In
        some cases, such as our geolocation services, this is data you provide
        in order to make use of the service. In other cases, such as error
        reports, this is data we generate. Other examples of interactions data
        include:
      </p>
      <ul>
        <li>
          <strong>Device and usage data.</strong> Data about your device and the
          product and features you use, including information about your
          hardware and software, how our products perform, as well as your
          settings. For example:
        </li>
        <li>
          <strong>Account history.</strong> Data about the activities associated
          with your account.
        </li>
        <li>
          <strong>Location data.</strong> Data about your device’s location,
          which can be either precise or imprecise. For example, we collect
          location data using Global Navigation Satellite System (GNSS) (GPS,
          for example) and data about nearby cell towers and Wi-Fi hotspots.
          Location can also be inferred from a device’s IP address or data in
          your account profile that indicates where it is located with less
          precision, such as at a city or postal code level.
        </li>
        <li>
          <strong>Device, connectivity, and configuration data.</strong> Data
          about your device, your device configuration, and nearby networks.
          Such examples include your IP address, device type and model,
          manufacturer, operating system (e.g. iOS or Android), carrier name,
          mobile browser (e.g. Chrome, Safari), identifiers assigned to your
          device (such as its advertising ID), unique device identifier (a
          number uniquely allocated to your device by your device manufacturer),
          the time and your time zone, network connection type (e.g., WiFi,
          cellular), and cookie information, system configuration information,
          and metadata concerning your files.
        </li>
        <li>
          <strong>Error reports and performance data.</strong> Data about the
          performance of the services and any problems you experience, including
          error reports. Error reports can include details of the software or
          hardware related to an error, and data about other software on your
          device.
        </li>
        <li>
          <strong>Troubleshooting and help data.</strong> Data you provide when
          you contact XY for help, such as the services you use, and other
          details that help us provide support. For example, contact or
          authentication data, data about the condition of your device, and the
          services you use related to your help inquiry. When you contact us,
          such as for customer support, phone conversations or chat sessions
          with our representatives may be monitored and recorded.
        </li>
        <li>
          <strong>Text and typing data.</strong> Text and typing data and
          related information. For example, if you create a name for your
          location beacon or other device connected to your account, we may
          collect that information.
        </li>
        <li>
          <strong>Contact and inquiry data.</strong> When you contact us, we
          collect information that helps us categorize and respond to your
          question. We also use this information to track potential problems and
          trends and customize our support responses to better serve you.
        </li>
        <li>
          <strong>Other input.</strong> Other inputs provided when you use our
          services.
        </li>
      </ul>
      <p>
        <strong>Content.</strong> Content of your files and communications you
        input, upload, receive, create, and control. For example, you may choose
        to communicate a customer support request to us in writing. Other
        content we collect when providing products to you include:
      </p>
      <ul>
        <li>
          Communications, including audio, video, text (typed or otherwise), in
          a message, email, call, meeting request, or chat.
        </li>
        <li>
          Photos, images, songs, movies, software, and other media or documents
          you store, retrieve, or otherwise process within our services.
        </li>
      </ul>
      <p>
        <strong>Feedback and ratings.</strong> Information you provide to us and
        the content of messages you send to us, such as feedback, survey data,
        and product reviews you write.
      </p>
      <p>
        <strong>How We Use Personal Information</strong>
      </p>
      <p>
        When we process personal data about you, we do so with your consent or
        as necessary to provide the products you use, operate our business, meet
        our contractual and legal obligations, protect the security of our
        systems and our customers, or fulfill other legitimate interests of XY
        as described in this section and in the Reasons We Share Personal
        Information section of this Policy.
      </p>
      <p>More specifically, XY uses the data we collect to:</p>
      <ul>
        <li>
          Provide our services and administer your use of them, which includes
          updating, securing, and troubleshooting, as well as providing support.
          It also includes sharing data, when it is required to provide the
          service or carry out the transactions you request.
        </li>
        <li>Improve and develop our services.</li>
        <li>Personalize your experience with our services.</li>
        <li>
          Advertise and market to you, which includes sending promotional
          communications, targeting advertising, and presenting you relevant
          offers.
        </li>
        <li>Notify you about changes to the services.</li>
        <li>
          Contact you regarding important XY updates or feature announcements,
          or to obtain feedback from you regarding the services.
        </li>
        <li>
          Fulfill any other purpose we may describe when you provide the
          information.
        </li>
        <li>Fulfill any other purpose with your consent.</li>
        <li>
          Ensure proper functioning of the XYO Network, XYO Tokens and other
          services.
        </li>
        <li>
          Operate our business, which includes analyzing our performance and
          research.
        </li>
        <li>
          Comply with legal obligations. For example, we use the age of our
          customers to ensure we meet our obligations to protect children’s
          privacy. We also process contact information and credentials to help
          customers exercise their data protection rights.
        </li>
        <li>
          Enforce or apply our terms of service or other agreements, including
          for billing and collection purposes.
        </li>
        <li>
          To protect the rights, property, or safety of our employees, customers
          or others, or to prevent or stop any activity we may consider to be a
          risk of being illegal, unethical, inappropriate or legally actionable.
        </li>
      </ul>
      <p>
        <strong>Reasons We Share Personal Information</strong>
      </p>
      <p>
        We share your personal data with your consent or as necessary to
        complete any transaction or provide any service you have requested,
        accessed or authorized. When you provide payment data to make a
        purchase, we will share payment data with banks and other entities that
        process payment transactions or provide other financial services, and
        for fraud prevention and credit risk reduction.
      </p>
      <p>
        In addition, we share personal data with service providers or agents
        working on our behalf for the purposes described in this Policy. We may
        employ third party companies and individuals to facilitate, enhance or
        secure the services, to provide the services on our behalf, to assist us
        in analyzing how the services are used (such as website analytics), and
        to perform functions related to administration of the services
        (including, without limitation, maintenance, hosting and database
        management services, website analytics and administration).
      </p>
      <p>
        We may also disclose personal data as part of a corporate transaction
        such as a merger or sale of assets.
      </p>
      <p>
        Finally, we will retain, access, transfer, disclose, and preserve
        personal data when we have a good faith belief that doing so is
        necessary to do any of the following:
      </p>
      <ul>
        <li>
          Comply with applicable law or respond to valid legal process,
          including from law enforcement or other government agencies.
        </li>
        <li>
          Protect our customers, for example, to prevent spam or attempts to
          defraud users of our services, or to help prevent the loss of life or
          serious injury to anyone.
        </li>
        <li>
          Operate and maintain the security of our products, including to
          prevent or stop an attack on our computer systems or networks.
        </li>
        <li>
          Protect the rights or property of XY, including enforcing the terms
          governing the use of the services, and referring appropriate matters
          to law enforcement.
        </li>
      </ul>
      <p>
        Please note that some of our services include links to services of third
        parties whose privacy practices differ from those of XY. If you provide
        personal data to any of those services, your data is governed by their
        privacy policies.
      </p>
      <p>
        We do not share, sell, rent or disclose your personal information to
        third parties for the third parties’ direct marketing purposes.
      </p>
      <p>
        <strong>Cookies and Similar Technologies</strong>
      </p>
      <p>
        Cookies are small files saved to a user’s device that track, save and
        store information about the user's interactions and usage of the XY
        websites. This allows each website, through its servers, to provide the
        user with a tailored experience within the website. This data often
        consists of a string of numbers and letters that uniquely identifies
        your computer, but it can contain other information as well. Some
        cookies are placed by third parties acting on our behalf. We use cookies
        and similar technologies to store and honor your preferences and
        settings, enable you to sign-in, provide interest-based advertising,
        combat fraud, analyze how our services perform, and fulfill other
        legitimate purposes described below. XY apps may use additional
        identifiers for similar purposes, and our websites and applications may
        also contain web beacons or other similar technologies, as described
        below.
      </p>
      <p>
        <strong>Our use of cookies and similar technologies</strong>
      </p>
      <p>
        XY uses cookies and similar technologies for several purposes, depending
        on the context or service, including:
      </p>
      <ul>
        <li>
          <strong>Storing your preferences and settings.</strong> We use cookies
          to store your preferences and settings on your device, and to enhance
          your experiences. Saving your preferences with cookies, such as your
          preferred language, prevents you from having to set your preferences
          repeatedly.
        </li>
        <li>
          <strong>Sign-in and authentication.</strong> We use cookies to
          authenticate you. When you sign in to a website using your account, we
          store a unique ID number, and the time you signed in, in an encrypted
          cookie on your device. This cookie allows you to move from page to
          page within the site without having to sign in again on each page. You
          can also save your sign-in information so you do not have to sign in
          each time you return to the site.
        </li>
        <li>
          <strong>Security.</strong> We use cookies to process information that
          helps us secure our services, as well as detect fraud and abuse.
        </li>
        <li>
          <strong>Storing information you provide to a website.</strong> We use
          cookies to remember information you shared. When you provide
          information to XY, we store the data in a cookie for the purpose of
          remembering the information.
        </li>
        <li>
          <strong>Social media.</strong> Some of our websites may include social
          media cookies, including those that enable users who are signed in to
          the social media service to share content via that service.
        </li>
        <li>
          <strong>Feedback.</strong> XY uses cookies to enable you to provide
          feedback on a website.
        </li>
        <li>
          <strong>Interest-based advertising.</strong> XY uses cookies to
          collect data about your online activity and identify your interests so
          that we can provide advertising that is most relevant to you.
        </li>
        <li>
          <strong>Displaying advertising.</strong> XY uses cookies to record how
          many visitors have clicked on an advertisement and to record which
          advertisements you have seen, for example, so you don’t see the same
          one repeatedly.
        </li>
        <li>
          <strong>Analytics.</strong> We use first- and third-party cookies and
          other identifiers to gather usage and performance data. For example,
          we use cookies to count the number of unique visitors to a web page or
          service and to develop other statistics about the operations of our
          services.
        </li>
        <li>
          <strong>Performance.</strong> XY uses cookies to understand and
          improve how our products perform. For example, we use cookies to
          gather data that helps with load balancing; this helps ensure that our
          websites remain up and running.
        </li>
      </ul>
      <p>
        In addition to the cookies XY sets when you visit our websites, third
        parties can also set cookies when you visit our sites. For example:
      </p>
      <ul>
        <li>
          Companies we hire to provide services on our behalf, such as site
          analytics, place cookies when you visit our sites.
        </li>
        <li>
          Companies that deliver content, such as videos or news, or ads on XY
          sites, place cookies on their own. These companies use the data they
          process in accordance with their privacy policies, which may enable
          these companies to collect and combine information about your
          activities across websites, apps, or online services.
        </li>
      </ul>
      <p>
        <strong>How you can control cookies</strong>
      </p>
      <p>
        Most web browsers automatically accept cookies but provide controls that
        allow you to block or delete them. Please refer to your browser's
        privacy or help documentation to find instructions for blocking or
        deleting cookies in other browsers.
      </p>
      <p>
        Certain features of XY services depend on cookies. If you choose to
        block cookies, you cannot sign in or use some of those features, and
        preferences that are dependent on cookies will be lost. If you choose to
        delete cookies, any settings and preferences controlled by those
        cookies, including advertising preferences, are deleted and will need to
        be recreated.
      </p>
      <p>
        <strong>Our use of web beacons and analytics services</strong>
      </p>
      <p>
        Some XY services, such as our website, contain electronic tags known as
        web beacons that we use to help deliver cookies on our websites, count
        users who have visited those websites, and deliver co-branded products.
        We also include web beacons or similar technologies in our electronic
        communications to determine whether you open and act on them.
      </p>
      <p>
        In addition to placing web beacons on our own websites, we sometimes
        work with other companies to place our web beacons on their websites or
        in their advertisements. This helps us develop statistics on how often
        clicking on an advertisement on a XY website results in a purchase or
        other action on the advertiser's website.
      </p>
      <p>
        Finally, XY services may contain web beacons or similar technologies
        from third-party analytics providers, which help us compile aggregated
        statistics about the effectiveness of our promotional campaigns or other
        operations. These technologies enable the analytics providers to set or
        read their own cookies or other identifiers on your device, through
        which they can collect information about your online activities across
        applications, websites, or other products. However, we prohibit these
        analytics providers from using web beacons on our sites to collect or
        access information that directly identifies you (such as your name or
        email address).
      </p>
      <p>
        <strong>Other similar technologies</strong>
      </p>
      <p>
        In addition to standard cookies and web beacons, our services can also
        use other similar technologies to store and read data files on your
        device. This is typically done to maintain your preferences or to
        improve speed and performance by storing certain files locally. But,
        like standard cookies, these technologies can also store a unique
        identifier for your device, which can then track behavior. These
        technologies include Local Shared Objects (or “Flash cookies”) and
        Silverlight Application Storage.
      </p>
      <p>
        <strong>Local Shared Objects or “Flash cookies.”</strong> Websites that
        use Adobe Flash technologies can use Local Shared Objects or "Flash
        cookies" to store data on your computer. To learn how to manage or block
        Flash cookies, go to the Flash Player help page.
      </p>
      <p>
        <strong>Silverlight Application Storage.</strong> Websites or
        applications that use XY Silverlight technology also have the ability to
        store data by using Silverlight Application Storage. To learn how to
        manage or block such storage, go to the Silverlight help page.
      </p>
      <p>
        <strong>Your communications preferences</strong>
      </p>
      <p>
        You can choose whether you wish to receive promotional communications
        from XY by email, SMS text, physical mail, and telephone. If you receive
        promotional email or SMS messages from us and would like to opt out, you
        can do so by following the directions in that message. You can also make
        choices about the receipt of promotional email, telephone calls, and
        postal mail by signing in with your personal account, and viewing your
        communication permissions where you can update contact information,
        manage contact preferences, opt out of email subscriptions, and choose
        whether to share your contact information with XY partners. These
        choices do not apply to mandatory service communications that are part
        of certain XY services or other informational communications that have
        their own unsubscribe method.
      </p>
      <p>
        <strong>Your advertising choices</strong>
      </p>
      <p>
        We do not control third parties’ collection or use of your information
        to serve interest-based advertising. However these third parties may
        provide you with ways to choose not to have your information collected
        or used in this way. You can opt out of receiving targeted ads from
        members of the Network Advertising Initiative (“<strong>NAI</strong>”)
        on NAI’s website at http://optout.networkadvertising.org. Because the
        data used for interest-based advertising is also used for other
        necessary purposes (including providing our products, analytics, and
        fraud detection), opting out of interest-based advertising does not stop
        that data collection. You will continue to get ads, although they may be
        less relevant to you.
      </p>
      <p>
        <strong>Browser-based controls</strong>
      </p>
      <p>
        When you use a browser, you can control your personal data using certain
        features. For example:
      </p>
      <ul>
        <li>
          <strong>Cookie controls.</strong> You can control the data stored by
          cookies and withdraw consent to cookies by using the browser-based
          cookie controls described in the Cookies section of this Policy.
        </li>
        <li>
          <strong>Tracking protection</strong>. The browser you use may permit
          you to control the data third-party sites can collect about you using
          tracking protection.
        </li>
        <li>
          <strong>Browser controls for “Do Not Track.”</strong> Some browsers
          have incorporated “Do Not Track” (DNT) features that can send a signal
          to the websites you visit indicating you do not wish to be tracked.
          Because there is not yet a common understanding of how to interpret
          the DNT signal, XY services do not yet respond to browser DNT signals.
        </li>
      </ul>
      <p>
        <strong>XY Account</strong>
      </p>
      <p>
        With a XY account, you can sign into XY services, as well as those of
        select XY partners. Personal data associated with your XY account may
        include credentials, name and contact data, payment data, device and
        usage data, your contacts, information about your activities, and your
        interests and favorites. Signing into your XY account enables
        personalization, consistent experiences across services and devices,
        allows you to make payments using payment instruments stored in your XY
        account, and enables other features.
      </p>
      <p>
        <strong>How We Secure Personal Information</strong>
      </p>
      <p>
        XY is committed to protecting the security of your personal data. We use
        a variety of security technologies and procedures to help protect your
        personal data from unauthorized access, use, or disclosure. For example,
        we store the personal data you provide on computer systems that have
        limited access and are in controlled facilities. When we transmit highly
        confidential data (such as a credit card number or password) over the
        internet, we protect it through the use of encryption. XY complies with
        applicable data protection laws, including applicable security breach
        notification laws.
      </p>
      <p>
        The safety and security of your information also depends on you. Where
        we have given you (or where you have chosen) a password for access to
        certain parts of the services, you are responsible for keeping this
        password confidential. We ask you not to share your password(s) with
        anyone.
      </p>
      <p>
        <strong>Where We Store and Process Personal Information</strong>
      </p>
      <p>
        We primarily store data about you, including personal information, on
        servers located and operated within the United States. Therefore, if you
        reside outside of the United States, in order to provide the services to
        you, we may send and store your personal information outside of the
        country where you reside or are located, to the United States. Our
        collection and use of your personal information is therefore subject to
        the laws of the United States related to privacy and data protection, in
        addition to laws in your country that may still apply.
      </p>
      <p>
        In some cases, the information we collect from you will be stored on
        servers owned and operated by us. In other instances, the information we
        collect about you may be stored on servers owned and operated by trusted
        third party providers we have contracted with. Our agreements with those
        providers require them to restrict use of your personal information to
        that which there is a legal basis for, and to employ reasonable security
        measures that are at least as stringent as those we use to protect your
        information from unauthorized access.
      </p>
      <p>
        <strong>Our Retention of Personal Information</strong>
      </p>
      <p>
        We retain your personal information for as long as necessary to provide
        the services you have requested, or for other legitimate purposes such
        as complying with our legal obligations, resolving disputes, and
        enforcing our agreements. Because these needs can vary for different
        data types, the context of our interactions with you or your use of
        products, actual retention periods can vary significantly.
      </p>
      <p>Other criteria we use to determine the retention periods include:</p>
      <ul>
        <li>
          <strong>
            Is a user’s account for receiving services considered to be active?
          </strong>
          <br />
          We will retain your information for as long as your account is active,
          as needed to provide the services you have requested, unless you
          request that we delete your personal information. Deactivation of your
          account does not mean that your personal information has been deleted
          from our database entirely if XY deems retention necessary to comply
          with legal obligations or protect the legitimate interests of itself
          or others.
        </li>
        <li>
          <strong>Is the personal data of a sensitive type?</strong> If so, a
          shortened retention time would generally be adopted.
        </li>
        <li>
          <strong>
            Is XY subject to a legal, contractual, or similar obligation to
            retain or delete the data?
          </strong>
          <br />
          Examples can include mandatory data retention laws in the applicable
          jurisdiction, government orders to preserve data relevant to an
          investigation, or data preserved for the purposes of litigation.
          Conversely, if we are required by law to remove unlawful content, we
          will do so.
        </li>
      </ul>
      <p>
        <strong>Collection of Information From Children</strong>
      </p>
      <p>
        The services are not intended for children under 13 years of age. We do
        not knowingly collect personal information from children under 13. If we
        learn we have obtained personal information about a child under 13
        without verification of parental consent, we will delete that
        information. If you believe we might have any information about a child
        under 13, please contact us.
      </p>
      <p>
        <strong>Changes to This Policy</strong>
      </p>
      <p>
        We update this Policy when necessary to provide greater transparency or
        in response to:
      </p>
      <ul>
        <li>
          Feedback from customer, regulators, industry, or other stakeholders.
        </li>
        <li>Changes in our services.</li>
        <li>Changes in our data processing activities</li>
      </ul>
      <p>
        When we post changes to this Policy, we will revise the “last updated”
        date at the top of the Policy. If there are material changes to the
        Policy, such as a change to the purposes of processing of personal data
        that is not consistent with the purpose for which it was originally
        collected, we will notify you either by prominently posting a notice of
        such changes before they take effect or by directly sending you a
        notification. We encourage you to periodically review this Policy.
      </p>
      <p>
        <strong>How to Contact Us</strong>
      </p>
      <p className="mb-5">
        If you have a concern, question or complaint concerning this Policy or
        your personal information, or if you wish to exercise your rights to
        access or control your information, please contact us by email at
        <a href="mailto:legal@xyo.network">legal@xyo.network</a>. Unless
        otherwise stated, XY – The Persistent Company is a data controller for
        personal data we collect for the services subject to this Policy. Our
        address is at 1133 Columbia Street, Suite 205, San Diego, CA 92101.
      </p>
      <FooterLinks />
    </div>
  )
}
export default Privacy

import { useState } from 'react'
import { useUser, useUserInfo, refetchAccounts } from '../../context/user'
import { SIGNING_DATA, verifyClient } from '../../context/verify'
import {
  VerifyType,
  useVerifyAddressMutation,
  MyVerifiedAddressesWithTokenAndPairBalancesDocument,
} from '@xyo-network/api-address-verifier-apollo/bindings'
import { swalError, swalSuccess } from '../Alert'
import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'
import { useAsyncEffect } from '../../utils/hooks'
import fetch from 'isomorphic-fetch'

export const SignatureVerification = ({
  title,
  onSuccess,
  onError,
  safeFHR,
}) => {
  const [mutate] = useVerifyAddressMutation({
    options: {
      client: verifyClient,
    },
  })
  const { user } = useUser()
  const { userInfo } = useUserInfo()
  const [verifying, setVerifying] = useState(false)
  const upgradedPlan = () =>
    userInfo && userInfo.activeSubscription === 'Plus' ? 'Pro' : 'Plus'
  const verifySignature = (next) => async ({ error, signature, address }) => {
    try {
      if (error) return swalError(error)
      if (!signature) return swalError('Signature not created')
      setVerifying(true)
      const variables = {
        type: VerifyType.Sign,
        data: SIGNING_DATA,
        address,
        signature,
      }
      await mutate({
        variables,
        refetchQueries: [
          {
            query: MyVerifiedAddressesWithTokenAndPairBalancesDocument,
          },
        ],
      })
      await next({ error, signature, address })
      setVerifying(false)
      onSuccess && onSuccess()
    } catch (e) {
      swalError(e.message)
      setVerifying(false)
      onError && onError()
    }
  }
  const handleSignatureSafeFHR = verifySignature(async ({ address }) => {
    try {
      const upgrade = await upgradeFHRAddress(address, user.uid)
      if (upgrade && upgrade.error) throw new Error(upgrade.error)
      await refetchAccounts()
      swalSuccess(
        `Congratulations! As an XYO FHR member your account has been upgraded to COIN ${upgradedPlan()}!`,
      )
    } catch (e) {
      swalSuccess(`You successfully verified ownership of: ${address}!`)
    }
  })
  const handleSignature = verifySignature(async ({ address }) => {
    const upgrade = await upgradeFHRAddress(address, user.uid)
    if (upgrade && upgrade.error) throw new Error(upgrade.error)
    await refetchAccounts()
    swalSuccess(`Your account has been upgraded to COIN ${upgradedPlan()}!`)
  })

  const { data: SignButton } = useAsyncEffect(() =>
    import('@xyo-network/tool-storybook-react/dist/lib/Signing').then(
      ({ SignButton }) => SignButton,
    ),
  )

  return (
    <div className="text-center mt-3 mb-5">
      <h4>{title}</h4>
      <p>
        <strong>Message:</strong> {SIGNING_DATA}
        {verifying ? (
          <button disabled={true} className="btn btn-outline-danger ml-2">
            Verifying...
          </button>
        ) : SignButton ? (
          <SignButton
            className="btn btn-outline-danger ml-2"
            onSuccess={safeFHR ? handleSignatureSafeFHR : handleSignature}
            data={SIGNING_DATA}
          />
        ) : (
          <button className="btn btn-outline-danger ml-2" disabled={true}>
            Initializing...
          </button>
        )}
      </p>
    </div>
  )
}

export const upgradeFHRAddress = async (address, userId) => {
  const res = await fetch(
    `${process.env.REACT_APP_COIN_SUBSCRIPTIONS_API}/coin_subscriptions/fhr-redeem?address=${address}&userId=${userId}`,
  )
  const upgrade = await res.json()
  if (upgrade && upgrade.error) throw new Error(upgrade.error)
  return upgrade
}

const updateMyVerifiedAddresses = (cache, { data }) => {
  let verifiedAddresses = []
  try {
    const prevData = cache.readQuery({
      query: MyVerifiedAddressesWithTokenAndPairBalancesDocument,
    })
    verifiedAddresses = get(prevData, 'verifiedAddresses') || []
  } catch (e) {}
  const newData = uniqBy(
    verifiedAddresses.concat(data.verifyAddress || []),
    'id',
  )
  cache.writeQuery({
    query: MyVerifiedAddressesWithTokenAndPairBalancesDocument,
    data: { verifiedAddresses: newData },
  })
}

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import React from 'react'
import { queryValue, useQueryString } from '../../utils/search'

const defaultTypeFilter = ['initial', 'renewal']
const defaultStatusFilter = [
  'past-due',
  'paid',
  'unpaid',
  'canceled',
  'refunded',
]

const pageQueryOptions = {
  page: queryValue(1),
  perPage: queryValue(10),
}
const invoiceQueryOptions = {
  type: queryValue(defaultTypeFilter),
  status: queryValue(defaultStatusFilter),
  ...pageQueryOptions,
}

export function useInvoicesQueryParams(history) {
  const [query, setQuery] = useQueryString(invoiceQueryOptions, history)
  const setStatusFilter = ev => {
    const page = 1
    const status = ev.target.value
    setQuery({ ...query, page, status })
  }
  const handleChangePage = (event, newPage) => {
    const page = newPage + 1
    setQuery({ ...query, page })
  }
  const handleChangeRowsPerPage = event => {
    const page = 1
    const perPage = parseInt(event.target.value, 10)
    setQuery({ ...query, page, perPage })
  }
  return {
    query,
    setQuery,
    setStatusFilter,
    handleChangePage,
    handleChangeRowsPerPage,
  }
}

export function usePageQueryParams(history) {
  const [query, setQuery] = useQueryString(pageQueryOptions, history)
  const handleChangePage = (event, newPage) => {
    const page = newPage + 1
    setQuery({ ...query, page })
  }
  const handleChangeRowsPerPage = event => {
    const page = 1
    const perPage = parseInt(event.target.value, 10)
    setQuery({ ...query, page, perPage })
  }
  return {
    query,
    setQuery,
    handleChangePage,
    handleChangeRowsPerPage,
  }
}

export const InvoiceStatusFilter = ({ value, onChange }) => {
  return (
    <FormControl variant="filled" margin="normal" size="small">
      <InputLabel id="invoice-status-filter-label">Status</InputLabel>
      <Select
        multiple
        labelId="invoice-status-filter-label"
        id="invoice-status-filter"
        value={value}
        onChange={onChange}
        className="ml-2"
        label="Status"
      >
        <MenuItem value={'paid'}>Paid</MenuItem>
        <MenuItem value={'unpaid'}>Unpaid</MenuItem>
        <MenuItem value={'past-due'}>Past Due</MenuItem>
        <MenuItem value={'canceled'}>Canceled</MenuItem>
        <MenuItem value={'refunded'}>Refunded</MenuItem>
      </Select>
    </FormControl>
  )
}

export const InvoiceTypeFilter = ({ value, onChange }) => {
  return (
    <FormControl variant="filled">
      <InputLabel id="invoice-type-filter-label">Type</InputLabel>
      <Select
        multiple
        labelId="invoice-type-filter-label"
        id="invoice-type-filter"
        value={value}
        onChange={onChange}
        className="ml-2"
        label="Type"
      >
        <MenuItem value={'initial'}>Initial</MenuItem>
        <MenuItem value={'renewal'}>Renewal</MenuItem>
      </Select>
    </FormControl>
  )
}

import { useState, useMemo, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { CheckoutProvider } from '../context/checkout'
import { BossProvider } from '../context/bossapi'
import Select from '@material-ui/core/Select'
import Skeleton from '@material-ui/lab/Skeleton'
import MenuItem from '@material-ui/core/MenuItem'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from '../components/Table/Pagination'
import times from 'lodash/times'
import { Query } from 'react-apollo'
import cx from 'classnames'
import lowerCase from 'lodash/lowerCase'
import first from 'lodash/first'
import capitalize from 'lodash/capitalize'
import mapValues from 'lodash/mapValues'
import ButtonBase from '@material-ui/core/ButtonBase'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { lightTheme } from '../context/page'
import { useQueryString, queryValue } from '../utils/search'
import ListLayout from '../components/Layouts/List'
import { InvoiceList } from '../components/Invoice/List'
import { MY_INVOICES } from '../components/Invoice'
import {
  useInvoicesQueryParams,
  InvoiceStatusFilter,
} from '../components/Invoice/Filter'

const Invoices = withRouter(({ history, match }) => {
  const [invoice, setInvoice] = useState(null)
  const {
    query,
    setStatusFilter,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useInvoicesQueryParams(history)
  return (
    <Query
      query={MY_INVOICES}
      variables={{
        pagination: { page: query.page, perPage: query.perPage },
        filter: {
          type: query.type,
          status: query.status,
          subscriptionId: match.params.subscriptionId,
        },
      }}
    >
      {({ data, loading }) => {
        const customer = data?.me
        const invoices = customer?.invoices?.data
        const total = customer?.invoices?.total
        const cards = customer?.paymentCards?.data
        return (
          <ListLayout
            title={
              <>
                <Typography variant="h4">Invoices</Typography>
                <Typography variant="subtitle2">
                  Past due invoices must be paid to continue subscription and
                  redeem rewards
                </Typography>
              </>
            }
            action={
              <InvoiceStatusFilter
                value={query.status}
                onChange={setStatusFilter}
              />
            }
          >
            <div>
              <InvoiceList
                perPage={query.perPage}
                loading={loading}
                invoices={invoices}
                cards={cards}
              />
              {total ? (
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10, 25]}
                  count={total}
                  rowsPerPage={query.perPage}
                  page={query.page - 1}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                ></TablePagination>
              ) : null}
            </div>
          </ListLayout>
        )
      }}
    </Query>
  )
})

export default () => {
  return (
    <CheckoutProvider>
      <Invoices />
    </CheckoutProvider>
  )
}

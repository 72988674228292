import React, { Suspense, useState, useMemo, useEffect, useRef } from 'react'
import { BossProvider } from '../context/bossapi'
import { useQuery } from 'react-apollo-hooks'
import { Link, useLocation } from 'react-router-dom'
import get from 'lodash/get'
import Map from '../components/Map'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from '../components/Table/Pagination'
import { AWSError } from '../components/Error'
import { View } from '../components/Footer'
import Skeleton from '@material-ui/lab/Skeleton'
import { QuadkeyMap, GlobalMap } from '../components/Map'
import { useScrollContext } from '../components/Scroll'
import { numberFormat } from '../utils/number'
import { useUser, useUserInfo } from '../context/user'
import gql from 'graphql-tag'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'
import capitalize from 'lodash/capitalize'
import PlusIcon from '@material-ui/icons/Add'
import times from 'lodash/times'
import qs from 'querystring'

export default () => (
  <BossProvider>
    <GeodropsPage />
  </BossProvider>
)

const GeodropsPage = () => {
  const { userInfo } = useUserInfo()
  const scrollElement = useScrollContext()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const { state, search } = useLocation()
  const { tab: tabQ } = qs.parse(search.replace('?', ''))
  const [tab, setTab] = useState(tabQ ?? 'history')
  const [filter, setFilter] = useState(state?.filter ?? 'dropsCollected')
  const remoteCountRef = useRef(null)

  // coin_drops_history
  const variables = useMemo(
    () => ({
      filter,
      page,
      perPage,
      field: filter === 'dropsCollected' ? 'claimedTime' : 'dropTime',
    }),
    [filter, perPage, page],
  )
  const dropsHistory = useQuery(DROPS_HISTORY, { variables, skip: tab !== 'history' })
  const drops = useQuery(DROPS, { variables, skip: tab === 'history' })

  const { data, fetchMore, loading, error } = tab === 'history' ? dropsHistory : drops

  const remoteRowCount = get(data, 'drops.total') || get(data, 'dropsHistory.total')
  const changePage = (p) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    setPage(p)
  }
  const handleChangePage = (event, newPage) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }
  const handleChange = (ev) => {
    setFilter(ev.target.value)
    changePage(1)
  }
  const list = get(data, 'drops.data') || get(data, 'dropsHistory.data') || []

  return (
    <View>
      <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8 px-0">
        <div className="d-flex align-items-center text-white px-2">
          <h1>Geodrops</h1>
          {userInfo && userInfo.webDropsAllowed && (
            <Box mx={1}>
              <Button component={Link} to="/geodrops/create">
                <Box display="flex" alignItems="center">
                  <PlusIcon />
                  <Box ml={1}>Create</Box>
                </Box>
              </Button>
            </Box>
          )}
          <div className="flex-grow-1" />
          <Select value={filter} onChange={handleChange} className="ml-2">
            {/* <MenuItem value={'all'}>All</MenuItem> */}
            <MenuItem value={'dropsCollected'}>Collected</MenuItem>
            <MenuItem value={'dropsCreated'}>Dropped</MenuItem>
            <MenuItem value={'geoclaimRewards'}>Geoclaim Rewards</MenuItem>
            <MenuItem value={'referrerRewards'}>Referrer Rewards</MenuItem>
            <MenuItem value={'bonusRewards'}>Bonus Rewards</MenuItem>
            <MenuItem value={'surveyRewards'}>Survey Rewards</MenuItem>
            {/* <MenuItem value={'inmarketRewards'}>Inmarket Rewards</MenuItem> */}
            <MenuItem value={'verificationRewards'}>
              Verification Rewards
            </MenuItem>
            <MenuItem value={'appInstallRewards'}>Play Rewards</MenuItem>
          </Select>
          <Box ml={1} />
          <Button size="small" onClick={() => setTab('recent')} color={tab !== 'history' ? 'primary' : 'initial'} variant={tab !== 'history' ? 'contained' : 'text'}>Recent</Button>
          <Button size="small" onClick={() => setTab('history')} color={tab === 'history' ? 'primary' : 'initial'} variant={tab === 'history' ? 'contained' : 'text'}>History</Button>
        </div>
        <div className="position-relative list-group text-dark">
          {loading ? (
            times(perPage).map((a, i) => <DropQuadkey key={i} loading={true} />)
          ) : list.length ? (
            list.map((item) => <DropQuadkey key={item.id} {...item} />)
          ) : error ? (
            <AWSError error={error} />
          ) : (
            <NoGeoDrops />
          )}
          {remoteRowCount && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25]}
              count={remoteRowCount}
              rowsPerPage={variables.perPage}
              page={variables.page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            ></TablePagination>
          )}
        </div>
      </div>
    </View>
  )
}

const DropQuadkey = ({
  id,
  dropUid,
  quadkey,
  amount,
  email,
  dropTime,
  claimedTime,
  collectedXyoCollected,
  collectedTime,
  dropXyoCollected,
  message,
  style,
  loading,
}) => {
  const { user } = useUser()
  const uid = user && user.uid
  const { newXyoCollected, prevXyoCollected } = computeRunningTotals(uid, {
    amount,
    dropUid,
    dropXyoCollected,
    collectedXyoCollected,
  })
  const title = dropUid
    ? dropUid.includes('_')
      ? dropUid
          .replace('CLAIM_REWARDS', 'GEOCLAIM_REWARDS')
          .replace('REFERRER', 'REFERRAL')
          .replace('INBRAIN_', '')
          .replace('INBRAIN', '')
          .replace('GTV_DROP', 'VERIFICATION_REWARD')
          .replace('ADGEM_DROP', 'APP_INSTALL_REWARD')
          .replace('SENSE_360_DROP', 'REWARDED_PLAY_DROP')
          .split('_')
          .map(capitalize)
          .join(' ')
      : dropUid === uid
      ? 'Dropped'
      : 'Collected'
    : ''
  return (
    <div
      className="list-group-item d-flex align-items-center rounded-0-sm"
      style={style}
    >
      {quadkey ? (
        <div
          className="quadkey-map bg-dark rounded overflow-hidden mr-3"
          style={{
            height: 140,
            minWidth: 140,
            maxWidth: 140,
            position: 'relative',
          }}
        >
          <QuadkeyMap
            id={id}
            quadkey={quadkey}
            style={{ height: '100%', width: '100%' }}
          />
        </div>
      ) : null}
      <div className="flex-grow-1">
        {loading ? (
          <>
            <Skeleton
              variant="text"
              width={180}
              className="skeleton"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
            />
            <Skeleton
              variant="text"
              width={160}
              className="skeleton"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
            <Skeleton
              variant="text"
              width={160}
              className="skeleton"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
          </>
        ) : (
          <>
            <h4 className="mb-2">{title}</h4>
            <p className="mb-0 nowrap">
              Tile: {quadkey ? <small>{quadkey}</small> : 'Any'}
            </p>
            {email && <p className="mb-0">Restricted to email: {email}</p>}
            {prevXyoCollected && (
              <p className="mb-0">
                Prev Total: {numberFormat(prevXyoCollected)}
              </p>
            )}
            {amount && <p className="mb-0">Amount: {numberFormat(amount)}</p>}
            {newXyoCollected && (
              <p className="mb-0">New Total: {numberFormat(newXyoCollected)}</p>
            )}
            {message && (
              <p className="my-2 p-2 bg-light rounded d-inline-block">
                <span
                  style={{ wordBreak: 'break-word' }}
                  dangerouslySetInnerHTML={{ __html: parseMessage(message) }}
                ></span>
              </p>
            )}
            <p className="mb-0">
              Created:{' '}
              <small className="text-muted">
                {new Date(
                  dropUid === uid ? dropTime : claimedTime,
                ).toLocaleString()}
              </small>
            </p>
            <p className="mb-0">
              Collected:{' '}
              <small className="text-muted">
                {collectedTime
                  ? !isNaN(Number(collectedTime))
                    ? new Date(Number(collectedTime)).toLocaleString()
                    : new Date(collectedTime).toLocaleString()
                  : 'Not Collected'}
              </small>
            </p>
          </>
        )}
      </div>
    </div>
  )
}

const NoGeoDrops = () => {
  return (
    <div className="list-group-item text-center py-5 rounded-0-sm">
      <h4>No Drops Found</h4>
      <p>
        Read more about geodrops{' '}
        <a
          href="https://support.coinapp.co/hc/en-us/categories/360002099633"
          target="_blank"
        >
          here
        </a>
        .
      </p>
    </div>
  )
}

const linkExp =
  /((http|https)\:\/\/)?([a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,4}(\?[a-zA-Z0-9\-\_\&\=]+)?)(\/\S*)?/

function computeRunningTotals(
  uid,
  { amount, dropUid, dropXyoCollected, collectedXyoCollected },
) {
  if (!uid) return {}
  if (uid === dropUid) {
    if (typeof dropXyoCollected !== 'number') return {}
    return {
      newXyoCollected: dropXyoCollected,
      prevXyoCollected: dropXyoCollected + amount,
    }
  }
  if (typeof collectedXyoCollected !== 'number') return {}
  return {
    newXyoCollected: collectedXyoCollected,
    prevXyoCollected: collectedXyoCollected - amount,
  }
}

function parseMessage(text) {
  const matched = text.match(linkExp)
  if (matched && matched[1]) {
    return text.replace(linkExp, `<a href="${matched[0]}">${matched[0]}</a>`)
  } else if (matched) {
    return text.replace(linkExp, `<a href="//${matched[0]}">${matched[0]}</a>`)
  }
  return text
}

const DROP_FRAGMENT = (name) => gql`
fragment UserDrop on ${name} {
    id
    dropUid
    dropTime
    dropYYYYMMDD
    dropXyoCollected
    quadkey
    fullQuadkey
    latitude
    longitude
    lonlat
    email
    asset
    amount
    fee
    message
    claimed
    collected
    collectedXyoCollected
    receiptDelivered
    claimedTime
    claimedUid
    collectedTime
    receiptMessage
    receiptTime
  }`

const DROPS = gql`
  query UserDrops($page: Int, $perPage: Int, $field: String, $filter: String) {
    drops(
      filter: $filter
      pagination: { page: $page, perPage: $perPage }
      sort: { field: $field, order: -1 }
    ) {
      total
      page
      data {
        ...UserDrop
      }
    }
  }

  ${DROP_FRAGMENT('Drop')}
`

const DROPS_HISTORY = gql`
  query UserDropsHistory($page: Int, $perPage: Int, $field: String, $filter: String) {
    dropsHistory(
      filter: $filter
      pagination: { page: $page, perPage: $perPage }
      sort: { field: $field, order: -1 }
    ) {
      total
      page
      data {
        ...UserDrop
      }
    }
  }

  ${DROP_FRAGMENT('DropHistory')}
`

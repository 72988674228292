import { Suspense, useState, useMemo, useEffect, useRef } from 'react'
import { BossProvider } from '../context/bossapi'
import { useQuery } from 'react-apollo-hooks'
import get from 'lodash/get'
import Map from '../components/Map'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from '../components/Table/Pagination'
import { PlanIcon } from '../components/Plans'
import Avatar from '@material-ui/core/Avatar'
import Skeleton from '@material-ui/lab/Skeleton'
import { QuadkeyMap, GlobalMap } from '../components/Map'
import { useScrollContext } from '../components/Scroll'
import { numberFormat } from '../utils/number'
import { AWSError } from '../components/Error'
import { View } from '../components/Footer'
import gql from 'graphql-tag'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'
import identity from 'lodash/identity'
import capitalize from 'lodash/capitalize'
import isFunction from 'lodash/isFunction'
import negate from 'lodash/negate'
import times from 'lodash/times'
import flow from 'lodash/flow'
import cx from 'classnames'
import moment from 'moment'

export default () => (
  <BossProvider>
    <BigWinsAcknowledgedPage />
  </BossProvider>
)

function BigWinsAcknowledgedPage() {
  return (
    <View>
      <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8 px-0">
        <div className="d-flex align-items-center justify-content-between text-white px-2">
          <h1>Big Geomines</h1>
        </div>
        <div className="position-relative list-group text-dark">
          <BigWinsAcknowledgedList />
        </div>
      </div>
    </View>
  )
}

function BigWinsAcknowledgedList() {
  const scrollElement = useScrollContext()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const variables = useMemo(
    () => ({
      page,
      perPage,
    }),
    [perPage, page],
  )
  const { data, loading, error } = useQuery(BIG_WIN_WITNESSES, { variables })
  const bigWinsAcknowledged = get(data, 'bigWinsAcknowledged.data') || []
  const remoteRowCount = get(data, 'bigWinsAcknowledged.total') || 0
  const handleChangePage = (event, newPage) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }
  const changePage = (p) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    setPage(p)
  }
  return (
    <>
      {loading ? (
        times(perPage).map((a, i) => (
          <BigWinsAcknowledged key={i} loading={true} />
        ))
      ) : bigWinsAcknowledged.length ? (
        bigWinsAcknowledged.map((bigWin) => (
          <BigWinsAcknowledged key={bigWin._id} bigWin={bigWin} />
        ))
      ) : error ? (
        <AWSError />
      ) : (
        <NoBigWinsAcknowledged />
      )}
      {remoteRowCount && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          count={remoteRowCount}
          rowsPerPage={variables.perPage}
          page={variables.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        ></TablePagination>
      )}
    </>
  )
}

function BigWinsAcknowledged({ loading, bigWin, style }) {
  const plan = get(bigWin, 'bigwinPlan') || get(bigWin, 'plan')
  const rank = get(bigWin, 'rank')
  const reward = get(bigWin, 'reward')
  const xyoCollected = get(bigWin, 'xyoCollected')
  const bigwinEpoch = get(bigWin, 'bigwinEpoch')
  const epoch = get(bigWin, 'epoch')
  const bigwinDate = toMoment(get(bigWin, 'bigwinDate'))
  const date = toMoment(get(bigWin, 'date'))
  const timeAgo = bigwinDate ? bigwinDate.to(date) : null
  const secs = bigwinEpoch ? Math.floor((epoch - (bigwinEpoch || 0)) / 1000) : 0
  return (
    <div
      className="list-group-item d-flex align-items-center flex-nowrap rounded-0-sm"
      style={style}
    >
      {loading ? (
        <Skeleton
          variant="rect"
          width={80}
          height={80}
          className="skeleton"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
        />
      ) : plan ? (
        <div
          className="bg-info rounded overflow-hidden text-center text-white d-flex align-items-center flex-column"
          style={{ height: 80, width: 80, position: 'relative' }}
        >
          <div className="d-flex flex-grow-1 align-items-center justify-content-center">
            <PlanIcon plan={plan} height={28} />
          </div>
          <p
            className="mb-0 w-100 text-uppercase"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}
          >
            {plan || 'basic'}
          </p>
        </div>
      ) : null}
      <div className="flex-grow-1 mt-0 ml-3">
        {loading ? (
          <div>
            <Skeleton
              variant="text"
              width={180}
              className="skeleton"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
            />
            <Skeleton
              variant="text"
              width={160}
              className="skeleton"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
            <Skeleton
              variant="text"
              width={160}
              className="skeleton"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
          </div>
        ) : (
          <div>
            <h4 className="mb-0 nowrap">{reward} COIN</h4>
            {rank ? (
              <p className="mb-0">
                <span className="nowrap">Rank: {ordinalSuffix(rank)}</span>
              </p>
            ) : null}
            {secs ? (
              <p className="mb-0">
                <span className="nowrap">Witness Time: {secs} secs</span>
              </p>
            ) : null}
            {xyoCollected ? (
              <p className="mb-0">
                <span className="">Running Total:</span>{' '}
                <span className="nowrap">{xyoCollected}</span>
              </p>
            ) : null}
            {date ? (
              <p className="mb-0 text-muted">
                <small>
                  <span className="d-none d-md-inline">Date:</span>{' '}
                  <span className="nowrap">
                    {date.toDate().toLocaleString()}
                  </span>
                </small>
              </p>
            ) : null}
          </div>
        )}
      </div>
      <div>
        {loading ? (
          <Skeleton
            variant="circle"
            width={60}
            height={60}
            className="skeleton"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
          />
        ) : rank ? (
          <div
            style={{
              backgroundColor: '#4a90e3',
              textAlign: 'center',
              color: '#fff',
              lineHeight: '60px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              fontSize:
                rank > 10000 ? 14 : rank > 1000 ? 16 : rank > 100 ? 18 : 20,
              borderRadius: 60,
              height: 60,
              width: 60,
            }}
          >
            <strong>{ordinalSuffix(rank)}</strong>
          </div>
        ) : null}
      </div>
    </div>
  )
}

function NoBigWinsAcknowledged() {
  return (
    <div className="list-group-item text-center py-5 rounded-0-sm">
      <h4>No Big Win Witnesses Found</h4>
      <p>
        Read more about witnessing big wins{' '}
        <a
          href="https://support.coinapp.co/hc/en-us/articles/360041640233-Witness-a-Big-Geomine-and-Earn-COIN-"
          target="_blank"
        >
          here
        </a>
        .
      </p>
    </div>
  )
}

const log = (n) => (v) => {
  console.log(n, v)
  return v
}

const toMoment = fork(identity, flow(Number, moment), () => null)

function fork(condition, truthy, falsy) {
  return function (...args) {
    if (condition(...args)) {
      return truthy(...args)
    } else {
      return falsy(...args)
    }
  }
}

function ordinalSuffix(i) {
  var j = i % 10,
    k = i % 100
  if (j == 1 && k != 11) {
    return i + 'st'
  }
  if (j == 2 && k != 12) {
    return i + 'nd'
  }
  if (j == 3 && k != 13) {
    return i + 'rd'
  }
  return i + 'th'
}

const BIG_WIN_WITNESSES = gql`
  query BigWinWitnesses($page: Int, $perPage: Int) {
    bigWinsAcknowledged(
      pagination: { page: $page, perPage: $perPage }
      sort: { field: "date", order: -1 }
    ) {
      total
      data {
        _id
        reference
        bigwinAmount
        bigwinPlan
        bigwinDate
        bigwinTier
        bigwinEpoch
        uid
        rank
        reward
        epoch
        date
        yyyymmdd
        plan
        xyoCollected
      }
    }
  }
`

import { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { swalError, swalSuccess } from '../components/Alert'
import { withRouter } from 'react-router-dom'
import { redeemSubscription } from '../context/checkout'

const subscriptionIsActive = ({ isActive }) => isActive

export const useRedeemControl = (email, onComplete) => {
  const [{ loading }, setState] = useState({ loading: true })
  const redeem = async () => {
    try {
      if (!email) throw new Error('Email not supplied')
      const data = await redeemSubscription(email)
      if (
        data &&
        data.success &&
        data.subscriptions &&
        data.subscriptions.some(subscriptionIsActive)
      ) {
        setState({ loading: false })
        await swalSuccess('Your subscription has been confirmed.')
        onComplete()
      } else if (data.userExists) {
        throw new Error('Subscription not found')
      } else {
        throw new Error(
          'You must login to the COIN app before activating your subscription',
        )
      }
    } catch (e) {
      setState({ loading: false })
      await swalError(e.message)
      onComplete()
    }
  }
  useEffect(() => {
    redeem()
  }, [])
  return { loading }
}

export default withRouter(({ history }) => {
  const match = history.location.search.match(/email=([^\&|\?]*)/)
  const email = match ? match[1] : ''
  const { loading } = useRedeemControl(email, () =>
    history.push(`/account?email=${email}`),
  )
  return (
    <div className="container pb-40vh pt-5 text-center">
      {loading && <CircularProgress />}
    </div>
  )
})

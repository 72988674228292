import React from 'react'
import { EditIconButton } from '../Buttons'
import { fromNanoseconds } from '../../utils/date'
import { numberFormat } from '../../utils/number'
import { TextRow } from '../Text/TextRow'
import cx from 'classnames'

const Address = ({ children }) => <div className='address'>{children}</div>

const Container = ({ title, status, children }) => {
  return (
    <div className='card mb-5 overflow-hidden text-dark'>
      <div className='card-header d-flex justify-content-between align-items-center'>
        <strong>{title}</strong>
        <span className='text-capitalize'>{status}</span>
      </div>
      <div className='card-body'>
        {children}
      </div>
    </div>
  )
}

export const ActiveRequest = ({ request, onSubmit }) => {
  return (
    <Container title={request.reference} status={request.status}>
      <TextRow bold title='Created' value={fromNanoseconds(request.created).toLocaleString()} />
      <TextRow bold title='Updated' value={fromNanoseconds(request.updated).toLocaleString()} />
      <TextRow bold title='Wallet' className='overflow-hidden' value={request.wallet}>
        {
          request.wallet
            ? <a onClick={onSubmit} className='text-dark'><Address><EditIconButton sm={true} className='p-0 mr-1' /> {request.wallet}</Address></a>
            : ''
        }
      </TextRow>
    </Container>
  )
}

export default ({ request, children }) => {
  const coinType = (request.coinType || '').toUpperCase()
  return (
    <Container title={request.reference} status={<span className={cx('text-capitalize', {
      'text-success': request.status === 'completed',
      'text-danger': request.status === 'denied',
    })}>{request.status}</span>}>
      <TextRow bold title='Created' value={fromNanoseconds(request.created).toLocaleString()} />
      <TextRow bold title='Updated' value={fromNanoseconds(request.updated).toLocaleString()} />
      <TextRow bold title='Wallet' className='overflow-hidden' value={request.wallet}>
        <a href={`https://etherscan.io/address/${request.wallet}`} target='_blank' className='text-dark'><Address>{request.wallet}</Address></a>
      </TextRow>
      <TextRow bold title='Transaction Hash' className='overflow-hidden' value={request.transactionHash}>
        <a href={`https://etherscan.io/tx/${request.transactionHash}`} target='_blank' className='text-dark'><Address>{request.transactionHash}</Address></a>
      </TextRow>
      <TextRow bold title='Receipt' className='overflow-hidden' value={request.confirmation}>
        <a href={`https://etherscan.io/tx/${request.transactionHash}`} target='_blank' className='text-dark'>Etherscan</a>
      </TextRow>
      <TextRow bold title={`${coinType} Sent`} value={request.sent}>
        {numberFormat(request.sent)} {coinType}
      </TextRow>
    </Container>
  )
}
import React from 'react'
import { useState, useRef, useEffect } from 'react'
import { fromNanoseconds } from '../../utils/date'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Avatar from '@material-ui/core/Avatar'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import arrow from '../../assets/arrow-down.svg'
import { EditIconButton } from '../Buttons'
import capitalize from 'lodash/capitalize'
import marked from 'marked'
import cx from 'classnames'

export default ({ request, option, active, onClick }) => {
  const time = fromNanoseconds(
    request.updated || request.created,
  ).toLocaleString()
  return (
    <ExpansionPanel expanded={active} onChange={onClick}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1" className="d-flex align-items-center">
          {option && option.imageUrl && (
            <Avatar
              src={option.imageUrl}
              className="d-inline-block mr-2"
              style={{ borderRadius: 0 }}
              imgProps={{ style: { objectFit: 'contain' } }}
            />
          )}
          {request.state === 'completed'
            ? `Redeemed ${(
                Number(request.xyoERC20Requested) ||
                Number(request.xyoCost) ||
                Number(request.sent) ||
                0
              ).toLocaleString()} ${option.title || ''}`
            : option.title
            ? `${option.title} ${capitalize(request.state)}`
            : `Redeem Request ${capitalize(request.state)}`}
          <i
            className={cx('fa', {
              'fa-check text-success mx-1':
                request.state === 'completed' ||
                request.status === 'completed' ||
                request.state === 'processed' ||
                request.status === 'processed',
              'fa-times text-danger mx-1':
                request.state === 'denied' ||
                request.status === 'denied' ||
                request.state === 'expired' ||
                request.status === 'expired',
            })}
          />
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid item xs={12}>
          {(request.ethAddress || request.wallet) && (
            <Typography
              variant="subtitle1"
              className="d-flex flex-wrap justify-content-between mb-2 mb-sm-0"
            >
              <strong>Wallet</strong>
              <Link
                className="address"
                href={`https://etherscan.io/address/${request.ethAddress ||
                  request.wallet}`}
                target="_blank"
              >
                {request.ethAddress || request.wallet}
              </Link>
            </Typography>
          )}
          {request.transactionHash && (
            <Typography
              variant="subtitle1"
              className="d-flex flex-wrap justify-content-between mb-2 mb-sm-0"
            >
              <strong>Transaction</strong>
              <Link
                className="address"
                href={`https://etherscan.io/tx/${request.transactionHash}`}
                target="_blank"
              >
                {request.transactionHash}
              </Link>
            </Typography>
          )}
          <Typography
            variant="subtitle1"
            className="d-flex flex-wrap justify-content-between mb-2 mb-sm-0"
          >
            <strong>Receipt</strong>
            {request.transactionHash ? (
              <Link
                className="address"
                href={`https://etherscan.io/tx/${request.transactionHash}`}
                target="_blank"
              >
                {time}
              </Link>
            ) : (
              <span>{time}</span>
            )}
          </Typography>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export const ActiveRedeem = ({ request, onSubmit }) => {
  return (
    <Card className="mb-3">
      <CardContent>
        <Typography
          color="textSecondary"
          gutterBottom
          className="text-capitalize"
        >
          {request.status}
        </Typography>
        <Typography variant="h6" component="h2" className="address">
          {request.wallet}
        </Typography>
        <Typography variant="body2" component="p">
          {fromNanoseconds(request.updated || request.created).toLocaleString()}
        </Typography>
      </CardContent>
      {request.wallet && (
        <CardActions>
          <Button size="small" onClick={onSubmit}>
            Update
          </Button>
        </CardActions>
      )}
    </Card>
  )
}

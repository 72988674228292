import React from 'react'
import Logo from './Logo'
import { ErrorAlertAction } from '../Error'
import { DangerBtn } from '../Buttons'

const reload = () => {
  window.location.reload()
}

export default ({ error, message }) => (
  <div
    style={{
      width: '100%',
      height: '100vh',
      backgroundColor: '#262525',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      position: 'absolute',
      top: 0,
      left: 0,
    }}
  >
    <ErrorAlertAction
      className="rounded-bottom"
      error={error}
      action={
        <button className="btn btn-outline-light ml-2" onClick={reload}>
          Reload
        </button>
      }
    />
    <div className="flex-grow-1" />
    <Logo style={{ height: 160 }} />
    <p className="text-center text-white my-2">{message}</p>
    <div className="flex-grow-1" />
  </div>
)

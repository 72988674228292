import { useMemo, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import ButtonBase from '@material-ui/core/ButtonBase'
import {
  swalRender,
  swalError,
  swalLoader,
  swalSuccess,
  swalConfirm,
} from '../components/Alert'
import { compose } from 'recompose'
import { CreditCard, CreditCardDetails } from '../components/PaymentCards'
import { useUser } from '../context/user'
import { CheckoutProvider, addPaymentCard } from '../context/checkout'
import AlertError from '../components/Error'
import Checkout from '../components/Checkout'
import { FadeIn, SlideAllUp } from '../components/Animate'
import { UserInfoDisplay } from '../components/User'
import {
  useAccountQuery,
  useDeactivatePaymentCardMutation,
  findMainSubscription,
  isActiveRebillySubscription,
} from '@xyo-network/api-checkout-apollo'
import {
  SubscriptionInfo,
  MySubscriptionDisplay,
} from '../components/Subscriptions'

export default () => {
  return (
    <CheckoutProvider>
      <SettingsPage />
    </CheckoutProvider>
  )
}

export const SettingsPage = () => {
  const accountQuery = useAccountQuery()
  const [mutate] = useDeactivatePaymentCardMutation()
  const subscription = findMainSubscription(
    (accountQuery.data?.account?.customer?.subscriptions ?? []).filter(
      isActiveRebillySubscription,
    ),
  )
  const onDeactivate = (card) => async () => {
    try {
      if (subscription) {
        await swalConfirm(
          `You have an active subscription. Your subscription must be canceled before deactivating payments.`,
        )
        return
      }
      const { value } = await swalConfirm(
        `Are you sure you would like to deactivate this payment method?`,
      )
      if (!value) return
      swalLoader(<p>Deactivating Payment Card...</p>)
      await mutate({ variables: { paymentCardId: card.id } })
      swalSuccess('Payment card deactivated.')
    } catch (e) {
      swalError(e.message)
    }
  }
  return (
    <div className="container pt-5 pb-40vh">
      <AlertError error={accountQuery.error} />
      <FadeIn>
        <div
          className="mb-5 text-white pb-3"
          style={{ borderBottom: '2px dashed' }}
        >
          <UserInfoDisplay resetPassword>
            <SubscriptionInfo />
          </UserInfoDisplay>
          <div className="mt-2">
            <MySubscriptionDisplay />
          </div>
        </div>
      </FadeIn>
      {accountQuery.loading && <CircularProgress />}
      <SlideAllUp className="row" rowClassName="col-12 col-md-6 col-lg-4 mb-4">
        {accountQuery.loading
          ? []
          : []
              .concat(
                (accountQuery.data.account?.customer?.paymentCards ?? [])
                  .filter((card) => card.status !== 'deactivated')
                  .sort(cardOrder)
                  .map((card) => (
                    <CreditCard
                      key={card.id}
                      {...card}
                      onClick={() =>
                        swalRender(
                          <CreditCardDetails
                            card={card}
                            onDeactivate={onDeactivate(card)}
                          />,
                        )
                      }
                    />
                  )),
              )
              .concat(
                <AddCreditCard
                  key={'add'}
                  id={'add'}
                  onClick={() => swalRender(<AddCreditCardForm />)}
                />,
              )}
      </SlideAllUp>
    </div>
  )
}

const AddCreditCard = ({ onClick }) => {
  return (
    <div className="card card-design hover-shadow w-100 h-100">
      <ButtonBase
        focusRipple
        onClick={onClick}
        className="d-block w-100 h-100 outline-0 text-white"
      >
        <div className="card-body position-relative text-center">
          <h4 className="mt-4 mb-0">Add Card</h4>
          <h4 className="mb-4">
            <i className="fa fa-plus" />
          </h4>
        </div>
      </ButtonBase>
    </div>
  )
}

const AddCreditCardForm = ({ onClose }) => {
  const initial = useMemo(() => ({ method: 'payment-card' }), [])
  return (
    <div className="position-relative overflow-hidden">
      <Checkout onSuccess={onClose} onCancel={onClose} />
    </div>
  )
}

const priority = {
  active: 2,
  inactive: 1,
}

function cardOrder(a, b) {
  return (priority[b.status] || 0) - (priority[a.status] || 0)
}

import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getOrCreateCoinUser, refetchAccounts, useUser } from '../context/user'
import { fromSearch, toSearch } from '../utils/search'
import { trackWarning } from '../utils/events'
import WelcomeText from '../components/Text/Welcome'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Slide from '@material-ui/core/Slide'
import FormHelperText from '@material-ui/core/FormHelperText'
import firebase from 'firebase/app'
import 'firebase/auth'
import {
  checkoutClient,
  redeemSubscription,
  redeemInitialSuccess,
} from '../context/checkout'
import { gTag, gtagCategoricalEvent } from '../utils/gtag'
import { useCustomTokenContext } from '../context/customToken'

export const Auth = () => {
  const history = useHistory()
  const authState = useAuthState(history)
  return (
    <>
      <div className="flex-grow-1" />
      <div className="text-center text-white px-1">
        <WelcomeText />
      </div>
      <Container maxWidth="xs">
        {authState.type === 'sign-up' ? (
          <AuthForm title="Create account" {...authState}>
            <Box textAlign="center" p={2} pt={0}>
              <Typography onClick={authState.signIn} key="sign-up">
                Already have an account?{' '}
                <Typography
                  component="span"
                  className="hover-underline"
                  color="secondary"
                >
                  Sign In
                </Typography>
              </Typography>
            </Box>
          </AuthForm>
        ) : authState.type === 'sign-in' ? (
          <AuthForm title="Sign in with email" {...authState} key="sign-in">
            <Box textAlign="center" p={2} pt={0}>
              <Typography onClick={authState.signUp}>
                Don't have an account?{' '}
                <Typography
                  component="span"
                  className="hover-underline"
                  color="secondary"
                >
                  Sign Up
                </Typography>
              </Typography>
              <Typography>
                <Link
                  onClick={authState.forgotPassword}
                  className="hover-underline"
                >
                  Forgot Password
                </Link>
              </Typography>
            </Box>
          </AuthForm>
        ) : authState.type === 'forgot' ? (
          <ForgotForm {...authState} />
        ) : authState.type === 'reset' ? (
          <ResetSent {...authState} />
        ) : null}
      </Container>
    </>
  )
}

export default Auth

const useAuthState = (history) => {
  const { skipRedeemSubscription } = useUser()
  const customTokenActions = useCustomTokenContext()[1]
  const { redirect, email: initialEmail, ...params } = fromSearch(history)
  const [{ email, password, loading, type, error }, setState] = useState({
    type: 'sign-in',
    email: initialEmail || '',
    password: '',
    loading: false,
    error: '',
  })
  const setValue = (name, value) => setState((v) => ({ ...v, [name]: value }))
  const setEmail = (ev) => setValue('email', ev.target.value)
  const setPassword = (ev) => setValue('password', ev.target.value)
  const forgotPassword = () => setValue('type', 'forgot')
  const signUp = () => setValue('type', 'sign-up')
  const signIn = () => setValue('type', 'sign-in')
  const handleSubmit = async (ev) => {
    ev.preventDefault()
    try {
      setState((v) => ({
        ...v,
        loading: true,
        error: '',
      }))
      if (!email) throw new Error('Email is required')
      if (!password) throw new Error('Password is required')
      if (type === 'sign-up') {
        if (process.env.NODE_ENV !== 'development') {
          await debounceEmailVerification(email)
        }
        skipRedeemSubscription.current = 'sign-up'
        gTag('event', 'sign_up')
        const { user } = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password)
      } else {
        // Clear out any previous skips
        skipRedeemSubscription.current = ''
        gTag('event', 'login')
        await firebase.auth().signInWithEmailAndPassword(email, password)
        console.log('Sign in success')
      }
      const user = firebase.auth().currentUser
      if (user) {
        await getOrCreateCoinUser()
          // .then(data => {
          //   if (!data.exists) {
          //     trackWarning('User created.', data)
          //   } else {
          //     trackWarning('User already exists.', data)
          //   }
          // })
          .then(() => checkoutClient.resetStore())
          .then(() => refetchAccounts())
          .then(() => {
            console.log('Refetched accounts')
          })
          .catch((err) => {
            trackWarning('User creation error.', err)
          })
      }
      if (type === 'sign-up') {
        await redeemSubscription(email)
          .then(async (redeem) => {
            console.log('redeemInitialSuccess', redeem)
            if (redeem && redeem.result && redeem.result.activeSubscription) {
              await redeemInitialSuccess(
                redeem.result.activeSubscription,
                user.uid,
                redeem.result.customerId,
              )
            }
          })
          .catch(() => {
            console.log('Initial redeem error')
          })
      }
      customTokenActions.rm()
      const search = toSearch(params)
      if (search) {
        history.push(`${redirect || '/account'}?${search}`)
      } else {
        history.push(`${redirect || '/account'}`)
      }
      setState((v) => ({ ...v, loading: false, error: '' }))
    } catch (e) {
      gtagCategoricalEvent('auth', 'auth-error', 'error', {
        value: e.message,
      })
      setState((v) => ({ ...v, loading: false, error: e.message }))
    }
  }
  const handleForgot = async (ev) => {
    ev.preventDefault()
    try {
      setState((v) => ({ ...v, loading: true, error: '' }))
      await firebase.auth().sendPasswordResetEmail(email)
      setState((v) => ({ ...v, loading: false, type: 'reset', error: '' }))
    } catch (e) {
      setState((v) => ({ ...v, loading: false, error: e.message }))
    }
  }
  return {
    type,
    error,
    email,
    password,
    loading,
    setValue,
    setEmail,
    setPassword,
    forgotPassword,
    handleSubmit,
    handleForgot,
    signUp,
    signIn,
  }
}

const AuthForm = ({
  title,
  email,
  password,
  setValue,
  setEmail,
  loading,
  setPassword,
  forgotPassword,
  handleSubmit,
  emailError,
  passwordError,
  error,
  signIn,
  signUp,
  children,
}) => {
  return (
    <Slide direction="up" in={true}>
      <Card component="form" onSubmit={handleSubmit}>
        <CardHeader title={title} />
        <Box px={2}>
          <TextField
            type="email"
            name="email"
            value={email}
            onChange={setEmail}
            margin="normal"
            variant="outlined"
            label="Email"
            fullWidth
            autoFocus
          />
          <TextField
            type="password"
            name="password"
            value={password}
            onChange={setPassword}
            margin="normal"
            variant="outlined"
            label="Password"
            fullWidth
          />
          {error ? (
            <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
          ) : null}
        </Box>
        <Box p={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ position: 'relative' }}
            disabled={loading}
            fullWidth
          >
            {loading ? (
              <LinearProgress
                style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
              />
            ) : null}
            Submit
          </Button>
        </Box>
        {children}
      </Card>
    </Slide>
  )
}

const ForgotForm = ({ email, setEmail, signIn, loading, handleForgot }) => {
  return (
    <Slide direction="up" in={true}>
      <Card component="form" onSubmit={handleForgot}>
        <CardHeader style={{ alignItems: 'center' }} title="Reset password" />
        <Box px={2}>
          <TextField
            type="email"
            name="email"
            value={email}
            onChange={setEmail}
            margin="normal"
            variant="outlined"
            label="Email"
            fullWidth
            autoFocus
          />
        </Box>
        <Box p={2} pt={3} display="flex">
          <Box flex={1} />
          <Button
            variant="contained"
            style={{ position: 'relative' }}
            onClick={signIn}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            style={{ position: 'relative', marginLeft: 4 }}
          >
            {loading ? (
              <LinearProgress
                style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
              />
            ) : null}
            Submit
          </Button>
        </Box>
      </Card>
    </Slide>
  )
}

const ResetSent = ({ email, setEmail, signIn, loading, handleForgot }) => {
  return (
    <Slide direction="up" in={true}>
      <Card>
        <CardHeader style={{ alignItems: 'center' }} title="Check your email" />
        <Box px={2}>
          <Typography>
            Follow the instructions sent to <strong>{email || 'email'}</strong>{' '}
            to recover your password.
          </Typography>
        </Box>
        <Box p={2} pt={3} display="flex">
          <Box flex={1} />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ position: 'relative' }}
            onClick={signIn}
          >
            Done
          </Button>
        </Box>
      </Card>
    </Slide>
  )
}

const debounceEmailVerificationFactory = () => {
  const emailCache = {}
  return async function (email) {
    if (emailCache[email]) return emailCache[email]
    emailCache[email] = fetch(
      `https://api.debounce.io/v1/?api=public_clZVdm5mM2VvUmptQk4zWkFqVHU1Zz09&email=${email}&send_transactional=5`,
    )
      .then((result) => result.json())
      .then((result) => {
        if (result && result.debounce) {
          const { debounce } = result
          console.log('Debounce result', result)
          if (debounce.result !== 'Safe to Send') {
            //  [Invalid, Risky, Safe to Send, Unknown]
            throw new Error(
              `Invalid email. ${
                debounce.did_you_mean
                  ? `Did you mean, ${debounce.did_you_mean}?`
                  : ''
              }`,
            )
          }
        }
        return result
      })
      .catch((e) => {
        if (e.message === 'Failed to fetch') {
          console.log('Debounce Error', e)
          return
          // setTimeout(() => {
          //   console.log('Debounce Allow Retry', e)
          //   emailCache[email] = null
          // }, 2000)
        }
        throw e
      })
    return emailCache[email]
  }
}

const debounceEmailVerification = debounceEmailVerificationFactory()

import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import CardActions from '@material-ui/core/CardActions'
import CardHeader from '@material-ui/core/CardHeader'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { makeContext } from './makeContext'
import React from 'react'
import { LowercaseButton } from './Buttons'
import { sweepstakesEvent } from './event'

export const SweepstakesFreeEntryDialogOpen = makeContext(
  false,
  'free_entry_dialog_open',
)

export const SweepstakesFreeEntryButton = () => {
  const setOpen = SweepstakesFreeEntryDialogOpen.useContext()[1]
  const onOpen = () => {
    sweepstakesEvent('freeEntry', 'pressed', {})
    setOpen(true)
  }
  return (
    <LowercaseButton
      style={{ color: 'inherit', opacity: 0.8, fontWeight: 'normal' }}
      onClick={onOpen}
      variant="outlined"
      size="small"
      fullWidth
    >
      Alternative way to enter
    </LowercaseButton>
  )
}

export const SweepstakesFreeEntryDialog = () => {
  const [open, setOpen] = SweepstakesFreeEntryDialogOpen.useContext()
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <CardHeader title="Sweepstakes Free Entries" />
      <Divider />
      <DialogContent
        style={{ overflowY: 'auto', WebkitOverflowScrolling: 'touch' }}
      >
        <DialogContentText>
          You may receive one hundred entries for each valid postcard mailed to
          us for any active Promotion.
        </DialogContentText>
        <DialogContentText>A valid postcard consists of:</DialogContentText>
        <DialogContentText component="div">
          <ul>
            <li>your printed name</li>
            <li>age</li>
            <li>complete mailing address</li>
            <li>a valid telephone number</li>
            <li>a valid email address</li>
            <li>the prize</li>
            <li>drawing date</li>
          </ul>
        </DialogContentText>
        <DialogContentText>
          all legibly printed on a 3.5" x 5" card. This card must be mailed,
          with first-class postage affixed, to XY - The Persistent Company, 1405
          30th Street, Suite A, San Diego, CA 92154.
        </DialogContentText>
        <DialogContentText style={{ lineHeight: 1 }}>
          <small>
            The card must be received and given due time to process during the
            Promotion Period for the Promotion so identified. If received during
            Promotion Period but not processed in time, the entry will be
            automatically applied to a future Promotion if one exists. It is the
            responsibility of the entrant to know and comply with the rules of
            their locale and our terms and conditions for the Promotion.
          </small>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <CardActions>
        <Box flexGrow={1} />
        <LowercaseButton variant="outlined" onClick={() => setOpen(false)}>
          Close
        </LowercaseButton>
      </CardActions>
    </Dialog>
  )
}

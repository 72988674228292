import React from 'react'
import './index.scss'

export const ExplorerMotionGraphic = props => {
  return (
    <svg
      viewBox="0 0 1400 1400"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      enableBackground="new 0 0 1400 1400"
      {...props}
    >
      <path className="st0" d="M1399.6,1398.5H-1.5V-1.5h1401.1" />
      <g id="sun">
        <path
          className="st1"
          d="M882.7,364.6c-0.6-123.6,100.9-227.4,229.7-225.1c119.4,2.2,222.7,100.4,220.3,230
		c-2.2,119.4-100.6,222.9-230.8,220C982.5,587,882.6,489.3,882.7,364.6z"
        />
        <path
          className="st2"
          d="M1269.7,364.6c0.6,89.1-73.1,163.6-165,162.1c-86.8-1.4-160.8-73.3-159.1-165.6
		c1.6-86.5,73.4-160.6,166.1-158.5C1198.1,204.4,1270.1,275.4,1269.7,364.6z"
        />
      </g>
      <g id="clouds">
        <path
          className="st2"
          d="M1679.2,690.7c-1.3,5-3.5,6.8-8.9,6.8c-77.2-0.2-154.3-0.1-231.5-0.1c-11.3,0-22.6,0-34,0
		c-11.3,0-22.6,0-33.9,0c-77.2,0-154.3,0-231.5,0.1c-5.4,0-7.6-1.8-8.9-6.8c-3.2-12.4,1.3-24.3,13.8-32.6
		c25.3-16.7,52.2-19.4,80.5-8.3c6.5,2.5,6.5,2.6,7.4-4.2c4-29.1,21.5-48.8,48-53.8c13.5-2.6,26.5,0.6,39.2,5.1
		c4.4,1.5,8.6,3.3,13.2,5.1c1.1-21.9,11.1-39.4,25.1-55c13.2-14.6,30-22.4,47.1-22c17.1-0.4,33.9,7.3,47.2,22
		c14,15.6,24,33.1,25.1,55c4.6-1.8,8.8-3.6,13.2-5.1c12.7-4.5,25.7-7.7,39.2-5.1c26.5,5,44,24.7,48,53.8c0.9,6.8,0.9,6.7,7.4,4.2
		c28.3-11.1,55.2-8.4,80.5,8.3C1677.9,666.4,1682.4,678.3,1679.2,690.7z"
        />
        <path
          className="st2"
          d="M106.1,420.5c-0.8,2-2.1,2.8-4.2,2.7c-1-0.1-2,0-3,0h-304.5c-1,0-2-0.1-3,0c-2.1,0.1-3.4-0.7-4.2-2.7
		c-1.9-5.2-3.4-10.5-2.3-16.1c2.1-11.3,9.7-18.5,19-23.8c25.3-14.2,51.5-15.8,78.2-4.3c4.3,1.9,5.1,1.1,5.3-3.4
		c1.3-25.1,17.6-46.3,40.9-53.8c6.1-2,12.2-2.7,18.4-2.5c6.1-0.1,12.3,0.6,18.4,2.5c23.3,7.5,39.6,28.7,40.9,53.8
		c0.2,4.5,1,5.3,5.3,3.4c26.7-11.5,52.9-9.9,78.2,4.3c9.3,5.3,16.9,12.5,19,23.8C109.5,410,108,415.3,106.1,420.5z"
        />
        <path
          className="st2"
          d="M616.3,154.6c-36.5,0-72.9,0-109.4,0c-1.6,0-3.7,0.8-4.6-0.8c-0.8-1.5,0.9-3.1,1.8-4.4
		c4.7-6.9,11.7-10.8,19.1-13.9c5.4-2.2,11-3.9,16.8-4.8c2.5-0.4,2.9-1.4,2.4-3.7c-5.2-23.7,14.8-38.4,36.7-33.6
		c5.1,1.1,6.8,0.3,8.7-4.6c8.2-21.1,27.8-31,47.1-24c13.4,4.8,22,14.7,27,27.7c1.3,3.5,2.4,4.5,5.9,2.7c2.6-1.4,5.7-1.7,8.7-1.7
		c11.9-0.1,18.6,6.3,18.2,18.1c-0.1,4.1,0.9,5.5,4.9,6.8c15,4.9,26.4,13.9,30.6,30c1.5,5.8,0.9,6.3-5,6.3
		C688.9,154.6,652.6,154.6,616.3,154.6z"
        />
        <path
          className="st2"
          d="M3077.5,689.4c-1.3,5-3.5,6.8-8.9,6.8c-77.2-0.2-154.3-0.1-231.5-0.1c-11.3,0-22.6,0-34,0
		c-11.3,0-22.6,0-33.9,0c-77.2,0-154.3,0-231.5,0.1c-5.4,0-7.6-1.8-8.9-6.8c-3.2-12.4,1.3-24.3,13.8-32.6
		c25.3-16.7,52.2-19.4,80.5-8.3c6.5,2.5,6.5,2.6,7.4-4.2c4-29.1,21.5-48.8,48-53.8c13.5-2.6,26.5,0.6,39.2,5.1
		c4.4,1.5,8.6,3.3,13.2,5.1c1.1-21.9,11.1-39.4,25.1-55c13.2-14.6,30-22.4,47.1-22c17.1-0.4,33.9,7.3,47.2,22
		c14,15.6,24,33.1,25.1,55c4.6-1.8,8.8-3.6,13.2-5.1c12.7-4.5,25.7-7.7,39.2-5.1c26.5,5,44,24.7,48,53.8c0.9,6.8,0.9,6.7,7.4,4.2
		c28.3-11.1,55.2-8.4,80.5,8.3C3076.2,665.1,3080.7,677,3077.5,689.4z"
        />
        <path
          className="st2"
          d="M1504.4,419.1c-0.8,2-2.1,2.8-4.2,2.7c-1-0.1-2,0-3,0h-304.5c-1,0-2-0.1-3,0c-2.1,0.1-3.4-0.7-4.2-2.7
		c-1.9-5.2-3.4-10.5-2.3-16.1c2.1-11.3,9.7-18.5,19-23.8c25.3-14.2,51.5-15.8,78.2-4.3c4.3,1.9,5.1,1.1,5.3-3.4
		c1.3-25.1,17.6-46.3,40.9-53.8c6.1-2,12.2-2.7,18.4-2.5c6.1-0.1,12.3,0.6,18.4,2.5c23.3,7.5,39.6,28.7,40.9,53.8
		c0.2,4.5,1,5.3,5.3,3.4c26.7-11.5,52.9-9.9,78.2,4.3c9.3,5.3,16.9,12.5,19,23.8C1507.8,408.6,1506.3,413.9,1504.4,419.1z"
        />
        <path
          className="st2"
          d="M2014.5,153.3c-36.5,0-72.9,0-109.4,0c-1.6,0-3.7,0.8-4.6-0.8c-0.8-1.5,0.9-3.1,1.8-4.4
		c4.7-6.9,11.7-10.8,19.1-13.9c5.4-2.2,11-3.9,16.8-4.8c2.5-0.4,2.9-1.4,2.4-3.7c-5.2-23.7,14.8-38.4,36.7-33.6
		c5.1,1.1,6.8,0.3,8.7-4.6c8.2-21.1,27.8-31,47.1-24c13.4,4.8,22,14.7,27,27.7c1.3,3.5,2.4,4.5,5.9,2.7c2.6-1.4,5.7-1.7,8.7-1.7
		c11.9-0.1,18.6,6.3,18.2,18.1c-0.1,4.1,0.9,5.5,4.9,6.8c15,4.9,26.4,13.9,30.6,30c1.5,5.8,0.9,6.3-5,6.3
		C2087.1,153.3,2050.8,153.3,2014.5,153.3z"
        />
        <path
          className="st2"
          d="M4476.8,689.4c-1.3,5-3.5,6.8-8.9,6.8c-77.2-0.2-154.3-0.1-231.5-0.1c-11.3,0-22.6,0-34,0
		c-11.3,0-22.6,0-33.9,0c-77.2,0-154.3,0-231.5,0.1c-5.4,0-7.6-1.8-8.9-6.8c-3.2-12.4,1.3-24.3,13.8-32.6
		c25.3-16.7,52.2-19.4,80.5-8.3c6.5,2.5,6.5,2.6,7.4-4.2c4-29.1,21.5-48.8,48-53.8c13.5-2.6,26.5,0.6,39.2,5.1
		c4.4,1.5,8.6,3.3,13.2,5.1c1.1-21.9,11.1-39.4,25.1-55c13.2-14.6,30-22.4,47.1-22c17.1-0.4,33.9,7.3,47.1,22
		c14,15.6,24,33.1,25.1,55c4.6-1.8,8.8-3.6,13.2-5.1c12.7-4.5,25.7-7.7,39.2-5.1c26.5,5,44,24.7,48,53.8c0.9,6.8,0.9,6.7,7.4,4.2
		c28.3-11.1,55.2-8.4,80.5,8.3C4475.5,665.1,4480,677,4476.8,689.4z"
        />
        <path
          className="st2"
          d="M2903.6,419.1c-0.8,2-2.1,2.8-4.2,2.7c-1-0.1-2,0-3,0h-304.5c-1,0-2-0.1-3,0c-2.1,0.1-3.4-0.7-4.2-2.7
		c-1.9-5.2-3.4-10.5-2.3-16.1c2.1-11.3,9.7-18.5,19-23.8c25.3-14.2,51.5-15.8,78.2-4.3c4.3,1.9,5.1,1.1,5.3-3.4
		c1.3-25.1,17.6-46.3,40.9-53.8c6.1-2,12.2-2.7,18.4-2.5c6.1-0.1,12.3,0.6,18.4,2.5c23.3,7.5,39.6,28.7,40.9,53.8
		c0.2,4.5,1,5.3,5.3,3.4c26.7-11.5,52.9-9.9,78.2,4.3c9.3,5.3,16.9,12.5,19,23.8C2907,408.6,2905.5,413.9,2903.6,419.1z"
        />
        <path
          className="st2"
          d="M3413.8,153.3c-36.5,0-72.9,0-109.4,0c-1.6,0-3.7,0.8-4.6-0.8c-0.8-1.5,0.9-3.1,1.8-4.4
		c4.7-6.9,11.7-10.8,19.1-13.9c5.4-2.2,11-3.9,16.8-4.8c2.5-0.4,2.9-1.4,2.4-3.7c-5.2-23.7,14.8-38.4,36.7-33.6
		c5.1,1.1,6.8,0.3,8.7-4.6c8.2-21.1,27.8-31,47.1-24c13.4,4.8,22,14.7,27,27.7c1.3,3.5,2.4,4.5,5.9,2.7c2.6-1.4,5.7-1.7,8.7-1.7
		c11.9-0.1,18.6,6.3,18.2,18.1c-0.1,4.1,0.9,5.5,4.9,6.8c15,4.9,26.4,13.9,30.6,30c1.5,5.8,0.9,6.3-5,6.3
		C3486.4,153.3,3450.1,153.3,3413.8,153.3z"
        />
      </g>
      <g id="dunes">
        <path
          className="st3"
          d="M4582.8,905.4c-19.6,4-36.9,10.2-54.9,15.8c-59.1,18.1-112.5,40-162.9,63.7c-32.9,15.4-71.1,27-109.3,38.7
		c-44.1,13.5-90.3,25.3-133.6,39.6c-7.2,2.3-12.9,5.4-19.6,8c-2.3,0.9-5.5,2.3-8.2,1.1c-2.3-1.1,0.2-2.7,1.3-3.9
		c13.5-16.5,37.6-27.5,70-34.1c20.7-4.2,42.4-6.4,63.5-9.7c34.6-5.3,63.9-14.4,84.2-30c22.4-17.4,18.1-30-14.1-43
		c-15.2-6.1-31.9-11.1-45.6-18c-37.8-18.8-46.6-45.1-24.3-69.5c15.6-16.9,43.5-27.5,74.1-36.5c23.8-7,49.6-12.7,69.4-23
		c26-13.4,25.7-32.6,0-46.2c-13.3-6.9-28.3-12.7-43-18.8c-7.4-3.1-13.7-6.6-18.8-10.6c-8-6.5-8.4-13,1.7-19.2
		c24.5,1.4,47.3,5.7,70.5,10c67.1,12.5,124.3,33.2,182.5,53.2c5.5,1.9,10.3,4.4,17.3,4.8C4582.8,820.2,4582.8,862.8,4582.8,905.4z"
        />
        <path
          className="st3"
          d="M1619.4,831.3c1.3,1.1,0,1.7-1.3,2.3c-32.3,15.6-63.9,31.7-99.6,45.6c-28.3,11.1-54.4,23.5-80.4,36
		c-42.4,20.3-88.2,38.3-135.7,55.6c-47.9,17.4-93.5,36.3-140.3,54.4c-50.2,19.4-103.2,36.9-154.7,55.2
		c-41.6,14.8-82.7,30-123.6,45.2c-7.2,2.7-13.5,3-21.1,0.9c-67.5-18.1-113.5-44.8-126.2-83.5c-8-24.8,10.8-45.7,49.6-61.4
		c23.6-9.7,50.8-16.4,78.5-22.7c20.9-4.8,41.4-9.9,59.9-16.7c33.1-12.3,42.2-26.3,30.6-46.1c-6.1-10.4-10.5-20.9-11-31.8
		c-1.1-23.6,20.9-40.8,56.5-54.6c24.5-9.5,53-15.4,77.4-24.7c18.8-7,34.4-15.2,36.1-28c1.3-8.2-3.2-15.6-11.2-22.8
		c-9.3-8.1-21.7-15.4-27-24.6c-6.3-10.6-2.5-20.1,10.3-29.6c11.8-8.5,27.4-12.8,45.8-15.8c4.2,0.2,8.7,0,12.4,0.5
		c48.5,7.2,94.9,17,138.6,30.2c55.5,16.7,108,35.6,158.2,56c43.5,17.7,82.1,38,117.7,59.7c10.3,6.3,20,12.7,33.5,17.5
		C1601,831.2,1609.4,832.4,1619.4,831.3z"
        />
        <path
          className="st3"
          d="M3961,797.7c1.3,1.1,0,1.7-1.3,2.3c-32.3,15.6-63.9,31.7-99.6,45.6c-28.3,11.1-54.4,23.5-80.4,36
		c-42.4,20.3-88.2,38.3-135.7,55.6c-47.9,17.4-93.5,36.3-140.3,54.4c-50.2,19.4-103.2,36.9-154.7,55.2
		c-41.6,14.8-82.7,30-123.6,45.2c-7.2,2.7-13.5,3-21.1,0.9c-67.5-18.1-113.5-44.8-126.2-83.5c-8-24.8,10.8-45.7,49.6-61.4
		c23.6-9.7,50.8-16.4,78.5-22.7c20.9-4.8,41.4-9.9,59.9-16.7c33.1-12.3,42.2-26.3,30.6-46.1c-6.1-10.4-10.5-20.9-11-31.8
		c-1.1-23.6,20.9-40.8,56.5-54.6c24.5-9.5,53-15.4,77.4-24.7c18.8-7,34.4-15.2,36.1-28c1.3-8.2-3.2-15.6-11.2-22.8
		c-9.3-8.1-21.7-15.4-27-24.6c-6.3-10.6-2.5-20.1,10.3-29.6c11.8-8.5,27.4-12.8,45.8-15.8c4.2,0.2,8.7,0,12.4,0.5
		c48.5,7.2,94.9,17,138.6,30.2c55.5,16.7,108,35.6,158.2,56c43.5,17.7,82.1,38,117.7,59.7c10.3,6.3,20,12.7,33.5,17.5
		C3942.6,797.5,3951.1,798.7,3961,797.7z"
        />
        <path
          className="st3"
          d="M2370.2,923.1c-12.2-5.6-23.2-12.6-33.3-18.9c-14.8-9.3-29.5-18.7-44.3-28.2c-28.7-18.3-57.8-36.3-87.8-53.9
		c-17.7-10.4-35.9-20.7-54.4-30.8c-9.5-3.6-19.2-7.1-28.7-10.9c-16.7-6.4-32.5-13.2-48.5-20.3c-4.6-2.1-8.9-4.1-12.9-6.4
		c-12.9-2.7-26-4.8-39.2-6.3c-18.1-1.9-38.4-3.3-56.3-0.6c-6.3,1-12,2.3-17.7,4.1c0.4,0.5,0.8,0.8,1.3,1.3c2.5,2.3,5.5,6.2,9.9,9.8
		c1.1,1.1,2.5,2.1,4.2,2.8c0.8,0.4,1.7,0.8,2.3,1.2c-2.1-2.5-0.6-1.6,4,2.9c7.8,4.8,15.8,9.4,23.4,14.3c3.4,2.2,6.5,4.5,9.7,6.9
		c0.2,0.1,0.4,0.4,0.6,0.5c1.7,1.5,3.2,3,4.4,4.6c0.4,0.4,0.6,0.8,1.1,1.2c0-0.1,0.2,0.4,0.4,0.8c0.4,3.1,0.6,6.2,0.2,9
		c-0.2,1.7-0.6,2.7-2.3,5.6c-0.8,1.5-1.9,2.9-2.7,4.4c-0.2,0.4-1.9,2.8-1.9,2.9c-1.5,1.7-3.4,3.3-5.1,4.8c-1.5,1.2-3,2.4-4.4,3.5
		c-0.6,0.4-1.1,0.7-1.7,1.1c4-2.7,3-1.9-3.4,2.2c5.5-3.7-3.4,1.9-4.6,2.8c-3.6,2.1-7.4,4.1-11.2,6c-7.2,3.6-14.6,7-21.9,10.5
		c-16,7.7-30.8,15.8-44.3,25.2c-16.7,11.6-25.7,27.1-27.6,42c-0.8,7,0.8,14.2,3.6,21.2c-0.4-0.1-0.8-0.2-1.3-0.4
		c8.7,14.3,19.4,28.5,21.9,43.4c1.9,11.1-2.1,22.4-11.4,32.3c-0.8,2.3-1.9,4.5-3.2,6.7c-1.9,1.7-4,3.5-5.9,5.2
		c-19,15.6-44.5,27.2-69.4,39.3c-21.1,10.2-43.9,19.4-62.2,31.4c-9.7,6.4-15.8,13.2-15.8,20.9c19-5.8,38.6-11.1,58.2-16.1
		c27.2-7,52.3-15.5,77.9-24.1c12.2-4.2,25.1-7.9,37.6-12c5.3-1.7,11.2-3.4,17.5-4c0.4-1.1,0.6-2.1,1.1-3.1c3.4-1.1,7-2.1,11-2.8
		c8.4-1.6,17.9-2.5,25.7-4.8c11.8-3.4,23.2-7,35.2-10c10.1-2.5,20.3-5.1,30.6-7.4c3.4-1.8,7-3.7,10.3-5.6c9.5-2.7,19.2-5.2,28.9-7.5
		c29.3-7.1,59.1-13.5,87.8-21.5c28.1-7.6,53.6-17.7,82.3-24.7c6.5-1.6,13.1-3,19.8-4.5c8-2.5,16.2-5.1,24.3-7.7
		c8-2.5,16-5.2,24.1-7.7l0,0c4.2-1.7,9.1-4.4,11.8-6.8c0.2-0.2,0.4-0.5,0.6-0.7c5.7-0.8,11.4-1.8,17.1-2.7c9.7-3.7,20-8.6,31-10.8
		C2369.4,930.3,2370.6,926.8,2370.2,923.1z"
        />
        <path
          className="st4"
          d="M4583.2,906.2c-4.9-2-9.3-0.6-13.7,0.5c-5.3,1.4-10.1,3.2-15.2,4.7c-60.8,17.2-116.5,38.2-168.4,61.6
		c-15,6.7-28.9,13.9-44.7,20.1c-15.8,6.2-32.1,11.9-48.7,17.4c12.9-5.3,23.8-11.6,31.9-19.7c13.5-13.6,10.3-24.4-11.2-35.2
		c-13.7-6.9-29.7-12.3-45.2-18.2c-26.2-10-42.6-23.2-44.9-39.9c-3.4-24.6,16.5-44,56.1-58.3c15.4-5.6,32.1-10.2,48.5-14.9
		c18.8-5.3,37.3-10.6,51.7-18.9c13.9-8.2,19.8-17.6,16.7-28.5c-3.8-13.2-21.9-21-40.1-28.8c-8.7-3.8-18.4-7.1-26.6-11.2
		c-12.9-6.3-24.7-13.1-15.4-23.2c0.8-1,0.6-2.1-0.8-3.1c-3.6-0.1-7-0.2-10.3,0.6c-21.7,5.9-45.2,9.6-68.4,13.8
		c-39.2,7.2-79.5,13.5-115.6,24.2c-54.9,16.2-112,30.5-162,50.7c-16.7,4.6-28.5,11.8-40.7,18.8c-5.3,3.1-10.8,6.1-17.3,8.5
		c-48.5,18.4-92.6,39.6-137.8,60c-21.1,9.6-42,19.4-64.8,27.9c-47.9,18-96.4,35.6-143,54.3c-43.9,17.7-88.8,34.7-135,50.9
		c-34,11.9-68.4,23.6-101.9,35.9c-31.9,11.7-63.3,23.5-95.4,35c-8,2.9-15,2.9-23.2,0.6c-59.7-16.6-101.5-40.7-117.3-74.6
		c-10.5-22.7,1.1-42.9,33.5-59.6c24.7-12.6,55.7-20.2,87.1-27.5c19.8-4.6,39.5-9.4,57.6-15.5c19.4-6.5,35.9-14.4,40.7-26.6
		c3.4-8.5,1.5-17-3.6-25.2c-7-11.6-11.4-23.4-9.9-35.6c1.5-12.6,9.7-23.8,26-33.5c15.8-9.5,35.4-16.6,56.1-23
		c22.2-6.8,46-12.2,64.6-21.8c26.8-13.8,31.4-30.4,11.8-46.9c-5.9-5-12.2-10-18.1-15c-12.7-10.6-17.5-21.7-3.8-33.1
		c9.3-7.7,20.3-14.5,38.6-16.9c3.6-0.5,8.7-1,8.2-3.9c-18.8-1-37.6-1.1-56.5-0.3c-54.9,2-104.9,11.2-151.7,25
		c-49.8,14.6-99,29.7-148.5,44.4c-61.6,18.4-123.6,36.4-182.9,56.6c-3.8,1.3-7.4,2.3-10.5-0.2c-4.4,1.5-3.2,3.6-3,5.7v0.3
		c-0.4,0.1-0.6,0.2-1.1,0.3c-1.1,0.3-8.8,2.7-9.7,3.1c0,0,0,0,0,0c-3.4,1-6.7,2.1-10.1,3.1c-12.9,3.9-24.7,8.9-36.7,13.4
		c-20.7,4.6-41.1,9.2-61.6,13.8c-61.2,13.9-122.4,28.2-184,41.6c-60.6,13.2-117.9,28.4-174.7,45.5c-27.9,8.4-55.5,17-83.8,25.2
		c-26.2,7.7-53.4,14.3-80.6,21c-10.6,2.6-21.1,5.2-31.6,8c-33.9,8.7-67.1,18-97.7,29.4c0-0.5,0-1,0.1-1.5
		c-34.3,12.1-67.1,25.3-98.4,39.4c-15,6.7-28.9,13.9-44.7,20.1c-15.8,6.2-32.1,11.9-48.7,17.4c12.9-5.3,23.8-11.6,31.9-19.7
		c13.5-13.6,10.3-24.4-11.2-35.2c-12.5-6.3-26.9-11.4-41-16.6c-1.4-0.5-2.8-1-4.2-1.6c-26.2-10-42.6-23.2-44.9-39.9
		c-3.4-24.6,16.5-44,56.1-58.3c15.4-5.6,32.1-10.2,48.5-14.9c5.9-1.7,11.9-3.4,17.7-5.2c6-14.5,10.5-29.4,12.8-44.3
		c1.1-2.7,2.1-5.3,3.4-8c2.3-4.8,4.6-9.4,5.3-14c-3.6-1.7-7.3-3.3-11-4.9c-8.7-3.8-18.4-7.1-26.6-11.2
		c-12.9-6.3-24.7-13.1-15.4-23.2c0.8-1,0.6-2.1-0.8-3.1c-3.6-0.1-7-0.2-10.3,0.6c-21.7,5.9-45.2,9.6-68.4,13.8
		c-39.2,7.2-79.5,13.5-115.6,24.2c-54.9,16.2-112,30.5-162,50.7c-16.7,4.6-28.5,11.8-40.7,18.8c-5.3,3.1-10.8,6.1-17.3,8.5
		c-48.5,18.4-92.6,39.6-137.8,60c-21.1,9.6-42,19.4-64.8,27.9c-47.9,18-96.4,35.6-143,54.3c-43.9,17.7-88.8,34.7-135,50.9
		c-34,11.9-68.4,23.6-101.9,35.9c-31.9,11.7-63.3,23.5-95.4,35c-8,2.9-15,2.9-23.2,0.6c-59.7-16.6-101.5-40.7-117.3-74.6
		c-10.5-22.7,1.1-42.9,33.5-59.6c24.7-12.6,55.7-20.2,87.1-27.5c19.8-4.6,39.5-9.4,57.6-15.5c19.4-6.5,35.9-14.4,40.7-26.6
		c3.4-8.5,1.5-17-3.6-25.2c-7-11.6-11.4-23.4-9.9-35.6c1.5-12.6,9.7-23.8,26-33.5c15.8-9.5,35.4-16.6,56.1-23
		c22.2-6.8,46-12.2,64.6-21.8c26.8-13.8,31.4-30.4,11.8-46.9c-5.9-5-12.2-10-18.1-15c-12.7-10.6-17.5-21.7-3.8-33.1
		c9.3-7.7,20.3-14.5,38.6-16.9c3.6-0.5,8.7-1,8.2-3.9c-18.8-1-37.6-1.1-56.5-0.3c-54.9,2-104.9,11.2-151.7,25
		c-49.8,14.6-99,29.7-148.5,44.4C711.1,754,649,772,589.7,792.2c-3.8,1.3-7.4,2.3-10.5-0.2c-4.4,1.5-3.2,3.6-3,5.7v0.3
		c-0.4,0.1-0.6,0.2-1.1,0.3c-1.1,0.3-7.9,2.5-9.5,3c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0c0,0,0,0,0,0c-3.4,1-6.7,2.1-10.1,3.1
		c-12.9,3.9-24.7,8.9-36.7,13.4c-20.7,4.6-41.1,9.2-61.6,13.8c-61.2,13.9-122.4,28.2-184,41.6c-60.6,13.2-117.9,28.4-174.7,45.5
		c-27.9,8.4-55.5,17-83.8,25.2c-26.2,7.7-53.4,14.3-80.6,21c-44.7,11.1-89.2,22.5-129.3,37.4c0-6.8,6.3-12.3,15.2-17.5
		c21.7-12.5,48.9-21.7,73.6-32.4c2.5-1.1,4.9-2.1,7.4-3.2c32.3-10.3,58.4-25.2,79.3-41.6c14.3-11.3,26.2-23.7,35.7-36.3
		c3.8-5,7-10.2,9.5-15.4c2.1-4.2,3.2-8.5,4.6-12.8c0.6-1.6,1.1-3.3,1.7-4.9c0.2-0.5,1.3-3.6,1.5-4.4c1.9-5.2,4.6-10.3,8.4-15.3
		c0.2-0.3,1.3-1.5,2.1-2.2c1.1-1.1,2.3-2.1,3.6-3.1c3.4-2.9,7.4-5.5,11.4-8.2c0.4-0.2,3.6-2.1,4-2.3c2.1-1.2,4.2-2.2,6.3-3.4
		c4.2-2.2,8.4-4.4,12.9-6.5c10.5-5.3,20.7-11,30-16.9c31-19.9,40.1-53.8,13.3-76.1c-18.4-15.2-51.1-23.8-86.1-22.9
		c-44.3,1.2-77.2,13.1-111.4,26c-39.5,14.9-79.3,29.6-117.5,45.6c-11.8,5-23.8,9.9-35.7,15c-0.4-0.7-1.1-1.6-1.5-2.3
		c-1.7-2.6-1.7-5.7-8.7-6.7c-2.1-0.5-4-0.3-5.7,0.5c-21.5,9.8-43,19.6-64.6,29.3c-4.4,1.9-5.5,4.3-5.5,7c-0.2,6.8,1.1,13.7-0.8,20.5
		c-0.4,0-0.6,0.1-1.1,0.1c2.7,7.5-1.1,14.5-4.6,22c-1.3,2.7-2.3,5.3-3.4,8c-2.3,15.1-7,30.2-13.1,44.9c-11.2,27-26.2,53.6-40.5,80.4
		c-9.5,17.7-23.8,34.4-46.4,49.1c-19,12.2-42,22.4-65.6,32c-18.4,7.6-34.6,16.8-58.4,20.4c-3,0.4-5.5,1.7-7.4,3
		c-11.2,8-20.5,16.5-24.5,26.3c-8,19.5-11.2,39.2-12.4,59c-1.3,18.2-1.5,36.5-2.3,54.7c-0.8,19.6,2.1,39.3,6.3,58.8
		c0.6,2.4,2.5,4.8,3,7.2c1.7,6.9,2.7,7.2,16.5,6.2c16.9-1.3,32.9,0.2,47.3,5c9.3,3.1,12.2,7.7,8.9,13c-4.9,7.7-15.8,11.9-31.2,13.2
		c-6.8,0.5-12.2,1.8-14.8,5.1c-5.9,7.6-18.1,11.6-32.5,14.1c-36.3,6.2-73.2,9.3-111.6,6.7c-3.6-0.2-7-0.2-10.1,1
		c0,26.1,0.2,52.2-0.2,78.2c0,4,1.9,4.9,9.7,4.9c978.3-0.1,1934.2,14.9,2912.6,14.9c1.1,0,15.6,4.1,16.7,4.2
		c5.3,0.5,55.6-0.1,55.6-2.8h75c789.3,87.8,1286,9,2059.1-16.8c1.1,0,135.1-22.5,136.2-22.4c5.3,0.5,29.2-11.3,29.2-14L4583.2,906.2
		z M1761.1,1103.2c5.9-7.5,13.5-13.5,24.1-18.6c20.9-10.1,47-15.1,74.5-18.9c16.7-2.3,33.8-4.2,49.6-7.8c-11.6,3.4-23.2,6.7-34.8,10
		C1836.2,1078.8,1795.9,1088.3,1761.1,1103.2z M4214.2,1034.2c-38.2,11-78.5,20.4-113.3,35.3c5.9-7.5,13.5-13.5,24.1-18.6
		c20.9-10.1,47-15.1,74.5-18.9c16.7-2.3,33.8-4.2,49.6-7.8C4237.4,1027.6,4225.8,1030.9,4214.2,1034.2z"
        />
        <path
          className="st3"
          d="M436.1,830.8c-12.2-4.9-23.2-11.1-33.3-16.6c-14.8-8.2-29.5-16.5-44.3-24.8c-28.7-16.1-57.8-31.9-87.8-47.5
		c-17.7-9.2-35.9-18.2-54.4-27.1c-9.5-3.2-19.2-6.3-28.7-9.6c-16.7-5.6-32.5-11.6-48.5-17.9c-4.6-1.8-8.9-3.6-12.9-5.6
		c-12.9-2.3-26-4.3-39.2-5.5c-18.1-1.7-38.4-2.9-56.3-0.5c-6.3,0.9-12,2-17.7,3.6c0.4,0.4,0.8,0.7,1.3,1.2c2.5,2,5.5,5.4,9.9,8.6
		c1.1,1,2.5,1.8,4.2,2.4c0.8,0.3,1.7,0.7,2.3,1.1c-2.1-2.2-0.6-1.4,4,2.6c7.8,4.3,15.8,8.3,23.4,12.6c3.4,1.9,6.5,3.9,9.7,6.1
		c0.2,0.1,0.4,0.3,0.6,0.4c1.7,1.3,3.2,2.7,4.4,4c0.4,0.3,0.6,0.7,1.1,1.1c0-0.1,0.2,0.3,0.4,0.7c0.4,2.8,0.6,5.4,0.2,7.9
		c-0.2,1.5-0.6,2.3-2.3,4.9c-0.8,1.3-1.9,2.6-2.7,3.8c-0.2,0.3-1.9,2.4-1.9,2.6c-1.5,1.5-3.4,2.9-5.1,4.3c-1.5,1.1-3,2.1-4.4,3.1
		c-0.6,0.3-1.1,0.6-1.7,1c4-2.3,3-1.7-3.4,1.9c5.5-3.3-3.4,1.7-4.6,2.4c-3.6,1.8-7.4,3.6-11.2,5.3c-7.2,3.2-14.6,6.2-21.9,9.3
		c-16,6.8-30.8,13.9-44.3,22.1c-16.7,10.2-25.7,23.8-27.6,36.9c-0.8,6.2,0.8,12.5,3.6,18.6c-0.4-0.1-0.8-0.2-1.3-0.3
		c8.7,12.6,19.4,25.1,21.9,38.2c1.9,9.8-2.1,19.7-11.4,28.4c-0.8,2-1.9,3.9-3.2,5.9c-1.9,1.5-4,3.1-5.9,4.6
		c-19,13.7-44.5,23.9-69.4,34.6c-21.1,8.9-43.9,17-62.2,27.7c-9.7,5.6-15.8,11.6-15.8,18.4c19-5.1,38.6-9.8,58.2-14.2
		c27.2-6.2,52.3-13.6,77.9-21.2c12.2-3.7,25.1-6.9,37.6-10.5c5.3-1.5,11.2-3,17.5-3.5c0.4-1,0.6-1.8,1.1-2.8c3.4-1,7-1.8,11-2.4
		c8.4-1.4,17.9-2.2,25.7-4.3c11.8-3,23.2-6.2,35.2-8.8c10.1-2.2,20.3-4.5,30.6-6.5c3.4-1.6,7-3.3,10.3-4.9
		c9.5-2.3,19.2-4.6,28.9-6.6c29.3-6.3,59.1-11.9,87.8-18.9c28.1-6.7,53.6-15.5,82.3-21.7c6.5-1.4,13.1-2.7,19.8-3.9
		c8-2.2,16.2-4.5,24.3-6.8c8-2.2,16-4.6,24.1-6.8l0,0c4.2-1.5,9.1-3.8,11.8-6c0.2-0.2,0.4-0.4,0.6-0.6c5.7-0.7,11.4-1.6,17.1-2.3
		c9.7-3.3,20-7.6,31-9.5C435.3,837,436.6,834,436.1,830.8z"
        />
      </g>
      <g id="folliage-back">
        <g>
          <path
            className="st5"
            d="M201.4,818.3c-1,19.8-3.1,39.5-6,59.1c-3.1,21.7-10.2,42.3-15.1,63.6c-2.6,11.4-5.7,22.6-9.3,33.7
			c-7,21.2-18.6,39.3-35.4,54.1c-10.5,9.2-21.1,18.4-31.8,27.4c-2.5,2.1-5.7,3.7-8.8,4.3c-3.8,0.8-5.4,3-7.4,5.8
			c-10.4,14.7-12.1,32-13.5,49c-3,36.9-3.4,73.8-2,110.8c0.3,7.8,1.3,15.6,2,23.4c0.7,3.6,0.4,7.3,0.3,10.9c-0.1,3.9-4.3,7.1-10,7
			c-8.3-0.1-16.2,1.7-23.9,4.4c-2,0.7-3.9,1.5-5.8,2.3c-3.5,1.6-6.7,3.5-9.8,5.7c-3.7-3-7.7-5.5-12-7.5c-1.9-0.9-3.8-1.7-5.8-2.3
			c-7.7-2.7-15.6-4.5-23.9-4.4c-5.7,0.1-9.9-3.1-10-7c-0.1-3.6-0.4-7.3,0.3-10.9c0.7-7.8,1.7-15.6,2-23.4c1.4-37,1-73.9-2-110.8
			c-1.4-17-3.1-34.3-13.5-49c-2-2.8-3.6-5-7.4-5.8c-3.1-0.6-6.3-2.2-8.8-4.3c-10.7-9-21.3-18.2-31.8-27.4
			c-16.8-14.8-28.4-32.9-35.4-54.1c-3.6-11.1-6.7-22.3-9.3-33.7c-4.9-21.3-12-41.9-15.1-63.6c-2.9-19.6-5-39.3-6-59.1
			c0.1-6,0.1-12-0.1-17.9c0.4-24.6,3.9-48.6,12.1-72c3.9-11,11.1-18.6,21.4-22.9c18.6-7.8,26.2-5.4,34.8,14.1c4,9,5.6,18.9,6.1,28.7
			c1.3,22.4,2.2,44.9,3.3,67.3c0.9,5.5-0.9,11.6,2.9,16.6c-0.3,11,1.5,21.9,1,32.8c-0.2,3.4,0.2,6.6,0.9,9.8
			c0.1,5.3,0.6,10.6,1.1,15.9c1.7,19.2,4.3,38.3,9.7,56.9c2.6,8.8,6.5,16.8,13.8,22.7c5.6,4.5,12.1,5.3,16.5,1.8
			c4.4-3.6,7-8,7.9-13.9c2.4-15.2,1.9-30.4,1.8-45.7c1.4-2.9-0.9-5.4-0.9-8.2c-0.2-15-2.5-29.9-2.8-44.8
			c-0.4-17.8-1.5-35.6-1.3-53.4c0.1-3.7-0.1-7.3-0.6-11c-0.6-4.6-1.1-9.4,1.7-13.6c0-1,0.1-2,0.1-3c-1.8-48.8-1.5-97.5,1.1-146.2
			c0.7-12.9,2.6-25.5,7.9-37.4c5.9-13.4,16.2-20.6,30.9-21.7c5.7-0.4,11.1,0.8,16.6,1.8h0c14.7,1.1,25,8.3,30.9,21.7
			c5.3,11.9,7.2,24.5,7.9,37.4c2.6,48.7,2.9,97.4,1.1,146.2c0,1,0.1,2,0.1,3c2.8,4.2,2.3,9,1.7,13.6c-0.5,3.7-0.7,7.3-0.6,11
			c0.2,17.8-0.9,35.6-1.3,53.4c-0.3,14.9-2.6,29.8-2.8,44.8c0,2.8-2.3,5.3-0.9,8.2c-0.1,15.3-0.6,30.5,1.8,45.7
			c0.9,5.9,3.5,10.3,7.9,13.9c4.4,3.5,10.9,2.7,16.5-1.8c7.3-5.9,11.2-13.9,13.8-22.7c5.4-18.6,8-37.7,9.7-56.9
			c0.5-5.3,1-10.6,1.1-15.9c0.7-3.2,1.1-6.4,0.9-9.8c-0.5-10.9,1.3-21.8,1-32.8c3.8-5,2-11.1,2.9-16.6c1.1-22.4,2-44.9,3.3-67.3
			c0.5-9.8,2.1-19.7,6.1-28.7c8.6-19.5,16.2-21.9,34.8-14.1c10.3,4.3,17.5,11.9,21.4,22.9c8.2,23.4,11.7,47.4,12.1,72
			C201.3,806.3,201.3,812.3,201.4,818.3z"
          />
          <path
            className="st6"
            d="M109.6,1280.7c-3,7.6-8.7,12-16.9,12.8c-2.2,0.2-3.9,0.6-4.6,2.9c-2.6,8.1-8.9,12.2-16.4,14.9
			c-16.5,5.9-33.6,8-51.1,7c-2.6-0.1-5.2-1.2-7.7-1.9c0-0.4,0-0.8,0-1.2c-9.1-1.1-18.2-3.1-27-6.3c-7.5-2.7-13.8-6.8-16.4-14.9
			c-0.7-2.3-2.4-2.7-4.6-2.9c-8.2-0.8-13.9-5.2-16.9-12.8c-2.7-6.8-0.8-12.4,5.4-16.4c7.4-4.7,15.6-6.1,24-4.3c4.9,1,6-0.3,5.5-4.7
			c-0.2-1.9-0.4-4,0.8-5.8c0,1.3,0.1,2.7,0.1,4c0,9.5,2.3,11.7,11.9,11.8c3,0,6,0.2,8.9,0.7c9.6,1.7,18.4,5,26.3,9.9
			c6.8-3.6,14.2-6.1,22.1-7.5c2.9-0.5,5.9-0.7,8.9-0.7c9.6-0.1,11.9-2.3,11.9-11.8c0-1.3,0.1-2.7,0.1-4c1.2,1.8,1,3.9,0.8,5.8
			c-0.5,4.4,0.6,5.7,5.5,4.7c8.4-1.8,16.6-0.4,24,4.3C110.4,1268.3,112.3,1273.9,109.6,1280.7z"
          />
        </g>
        <path
          className="st7"
          d="M531.2,1089.5c0.2,3.1-0.3,6.9-1.3,10.6c-1.1,4.1-0.2,5.7,4.3,5.5c5-0.2,9.4,1.7,13.4,4.7
		c2.4,1.7,4.4,4.1,4.6,6.9c0.4,4.6,3.1,5.7,7,6.6c5.9,1.3,10.5,4.9,14.1,9.8c5.6,7.7,4.6,18.1-3.5,24.6c-13.6,11-29.6,16.1-46.2,9.7
		c-8.3-3.2-14.9-4.3-23-1.3c-6.5,2.4-12.9,0.1-18.4-3.7c-2.1-1.5-3.7-1.5-5.9-0.6c-9.8,3.9-19.8,7-30.6,5.4
		c-11.3-1.7-18.7-7.5-20.4-15.7c-1.7-8.5,3.8-18.5,13.1-23.9c5.6-3.3,11.7-4.8,18.2-4.6c3.1,0.1,3.8-0.7,3.5-4
		c-0.9-10.5,6.2-18.1,16.7-17.8c3.9,0.1,4.3-1.3,4.2-4.5c-0.2-5.7-0.2-11.3,1.4-16.8c3.8-13.1,15-21.3,27-19.7
		c12.6,1.7,21.4,12.3,21.6,26C531.2,1087.3,531.2,1088,531.2,1089.5z"
        />
        <path
          className="st5"
          d="M1134.7,1221c0.3,4.3-0.4,9.5-1.8,14.6c-1.5,5.6-0.3,7.8,5.9,7.6c6.9-0.3,12.9,2.3,18.4,6.5
		c3.3,2.3,6,5.6,6.3,9.5c0.5,6.3,4.3,7.8,9.6,9.1c8.1,1.8,14.4,6.7,19.4,13.5c7.7,10.6,6.3,24.9-4.8,33.8
		c-18.7,15.1-40.6,22.1-63.4,13.3c-11.4-4.4-20.5-5.9-31.6-1.8c-8.9,3.3-17.7,0.1-25.3-5.1c-2.9-2.1-5.1-2.1-8.1-0.8
		c-13.5,5.4-27.2,9.6-42,7.4c-15.5-2.3-25.7-10.3-28-21.6c-2.3-11.7,5.2-25.4,18-32.8c7.7-4.5,16.1-6.6,25-6.3
		c4.3,0.1,5.2-1,4.8-5.5c-1.2-14.4,8.5-24.9,22.9-24.4c5.4,0.1,5.9-1.8,5.8-6.2c-0.3-7.8-0.3-15.5,1.9-23.1
		c5.2-18,20.6-29.3,37.1-27.1c17.3,2.3,29.4,16.9,29.7,35.7C1134.7,1217.9,1134.7,1218.9,1134.7,1221z"
        />
        <g>
          <path
            className="st5"
            d="M1599.6,817c-1,19.8-3.1,39.5-6,59.1c-3.1,21.7-10.2,42.3-15.1,63.6c-2.6,11.4-5.7,22.6-9.3,33.7
			c-7,21.2-18.6,39.3-35.4,54.1c-10.5,9.2-21.1,18.4-31.8,27.4c-2.5,2.1-5.7,3.7-8.8,4.3c-3.8,0.8-5.4,3-7.4,5.8
			c-10.4,14.7-12.1,32-13.5,49c-3,36.9-3.4,73.8-2,110.8c0.3,7.8,1.3,15.6,2,23.4c0.7,3.6,0.4,7.3,0.3,10.9c-0.1,3.9-4.3,7.1-10,7
			c-8.3-0.1-16.2,1.7-23.9,4.4c-2,0.7-3.9,1.5-5.8,2.3c-3.5,1.6-6.7,3.5-9.8,5.7c-3.7-3-7.7-5.5-12-7.5c-1.9-0.9-3.8-1.7-5.8-2.4
			c-7.7-2.7-15.6-4.5-23.9-4.4c-5.7,0.1-9.9-3.1-10-7c-0.1-3.6-0.4-7.3,0.3-10.9c0.7-7.8,1.7-15.6,2-23.4c1.4-37,1-73.9-2-110.8
			c-1.4-17-3.1-34.3-13.5-49c-2-2.8-3.6-5-7.4-5.8c-3.1-0.6-6.3-2.2-8.8-4.3c-10.7-9-21.3-18.2-31.8-27.4
			c-16.8-14.8-28.4-32.9-35.4-54.1c-3.6-11.1-6.7-22.3-9.3-33.7c-4.9-21.3-12-41.9-15.1-63.6c-2.9-19.6-5-39.3-6-59.1
			c0.1-6,0.1-12-0.1-17.9c0.4-24.6,3.9-48.6,12.1-72c3.9-11,11.1-18.6,21.4-22.9c18.6-7.8,26.2-5.4,34.8,14.1c4,9,5.6,18.9,6.1,28.7
			c1.3,22.4,2.2,44.9,3.3,67.3c0.9,5.5-0.9,11.6,2.9,16.6c-0.3,11,1.5,21.9,1,32.8c-0.2,3.4,0.2,6.6,0.9,9.8
			c0.1,5.3,0.6,10.6,1.1,15.9c1.7,19.2,4.3,38.3,9.7,56.9c2.6,8.8,6.5,16.8,13.8,22.7c5.6,4.5,12.1,5.3,16.5,1.8
			c4.4-3.6,7-8,7.9-13.9c2.4-15.2,1.9-30.4,1.8-45.7c1.4-2.9-0.9-5.4-0.9-8.2c-0.2-15-2.5-29.9-2.8-44.8
			c-0.4-17.8-1.5-35.6-1.3-53.4c0.1-3.7-0.1-7.3-0.6-11c-0.6-4.6-1.1-9.4,1.7-13.6c0-1,0.1-2,0.1-3c-1.8-48.8-1.5-97.5,1.1-146.2
			c0.7-12.9,2.6-25.5,7.9-37.4c5.9-13.4,16.2-20.6,30.9-21.7c5.7-0.4,11.1,0.8,16.6,1.8h0c14.7,1.1,25,8.3,30.9,21.7
			c5.3,11.9,7.2,24.5,7.9,37.4c2.6,48.7,2.9,97.4,1.1,146.2c0,1,0.1,2,0.1,3c2.8,4.2,2.3,9,1.7,13.6c-0.5,3.7-0.7,7.3-0.6,11
			c0.2,17.8-0.9,35.6-1.3,53.4c-0.3,14.9-2.6,29.8-2.8,44.8c0,2.8-2.3,5.3-0.9,8.2c-0.1,15.3-0.6,30.5,1.8,45.7
			c0.9,5.9,3.5,10.3,7.9,13.9c4.4,3.5,10.9,2.7,16.5-1.8c7.3-5.9,11.2-13.9,13.8-22.7c5.4-18.6,8-37.7,9.7-56.9
			c0.5-5.3,1-10.6,1.1-15.9c0.7-3.2,1.1-6.4,0.9-9.8c-0.5-10.9,1.3-21.8,1-32.8c3.8-5,2-11.1,2.9-16.6c1.1-22.4,2-44.9,3.3-67.3
			c0.5-9.8,2.1-19.7,6.1-28.7c8.6-19.5,16.2-21.9,34.8-14.1c10.3,4.3,17.5,11.9,21.4,22.9c8.2,23.4,11.7,47.4,12.1,72
			C1599.5,805,1599.5,811,1599.6,817z"
          />
          <path
            className="st6"
            d="M1507.8,1279.4c-3,7.6-8.7,12-16.9,12.8c-2.2,0.2-3.9,0.6-4.6,2.9c-2.6,8.1-8.9,12.2-16.4,14.9
			c-16.5,5.9-33.6,8-51.1,7c-2.6-0.1-5.2-1.2-7.7-1.9c0-0.4,0-0.8,0-1.2c-9.2-1.1-18.2-3.2-27-6.3c-7.5-2.7-13.8-6.8-16.4-14.9
			c-0.7-2.3-2.4-2.7-4.6-2.9c-8.2-0.8-13.9-5.2-16.9-12.8c-2.7-6.8-0.8-12.4,5.4-16.4c7.4-4.7,15.6-6.1,24-4.3c4.9,1,6-0.3,5.5-4.7
			c-0.2-1.9-0.4-4,0.8-5.8c0,1.3,0.1,2.7,0.1,4c0,9.5,2.3,11.7,11.9,11.8c3,0,6,0.2,8.9,0.7c9.6,1.7,18.4,4.9,26.3,9.9
			c6.8-3.6,14.2-6.1,22.1-7.5c2.9-0.5,5.9-0.7,8.9-0.7c9.6-0.1,11.9-2.3,11.9-11.8c0-1.3,0.1-2.7,0.1-4c1.2,1.8,1,3.9,0.8,5.8
			c-0.5,4.4,0.6,5.7,5.5,4.7c8.4-1.8,16.6-0.4,24,4.3C1508.6,1267,1510.5,1272.6,1507.8,1279.4z"
          />
        </g>
        <path
          className="st7"
          d="M1929.4,1088.2c0.2,3.1-0.3,6.9-1.3,10.6c-1.1,4.1-0.2,5.7,4.3,5.5c5-0.2,9.4,1.7,13.4,4.7
		c2.4,1.7,4.4,4.1,4.6,6.9c0.4,4.6,3.1,5.7,7,6.6c5.9,1.3,10.5,4.9,14.1,9.8c5.6,7.7,4.6,18.1-3.5,24.6c-13.6,11-29.6,16.1-46.2,9.7
		c-8.3-3.2-14.9-4.3-23-1.3c-6.5,2.4-12.9,0.1-18.4-3.7c-2.1-1.5-3.7-1.5-5.9-0.6c-9.8,3.9-19.8,7-30.6,5.4
		c-11.3-1.7-18.7-7.5-20.4-15.7c-1.7-8.5,3.8-18.5,13.1-23.9c5.6-3.3,11.7-4.8,18.2-4.6c3.1,0.1,3.8-0.7,3.5-4
		c-0.9-10.5,6.2-18.1,16.7-17.8c3.9,0.1,4.3-1.3,4.2-4.5c-0.2-5.7-0.2-11.3,1.4-16.8c3.8-13.1,15-21.3,27-19.7
		c12.6,1.7,21.4,12.3,21.6,26C1929.4,1086,1929.4,1086.7,1929.4,1088.2z"
        />
        <path
          className="st5"
          d="M2532.9,1219.6c0.3,4.3-0.4,9.5-1.8,14.6c-1.5,5.6-0.3,7.8,5.9,7.6c6.9-0.3,12.9,2.3,18.4,6.5
		c3.3,2.3,6,5.6,6.3,9.5c0.5,6.3,4.3,7.8,9.6,9.1c8.1,1.8,14.4,6.7,19.4,13.5c7.7,10.6,6.3,24.9-4.8,33.8
		c-18.7,15.1-40.6,22.1-63.4,13.3c-11.4-4.4-20.5-5.9-31.6-1.8c-8.9,3.3-17.7,0.1-25.3-5.1c-2.9-2.1-5.1-2.1-8.1-0.8
		c-13.5,5.4-27.2,9.6-42,7.4c-15.5-2.3-25.7-10.3-28-21.6c-2.3-11.7,5.2-25.4,18-32.8c7.7-4.5,16.1-6.6,25-6.3
		c4.3,0.1,5.2-1,4.8-5.5c-1.2-14.4,8.5-24.9,22.9-24.4c5.4,0.1,5.9-1.8,5.8-6.2c-0.3-7.8-0.3-15.5,1.9-23.1
		c5.2-18,20.6-29.3,37.1-27.1c17.3,2.3,29.4,16.9,29.7,35.7C2532.9,1216.6,2532.9,1217.6,2532.9,1219.6z"
        />
        <path
          className="st5"
          d="M3004.6,818.2c-1,19.8-3.1,39.5-6,59.1c-3.1,21.7-10.2,42.3-15.1,63.6c-2.6,11.4-5.7,22.6-9.3,33.7
		c-7,21.2-18.6,39.3-35.4,54.1c-10.5,9.2-21.1,18.4-31.8,27.4c-2.5,2.1-5.7,3.7-8.8,4.3c-3.8,0.8-5.4,3-7.4,5.8
		c-10.4,14.7-12.1,32-13.5,49c-3,36.9-3.4,73.8-2,110.8c0.3,7.8,1.3,15.6,2,23.4c0.7,3.6,0.4,7.3,0.3,10.9c-0.1,3.9-4.3,7.1-10,7
		c-8.3-0.1-16.2,1.7-23.9,4.4c-2,0.7-3.9,1.5-5.8,2.3c-3.5,1.6-6.7,3.5-9.8,5.7c-3.7-3-7.7-5.5-12-7.5c-1.9-0.9-3.8-1.7-5.8-2.4
		c-7.7-2.7-15.6-4.5-23.9-4.4c-5.7,0.1-9.9-3.1-10-7c-0.1-3.6-0.4-7.3,0.3-10.9c0.7-7.8,1.7-15.6,2-23.4c1.4-37,1-73.9-2-110.8
		c-1.4-17-3.1-34.3-13.5-49c-2-2.8-3.6-5-7.4-5.8c-3.1-0.6-6.3-2.2-8.8-4.3c-10.7-9-21.3-18.2-31.8-27.4
		c-16.8-14.8-28.4-32.9-35.4-54.1c-3.6-11.1-6.7-22.3-9.3-33.7c-4.9-21.3-12-41.9-15.1-63.6c-2.9-19.6-5-39.3-6-59.1
		c0.1-6,0.1-12-0.1-17.9c0.4-24.6,3.9-48.6,12.1-72c3.9-11,11.1-18.6,21.4-22.9c18.6-7.8,26.2-5.4,34.8,14.1c4,9,5.6,18.9,6.1,28.7
		c1.3,22.4,2.2,44.9,3.3,67.3c0.9,5.5-0.9,11.6,2.9,16.6c-0.3,11,1.5,21.9,1,32.8c-0.2,3.4,0.2,6.6,0.9,9.8
		c0.1,5.3,0.6,10.6,1.1,15.9c1.7,19.2,4.3,38.3,9.7,56.9c2.6,8.8,6.5,16.8,13.8,22.7c5.6,4.5,12.1,5.3,16.5,1.8
		c4.4-3.6,7-8,7.9-13.9c2.4-15.2,1.9-30.4,1.8-45.7c1.4-2.9-0.9-5.4-0.9-8.2c-0.2-15-2.5-29.9-2.8-44.8c-0.4-17.8-1.5-35.6-1.3-53.4
		c0.1-3.7-0.1-7.3-0.6-11c-0.6-4.6-1.1-9.4,1.7-13.6c0-1,0.1-2,0.1-3c-1.8-48.8-1.5-97.5,1.1-146.2c0.7-12.9,2.6-25.5,7.9-37.4
		c5.9-13.4,16.2-20.6,30.9-21.7c5.7-0.4,11.1,0.8,16.6,1.8h0c14.7,1.1,25,8.3,30.9,21.7c5.3,11.9,7.2,24.5,7.9,37.4
		c2.6,48.7,2.9,97.4,1.1,146.2c0,1,0.1,2,0.1,3c2.8,4.2,2.3,9,1.7,13.6c-0.5,3.7-0.7,7.3-0.6,11c0.2,17.8-0.9,35.6-1.3,53.4
		c-0.3,14.9-2.6,29.8-2.8,44.8c0,2.8-2.3,5.3-0.9,8.2c-0.1,15.3-0.6,30.5,1.8,45.7c0.9,5.9,3.5,10.3,7.9,13.9
		c4.4,3.5,10.9,2.7,16.5-1.8c7.3-5.9,11.2-13.9,13.8-22.7c5.4-18.6,8-37.7,9.7-56.9c0.5-5.3,1-10.6,1.1-15.9
		c0.7-3.2,1.1-6.4,0.9-9.8c-0.5-10.9,1.3-21.8,1-32.8c3.8-5,2-11.1,2.9-16.6c1.1-22.4,2-44.9,3.3-67.3c0.5-9.8,2.1-19.7,6.1-28.7
		c8.6-19.5,16.2-21.9,34.8-14.1c10.3,4.3,17.5,11.9,21.4,22.9c8.2,23.4,11.7,47.4,12.1,72C3004.5,806.2,3004.5,812.2,3004.6,818.2z"
        />
        <path
          className="st7"
          d="M3334.5,1089.4c0.2,3.1-0.3,6.9-1.3,10.6c-1.1,4.1-0.2,5.7,4.3,5.5c5-0.2,9.4,1.7,13.4,4.7
		c2.4,1.7,4.4,4.1,4.6,6.9c0.4,4.6,3.1,5.7,7,6.6c5.9,1.3,10.5,4.9,14.1,9.8c5.6,7.7,4.6,18.1-3.5,24.6c-13.6,11-29.6,16.1-46.2,9.7
		c-8.3-3.2-14.9-4.3-23-1.3c-6.5,2.4-12.9,0.1-18.4-3.7c-2.1-1.5-3.7-1.5-5.9-0.6c-9.8,3.9-19.8,7-30.6,5.4
		c-11.3-1.7-18.7-7.5-20.4-15.7c-1.7-8.5,3.8-18.5,13.1-23.9c5.6-3.3,11.7-4.8,18.2-4.6c3.1,0.1,3.8-0.7,3.5-4
		c-0.9-10.5,6.2-18.1,16.7-17.8c3.9,0.1,4.3-1.3,4.2-4.5c-0.2-5.7-0.2-11.3,1.4-16.8c3.8-13.1,15-21.3,27-19.7
		c12.6,1.7,21.4,12.3,21.6,26C3334.5,1087.2,3334.5,1087.9,3334.5,1089.4z"
        />
        <path
          className="st5"
          d="M3938,1220.8c0.3,4.3-0.4,9.5-1.8,14.6c-1.5,5.6-0.3,7.8,5.9,7.6c6.9-0.3,12.9,2.3,18.4,6.5
		c3.3,2.3,6,5.6,6.3,9.5c0.5,6.3,4.3,7.8,9.6,9.1c8.1,1.8,14.4,6.7,19.4,13.5c7.7,10.6,6.3,24.9-4.8,33.8
		c-18.7,15.1-40.6,22.1-63.4,13.3c-11.4-4.4-20.5-5.9-31.6-1.8c-8.9,3.3-17.7,0.1-25.3-5.1c-2.9-2.1-5.1-2.1-8.1-0.8
		c-13.5,5.4-27.2,9.6-42,7.4c-15.5-2.3-25.7-10.3-28-21.6c-2.3-11.7,5.2-25.4,18-32.8c7.7-4.5,16.1-6.6,25-6.3
		c4.3,0.1,5.2-1,4.8-5.5c-1.2-14.4,8.5-24.9,22.9-24.4c5.4,0.1,5.9-1.8,5.8-6.2c-0.3-7.8-0.3-15.5,1.9-23.1
		c5.2-18,20.6-29.3,37.1-27.1c17.3,2.3,29.4,16.9,29.7,35.7C3938,1217.8,3938,1218.7,3938,1220.8z"
        />
      </g>
      <g id="explorer">
        <g>
          <path
            className="st6"
            d="M598.5,1361.2c-4.7,0-9.3,0.1-14,0.1c-114.9,0-229.8,0-344.7,0c-3.3,0-6.7-0.1-9.9-1.1
			c-5.6-1.7-9.3-6.2-9.4-12.1c-0.1-4,0.3-8-0.1-12c-1.1-10.7,7.7-15.9,15.7-15.9c17,0.1,34,0,51,0c11.5,0,23,0,34.5,0
			c2.6,5.9,6.5,9.3,13.1,11.4c4.3,1.3,8.8,3.2,13.1,3.9c8.1,1.3,15.9,4.9,24.3,4.4c3-0.2,5.1,2.3,7.7,3.3c6.2,2.4,12.9,2.8,19.2,4.3
			c5,1.2,10.2,3.8,15.8,2.5c1.3-0.3,2.6,0.4,3.9,0.8c6.9,2.5,14.2,3.2,21.4,3.9c3.3,0.3,5.7-1.7,7.2-4.5c1-1.8,1.5-3.7,1.9-5.7
			c3.8-6.8,6.9-13.9,7.4-21.8c0.1-1.7,1.1-3.1,2.6-4c2.2-0.9,4.6-0.8,6.9-0.8c35.2-0.1,70.4,0,105.6,0c2.6,0,5.3-0.2,7.8,1.2
			c2.2,1.5,2.1,3.9,2.2,6.2c0.2,5.1-0.2,10.2,0.6,15.3c-1,2.9-1.1,5.8-0.4,8.8c1.3,5.8,2.7,6.7,8.8,6.5
			C594.4,1356,597.8,1356.8,598.5,1361.2z"
          />
          <path
            className="st8"
            d="M561.5,1069.6c25.1-1.6,50.1-3.3,75.2-4.9c0.4,32.5,0.3,65,1.2,97.4c0.6,22.1,1.4,44.2,1.9,66.4
			c0.1,3.3,0.6,6.6,0.9,9.9c-1.2,2.1-3.3,2.1-5.2,2.2c-14.3,0.3-28.5,1.7-42.8,1.1c-2.3-0.1-4.7,0.3-6.8-1.1
			c-3.3-3.5-2.9-8.1-3.1-12.3c-1.2-20.3-4.3-40.3-7.6-60.3c-4.2-25.9-8.7-51.8-13.3-77.7c-0.8-4.7-1.3-9.5-2.1-14.3
			C559.3,1073.7,559.3,1071.3,561.5,1069.6z"
          />
          <path
            className="st8"
            d="M388.1,1060.9c22.6,1.2,45,3.9,67.4,7.1c2.8,0.4,5.7-0.1,8.3,1.4c2.1,2.6,0.8,5.1-0.2,7.5
			c-5.3,13.1-10.6,26.2-16,39.3c-9.1,22.4-17.5,44.9-25.9,67.5c-4.7,12.6-9.2,25.3-13.7,38c-0.7,2.1-1.6,4-3.4,5.4
			c-5.3,1.3-10.2-1.1-15.2-2c-9.5-1.7-19.1-2.9-28.4-5.5c-2.3-0.6-5.1-0.7-5.5-4.1c6.3-32.1,15-63.7,22.6-95.5
			c4.3-18.1,7.9-36.3,11.2-54.6C389.7,1063.6,389.7,1062.1,388.1,1060.9z"
          />
          <path
            className="st9"
            d="M458.6,1320.4c0,9.3-1.8,17.9-8,25.2c-1.8,1.8-3.8,1.5-6,1.2c-27.6-4.7-55.1-10.1-82-18.1
			c-9.5-2.8-19.2-5.3-29.1-6.3c-7.5-0.7-9.3-3.2-9.2-11.7c1.3-13.1,7.3-25.1,9.8-37.9c2.9-14.8,5.9-29.5,8.9-44.3
			c1.4-2.2,3.5-1.9,5.8-1.7c9.4,0.7,18.3,3.3,27.3,5.8s18,5,27.3,5.7c1.4,0.1,2.6,0.5,3.6,1.5c2,3.6,0.9,6.8-1.4,9.8
			c-1.8,1.6-3.9,1.4-6.1,1c-4.2-0.8-8.5-1.1-12.7-2.2c-1.4-0.4-2.8-1.2-4.4,0.5c1.8,2,4.4,1.9,6.7,2.2c3.6,0.5,7.1,1.7,10.7,2.2
			c2.3,0.3,4,1.5,4.4,4c0.2,1.1,0,2-0.6,2.9c-1.9,2.2-4.3,2-6.8,1.6c-4.7-0.8-9.3-1.9-14.1-1.9c4.3,3,9.4,3.1,14.3,4.2
			c2.3,0.5,4.5,1,5.7,3.3c0.3,1,0.2,2-0.3,3c-1.6,2.1-4,1.9-6.3,2c-4.7,0.2-9.5-0.3-14.3,1c5,0.8,10,0.6,15,0.7
			c2.3,0,4.7-0.6,6.4,1.6c0.4,0.7,0.5,1.4,0.4,2.2c-1.2,3.4-4.4,3.6-7.2,4.4c-4.2,1.2-8.9,0.6-12.4,4c4.9-0.8,9.6-2.3,14.4-3.7
			c2.5-0.8,4.9-2.3,7.7-1c0.9,0.6,1.4,1.4,1.7,2.3c0.4,2.8-1.5,4.2-3.5,5.5c-2.9,1.9-6,3.5-8.3,6.3c3.1-0.1,5.3-2.2,7.7-3.8
			c2.6-1.7,4.8-4.3,8.3-3.8c1,0.3,1.8,0.8,2.4,1.6c1.4,3-0.3,5.4-1.4,7.9c-1.1,2.4-2.6,4.8-2.2,8.5c4-4.1,3.1-10.8,9.3-12.1
			c2.2,0,4,0.8,5.4,2.5c2.1,3.9-0.5,7.4-0.9,11c-0.2,1.8-2,4.4,0.5,5c2,0.5,1.9-2.7,2.4-4.3c1-3.1,0.8-6.7,4.4-8.4
			c9.6,0.5,17.9,4.4,24.9,10.8C459.1,1313.4,460.8,1316.7,458.6,1320.4z"
          />
          <path
            className="st9"
            d="M580.6,1342.3c-2.1-7.2-0.8-14.6-1.1-22c-1.2-1.2-1.8-2.7-1.6-4.5c1-14.2,0.2-28.5,0.5-42.8
			c0.1-3.7-0.9-7.2-0.9-10.9c0-2.8-0.3-5.7,1.3-8.2c2.3-2.3,5.2-2.7,8.2-2.8c11.6-0.5,23.1-0.4,34.7,0.7c5.8,0.6,11.5-1,17.3-1.1
			c2,0,4-0.3,5.4,1.6c0,1-0.1,2.1-0.1,3.1c-0.5,7.6-0.5,7.6-8.3,8c-3.3,0.2-6.5,0.9-9.9,0.3c-1.3-0.3-2.7,0-3.4,1.5
			c0.4,1.7,1.7,1.2,2.8,1.1c5-0.4,10-0.2,14.9-0.3c1.5,0,3-0.2,4,1.4c0.1,1.6,0.2,3.2,0.3,4.8c-1.7,2.9-4.7,2.7-7.4,3
			c-4,0.4-8.1-0.1-12.1,0.8c4.4,1.9,8.9,0.9,13.4,1.1c2.5,0.1,5.4-0.8,7.1,2.1c0.1,0.7,0.2,1.4,0.3,2.1c-0.3,3-2.8,3.3-5,3.9
			c-4.8,1.3-9.7,2-14.1,4.6c5.2,0,10-1.5,14.9-2.8c2.2-0.5,4.4-1.9,6.4,0.4c0.2,0.6,0.5,1.2,0.7,1.8c0.4,3-1.9,4-3.9,5
			c-3,1.4-6,2.7-9.1,4c-1.6,0.7-3.6,1-3.9,3.8c4.5-1.4,7.9-4.2,11.8-6.2c2.7-1.4,5.1-3.9,8.6-2.6c0.8,0.7,1.5,1.4,2.3,2
			c0.9,2.8-1.2,4.2-2.7,5.9c-2.1,2.4-4.9,4.2-6.8,8.2c3.7-1.4,5.1-3.8,6.9-5.7c1.9-2.1,3.1-5.2,6.5-5.5c1.3,0.5,2.5,0.9,3.8,1.4
			c2.2,1.9,1.1,4.2,0.8,6.4c-0.5,2.8-1.1,5.6-0.4,8.5c2.4-2.3,1.4-5.5,2.4-8.1c0.7-1.7,0.5-4.1,3.2-4.1c1.8,0.4,3.5,0.9,5.3,1.3
			c3,0.4,2.8,2.7,2.9,4.9c0.2,3.3-0.5,6.8,1,10.9c2.3-2.9,1.5-5.4,1.7-7.7c0.3-2.2-0.4-4.8,2.3-6.1c4.9,0.6,9.9,1.2,14.8,1.8
			c8.3,1,14.5,4.7,17,13.1c0.8,0.8,1.6,1.6,2.1,2.7c2.6,5.9,0,18.6-4.8,22.6c-2.5,1-5.1,1.7-7.7,1.7c-23.2,0.4-46.5,1.9-69.7,0.9
			c-8.3-0.4-16.6-0.5-24.8-1.6c-4.8-0.6-9.6,0.8-14.3,1.3c-3.2,0.3-6.3,0.6-9.3-0.5C582.4,1346.7,580.6,1345.3,580.6,1342.3z"
          />
          <path
            className="st6"
            d="M710.7,1344.7c4.1-7.7,4.1-15.9,2.9-24.2c23.6,0,47.3,0,70.9,0c12,0,17.1,5,17.1,16.9c0,2.5,0,5,0,7.5
			c0,11.1-5.2,16.4-16.5,16.5c-12.3,0.1-24.6,0-37,0c-24.8,0-49.6-0.1-74.4-0.1c0.3-4.3,3.6-4.3,6.5-4.3c6.5,0.2,12.9,0.4,19.3-0.5
			c6.2-0.9,8.1-2.1,9.7-8.1C709.6,1347.1,709.7,1345.7,710.7,1344.7z"
          />
          <path
            className="st10"
            d="M324.2,1310.7c0.1,5.5,2.8,9,8.3,9.4c17.3,1.2,33.3,7.7,49.9,11.7c22.6,5.4,45.4,10,68.3,13.9
			c-0.3,9-5,12.2-13.9,10.9c-4.5-0.6-9.3-0.3-13.4-2.3c-5.7-2.7-12.7,0.6-17.8-4.2c-0.8-0.7-1.9-0.1-2.9,0.1c-1,0.3-2.3,1-2.8,0.6
			c-4.4-3.3-9.8-2.7-14.8-3.8c-6.2-1.3-11.7-4.5-18.4-5.2c-7.2-0.7-14.5-3.8-21.8-4.4c-6.6-0.5-10.8-6.7-17.7-5.4
			c-1.7,0.3-2.8-1.5-3.6-3c-1.6-2.7-1.9-5.6-1.9-8.6C322.4,1317.2,323.3,1314,324.2,1310.7z"
          />
          <path
            className="st10"
            d="M710.7,1344.7c2.1,7.6-2.3,13.2-10,13.8c-7.3,0.6-14.4,1.6-21.7,0.2c-1.9-0.4-3.6,1.4-5.3,2.5
			c-0.7,0-1.4,0-2.1,0c-2.7-0.9-5.3-1.9-8,0c-1.3,0-2.7,0-4,0c-2.6-1.2-5.3-1.2-7.9,0c-1.7,0-3.4,0-5.1,0c-6-0.1-12-2.2-18,0h-0.5
			h-0.5c-9-1.3-18-0.5-27,0c-0.7,0-1.4,0-2.1,0c-3.3-1.7-6.1-4.9-10.7-2.2c-1.9,1.1-4.5-0.9-5.7-3c-2.4-4.3-3.8-8.8-1.5-13.7
			c2.4,3.3,6,4.5,9.7,3.8c15.1-2.6,30.1-0.4,45.2,0.1C660.6,1347,685.6,1345.8,710.7,1344.7z"
          />
          <path
            className="st10"
            d="M644.4,1252.5c-17.7,3.1-35.5,0.1-53.3,0.8c-3.8,0.2-7.6,0.7-11.4,1.3c-2.9,2.5-5.8,2.2-8.4-0.3
			c-2.9-2.8-3.5-6.3-1.8-10c2.4-5.4,10.4-7.7,16.1-4.8c18.3,0.8,36.6-1.2,54.9-1c1.6,0.3,3.2,0.7,4.9,0.8c3.6,0.4,5.9,2.1,6.1,5.8
			c0.2,3.5-1.5,5.6-4.9,6.4C645.9,1251.7,645.2,1252.1,644.4,1252.5z"
          />
          <path
            className="st10"
            d="M406.4,1240.6c-13.4,0.1-25.8-4.3-38.5-7.7c-8.2-2.2-16.5-4-25-4.4c-4.5-0.4-7.6-2.2-7.3-7.3
			c0.3-4.3,3.5-6.9,8.7-6.7c3.8,0.1,7.5,0.7,11.3,1c11.8,4.2,24.3,5.1,36.4,7.8c4.2,1,8.4,2,12.6,2.9c6-2.1,12.4,1.6,12.7,7.5
			c0.2,3.1-1,5.5-3.6,7.2C411.2,1242.5,408.7,1242.6,406.4,1240.6z"
          />
          <path
            className="st11"
            d="M644.3,1267.6c-7,1.6-14.2,0.2-21.3,1.6c-2.2,0.4-3.9-0.9-4.3-3.2c-0.4-2.6,1.4-3.7,3.6-3.9
			c4.9-0.4,9.9-0.9,14.8-0.6c4.6,0.2,7.2-1,7.1-5.9c0.5,2.4,0.1,4.8,1.6,7.1C647.3,1264.6,646.3,1266.4,644.3,1267.6z"
          />
          <path
            className="st11"
            d="M645.6,1279.4c-6.5-0.3-12.9,1-19.4,0c-2.3-0.4-5.9,0.2-5.9-3.1c0-3.3,3.5-2.8,5.9-3
			c6.1-0.4,12.3-0.7,18.4-1C649.6,1275.2,649.7,1275.9,645.6,1279.4z"
          />
          <path
            className="st11"
            d="M648.2,1287.6c-6.4,1.7-12.8,3.4-19.2,5.1c-2.4,0.6-5.3,2-6.3-1.5c-1-3.6,2.4-3.7,4.6-4.6
			c6-2.5,12.7-2.2,18.6-5.1C648.7,1282.8,649,1285,648.2,1287.6z"
          />
          <path
            className="st11"
            d="M652.5,1293.4c-6.3,3.7-12.5,7.4-18.7,11.1c-1.7,1-3.5,1.4-4.8-0.5c-1.1-1.7-0.3-3.1,1-4.4
			c1.9-2,4.4-2.9,6.8-4c4.1-2,8.9-2.7,12.2-6.2C651.7,1289.3,652.7,1290.9,652.5,1293.4z"
          />
          <path
            className="st10"
            d="M681.7,1305.5c0,4,0,8,0,12c0,2.5-0.4,4.9-3.7,4.7c-3.1-0.2-3.6-2.5-3.5-5c0.1-3.3,0.1-6.7,0.3-10
			c0.1-1.4,0.2-2.6-0.9-3.6C677.1,1301.9,680.3,1299.9,681.7,1305.5z"
          />
          <path
            className="st11"
            d="M668.6,1302.3c-1.4,3.8-1.9,7.7-2,11.6c-0.1,2.2-0.5,4.6-3.5,4.4c-3.2-0.2-3.6-2.7-3.2-5.1
			c0.7-4.6,1.7-9.1,2.6-13.6C666.1,1296.5,666.4,1296.6,668.6,1302.3z"
          />
          <path
            className="st12"
            d="M658.7,1298.3c-2.6,4.9-6.5,8.7-10.3,12.5c-1.4,1.4-3.1,2.2-4.8,0.7c-2-1.8-0.9-3.6,0.5-5.1
			c3.5-3.7,7.1-7.3,10.7-11C656.9,1295.3,658.8,1295.4,658.7,1298.3z"
          />
          <path
            className="st6"
            d="M600.6,1361.2c3.5-3,7.7-1,11.4-1.1c5.1-0.2,10.7-2.4,15.5,1.1C618.6,1361.2,609.6,1361.2,600.6,1361.2z"
          />
          <path
            className="st6"
            d="M628.5,1361.2c2.8-2.4,5.4-4.4,9.3-1.4c2.4,1.8,6.2-1.7,8.7,1.4C640.5,1361.2,634.5,1361.2,628.5,1361.2z"
          />
          <path
            className="st6"
            d="M663.6,1361.2c2.7-4.2,5.3-2.2,8,0C668.9,1361.2,666.3,1361.2,663.6,1361.2z"
          />
          <path
            className="st6"
            d="M651.6,1361.2c2.6-3,5.3-2.8,7.9,0C656.9,1361.2,654.3,1361.2,651.6,1361.2z"
          />
          <path
            className="st11"
            d="M404.5,1249.3c2.5,3.2,0.3,5.5-1.6,7.8c-3.9-3.3-9.1-1.9-13.5-3.6c-2-0.7-4.2-0.8-6.4-1
			c-2.7-0.2-4.6-1.2-4.3-4.2c0.4-3.3,3-3.3,5.3-2.9C390.8,1246.6,397.6,1248,404.5,1249.3z"
          />
          <path
            className="st11"
            d="M402.4,1260.3c4.1,4.2,4,4.8-0.8,7.1c-6.8-1.5-13.8-2-20.3-4.7c-1.7-0.7-4.3-1.2-3.6-3.9
			c0.7-2.6,3.1-1.9,5-1.6C389.4,1258,395.7,1260.1,402.4,1260.3z"
          />
          <path
            className="st10"
            d="M401.5,1270.4c3.4,1.5,2.1,3.7,0.8,5.9c-6.9,0.1-13.9,0.2-20.8,0.5c-2.3,0.1-4.6,0.2-4.8-2.8
			c-0.2-3,2.4-3.3,4.5-3.4C388,1270.4,394.8,1270.4,401.5,1270.4z"
          />
          <path
            className="st11"
            d="M402.7,1278.3c2.9,0.2,3,2.1,2.6,4.3c-6.9,2.2-13.9,4.4-20.8,6.8c-2,0.7-3.7,0.6-4.6-1.4
			c-1-2.1,0.6-3.4,2.2-4.4c3.7-2.2,7.9-2.2,12-3C397,1280,400,1279.6,402.7,1278.3z"
          />
          <path
            className="st11"
            d="M424.5,1297.8c4.1-2.4,6.3-1,7.1,3.4c-1.8,3.6-2,7.7-3.1,11.5c-0.7,2.5-1.9,4.4-4.9,3.7
			c-3-0.8-3-3.1-2.3-5.6C422.4,1306.5,423.4,1302.2,424.5,1297.8z"
          />
          <path
            className="st11"
            d="M413.3,1290.7c5.4-3.3,5,1.6,6,4.3c-3,3-3.7,7-5,10.7c-0.7,2.1-1.6,4.4-4.5,3.6c-3.1-0.9-2.7-3.5-1.9-5.8
			C409.7,1299.3,411.6,1295,413.3,1290.7z"
          />
          <path
            className="st12"
            d="M406.8,1284.7c2.5,0.5,4.3,1.9,4.6,4.6c-4.4,2.9-8.8,5.9-13.2,8.8c-1.9,1.2-4.1,2.4-5.8,0.2
			c-1.9-2.6,0.7-3.8,2.2-5.1C398.4,1289.9,403.5,1288.5,406.8,1284.7z"
          />
        </g>
        <path
          className="st5"
          d="M392.4,921.2C440.7,939,490.5,947.5,542,945c16.4-0.8,32.9-1.5,49.2-4c19.9-3.1,37.8-11,54.2-22.6
		c6.5,43.2,12,86.7,20.1,129.7c0.6,3.2-0.4,5.4-2.9,7.3c-8.2,6.8-20,8.7-30,11.7c-7.3,2.2-15.8,0.8-23.4,1.3c-9,0.7-18,1.6-27.1,2.2
		c-6.6,0.4-13.2,1.4-19.9,0.6c-5,0-9.9,0.7-14.9-0.7c-4.8-1.3-7.3-4.3-8-9.1c-2-14.9-6.3-29.3-10.4-43.7c-0.9-3.2-2-6.4-1.5-9.4
		c0,9.7-3,19.1-5.4,28.6c-1.5,5.8-3.3,11.5-5.6,17.1c-4.7,11.5-13.3,17.6-25.6,18.3c-9.3,0.6-18.6-0.2-27.9-1.6
		c-20.8-3-42-3.9-62.7-7.1c-8.3-1.3-15.9-2.1-24.2-2.5c-3.8-0.2-5.7-0.8-4.8-5.4c8-40.9,12.6-84.4,17.1-126.3
		C388.9,926.5,388.4,922.8,392.4,921.2z"
        />
        <path
          className="st13"
          d="M637.6,992.4c1.7,6.5,2,13,0.8,19.7c-2.1,12.5-11,20.6-23.9,21.3c-8.4,0.4-16.9,1.1-25.2-0.8
		c-16.1-3.8-24.2-16-21.5-32.3c0.5-3.3,1.2-6.5,1.8-9.8c1.1-1.2,2.5-1.1,3.6-0.3c5.9,4,12.6,5.3,19.6,5.4c1,0,1.9,0.5,2.8,1
		c2.7,1.5,1,5.9,4.6,6.8c2.2,2.7,4.2,1.1,6.2-0.4c2.5-2.3,1.6-5.5,1.9-8.3c0.3-2.5-0.5-5.3,1.8-7.3c2.8-0.3,3.2,1.9,3.4,3.8
		c0.4,3.6,2.2,4,5.5,3.6c4.9-0.6,9.8-1.2,14.3-3.4C635,990.5,636.6,990.5,637.6,992.4z"
        />
        <path
          className="st14"
          d="M595.4,997.4c-8.6,0.6-16.9-0.1-23.8-6.2c-0.5-0.4-1.2-0.4-1.8-0.6c-3.9-7.2-1.3-14.2,0.9-21.2
		c1.1-3.5,3.3-5.2,7-4.9c4.6,0.4,9.3,1,13.9,0.9c12.1-0.3,24.2-1,36.3-1.1c5.1,0,7.5,2.1,9.4,6.3c3.2,7.3,2.9,14.5,0.4,21.8
		c-1.2-0.5-2.1-0.1-3.2,0.6c-5.9,4-12.8,3.5-19.4,4.4c-2.6,0.3-3.4-1.3-3.4-3.7c0-2.1,0.4-4.3-0.8-6.3c-1,0-1.9-0.3-2.9-0.5
		c-6.8-1.5-8.1-0.7-10,6.1C597.5,994.7,597.5,996.7,595.4,997.4z"
        />
        <path
          className="st15"
          d="M595.4,997.4c0.3-2.4,0.8-4.8,1-7.3c0.2-3.1,1.3-5.5,4.7-5.8c3.7-0.3,7.7-1.5,9.8,3.1c0,3.8-0.2,7.6-0.1,11.4
		c0,3-1,5.1-4.1,5.9c-2.7,0-2.4-2.5-3.6-4.4c-1.5,1.2-1.4,3.7-3.4,4.3C595.5,1003.8,595.6,1000.5,595.4,997.4z"
        />
        <path
          className="st16"
          d="M599.6,1004.6c0.3-1.9,0.6-3.9,1-5.8c0.3-1.5,0.8-3.1,2.6-3c2.2,0.1,2.3,2,2.4,3.7c0.1,1.8-0.2,3.7,1,5.2
		C603.4,1009.5,602.2,1009.5,599.6,1004.6z"
        />
        <path
          className="st2"
          d="M662.7,831.4c1.6,16.8,3.3,33.6,6.2,50.2c1.2,7.2-0.5,13-5.3,18.7c-5.6,6.7-11.3,13-18.2,18.2
		c-1.2,2.9-3.4,4.8-5.9,6.5c-18,12-38.1,18-59.5,19.6c-17.2,1.3-34.4,3-51.7,3.2c-21.9,0.3-43.5-1.5-64.9-5.4
		c-20-3.6-39.8-7.6-59.1-14.1c-4.1-1.4-8.2-2.7-11.8-5.3c-2-0.7-4.1-1.3-6-2.3c-13-6.8-21.1-15.6-14.7-35.5
		c2.4-11,5.8-21.7,8.1-32.6c2.4-14.2,4.6-28.4,6.6-42.7c0.6-3.9,0.8-7.9,1.6-11.8c0.9-4.4-0.1-8.3-3.8-11.1
		c-11.7-8.9-23.4-18-34.3-28c-16.1-14.6-30.8-30.3-41.4-49.4c-1.6-2.9-1.7-6-2.4-9.1c-19.6-41.7-21.4-84-3.7-126.8
		c8.7-21,22.7-38.3,38.9-53.9c1.1-1,2.1-2.2,3.1-3.2c-0.1-3.1,2.1-4.8,4.1-6.5c19.1-16.1,39.8-29.8,61.9-41.5c2.3-1.2,4.7-2.5,7.6-2
		c3.6,1.9,6.7,4.2,7.1,8.7c0.7,4,0.8,7.9,0.7,12c-0.7,18.8,0.2,37.7,1.3,56.4c1.4,24.2-0.3,47.9-6.3,71.3c-1.2,4.8-2.6,9.7-4.3,14.4
		c-0.7,1.9-1.2,3.9-3,5c-0.8,0.5-1.6,0.5-2.4,0c-2.1-2.2-1.7-4.8-1.2-7.5c2.4-13.9,5.1-27.7,7.8-41.5c0.9-4.5,0.5-4.8-3.8-3.1
		c-15.9,6.4-28.2,17.1-37.2,31.8c-5.6,9.2-8.9,19.3-11.5,29.6c-2.5,13.4-1.6,26.6,1.7,39.8c0,4.8,2.5,8.9,4,13.2
		c2.9,8.5,6.2,16.8,10.4,24.8c0,7.9,4.4,14.1,8.1,20.5c1.8,3.1,4,3.1,6.6,1.3c6.4-4.3,11.8-9.9,16.5-15.8
		c10.4-13.1,18.5-27.6,26.4-42.3c13.4-25.1,23.3-51.5,29.9-79.1c4.5-18.8,6.8-37.9,7.8-57.2c1.2-22.8-0.6-45.1-6.2-67.2
		c-1.1-4.3-2.9-8.5-3.4-13c-0.2-3.6,1.8-5.1,5.2-5.2c1.8-0.1,3.7,0.1,5.5-0.4c6.6-1.6,11.2-5.1,13-12.2c1.2-4.9,0.6-10.3,3.3-14.9
		c1.9-1.1,4-1.8,6.2-2.4c4.5-0.2,6.6,2.3,7.5,6.4c1.2,5.2,2.1,10.4,4,15.4c2.8,7.4,9.7,11.7,17.2,10.8c8-0.9,13.4-6.5,14.5-14.8
		c0.7-5.3,1.4-10.6,0.7-15.9c-0.2-1.6-0.1-3,1.3-4.1c5.4,0.1,10.8,0.9,16.1,2c3.4,0.4,5,3,6.7,5.5c6.9,10.8,12.7,22.2,19.3,33.1
		c10.9,17.8,21.7,35.8,29.5,55.3c6.2,15.5,10.8,31.4,15.6,47.3c2.2,7.3,4.7,14.5,6.6,21.9c3.2,14.9,6.8,29.8,8.5,45
		c1.9,17.5,5.3,34.8,7.4,52.3c1.2,10.4,2.3,20.8,2.7,31.2c0.2,4.8,1.3,9.4,1.2,14.8c4.9-3.2,7.6-7.2,10.3-11
		c19.5-27.6,25.5-57.5,15.4-90.4c-6.7-21.9-18-41-34.2-57.2c-1.9-1.9-3.2-4.2-5.1-6.1c-1.8-4.1-0.9-8.5-1-12.8
		c-0.4-21.9-3.2-43.3-12.1-63.6c-6.2-14.2-13.5-27.8-21.9-40.7c-1.4-2.2-3.3-4.3-2.7-7.2c0.3-0.8,0.8-1.4,1.8-1.2
		c45.7,23.2,81.9,56.5,104.5,102.9c15.2,31.2,24.9,64.4,23.3,99.7c-2.5,55.2-23.3,102-65.5,138.5c-2,1.7-4.1,3.8-6.5,4.5
		c-4.9,1.6-5.4,4.7-4.9,9.1C661.5,813.1,662,822.2,662.7,831.4z"
        />
        <path
          className="st17"
          d="M494.7,436.5c-0.2,4.8-0.3,9.6-1.5,14.3c-2.6,10-10.4,15.7-20.6,14.4c-3.1-0.4-3.9,0.7-4,3.2
		c-2.6,9.3-10.2,12.9-18.3,14.7c-6.4,1.5-13.2,0.6-19.5-2.3c-3.1-1.4-5.9-3-7-6.5c-2-2.3-4-4.6-6.1-6.9c-2.5-0.6-3.8-2.5-4.7-4.8
		c-1-2.5-1.6-5.1-1.9-7.7c-0.5-4.2-2.7-5.4-6.7-5.5c-11.2-0.4-20-7.9-22.3-18.6c-0.3-1.2-0.7-2.5-0.2-3.4c2.1-4.6,0.9-9.5,1.9-14.2
		c2.3-7.4,0.3-12.6-5.4-18.8c-8.3-9-7.9-21.1-4.7-32.7c0.8-2.9,1.9-5.7,2.9-8.5c-0.5-2.9,1.8-4.2,3.6-5.4
		c30.7-20.3,61.4-40.5,92.1-60.7c20.6-13.5,41.2-26.9,61.7-40.6c5.1-3.4,10.3-6.8,15.5-10c1.9-1.2,3.8-2.9,6.4-1.7
		c11.5,4.1,16.3,13.4,17.1,24.6c0.8,11.7-2,22.5-13.3,29.1c-1.9,4.1-5.9,4-9.4,4.3c-2.5,0.2-4.5,1-6.1,2.8c-6,6.2-13.4,7.2-21.2,4.9
		c-3.8-1.1-6.5-0.8-9.2,2.4c-11,12.6-24.6,15.6-40.2,10.5c-3.7-1.2-4.4-0.1-5,3.4c-1.1,6.3-0.4,12.6-1.5,18.9
		c-0.7,4.1-2.2,6.2-6.4,6.8c-4.8,0.6-10.1,1.3-11.4,7.1c-1.2,5.6,2.2,9.5,6.3,12.7c5.5,4.3,11.2,7.6,18.3,9.3
		c13,3.2,21.8,11.6,24.6,25.3c1.3,6.2-0.3,12-3.1,17.3c-2.1,4-2.8,7.6-0.6,11.8c1.2,2.4,1.4,5.1,1.5,7.8
		C496,434.8,495.4,435.7,494.7,436.5z"
        />
        <path
          className="st6"
          d="M373.6,333.6c-8.5-11.1-15.6-23.2-23.3-34.8c-2.2-3.3-4-7-6.6-10c-10.3-12.4-8.8-25.6-2.4-38.8
		c6.7-13.8,17-25,28.9-34.7c20.7-16.8,42.8-31.1,68.3-39.4c21-6.8,40.4-3.1,58.2,9.6c1.2,0.8,2.2,2,3,3.2
		c8.9,13.6,17.8,27.3,26.7,41c0.5,0.8,0.8,1.8,1.2,2.7c0.8,3.2-1.6,4.5-3.6,5.8c-21.6,14-43.1,28.3-64.7,42.5
		c-21,13.8-42.1,27.4-63,41.4c-5.4,3.6-10.9,7-16.4,10.5C378.1,333.9,376.1,335.4,373.6,333.6z"
        />
        <path
          className="st8"
          d="M494.6,433.4c-1.4-4.1-2.7-8.2-4.2-12.2c-0.8-1.9,0.3-3.1,1.1-4.6c11.5-20.2-0.3-42.2-23.7-44.2
		c-6.4-0.6-20-12.9-21.3-19.2c-1.2-6.4,2.6-12,10.9-12.5c7.1-0.4,7.7-3.6,7.8-9c0.1-6.5,1-12.9,1.4-19.4c0.2-2.8,0.7-3.8,3.8-2.5
		c18.4,7.5,31.2,4.3,43.8-11c1.7-2.1,3.1-2.4,5.5-1.5c9.3,3.8,17.9,3.3,24.8-5.1c1-1.3,2.3-1.7,4-1.5c3.9,0.5,7.6-0.7,11.2-2.1
		c5.9,8.7,11.1,17.7,14.5,27.6c9.8,28.3,9.1,55.6-6.2,82.1c-5.9,10.3-13.2,19.1-22.6,26.2c-3.7,2.8-1.6,4.6,0.1,6.8
		c2,7.4,1.2,14.9,0.1,22.3c-1.1,7.6-7.6,13.5-15.7,14.5c-8.4,1-15.9-2.7-19.1-9.7c-2.6-5.8-4.2-12-5-18.2c-0.4-3.4-1.6-5.3-5.2-5.1
		C498.5,434.5,496.1,435.6,494.6,433.4z"
        />
        <path
          className="st9"
          d="M306.3,700.4c10.8,22.1,26.7,40.2,44.6,56.7c2.9,6.1,0.1,12.1-0.7,18c-2,14.9-1.6,29.4,3.4,43.7
		c3.9,11.1,11.2,19.4,20.5,26.3c2.8,2.1,6.8,3.2,7.7,7.4c-1.3,11.3-4.8,22.1-8.2,32.8c-1.9,1.4-3.8,0.8-5.7,0
		c-8.9-3.9-17.1-8.9-25-14.4c-1.6-1.1-2-3-3.2-4.4c-2-1.8-4-3.5-6-5.3c-3.8-0.1-6-2.8-7.8-5.5c-11.6-17.4-19.1-36.4-22.3-57.1
		c-3.1-20-3.7-40.1-2.8-60.2c0.4-8.4,0.2-17,1.8-25.3c0.3-1.8,0.3-3.7,2.2-4.8C305.1,705.7,305.7,703.1,306.3,700.4z"
        />
        <path
          className="st18"
          d="M373.6,333.6c7-2.9,12.8-7.6,19.1-11.7c41.5-27.1,82.8-54.4,124.1-81.6c3.7-2.4,8.1-4,10.9-7.8
		c11.1-5.4,20.7-13.2,31.1-19.7c10.1-6.4,20-13.1,30.1-19.5c4.8-3.1,9.9-2.2,12.3,1.7c2.8,4.7,1.8,8.9-3.3,12.3
		c-14,9.3-28,18.5-42,27.7c-58.2,38.1-116.3,76.2-174.4,114.4c-1.8,1.2-3.4,2.6-5.1,3.9c-9.8,5-18.4,11.9-27.7,17.6
		c-8.5,5.2-16.7,11-25.1,16.4c-4,2.5-8.8,1.4-11.3-2.4c-2.9-4.4-2.4-8.3,1.9-11.2c17.8-11.9,35.8-23.6,53.6-35.5
		C369.9,336.9,372.3,335.8,373.6,333.6z"
        />
        <path
          className="st19"
          d="M383.5,413.3c0,4.1,0,8.3,0.1,12.4c0.2,12.5,9.3,21.8,21.8,21.5c5.1-0.1,7.6,1,7.7,6.6
		c0.1,4.8,2.1,9.3,4.5,13.6c-26.5,13.2-50.9,29.4-73,49c-5.6-4.1-9.3-9.4-11.2-16.1c-5.8-20.7-5.2-40.4,9.2-58
		c6.8-8.2,15.9-12.3,25.7-15.4c0.5-0.1,0.9-0.4,1.4-0.4C377.9,425.9,384.3,423.4,383.5,413.3z"
        />
        <path
          className="st9"
          d="M608.4,450.5c13.4,20.7,26,41.8,32.6,65.9c5.2,19,5.7,38.4,5.7,57.9c2.3,7.1,0,13.8-2.6,20.2
		c-1,2.4-3,5.2-6.3,1.9c-4.7-10.7-7.5-22-10.6-33.2c-9.4-33.9-24.9-64.8-43.8-94.2c-5.9-9.2-11-19-16.6-28.5
		c-1.5-2.6-3.4-4.9-5.2-7.3c13.5-5.2,30.5-0.9,40.7,10.1c1.5,1.6,2.9,3.2,4.3,4.8C607.5,448.7,608.2,449.5,608.4,450.5z"
        />
        <path
          className="st19"
          d="M304.6,708.3c-0.9,19.6-2.7,39.1-2,58.8c1.1,28.9,6.2,56.8,21.3,82.1c2.7,4.5,6.2,8.2,9.6,12
		c-2.5,2.1-5.1,2.3-8.3,1.4c-14-4.1-23.8-13.3-31.9-24.9c-8.7-12.5-12.5-27-16.9-41.3c-5.4-23.1-5.3-46.5-2.1-69.9
		C276.7,709,287.7,703.4,304.6,708.3z"
        />
        <path
          className="st9"
          d="M423.6,474.4c6.3,4.2,13,7.2,20.9,7.1c8.2-0.1,14.7-3.2,20-9.3c1.2-1.4,2.6-2.5,4-3.7c6.4,15.2,9,31.3,10,47.6
		c4.9,74-16.5,140.8-56.5,202.3c-7,10.8-15.6,20.3-26.2,27.8c-3.1,2.2-5.1,2.4-7.1-1.2c-4.1-7.5-10.5-14-9.2-23.5
		c-2.5-7.5,0.4-14.3,2.7-21.1c1-3.1,2.4-6.1,3.6-9.2c1-2.5,0.2-3.4-2.3-3.4c-4.1-0.1-8.3-0.2-12.4,0.1c-3,0.2-5.2-0.4-6.5-3.3
		c-2.7-12.1-4.2-24.2-2.2-36.5c0.3-1.8,0.8-3.5,1.2-5.3c2.2-1.8,4.4-0.8,6.6-0.1c9.1,2.7,18.6,3,28,3.8c5,0.4,8.4-1,9.8-6.1
		c0.7-1,1.6-1.9,1.9-3.2c0.2-1.4,1.1-2.2,2.3-2.7c11-29.4,14.3-59.9,12.7-91.2C423.9,520.3,422.3,497.4,423.6,474.4z"
        />
        <path
          className="st19"
          d="M381.7,852.4c-20.4-10.9-32-27.4-34.4-50.8c-1.5-15.2,1.3-29.7,3.5-44.5c11.2,9.4,22.5,18.6,34.2,27.5
		c4.1,3.1,6.5,7.1,5.6,12.9c-2.9,17.5-5.4,35.1-8.1,52.7C382.4,850.9,382,851.7,381.7,852.4z"
        />
        <path
          className="st6"
          d="M410.5,640.3c-0.7,1.8-1.5,3.7-2.1,5.5c-0.6,1.7-1.7,2.7-3.7,2.6c-13.9-0.4-27.7-0.7-40.9-5.9
		c6.3-31.3,23.7-53.3,54-64.4c3.6-1.3,3.9-0.2,3.2,3.1c-3.4,17.3-7.3,34.5-9.5,52c-0.3,1.3-0.2,2.6-0.4,4
		C410.8,638.2,410.2,639.2,410.5,640.3z"
        />
        <path
          className="st3"
          d="M364.7,684.4c1.4,1.5,3.1,1.4,5,1.3c5.6-0.2,11.3-0.1,16.9-0.1c3.1,0,4.1,0.9,2.5,4.1c-5,10-8.2,20.6-9.6,31.7
		C372.7,709.8,368.2,697.3,364.7,684.4z"
        />
        <path
          className="st8"
          d="M414.1,762.6c-5.1-5.4-10.1-9.4-16.8-12.6c-1.8-0.9-4.6-2-6.6-3.6c-0.3,0.2-0.7,0.4-1,0.7
		c0.5,5.6,0.5,11.2,0.5,16.9c0,6.3,0.4,12.9-0.3,19.2c-0.1,0.7-0.2,1.4-0.2,2.1c0.1,0.7,0.3,1.3,0.4,2c-0.6,0.8-0.9,1.8-0.8,3.1
		c1.2,9.5,7.1,19.8,13,27.2c5.5,6.9,13.7,13.8,22.8,13c8-0.7,11-9.8,11.3-16.4C437.4,796.2,426,775.4,414.1,762.6z"
        />
      </g>
    </svg>
  )
}

import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Fab from '@material-ui/core/Fab'
import adPlay from '../../assets/adPlay.png'
import bigWinStars from '../../assets/bigWinStars@3x.png'
import bot from '../../assets/bot.png'
import { RewardIcon } from '../Rewards/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import verifications from '../../assets/verificationsFull.png'
import sweepstakes from '../../assets/sweepstakesIcon.png'

export default () => {
  return (
    <div
      className="position-absolute d-none d-lg-block pl-2"
      style={{ left: 0, top: 80, zIndex: 1 }}
    >
      <div className="mb-2">
        <Tooltip title={<Typography>Background Mining</Typography>}>
          <Fab
            component={NavLink}
            className="bg-bkgd"
            activeClassName="bg-white"
            to="/background"
            style={{
              width: 36,
              height: 36,
              border: '2px solid #bbb',
              textAlign: 'center',
            }}
          >
            <img style={{ width: 20 }} src={bot} alt="Background Mining" />
          </Fab>
        </Tooltip>
      </div>
      <div className="mb-2">
        <Tooltip title={<Typography>Ad Plays</Typography>}>
          <Fab
            component={NavLink}
            className="bg-bkgd"
            activeClassName="bg-white"
            to="/videoads"
            style={{
              width: 36,
              height: 36,
              border: '2px solid #bbb',
              textAlign: 'center',
            }}
          >
            <img
              style={{ width: 14, transform: `translateX(0.5px)` }}
              src={adPlay}
              alt="Ad Plays"
            />
          </Fab>
        </Tooltip>
      </div>
      <div className="mb-2">
        <Tooltip title={<Typography>Big Wins</Typography>}>
          <Fab
            component={NavLink}
            className="bg-bkgd"
            activeClassName="bg-white"
            to="/bigwins"
            style={{
              width: 36,
              height: 36,
              border: '2px solid #bbb',
              textAlign: 'center',
            }}
          >
            <img style={{ width: 20 }} src={bigWinStars} alt="Big Wins" />
          </Fab>
        </Tooltip>
      </div>
      <div className="mb-2">
        <Tooltip title={<Typography>Surveys</Typography>}>
          <Fab
            component={NavLink}
            className="bg-bkgd"
            activeClassName="bg-white"
            to="/surveys"
            style={{
              width: 36,
              height: 36,
              border: '2px solid #bbb',
              textAlign: 'center',
            }}
          >
            <RewardIcon
              style={{ width: 20 }}
              type="surveys"
              variant="green"
              alt="Surveys"
            />
          </Fab>
        </Tooltip>
      </div>
      <div className="mb-2">
        <Tooltip title={<Typography>Rewarded Visits</Typography>}>
          <Fab
            component={NavLink}
            className="bg-bkgd"
            activeClassName="bg-white"
            to="/rewarded/visits"
            style={{
              width: 36,
              height: 36,
              border: '2px solid #bbb',
              textAlign: 'center',
            }}
          >
            <RewardIcon
              style={{ width: 20 }}
              type="visits"
              variant="green"
              alt="Rewarded Visits"
            />
          </Fab>
        </Tooltip>
      </div>
      <div className="mb-2">
        <Tooltip title={<Typography>Rewarded Scans</Typography>}>
          <Fab
            component={NavLink}
            className="bg-bkgd"
            activeClassName="bg-white"
            to="/rewarded/scans"
            style={{
              width: 36,
              height: 36,
              border: '2px solid #bbb',
              textAlign: 'center',
            }}
          >
            <RewardIcon
              style={{ width: 20 }}
              type="scans"
              variant="green"
              alt="Rewarded Scans"
            />
          </Fab>
        </Tooltip>
      </div>
      <div className="mb-2">
        <Tooltip title={<Typography>Sweepstakes History</Typography>}>
          <Fab
            component={NavLink}
            className="bg-bkgd"
            activeClassName="bg-white"
            to="/sweepstakes/history"
            style={{
              width: 36,
              height: 36,
              border: '2px solid #bbb',
              textAlign: 'center',
            }}
          >
            <img
              src={sweepstakes}
              alt="Sweepstakes History"
              style={{ width: 20 }}
            />
          </Fab>
        </Tooltip>
      </div>
      <div className="mb-2">
        <Tooltip title={<Typography>Rewarded Verifications</Typography>}>
          <Fab
            component={NavLink}
            className="bg-bkgd"
            activeClassName="bg-white"
            to="/rewarded/verifications"
            style={{
              width: 36,
              height: 36,
              border: '2px solid #bbb',
              textAlign: 'center',
            }}
          >
            <img
              src={verifications}
              alt="Rewarded Verificationss"
              style={{ width: 20 }}
            />
          </Fab>
        </Tooltip>
      </div>
    </div>
  )
}

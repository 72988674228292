import {
  useCancelValidationSessionMutation,
  useGtvRewardsQuery,
  useGtvSessionsQuery,
} from '@coin-microservices/foursquare-react-hooks/dist/client.generated'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Skeleton from '@material-ui/lab/Skeleton'
import keyBy from 'lodash/keyBy'
import times from 'lodash/times'
import React, { useMemo, useState } from 'react'
import closedSign from '../assets/closedSign.png'
import inaccessibleImg from '../assets/inaccessible.png'
import { swalConfirm } from '../components/Alert'
import { View } from '../components/Footer'
import { StatCard } from '../components/Dashboard/Card'
import { useScrollContext } from '../components/Scroll'
import { useSnackbar } from '../components/Snackables'
import { TableCursorActions } from '../components/Table/Pagination'
import { FoursquareProvider } from '../context/foursquare'
import { parseError } from '../utils/error'
import { numberFormat } from '../utils/number'

const supportUri =
  'https://support.coinapp.co/hc/en-us/articles/1500001541681-Rewarded-Verifications'
const bonusGameFilter = {
  GT: new Date('2021-03-17T08:00:00.000Z'),
  LT: new Date('2021-04-17T06:59:59.000Z'),
}

const Verifications = () => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [filterType, setFilterType] = useState('')
  const scrollElement = useScrollContext()

  const variables = useMemo(() => {
    const filter = {}
    if (filterType === 'bonusGame') {
      filter.created = bonusGameFilter
    }
    return {
      filter,
      pagination: { page, perPage },
      sort: { field: 'created', order: -1 },
    }
  }, [page, perPage, filterType])

  const rewardsQuery = useGtvRewardsQuery({
    variables: { filter: variables.filter },
  })
  const sessionsQuery = useGtvSessionsQuery({ variables })

  const rewardData = rewardsQuery.data?.gtvRewards

  const { remoteRowCount, rowData } = useMemo(() => {
    const remoteRowCount = sessionsQuery.data?.gtvSessions?.total ?? 0
    const rowData = sessionsQuery.data?.gtvSessions?.data ?? []
    return { remoteRowCount, rowData }
  }, [sessionsQuery.data])

  const changePage = (p) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    setPage(p)
  }
  const handleChangePage = (event, newPage) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }

  return (
    <View>
      <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8 px-0">
        <div className="d-flex align-items-center text-white px-2 px-md-0">
          <h1>Rewarded Verifications</h1>
          <Box flexGrow={1}></Box>
          <Button
            onClick={() => setFilterType('')}
            // color={activeTab === 'direct' ? 'secondary' : ''}
            variant={filterType === '' ? 'contained' : 'text'}
          >
            All-time
          </Button>
          <Box ml={1}>
            <Button
              onClick={() => setFilterType('bonusGame')}
              // color={activeTab === 'sweepstakes' ? 'secondary' : ''}
              variant={filterType === 'bonusGame' ? 'contained' : 'text'}
            >
              Bonus Game
            </Button>
          </Box>
        </div>
        <div className="d-flex flex-wrap align-items-center mb-4">
          <Box p={1} flexGrow={1}>
            <StatCard label="Verifications" loading={rewardsQuery?.loading}>
              {numberFormat(rewardData?.validated ?? 0, 0)}
            </StatCard>
          </Box>
          <Box p={1} flexGrow={1}>
            <StatCard label="Created" loading={rewardsQuery?.loading}>
              {numberFormat(rewardData?.inserted ?? 0, 0)}
            </StatCard>
          </Box>
          <Box p={1} flexGrow={1}>
            <StatCard label="Closed" loading={rewardsQuery?.loading}>
              {numberFormat(rewardData?.closed ?? 0, 0)}
            </StatCard>
          </Box>
        </div>
        <div className="d-flex align-items-center text-white px-2 px-md-0">
          <h2>Rewards</h2>
        </div>
        <div className="d-flex flex-wrap align-items-center mb-4">
          <Box p={1} flexGrow={1}>
            <StatCard label="Pending" loading={rewardsQuery?.loading}>
              {numberFormat(rewardData?.pending)} <small>COIN</small>
            </StatCard>
          </Box>
          <Box p={1} flexGrow={1}>
            <StatCard label="Conditional" loading={rewardsQuery?.loading}>
              {numberFormat(rewardData?.conditional)} <small>COIN</small>
            </StatCard>
          </Box>
          <Box p={1} flexGrow={1}>
            <StatCard label="Delivered" loading={rewardsQuery?.loading}>
              {numberFormat(rewardData?.delivered)} <small>COIN</small>
            </StatCard>
          </Box>
        </div>
        <div className="d-flex align-items-center text-white px-2 px-md-0">
          <h2>Entries</h2>
        </div>
        <div className="position-relative list-group text-dark">
          {sessionsQuery?.loading ? (
            times(perPage).map((a, i) => (
              <Box key={i} mb={1}>
                <VerificationSessionListItem loading={true} />
              </Box>
            ))
          ) : (rowData || []).length ? (
            (rowData || []).map((session, i) => (
              <Box key={session.id} mb={1}>
                <VerificationSessionListItem session={session} />
              </Box>
            ))
          ) : (
            <NoVerifications />
          )}
          {remoteRowCount ? (
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25]}
              count={remoteRowCount}
              rowsPerPage={perPage}
              page={page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TableCursorActions}
            ></TablePagination>
          ) : null}
        </div>
      </div>
    </View>
  )
}

const NoVerifications = () => {
  return (
    <Paper>
      <Box p={2} py={4} textAlign="center">
        <h4 className="mb-2">No Rewarded Verifications Found</h4>
        <p className="mb-0">
          Read more about rewarded verifications{' '}
          <a href={supportUri} target="_blank" rel="noopener noreferrer">
            here
          </a>
          .
        </p>
      </Box>
    </Paper>
  )
}

const VerificationSessionListItem = ({ session, loading }) => {
  const [cancelSession, { loading: cancelling }] =
    useCancelValidationSessionMutation()

  const isUndetermined = !session?.state || session?.state === 'undetermined'
  const { setSnackbar } = useSnackbar()[1]
  const { name } = useMemo(() => {
    const fieldsByName = keyBy(session?.validations?.data, 'fieldName')
    const name = session?.venue?.name ?? fieldsByName['name']?.value ?? ''
    return { name }
  }, [session])

  const handleConfirmCancel = async (id) => {
    if (!id) return
    if (cancelling) return
    try {
      const { value } = await swalConfirm(
        `Are you sure you want to cancel? This action will revoke your ${
          session?.rewardPending
            ? `${numberFormat(session?.rewardPending)} pending`
            : session?.rewardConditional
            ? `${numberFormat(session?.rewardConditional)} conditional`
            : ''
        } rewards.`,
        'Confirm',
        'warning',
      )
      if (!value) return
      await cancelSession({ variables: { id } })
      setSnackbar({
        severity: 'success',
        message: (
          <>
            <AlertTitle>Successfully Canceled</AlertTitle>You have successfully
            canceled your venue verification. If you would like to update it,
            you can do so through the app.
          </>
        ),
        autoHideDuration: 10000,
      })
    } catch (err) {
      console.log(err)
      setSnackbar({
        severity: 'error',
        message: parseError(err),
        autoHideDuration: 6000,
      })
    }
  }

  return (
    <Paper>
      <Box display="flex" alignItems="center" p={2}>
        {loading ? (
          <Skeleton
            variant="rect"
            width={80}
            height={80}
            className="skeleton"
          />
        ) : (
          <VenueState session={session} />
        )}
        <h4 className="mb-0 mx-2">
          {loading ? (
            <Skeleton variant="rect" width={80} className="skeleton" />
          ) : (
            name
          )}
          &nbsp;
        </h4>
        <div className="flex-grow-1" />
        <div className="ml-4 text-right">
          {loading ? (
            <>
              <Skeleton
                variant="text"
                width={80}
                className="skeleton"
                classes={{ root: 'ml-auto' }}
              />
              <Skeleton
                variant="text"
                width={60}
                className="skeleton"
                classes={{ root: 'ml-auto' }}
              />
            </>
          ) : (
            <>
              <h6 className="mb-0">
                {session?.rewardDelivered
                  ? `Delivered ${numberFormat(session?.rewardDelivered)}`
                  : session?.rewardPending
                  ? `Pending ${numberFormat(session?.rewardPending)}`
                  : session?.rewardConditional
                  ? `Conditional ${numberFormat(session?.rewardConditional)}`
                  : ''}
              </h6>
              <p className="mb-0 text-muted">
                <small>{new Date(session?.created).toLocaleDateString()}</small>
              </p>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => handleConfirmCancel(session?.id)}
                disabled={loading || cancelling || !isUndetermined}
                className="mt-1"
              >
                {isUndetermined ? 'Cancel' : session?.state}
              </Button>
            </>
          )}
        </div>
      </Box>
    </Paper>
  )
}

const VerificationsView = () => {
  return (
    <FoursquareProvider>
      <Verifications />
    </FoursquareProvider>
  )
}

const VenueState = ({ session }) => {
  const { name, inaccessible, residential, missing, moved, photo } =
    useMemo(() => {
      const fieldsByName = keyBy(session?.validations?.data, 'fieldName')
      const name = session?.venue?.name ?? fieldsByName['name']?.value ?? ''
      const inaccessible = fieldsByName['inaccessible']?.value ?? ''
      const residential = fieldsByName['residential']?.value ?? ''
      const missing = fieldsByName['missing']?.value ?? ''
      const moved = fieldsByName['moved']?.value ?? ''
      const photo = fieldsByName['photo']?.value ?? ''
      return { name, inaccessible, photo, residential, missing, moved }
    }, [session])
  return (
    <div
      className="border border-light rounded overflow-hidden text-center text-white d-flex align-items-center flex-column"
      style={{ height: 80, width: 80, position: 'relative' }}
    >
      {photo ? (
        <div className="d-flex flex-grow-1 align-items-center justify-content-center">
          <img
            src={photo}
            alt={name}
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          />
        </div>
      ) : session?.closed ? (
        <div className="d-flex flex-grow-1 align-items-center justify-content-center">
          <img
            src={closedSign}
            alt={`Closed ${session?.closed}`}
            style={{ height: 28, objectFit: 'cover' }}
          />
        </div>
      ) : inaccessible || residential || missing || moved ? (
        <div className="d-flex flex-grow-1 align-items-center justify-content-center">
          <img
            src={inaccessibleImg}
            alt="Inaccessible"
            style={{ height: 28, objectFit: 'cover' }}
          />
        </div>
      ) : null}
      <p className="mb-0 w-100 text-capitalize bg-light text-dark">
        {inaccessible
          ? 'inaccessible'
          : residential
          ? 'residential'
          : missing
          ? 'missing'
          : moved
          ? 'moved'
          : session?.closed ?? ''}
      </p>
    </div>
  )
}

export default VerificationsView

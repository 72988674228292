import TablePagination from '@material-ui/core/TablePagination'
import Skeleton from '@material-ui/lab/Skeleton'
import gql from 'graphql-tag'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import times from 'lodash/times'
import React, { useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-apollo-hooks'
import { QuadkeyMap } from '../components/Map'
import { useScrollContext } from '../components/Scroll'
import { AWSError } from '../components/Error'
import { View } from '../components/Footer'
import TablePaginationActions from '../components/Table/Pagination'
import { BossProvider } from '../context/bossapi'
import { numberFormat } from '../utils/number'

export default () => (
  <BossProvider>
    <VideoAdsPage />
  </BossProvider>
)

const VideoAdsPage = React.memo(() => {
  const scrollElement = useScrollContext()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const remoteCountRef = useRef(null)
  const variables = useMemo(
    () => ({
      page,
      perPage,
      field: 'yyyymmdd',
    }),
    [perPage, page],
  )
  const { data, fetchMore, loading, error } = useQuery(VIDEO_ADS, { variables })
  const remoteRowCount = get(data, 'drops.total')
  const changePage = (p) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    setPage(p)
  }
  const handleChangePage = (event, newPage) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }
  const list = get(data, 'videoAdsHistoryList.data') || []

  return (
    <View>
      <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8 px-0">
        <div className="d-flex align-items-center text-white px-2">
          <h1>Video Ad Rewards</h1>
        </div>
        <div className="position-relative list-group text-dark">
          {loading ? (
            times(perPage).map((a, i) => <VideoAd key={i} loading={true} />)
          ) : list.length ? (
            list.map((item) => <VideoAd key={item._id} {...item} />)
          ) : error ? (
            <AWSError error={error} />
          ) : (
            <NoVideoAds />
          )}
          {remoteRowCount && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25]}
              count={remoteRowCount}
              rowsPerPage={variables.perPage}
              page={variables.page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            ></TablePagination>
          )}
        </div>
      </div>
    </View>
  )
})

const VideoAd = ({
  _id,
  uid,
  amount,
  xyoCollected,
  adQueue,
  adsViewed,
  adsCollected,
  date,
  yyyymmdd,
  style,
  loading,
}) => {
  const title = ''
  return (
    <div
      className="list-group-item d-flex align-items-center rounded-0-sm"
      style={style}
    >
      <div className="flex-grow-1">
        {loading ? (
          <>
            <Skeleton
              variant="text"
              width={180}
              className="skeleton"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
            />
            <Skeleton
              variant="text"
              width={160}
              className="skeleton"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
            <Skeleton
              variant="text"
              width={160}
              className="skeleton"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
          </>
        ) : (
          <>
            <h4 className="mb-2">
              New Total: {(xyoCollected || 0).toLocaleString()}
            </h4>
            <p className="mb-0 nowrap">
              Previous Total:{' '}
              <small>
                {((xyoCollected || 0) - (amount || 0)).toLocaleString()}
              </small>
            </p>
            <p className="mb-0 nowrap">
              Reward: <small>{amount}</small>
            </p>
            <p className="mb-0 text-muted">
              <small>{new Date(Number(date)).toLocaleString()}</small>
            </p>
          </>
        )}
      </div>
    </div>
  )
}

const NoVideoAds = () => {
  return (
    <div className="list-group-item text-center py-5 rounded-0-sm">
      <h4>No Video Ads Found</h4>
      <p>
        Read more about video ads{' '}
        <a
          href="https://support.coinapp.co/hc/en-us/categories/360002099633"
          target="_blank"
        >
          here
        </a>
        .
      </p>
    </div>
  )
}

const VIDEO_ADS = gql`
  query VideoAdsHistoryList($page: Int, $perPage: Int, $field: String) {
    videoAdsHistoryList(
      pagination: { page: $page, perPage: $perPage }
      sort: { field: $field, order: -1 }
    ) {
      total
      page
      data {
        ...VideoAdsHistory
      }
    }
  }

  fragment VideoAdsHistory on VideoAdsHistory {
    _id
    uid
    amount
    xyoCollected
    adQueue
    adsViewed
    adsCollected
    date
    yyyymmdd
  }
`

import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { useUserInfo } from '../../context/user'
import { numberFormat } from '../../utils/number'
import { Link } from 'react-router-dom'
import { StatCard } from './Card'

export const UserTotalsDashboard = ({ graphics }: { graphics?: boolean }) => {
  return (
    <>
      <Box my={4}>
        <Grid container spacing={2}>
          <Grid item md={1} />
          <Grid item xs={12} md={5}>
            <BalanceCard graphics={graphics} />
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <GiftedCard />
          </Grid> */}
          <Grid item xs={12} md={5}>
            <RedeemedCard graphics={graphics} />
          </Grid>
          <Grid item md={1} />
        </Grid>
      </Box>
      <Typography variant="h4" paragraph>
        Collected
      </Typography>
      <Box mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <GeominesCard graphics={graphics} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CollectedCard graphics={graphics} />
          </Grid>
          <Grid item xs={12} md={4}>
            <SurveyCard graphics={graphics} />
          </Grid>
        </Grid>
      </Box>
      <Typography variant="h4" paragraph>
        Spent
      </Typography>
      <Box mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <GeoclaimedCard graphics={graphics} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DroppedCard graphics={graphics} />
          </Grid>
          <Grid item xs={12} md={4}>
            <SweepstakesCard graphics={graphics} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const BalanceCard = ({ graphics }: { graphics?: boolean }) => {
  const { userInfo, loading } = useUserInfo()

  return (
    <StatCard
      label="Balance"
      loading={loading}
      secondary={<span>&nbsp;</span>}
      graphic={graphics ? 'collected' : ''}
      action={<ButtonSpace />}
    // action={
    //   <Button component={Link} to="/geomines">
    //     View More
    //   </Button>
    // }
    >
      {numberFormat(userInfo?.xyoCollected ?? 0, 0)} <COIN />
    </StatCard>
  )
}

const RedeemedCard = ({ graphics }: { graphics?: boolean }) => {
  const { userInfo, loading } = useUserInfo()

  return (
    <StatCard
      label="Redeems"
      graphic={graphics ? 'redeem' : ''}
      loading={loading}
      secondary={
        userInfo?.xyoPending ? (
          <span>
            {numberFormat(userInfo?.xyoPending ?? 0, 0)}{' '}
            <small>(Pending)</small>
          </span>
        ) : (
          <span>&nbsp;</span>
        )
      }
      action={
        <Button component={Link} to="/redeem">
          View More
        </Button>
      }
    >
      {numberFormat(userInfo?.xyoRedeemed ?? 0, 0)} <COIN />
    </StatCard>
  )
}

// const HodlCard = () => {
//   const { userInfo, loading } = useUserInfo()

//   return (
//     <StatCard
//       label="HODL Rewards"
//       graphic="pile"
//       loading={loading}
//       secondary={<span>&nbsp;</span>}
//       action={<ButtonSpace />}
//     >
//       {numberFormat(userInfo?.xyoHodlRewards ?? 0, 0)} <COIN />
//     </StatCard>
//   )
// }

// const ScanCard = ({ graphics }: { graphics?: boolean }) => {
//   const { userInfo, loading } = useUserInfo()

//   return (
//     <StatCard
//       label="SentinelX Scans"
//       graphic={graphics ? 'nfc' : ''}
//       loading={loading}
//       secondary={<span>NFC Scans</span>}
//       action={<ButtonSpace />}
//     >
//       {numberFormat(userInfo?.nfcScanCount ?? 0, 0)}
//     </StatCard>
//   )
// }

const GeominesCard = ({ graphics }: { graphics?: boolean }) => {
  const { userInfo, loading } = useUserInfo()

  return (
    <StatCard
      label="Geomines"
      graphic={graphics ? 'count' : ''}
      loading={loading}
      secondary={<span>&nbsp;</span>}
      // action={<ButtonSpace />}
      action={
        <Button component={Link} to="/geomines">
          View More
        </Button>
      }
    >
      {numberFormat(userInfo?.xyoGeomined ?? 0, 0)} <COIN />
    </StatCard>
  )
}

const CollectedCard = ({ graphics }: { graphics?: boolean }) => {
  const { userInfo, loading } = useUserInfo()

  return (
    <StatCard
      graphic={graphics ? 'raining' : ''}
      label="Geodrops"
      loading={loading}
      secondary={<span>&nbsp;</span>}
      action={
        <Button
          component={Link}
          to={{
            pathname: '/geodrops',
            state: {
              filter: 'dropsCollected',
            },
          }}
        >
          View More
        </Button>
      }
    >
      {numberFormat(userInfo?.xyoPickedUp ?? 0, 0)} <COIN />
    </StatCard>
  )
}

const SurveyCard = ({ graphics }: { graphics?: boolean }) => {
  const { userInfo, loading } = useUserInfo()

  return (
    <StatCard
      label="Surveys"
      graphic={graphics ? 'survey' : ''}
      loading={loading}
      secondary={<span>&nbsp;</span>}
      // action={<ButtonSpace />}
      action={
        <Button component={Link} to="/surveys">
          View More
        </Button>
      }
    >
      {numberFormat((userInfo?.xyoSurveys ?? 0) - (userInfo?.xyoInbrainRevoked ?? 0), 0)} <COIN />
    </StatCard>
  )
}

const GeoclaimedCard = ({ graphics }: { graphics?: boolean }) => {
  const { userInfo, loading } = useUserInfo()

  return (
    <StatCard
      label="Geoclaims"
      loading={loading}
      secondary={<span>&nbsp;</span>}
      graphic={graphics ? 'geoclaim' : ''}
      action={
        <Button component={Link} to="/geoclaims">
          View More
        </Button>
      }
    >
      {numberFormat(userInfo?.xyoGeoclaimed ?? 0, 0)} <COIN />
    </StatCard>
  )
}

const DroppedCard = ({ graphics }: { graphics?: boolean }) => {
  const { userInfo, loading } = useUserInfo()

  return (
    <StatCard
      label="Geodrops"
      loading={loading}
      secondary={<span>&nbsp;</span>}
      graphic={graphics ? 'geodrop' : ''}
      action={
        <Button
          component={Link}
          to={{
            pathname: '/geodrops',
            state: {
              filter: 'dropsCreated',
            },
          }}
        >
          View More
        </Button>
      }
    >
      {numberFormat(userInfo?.xyoDropped ?? 0, 0)} <COIN />
    </StatCard>
  )
}

const SweepstakesCard = ({ graphics }: { graphics?: boolean }) => {
  const { userInfo, loading } = useUserInfo()

  return (
    <StatCard
      label="Sweepstakes"
      loading={loading}
      secondary={<span>&nbsp;</span>}
      graphic={graphics ? 'sweepstakes' : ''}
      action={
        <Button component={Link} to="/sweepstakes/history">
          View More
        </Button>
      }
    >
      {numberFormat(userInfo?.xyoSweepstakes ?? 0, 0)} <COIN />
    </StatCard>
  )
}

// const Percentage = ({ value }: { value: number }) => {
//   return (
//     <Typography color={value > 0 ? 'secondary' : 'primary'} component="span">
//       {numberFormat(value)}%
//     </Typography>
//   )
// }

const COIN = () => <small>COIN</small>

const ButtonSpace = () => <Box style={{ height: 36 }} />

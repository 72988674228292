import React from 'react'
import { FadeIn } from '../components/Animate'
import { BadgeCards } from '../components/Badges'
import { SubscriptionInfo } from '../components/Subscriptions'
import { UserInfoDisplay } from '../components/User'

const Badges = () => {
  return (
    <div className="container text-white pt-2 pb-40vh">
      <div className="pb-4">
        <FadeIn>
          <UserInfoDisplay>
            <SubscriptionInfo />
          </UserInfoDisplay>
        </FadeIn>
      </div>
      <div>
        <BadgeCards />
      </div>
    </div>
  )
}

export default Badges

import { Suspense } from 'react'
import { useQuery } from 'react-apollo-hooks'
import { BossProvider } from '../../context/bossapi'
import Table, { AssetTitleIcon, LocaleNumber } from '../Table'
import ErrorDisplay from '../Error'
import Loader from '../Loader'
import gql from 'graphql-tag'

const BALANCES = gql`
  query Balances {
    balances {
      icon
      asset
      title
      collected
      pending
      withdrawn
    }
  }
`

const useBalances = (options) => useQuery(BALANCES, options)

const BalancesDisplay = ({ isActive, onClick, className }) => {
  const { data, error, loading } = useBalances()
  return (
    <div className={className}>
      <Loader loading={loading} />
      <ErrorDisplay error={error} />
      {data.balances && (
        <BalancesTable
          isActive={isActive}
          onClick={onClick}
          data={data.balances}
        />
      )}
    </div>
  )
}

const BalanceDisplay = ({ asset, className }) => {
  const { data, error, loading } = useBalances()
  const balance = (data.balances || []).find(
    (b) => b.asset.toLowerCase() === asset.toLowerCase(),
  )
  return (
    <div className={className}>
      <Loader loading={loading} />
      <ErrorDisplay error={error} />
      {balance && (
        <div className="d-flex text-white">
          <AssetTitleIcon {...balance} />
          <LocaleNumber source="collected" {...balance} />
          <LocaleNumber source="pending" {...balance} />
          <LocaleNumber source="withdrawn" {...balance} />
        </div>
      )}
    </div>
  )
}

export const BalancesTable = (props) => {
  return (
    <div className="border rounded mb-4 scroll-x mw-100">
      <Table className="table table-sm text-white mb-0" {...props}>
        <AssetTitleIcon label="Asset" />
        <LocaleNumber label="Collected" source="collected" />
        <LocaleNumber label="Pending" source="pending" />
        <LocaleNumber label="Redeemed" source="withdrawn" />
      </Table>
    </div>
  )
}

export const Balances = (props) => {
  return (
    <BossProvider>
      <Suspense fallback={<Loader loading={true} />}>
        <BalancesDisplay {...props} />
      </Suspense>
    </BossProvider>
  )
}

export const Balance = (props) => {
  return (
    <BossProvider>
      <Suspense fallback={<Loader loading={true} />}>
        <BalanceDisplay {...props} />
      </Suspense>
    </BossProvider>
  )
}

export const isActiveAsset = (asset) => (item) =>
  (item.asset || '').toLowerCase() === asset.toLowerCase()

export default Balances

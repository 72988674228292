import { useEffect, useMemo } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { fromSearch, toSearch } from '../../utils/search'
import { useUser, useUserInfo } from '../../context/user'
import firebase from 'firebase/app'
import 'firebase/auth'

export const ExternalRedirect = ({ to, ...routeProps }) => {
  return (
    <Route
      {...routeProps}
      render={() => {
        window.location = to
        return null
      }}
    />
  )
}

export const Private = ({ component: Component, to, ...rest }) => {
  const path = rest.location.pathname
  const renderRoute = () => {
    const user = firebase.auth().currentUser
    const { token, email, ...params } = fromSearch(rest)
    if (token)
      return (
        <TokenMatch
          token={token}
          redirect={`${path}?${toSearch(params)}`}
          params={{ ...params, redirect: path }}
        />
      )
    if (user && email && user.email !== email)
      return (
        <LogoutMatch to={to} params={{ ...params, redirect: path, email }} />
      )
    if (user) return <Component {...rest} />
    if (email)
      return (
        <Redirect
          to={`${to}?${toSearch({ ...params, redirect: path, email })}`}
        />
      )
    return <Redirect to={`${to}?${toSearch({ ...params, redirect: path })}`} />
  }

  return <Route {...rest} path={path} render={renderRoute} />
}

export const RequiredEmail = ({ oneOf, children }) => {
  const { user } = useUser()
  const email = user?.email
  const pass = useMemo(() => {
    return oneOf.indexOf(email) !== -1
  }, [oneOf, email])
  if (pass) return <>{children}</>
  return null
}

export const RequireReauthentication = ({
  component: Component,
  to,
  ...rest
}) => {
  const path = rest.location.pathname
  const renderRoute = () => {
    const user = firebase.auth().currentUser
    const { token, email, ...params } = fromSearch(rest)
    if (email)
      return (
        <LogoutMatch to={to} params={{ ...params, redirect: path, email }} />
      )
    if (user) return <Component {...rest} />
    return <Redirect to={`${to}?${toSearch({ ...params, redirect: path })}`} />
  }

  return <Route {...rest} path={path} render={renderRoute} />
}

export const PrivateEmail = ({ component: Component, to, ...rest }) => {
  const path = rest.location.pathname
  const renderRoute = () => {
    const user = firebase.auth().currentUser
    const { token, email, ...params } = fromSearch(rest)
    if (token)
      return (
        <TokenMatch
          token={token}
          redirect={`${path}?${toSearch(params)}`}
          params={{ ...params, redirect: path }}
        />
      )
    if (user && email && user.email !== email)
      return (
        <LogoutMatch to={to} params={{ ...params, redirect: path, email }} />
      )
    return <Component {...rest} />
  }
  return <Route {...rest} path={path} render={renderRoute} />
}

export const InvitedAdminRoute = ({ path, property, ...props }) => {
  const { initialized, user } = useUser()
  const { userInfo } = useUserInfo()
  if (initialized && !user) {
    return <Redirect to={`/auth?redirect=${path}`} />
  }
  if (userInfo !== null && (!userInfo || !userInfo[property])) {
    return <Redirect to="/" />
  }
  return <Route path={path} {...props} />
}

const LogoutMatch = ({ to, params }) => {
  const { logout } = useUser()
  useEffect(() => {
    logout(`${to}?${toSearch(params)}`)
  }, [])
  return (
    <div className="text-center text-danger">
      <div className="spinner-border" />
    </div>
  )
}

const TokenMatch = ({ token, redirect, params }) => {
  const { loginWithToken } = useUser()
  useEffect(() => {
    loginWithToken(token, redirect, toSearch(params))
  }, [])
  return (
    <div className="text-center text-danger">
      <div className="spinner-border" />
    </div>
  )
}

export default Private

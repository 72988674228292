import { createContext, useEffect, useContext, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { swalWarning, swalConfirm, swalClose } from '../components/Alert'
import { trackWarning, configureRollbar } from '../utils/events'
import { checkoutClient, redeemSubscription } from './checkout'
import { bossClient } from './bossapi'
import { useCustomTokenContext } from './customToken'
import fetch from 'isomorphic-fetch'
import get from 'lodash/get'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import {
  useAccountQuery,
  AccountDocument,
} from '@xyo-network/api-checkout-apollo'

const defaultUserContext = {
  skipRedeemSubscription: {
    current: '',
  },
  tokenLogin: false,
  initialized: false,
  user: null,
}

export const UserContext = createContext(defaultUserContext)

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
})

const xyAccount = () => {
  let user
  let listeners = []
  const timerId = setTimeout(() => {
    user = firebase.auth().currentUser
    listeners.forEach((listener) => listener(user))
    trackWarning('User not initialized after 15 seconds', user)
    swalWarning('This is taking too long, try closing other tabs.')
  }, 10000)
  const account = firebase.auth().onAuthStateChanged((_user) => {
    user = _user
    clearTimeout(timerId)
    listeners.forEach((listener) => listener(user))
  })
  return {
    subscribe: (fn) => {
      if (typeof fn === 'function') {
        listeners.push(fn)
        if (typeof user !== 'undefined') fn(user)
      }

      return () => {
        listeners = listeners.filter((l) => l === fn)
      }
    },
  }
}

const account = xyAccount()

export const UserProvider = ({ children }) => {
  const history = useHistory()
  const skipRedeemSubscription = useRef('')
  const [initialized, setInitialized] = useState(false)
  const [tokenLogin, setTokenLogin] = useState(false)
  const customTokenActions = useCustomTokenContext()[1]
  const [user, setUser] = useState(null)

  const onLogout = async () => {
    const { value } = await swalConfirm(
      'Are you sure you would like to logout?',
    )
    if (value) {
      await logout()
    }
  }

  const logout = async (to) => {
    try {
      await firebase.auth().signOut()
      customTokenActions.rm()
      try {
        await checkoutClient.clearStore()
        await bossClient.clearStore()
      } catch (e) {
        console.log('Clear stores error', e)
      }
      if (to) {
        history.push(to)
      } else {
        history.push('/auth')
      }
    } catch (e) {}
  }

  const loginWithToken = async (token, redirect, search) => {
    try {
      const customToken = await retrieveCustomToken(token)
      if (!customToken) throw new Error('Token invalid')
      const timerId = setTimeout(async () => {
        await swalWarning('This is taking too long, try closing other tabs.')
      }, 10000)
      const { user } = await firebase.auth().signInWithCustomToken(customToken)
      clearTimeout(timerId)
      if (search && search.indexOf('from=admin') !== -1) {
        customTokenActions.set(user.email)
      }
      setTokenLogin(true)
      await new Promise((res) => setTimeout(res, 100))
      try {
        console.log('Reset checkout store start')
        await checkoutClient.resetStore()
        console.log('Reset checkout store end')
        console.log('Reset boss store start')
        await bossClient.resetStore()
        await refetchAccounts()
        console.log('Reset boss store end')
      } catch (e) {
        console.log('Reset stores error', e)
      }
      swalClose()
      if (redirect) history.push(redirect)
    } catch (e) {
      if (search) {
        history.push(`/auth?${search}`)
      } else {
        history.push(`/auth`)
      }
    }
  }

  useEffect(() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      const email = window.prompt('Please provide your email for confirmation')
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then((u) => {
          console.log('Sign in link', u)
        })
        .catch((e) => {
          console.log('Sign in link error', e)
        })
    }

    return account.subscribe((user) => {
      setUser(user)
      setInitialized(true)
    })
  }, [])

  useEffect(() => {
    configureRollbar(user)
  }, [user])

  return (
    <UserContext.Provider
      value={{
        user,
        initialized,
        onLogout,
        logout,
        tokenLogin,
        loginWithToken,
        skipRedeemSubscription,
      }}
    >
      {children}
      <RedeemSubscription />
    </UserContext.Provider>
  )
}

const RedeemSubscription = () => {
  const { user, skipRedeemSubscription } = useUser()
  const { userInfo } = useUserInfo()
  useEffect(() => {
    if (user && user.email && userInfo) {
      if (userInfo.subActive) {
        console.log('Redeem was skipped: subActive')
      } else if (!skipRedeemSubscription.current) {
        redeemSubscription(user.email).then(() =>
          console.log('Sub was redeemed'),
        )
      } else {
        console.log('Redeem was skipped', skipRedeemSubscription.current)
      }
    }
  }, [user, userInfo?.ownerUID, skipRedeemSubscription])
  return null
}

export const fireAuth = async () => {
  if (!firebase.auth) return {}
  const user = firebase.auth().currentUser
  if (!user) return {}
  const token = await user.getIdToken()
  return {
    headers: {
      'X-Auth-Token': token,
    },
  }
}

export const useUser = () => useContext(UserContext)
export const useUserInfo = () => {
  const { user } = useUser()
  const { loading, data, error, refetch } = useAccountQuery({
    client: checkoutClient,
    skip: !user,
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'ignore',
  })
  const userInfo = data?.account?.userInfo

  return { userInfo, loading, error, refetch }
}

export const refetchAccounts = () => {
  return checkoutClient.query({
    query: AccountDocument,
    fetchPolicy: 'network-only',
  })
}

// export const doesUserExist = async uid => {
//   const db = firestore()
//   const col = db.collection('coin_users')
//   const ref = col.doc(uid)
//   const doc = await ref.get()
//   return doc.exists
// }

export const getOrCreateCoinUser = async () => {
  const res = await fetch(
    `${process.env.REACT_APP_COIN_SUBSCRIPTIONS_API}/coin_subscriptions/get-or-create/`,
    await fireAuth(),
  )
  const data = await res.json()
  return data
}

export const retrieveCustomToken = async (token) => {
  const res = await fetch(
    `${process.env.REACT_APP_COIN_SUBSCRIPTIONS_API}/coin_subscriptions/retrieve-token/${token}`,
  )
  const data = await res.json()
  return get(data, 'customToken')
}

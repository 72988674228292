import coinLogo from '../assets/CoinNoText.svg'
import { StartFreeTrial } from '../components/Upgrade'

const FreeTrialTest = () => {
  return (
    <div className="container-fluid text-white pt-2 pb-40vh">
      <div className="row">
        <div className="col-12 text-center mb-5">
          <img style={{ height: 120 }} alt="COIN Logo" src={coinLogo} />
        </div>
        <div className="col-12 text-center mt-4">
          <h1 style={{ fontWeight: 100 }}>GET PAID TO EXPLORE THE WORLD!</h1>
          <StartFreeTrial />
        </div>
      </div>
    </div>
  )
}

export default FreeTrialTest

import { MuiThemeProvider } from '@material-ui/core/styles'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks'
import { lightTheme, theme } from './context/page'
import client from './store'

export default ({ children, type = 'dark' }) => {
  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <MuiThemeProvider theme={type === 'dark' ? theme : lightTheme}>
          {children}
        </MuiThemeProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  )
}

import React from 'react'
import Box from '@material-ui/core/Box'
import { FadeIn } from '../components/Animate'
import { UserInfoDisplay } from '../components/User'
import { UpgradeModal } from '../components/Upgrade'
import {
  UserDashboard,
  UserTotalsDashboard,
  useUserDashboardData,
} from '../components/Dashboard'
// import {
//   CoinDashboard,
//   UserContext,
//   SweepstakesSummaryContext,
//   UserStatsContext,
// } from '@xyo-network/sdk-coin-react-js'
// import {
//   FlexGrowCol,
//   InvertableThemeProvider,
// } from '@xyo-network/sdk-xyo-react-js'
import { CheckoutProvider } from '../context/checkout'
import {
  MySubscriptionDisplay,
  SubscriptionPotential,
  SubscriptionInfo,
} from '../components/Subscriptions'
import { View } from '../components/Footer'
import { useUserInfo } from '../context/user'
import { toYYYYMMDD } from '../utils/date'

// import {
//   CoinUserProvider,
//   SweepstakesSummaryProvider,
//   UserStatsContextProvider,
// } from '../context/d'
// import BasePage from '../../BasePage'

const AccountPage = () => {
  return (
    <div className="container text-white pt-2 pb-40vh">
      <div className="pb-4">
        <FadeIn>
          <UserInfoDisplay>
            <SubscriptionInfo />
          </UserInfoDisplay>
        </FadeIn>
      </div>
      <div className="mb-3">
        <MySubscriptionDisplay />
      </div>
      <SubscriptionPotential />
      <UpgradeModal />
    </div>
  )
}

export default () => {
  return (
    <CheckoutProvider>
      <AccountPage />
    </CheckoutProvider>
  )
}

export const AccountV2 = () => {
  return (
    <CheckoutProvider>
      <div className="container text-white pt-2 pb-40vh">
        <div className="pb-4">
          <FadeIn>
            <UserInfoDisplay>
              <SubscriptionInfo />
            </UserInfoDisplay>
          </FadeIn>
        </div>
        <div className="mb-3">
          <MySubscriptionDisplay />
        </div>

        <UserDashboard />

        <UpgradeModal />
      </div>
    </CheckoutProvider>
  )
}

export const AccountV3 = () => {
  return (
    <CheckoutProvider>
      <div className="container text-white pt-2 pb-40vh">
        <div className="pb-4">
          <FadeIn>
            <UserInfoDisplay>
              <SubscriptionInfo />
            </UserInfoDisplay>
          </FadeIn>
        </div>

        <div className="mb-3">
          <MySubscriptionDisplay />
        </div>

        <UserTotalsDashboard />

        <UpgradeModal />
      </div>
    </CheckoutProvider>
  )
}

export const AccountV3b = () => {
  return (
    <CheckoutProvider>
      <View>
        <div className="container">
          <div className="pb-4">
            <FadeIn>
              <UserInfoDisplay>
                <SubscriptionInfo />
              </UserInfoDisplay>
            </FadeIn>
          </div>

          <div className="mb-3">
            <MySubscriptionDisplay />
          </div>

          <UserTotalsDashboard graphics />

          <UpgradeModal />
        </div>
      </View>
    </CheckoutProvider>
  )
}

// const CoinUserProvider = ({ children }) => {
//   const userInfo = useUserInfo()
//   return (
//     <UserContext.Provider value={userInfo}>{children}</UserContext.Provider>
//   )
// }

// const UserStatsContextProvider = ({ children }) => {
//   const { data } = useUserDashboardData()
//   const stats = data?.[0]?.data
//     ?.map((v, i) => ({
//       collected: data?.reduce((v, d) => v + (d?.data?.[i]?.collected ?? 0), 0),
//       yyyymmdd: toYYYYMMDD(new Date(v.date)),
//     }))
//     .slice(0, -1)
//   // const value = data ? { stats } : {}
//   const value = {}
//   return (
//     <UserStatsContext.Provider value={value}>
//       {children}
//     </UserStatsContext.Provider>
//   )
// }

// const SweepstakesSummaryProvider = ({ children }) => {
//   const userInfo = useUserInfo()
//   const value = userInfo?.userInfo?.xyoSweepstakes
//     ? { totalEntries: userInfo?.userInfo?.xyoSweepstakes / 10 }
//     : {}
//   return (
//     <SweepstakesSummaryContext.Provider value={value}>
//       {children}
//     </SweepstakesSummaryContext.Provider>
//   )
// }

// export const AccountV4 = () => {
//   return (
//     <Box px={2}>
//       <InvertableThemeProvider dark={true}>
//         <FlexGrowCol
//           alignItems="stretch"
//           justifyContent="flex-start"
//           marginY={2}
//         >
//           <CoinUserProvider>
//             <SweepstakesSummaryProvider>
//               <UserStatsContextProvider>
//                 <CoinDashboard />
//               </UserStatsContextProvider>
//             </SweepstakesSummaryProvider>
//           </CoinUserProvider>
//         </FlexGrowCol>
//       </InvertableThemeProvider>
//     </Box>
//   )
// }

export const localJSONStore = (key) => {
  const to = (value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (e) {}
  }
  const from = () => {
    try {
      const data = localStorage.getItem(key)
      return JSON.parse(data)
    } catch (e) {
      return null
    }
  }
  const rm = () => {
    try {
      localStorage.removeItem(key)
    } catch (e) {}
  }
  const saveCall = (fn) => (param) => {
    to(param)
    return fn(param)
  }
  return { saveCall, from, to, rm }
}
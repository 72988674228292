import { useCallback, FC, SVGProps } from 'react'
import { swalRender } from '../Alert'
import { withRouter } from 'react-router-dom'
// import Bolt from './Bolt'
// import Crown from './Crown'
// import Profile from './Profile'
// import Diamond from './Diamond'
import bolt from './bolt.png'
import crown from './crown.png'
import profile from './profile.png'
import diamond from './diamond.png'

const stopPropagation = (ev: any) => {
  if (ev) ev.stopPropagation()
}

export const RenderPlanModal = withRouter(({ history, children }) => {
  const renderPlanModal = useCallback(() => {
    if (window.innerWidth < 992) {
      history.push('/plans')
    } else {
      swalRender(<Plans />, {
        customClass: {
          popup: 'bg-transparent w-100 p-0',
        },
      })
    }
  }, [history])

  return <span onClick={renderPlanModal}>{children}</span>
})

const Plans = ({ onClose }: any) => {
  return (
    <div className="container-fluid text-dark" onClick={onClose}>
      <div className="row pt-3 pb-5 pl-lg-5">
        <div className="col-12 col-lg-3 d-flex align-items-center mr-lg-n4">
          <div
            className="card bg-light shadow shadow-lg-sm h-lg-50 w-100 my-2 flex-column"
            style={{ zIndex: 1 }}
            onClick={stopPropagation}
          >
            <div className="card-header">
              <h3 className="card-title text-primary-dark">COIN Basic</h3>
              <h6 className="card-subtitle mb-4 text-muted">Core Features</h6>
              <h2 className="card-subtitle text-primary-dark font-weight-bold mb-2">
                Free
              </h2>
            </div>
            <div className="card-body">
              <p className="card-text" />
              <ul>
                <li>1x Geomining Speed</li>
                <li>1x Recharge Rate</li>
                <li>1x Likelihood of Big Geomines</li>
                <li>12x Geomining Rewards (requires SentinelX)</li>
                <li>No Additional Geomining Rewards</li>
                <li>Auto Collect</li>
                <li>Geodropping</li>
              </ul>
              <p />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5 col-xl-6 mx-lg-n2">
          <div
            className="card bg-light shadow shadow-lg-lg h-lg-75 w-100 my-2 flex-column"
            style={{ zIndex: 2 }}
            onClick={stopPropagation}
          >
            <div className="card-header d-flex justify-content-between align-items-start">
              <span>
                <h3 className="card-title text-primary-dark">COIN Pro</h3>
                <h6 className="card-subtitle mb-4 text-muted">
                  Includes all COIN Basic Features
                </h6>
                <h3 className="card-subtitle text-primary-dark font-weight-bold mb-2">
                  $ 34<small>.95/month</small>
                </h3>
              </span>
              <span className="badge-pill badge-success py-2 px-2 d-none d-lg-block">
                Most Popular!
              </span>
            </div>
            <div className="card-body">
              <p className="card-text" />
              <ul>
                <li>3x Geomining Speed</li>
                <li>3x Recharge Rate</li>
                <li>3x More Big Geomines</li>
                <li>12x Geomining Rewards (with or without SentinelX)</li>
                <li>3x Additional Geomining Rewards</li>
                <li>3x Bonus Drop Power</li>
                <li>3x Background Rewards</li>
                <li>Geoclaim Shield &amp; Bonus</li>
                <li>Auto Collect</li>
                <li>Geodropping</li>
                <li>+ More</li>
              </ul>
              <p />
              <h5 className="font-weight-bold mb-4 mt-5 d-none d-lg-block" />
              <p className="d-none d-lg-block mb-5">
                COIN Pro is the perfect plan for frequent geominers who want to
                get more out of their COIN experience.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 col-xl-3 d-flex align-items-center ml-lg-n4">
          <div
            className="card bg-light shadow shadow-lg-sm h-md-50 w-100 my-2 flex-column"
            onClick={stopPropagation}
            style={{ zIndex: 1 }}
          >
            <div className="card-header">
              <h3 className="card-title text-primary-dark">COIN Plus</h3>
              <h6 className="card-subtitle mb-4 text-muted">
                Includes all COIN Basic Features
              </h6>
              <h2 className="card-subtitle text-primary-dark font-weight-bold mb-2">
                $ 24<small>.95/month</small>
              </h2>
            </div>
            <div className="card-body">
              <p className="card-text" />
              <ul>
                <li>2x Geomining Speed</li>
                <li>2x Recharge Rate</li>
                <li>2x More Big Geomines</li>
                <li>12x Geomining Rewards (with or without SentinelX)</li>
                <li>2x Additional Geomining Rewards</li>
                <li>2x Bonus Drop Power</li>
                <li>2x Background Rewards</li>
                <li>Geoclaim Shield</li>
                <li>Auto Collect</li>
                <li>Geodropping</li>
                <li>+ More</li>
              </ul>
              <p />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Plans

export const PlanIcon: FC<{ plan: string } & SVGProps<any>> = ({
  plan,
  ...props
}) => {
  switch ((plan || '').toLowerCase()) {
    case 'master':
    case 'Master':
      // return <Bolt {...props} />
      return <img src={bolt} {...props} />
    case 'plus':
    case 'Plus':
      // return <Crown {...props} />
      return <img src={crown} {...props} />
    case 'pro':
    case 'Pro':
      // return <Diamond {...props} />
      return <img src={diamond} {...props} />
    default:
      // return <Profile {...props} />
      return <img src={profile} {...props} />
  }
}

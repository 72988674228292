import { useCallback, useEffect, useState, useMemo } from 'react'
import ca from './action'

export const defaultState = {
  loading: true,
  data: undefined,
  error: '',
}

const setLoading = ca('SET_LOADING')
const setError = ca('SET_ERROR')
const setData = ca('SET_DATA')
const setTrue = ca('SET_TRUE')
const setFalse = ca('SET_FALSE')
const toggle = ca('TOGGLE')

export const requestsReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case setLoading.type:
      return { loading: !!payload, error: '', data: undefined }
    case setError.type:
      return { loading: false, error: payload, data: undefined }
    case setData.type:
      return { loading: false, error: '', data: payload }
    default:
      return state
  }
}

export const booleanReducer = (state = false, { type }) => {
  switch (type) {
    case setTrue.type:
      return true
    case setFalse.type:
      return false
    case toggle.type:
      return !state
    default:
      return state
  }
}

export const useAsyncControls = (initial = false) => {
  const [state, setState] = useState(initial)
  return useMemo(() => {
    const setData = (data) =>
      setState((v) => ({ loading: false, error: '', data }))
    const setError = (error) =>
      setState((v) => ({ loading: false, data: undefined, error }))
    const setLoading = (loading) =>
      setState((v) => ({ error: '', data: undefined, loading: !!loading }))
    return [state, { setData, setError, setLoading }]
  }, [state])
}

export const useBooleanControls = (initial = false) => {
  const [state, setState] = useState(initial)
  const setTrue = () => setState(true)
  const setFalse = () => setState(false)
  const toggle = () => setState((v) => !v)
  return [state, { setTrue, setFalse, toggle }]
}

export const useAsyncMethod = (fn, initial = { loading: false }) => {
  const [state, controls] = useAsyncControls(initial)
  const method = useCallback(
    async (...args) => {
      try {
        controls.setLoading(true)
        const data = await fn(...args)
        controls.setData(data)
      } catch (e) {
        controls.setError(e.message)
      }
    },
    [fn],
  )
  return [state, method]
}

export const useAsyncEffect = (fn) => {
  const [state, method] = useAsyncMethod(fn, { loading: true })
  useEffect(() => {
    method()
  }, [])
  return state
}

import { numberFormat } from './number'
import { bbox, origin } from 'quadkeytools'
import pick from 'lodash/pick'
import flow from 'lodash/flow'

export function featureCollection(...fns) {
  return function(features) {
    return {
      type: 'FeatureCollection',
      features: (features || []).map(flow(fns)),
    }
  }
}

export function featureType(feature) {
  return {
    type: 'Feature',
    properties: {
      'marker-size': 'small',
      ...feature,
    },
    geometry: {
      type: 'Point',
      coordinates: [feature.longitude, feature.latitude],
    },
  }
}

export function quadkeyType(feature) {
  const { min, max } = bbox(feature.quadkey)
  const { lat: latitude, lng: longitude } = origin(feature.quadkey)
  return {
    type: 'Feature',
    properties: {
      latitude,
      longitude,
      ...feature,
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [min.lng, max.lat],
          [max.lng, max.lat],
          [max.lng, min.lat],
          [min.lng, min.lat],
          [min.lng, max.lat],
        ],
      ],
    },
  }
}

export function collectionPopupHtml(feature) {
  const v = feature.properties
  return `
    <div class='my-2' style='max-width: 400px'>
      <h6>Collection</h6>
      ${
        v.suspended
          ? `<p class="mb-0 text-danger text-capitalize">${v.suspended}</p>`
          : ''
      }
      ${dd('Amount', `${v.minedAmount} ${v.asset}`)}
      ${dd(
        'Maphash',
        `${v.fullMaphash} <strong>Accuracy:</strong> ${numberFormat(
          v.accuracy,
        )}`,
      )}
      ${dd('Tier', `${v.payoutCollection} / ${v.payoutTier}`)}
      ${dd('Mob Count', v.mob)}
      ${dd('Speed', numberFormat(v.speed))}
      ${dd('Altitude', numberFormat(v.altitude))}
      ${dd('Direction', numberFormat(v.direction))}
      ${dd('Timestamp', v.lastMined)}
    </div>
  `
}

export function claimPopupHtml(feature) {
  const v = feature.properties
  return `
    <div class='my-2' style='max-width: 400px'>
      <h6>Claim</h6>
      ${dd('Quadkey', v.quadkey)}
      ${dd('Amount', v.amount || v.paid)}
      ${dd('Earned', v.amount)}
    </div>
  `
}

export function dropPopupHtml(name, feature) {
  const v = feature.properties
  return `
    <div class='my-2' style='max-width: 400px'>
      <h6>Drop ${name}</h6>
      ${dd('Amount', `${v.amount} ${v.asset}`)}
      ${dd('Dropped At', v.dropTime)}
      ${dd('Maphash', v.quadkey)}
      ${dd('Email', v.email)}
      ${dd('Message', v.message)}
      ${dd('Collected', v.collected)}
      ${dd('Collected At', v.collectedTime)}
      ${dd('Receipt Message', v.receiptMessage)}
    </div>
  `
}

function dd(title, value) {
  if (typeof value === 'function') {
    return dd(title, value())
  }
  if (!value || value === 'null') return ''
  return `<p class='mb-0'><strong>${title}:</strong> ${value}</p>`
}

export function toFeatures(data, cb = v => v) {
  return (data || []).reduce(
    (acc, c) => {
      if (!acc[c.asset || c.coinType])
        acc[c.asset || c.coinType] = featureCollection([])
      acc[c.asset || c.coinType].features.push(
        featureType({
          ...c,
          ...cb(c),
        }),
      )
      return acc
    },
    {
      xyo: featureCollection([]),
    },
  )
}

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { useRef } from 'react'
import { View } from '../components/Footer'
import {
  SweepstakesHistoryDrawer,
  SweepstakesHistoryFab,
  SweepstakesProvider,
  SweepstakesSize,
} from '../components/Sweepstakes'
import { EntryHisory } from '../components/Sweepstakes/EntryHistory'
import { EntryDialog } from '../components/Sweepstakes/Dialog'

const Sweepstakes = () => {
  const form = useRef(null)
  return (
    <SweepstakesProvider>
      <SweepstakesSize.Provider initial="lg">
        <View>
          <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8 px-0">
            <Paper>
              <Box px={2} py={1}>
                <Typography variant="h6">Sweepstakes History</Typography>
              </Box>
              <Divider />
              <List>
                <EntryHisory />
              </List>
            </Paper>
          </div>
        </View>
      </SweepstakesSize.Provider>
      <SweepstakesHistoryDrawer />
      <SweepstakesHistoryFab
        style={{ position: 'fixed', top: '50%', right: 0 }}
      />
      <EntryDialog />
    </SweepstakesProvider>
  )
}

export default Sweepstakes

import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import fetch from 'isomorphic-fetch'
import resolvers from './resolvers'

export const cache = new InMemoryCache()

export default new ApolloClient({
  cache,
  fetch,
  resolvers,
})

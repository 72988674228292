import times from 'lodash/times'

export function popParticles(
  particles: { [content: string]: number },
  x = 0,
  y = 0,
) {
  const enabled = Boolean(document.body.animate)
  if (enabled) {
    return Promise.all(
      Object.keys(particles).map(content =>
        Promise.all(
          times(Math.min(particles[content], 10)).map(() =>
            createParticle(content, x, y),
          ),
        ),
      ),
    )
  }
  return Promise.resolve([])
}

export function createParticle(content = '', x = 0, y = 0) {
  return new Promise(res => {
    // Create a custom particle element
    const particle = document.createElement('particle')
    // Append the element into the body
    document.body.appendChild(particle)
    particle.innerHTML = content

    const size = Math.floor(Math.random() * 40 + 5)
    particle.style.fontSize = `${size}px`
    // Generate a random color in a blue/purple palette
    // particle.style.background = `hsl(${Math.random() * 90 + 180}, 70%, 60%)`

    // Generate a random x & y destination within a distance of 75px from the mouse
    const destinationX = x + (Math.random() - 0.5) * 2 * 175
    const destinationY = y + (Math.random() - 0.5) * 2 * 175

    // Store the animation in a variable because we will need it later
    const animation = particle.animate(
      [
        {
          // Set the origin position of the particle
          // We offset the particle with half its size to center it around the mouse
          transform: `translate(${x - size / 2}px, ${y - size / 2}px)`,
          opacity: 1,
        },
        {
          // We define the final coordinates as the second keyframe
          transform: `translate(${destinationX}px, ${destinationY}px)`,
          opacity: 0,
        },
      ],
      {
        // Set a random duration from 500 to 1500ms
        duration: 500 + Math.random() * 1000,
        easing: 'cubic-bezier(0, .9, .57, 1)',
        // Delay every particle with a random value from 0ms to 200ms
        delay: Math.random() * 200,
      },
    )

    animation.onfinish = () => {
      particle.remove()
      res(undefined)
    }
  })
}

import { useEffect, useState } from 'react'
import { FadeIn } from '../components/Animate'
import coinLogo from '../assets/CoinNoText.svg'
import appStore from '../assets/app-store-badge.svg'
import playStore from '../assets/get-it-on-google-play.svg'
import { verifyRedeem, BossProvider } from '../context/bossapi'
import { useSnackbar } from '../components/Snackables'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { parseError } from '../utils/error'
import { RedeemOptions } from './withdraw'

export default ({ history }) => {
  const [isDesktop, setIsDesktop] = useState(false)
  const [snack, { setSnackbar }] = useSnackbar()
  useEffect(() => {
    setSnackbar({
      loading: true,
      severity: 'info',
      message: 'Verifying redeem request',
    })
    verifyRedeem()
      .then(() => {
        setSnackbar({
          severity: 'success',
          message: (
            <>
              <AlertTitle>Redeem Verified</AlertTitle>You have successfully
              verified your redeem request and it should be processed within 7
              business days. If you would like to cancel it, you can do so
              through the app anytime before it has finished processing.
            </>
          ),
          autoHideDuration: 10000,
        })
        history.push('/redeem')
      })
      .catch((err) => {
        console.log(err)
        setSnackbar({
          severity: 'error',
          message: parseError(err),
          autoHideDuration: 6000,
        })
        history.push('/redeem')
      })
  }, [])
  return (
    <div className="container-fluid text-white pt-2 pb-40vh">
      <BossProvider>
        <RedeemOptions />
      </BossProvider>
      <div className="row mt-5">
        <div className="col-12 text-center mb-5">
          <img style={{ height: 120 }} alt="COIN Logo" src={coinLogo} />
        </div>
        <div className="col-12 text-center mt-4">
          <h1 style={{ fontWeight: 100 }}>GET PAID TO EXPLORE THE WORLD!</h1>
        </div>
        <div className="col-12 offset-lg-4 offset-md-3 offset-sm-2 col-md-3 col-sm-4 col-lg-2 text-center mt-4">
          <a href="https://itunes.apple.com/app/id1450443351">
            <img
              style={{ height: 50 }}
              alt="Available on the AppStore"
              src={appStore}
            />
          </a>
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-2 text-center mt-4">
          <a href="https://play.google.com/store/apps/details?id=network.xyo.coin">
            <img
              style={{ height: 50 }}
              alt="Get it on Google Play"
              src={playStore}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

import { useState } from 'react'
import { BossProvider } from '../context/bossapi'
import { UserInfoDisplay } from '../components/User'
import { useQuery, useMutation } from 'react-apollo-hooks'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import startCase from 'lodash/startCase'
import get from 'lodash/fp/get'
import flow from 'lodash/flow'
import split from 'lodash/fp/split'
import replace from 'lodash/fp/replace'
import first from 'lodash/first'
import gql from 'graphql-tag'
import { useUser } from '../context/user'
import { parseError } from '../utils/error'
import { LinearProgress } from '@material-ui/core'
import {
  useSetAlertError,
  useSetAlertSuccess,
} from '@xyo-network/coin-forms/build/lib/components'

export default () => {
  return (
    <BossProvider>
      <ReferralsPage />
    </BossProvider>
  )
}

const emailRegEx = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/
const getReferredBy = get('referredBy')
const getEmail = get('email')
const getType = get('type')
const getGift = get('gift')
const getReferredByType = flow(
  getType,
  replace('s2s_', '_'),
  split('='),
  first,
  startCase,
)

const ReferralsPage = () => {
  const { user } = useUser()
  const { data, loading, error, refetch } = useQuery(REFERRED_BY)
  const [updating, setUpdating] = useState(false)
  const [updateError, setUpdateError] = useState('')
  const [referrerEmail, setReferrerEmail] = useState('')
  const setAlertError = useSetAlertError()
  const setAlertSuccess = useSetAlertSuccess()
  const [setReferrer] = useMutation(SET_REFERRER)
  const referredBy = getReferredBy(data)
  const referredByEmail = getEmail(referredBy)
  const referredByType = getReferredByType(referredBy)
  const referredByGift = getGift(referredBy)
  const hasReferredByEmail = Boolean(referredByEmail)
  const hasReferredByType = Boolean(referredByType)
  const hasReferredByGift = referredByGift > 200
  const handleChange = (ev) => {
    const value = ev.target.value
    setReferrerEmail(ev.target.value)
    if (emailRegEx.test(referrerEmail)) {
      setUpdateError('')
    }
  }
  const handleSubmit = async (ev) => {
    ev.preventDefault()
    try {
      setUpdateError('')
      if (hasReferredByEmail) {
        throw new Error('Referrer can only be set once.')
      }
      if (referredByType && referredByType.indexOf(/organic/i) === -1) {
        throw new Error('Not eligible to set referrer.')
      }
      if (hasReferredByGift) {
        throw new Error('Already received a referral gift.')
      }
      if (user && user.email === referrerEmail) {
        throw new Error('You can not be your own referrer.')
      }
      if (!emailRegEx.test(referrerEmail)) {
        throw new Error('Please enter a valid email')
      }
      setUpdating(true)
      await setReferrer({ variables: { email: referrerEmail } })
      await refetch({ variables: {} })
      setAlertSuccess(`Your referrer was succefully set as ${referrerEmail}`)
    } catch (e) {
      setAlertError(e.message)
      setUpdateError(e.message)
    }

    setUpdating(false)
  }
  console.log(data)
  return (
    <div className="container pt-2 pb-40vh text-white text-center">
      <h4 className="mb-5">Referred By</h4>
      {loading ? (
        <CircularProgress />
      ) : (
        <div className="row pt-1">
          <div className="col-12 offset-sm-2 col-sm-8 offset-md-4 col-md-4">
            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                label={
                  <div className="d-flex align-items-center justify-content-center">
                    Referring email
                  </div>
                }
                value={referredByEmail || referredByType || referrerEmail}
                onChange={handleChange}
                placeholder="Enter email"
                className="mb-4"
                error={Boolean(updateError)}
                helperText={parseError(updateError)}
                InputProps={{
                  disabled: hasReferredByType || hasReferredByEmail || updating,
                }}
                fullWidth
              ></TextField>
              <Button
                variant="outlined"
                type="submit"
                disabled={updating}
                fullWidth
              >
                {updating && (
                  <LinearProgress
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                    }}
                  />
                )}
                Update Referrer
              </Button>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

const REFERRED_BY = gql`
  query ReferredBy {
    referredBy {
      uid
      type
      gift
      email
    }
  }
`

const SET_REFERRER = gql`
  mutation SetReferrer($email: String!) {
    setReferrer(email: $email) {
      uid
      type
      gift
      email
    }
  }
`

import { useEffect, useState, useContext } from 'react'
import { RequestsContext, isActiveRequest } from './'
import { useUser } from '../context/user'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { fromNanoseconds, addDays } from '../utils/date'
import { useMySubscription } from '../components/Subscriptions/mySubscription'
import {
  UpgradePlanToPro,
  UpgradePlanToMaster,
} from '../components/Subscriptions'
import { UserInfoDisplay } from '../components/User'
import marked from 'marked'
import cx from 'classnames'
import { CheckoutProvider } from '../context/checkout'

export const MasterUpgradeSteps = () => {
  const { user } = useUser()
  const { subscription, activeSubscription, userInfo } = useMySubscription()
  const [expanded, setExpanded] = useState(null)
  if (!userInfo) return null
  const created = fromNanoseconds(userInfo.created)
  const threeMonths = addDays(created, 90)
  const steps = [
    {
      completed:
        activeSubscription === 'Pro' || activeSubscription === 'Master',
      text: 'Account has activated COIN Pro',
      more:
        activeSubscription === 'Pro' ? (
          ``
        ) : activeSubscription === 'Plus' ? (
          <UpgradePlanToPro />
        ) : (
          ''
        ),
    },
    {
      completed: Date.now() > threeMonths.valueOf(),
      text: 'COIN Pro active for minimum of 3 months',
      more: `Account ${
        user.email
      }, was created on ${created.toLocaleDateString()}.`,
    },
    {
      completed: Date.now() > threeMonths.valueOf(),
      text: 'Account upgraded to COIN Master',
      more: `Account ${
        user.email
      }, was created on ${created.toLocaleDateString()}.`,
    },
  ]
  const hasSteps = steps.some(({ completed }) => !completed)
  return (
    <div className="container my-5">
      <UserInfoDisplay />
      <div className="mt-5">
        {hasSteps && <h6>Unlock COIN Master</h6>}
        {steps.map(({ completed, text, more }, i) => (
          <ExpansionPanel
            key={text}
            expanded={more && expanded === i}
            onClick={() => more && setExpanded(expanded === i ? null : i)}
          >
            <ExpansionPanelSummary expandIcon={more && <ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                <i
                  className={cx('fa', {
                    'fa-check text-success mr-1': completed,
                    'fa-times text-danger mr-1': !completed,
                  })}
                />
                {text}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>{more}</ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </div>
    </div>
  )
}

export default () => {
  return (
    <CheckoutProvider>
      <MasterUpgradeSteps />
    </CheckoutProvider>
  )
}

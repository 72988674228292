import React from 'react'
import particles from './particles'
import { hawk, useHawkState, dispatch } from 'react-hawk'
import once from 'lodash/once'
import cx from 'classnames'

const id = 'particles-js'
const particlesVisibleHawk = hawk({
  id,
  default: false
})
const useParticlesVisible = () => useHawkState(particlesVisibleHawk)
const setParticlesVisible = async (visible = false) => {
  if (visible) await loadParticlesOnce()
  dispatch(particlesVisibleHawk, visible)
}

export const Particles = () => {
  const visible = useParticlesVisible()
  return (
    <div
      className={cx('particles', { 'particles-visible': visible })}
      id={id}
    />
  )
}

export default Particles

window.setParticlesVisible = setParticlesVisible

const loadParticlesOnce = once(loadParticles)
function loadParticles () {
  return loadScript(`${process.env.PUBLIC_URL}/js/particles.min.js`)
  .then(src => window.particlesJS(id, particles))
}

function loadScript(src) {
  return new Promise((res, rej) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => res()
    script.onerror = e => rej()
    document.body.appendChild(script)
  })
}


import { useState } from 'react'
import { withRouter } from 'react-router-dom'
import Modal, { ModalContent } from '../components/Modal'
import { UserInfoDisplay } from '../components/User'
import {
  RequestsProvider,
  ActiveRequests,
  HistoricalRequests,
} from '../components/Requests'
import { FadeIn } from '../components/Animate'
import { GoToWithdraw } from '../components/GoTo'
import { swalSuccess } from '../components/Alert'
import Balances, { isActiveAsset } from '../components/Balances'

const SUCCESS_MSG =
  'Thank you for providing your wallet address. Our team is now processing your request. You should see the transfer go through within 2 business days and receive an email confirmation when it does.'

export default withRouter(({ match }) => {
  const { asset } = match.params
  const [open, setModalOpen] = useState(false)
  const closeModal = () => setModalOpen(false)
  const openModal = () => setModalOpen(true)
  const onSuccess = () => {
    swalSuccess(SUCCESS_MSG)
    closeModal()
  }
  return (
    <RequestsProvider>
      <div className="container text-white pt-5 pb-40vh">
        <FadeIn>
          <div className="mb-5">
            <UserInfoDisplay />
          </div>
        </FadeIn>
        <div className="row">
          <div className="col col-md-5">
            <GoToWithdraw>
              {(onClick) => (
                <Balances
                  className="mb-5"
                  onClick={onClick}
                  isActive={isActiveAsset(asset)}
                />
              )}
            </GoToWithdraw>
          </div>
          <div className="col col-md-7">
            <FadeIn timer={400} style={{ zIndex: 2 }}>
              <ActiveRequests onSubmit={openModal} />
            </FadeIn>
            <FadeIn timer={800}>
              <HistoricalRequests />
            </FadeIn>
          </div>
        </div>
        <Modal open={open} onClose={closeModal} isScrolling>
          <ModalContent>
            <div className="modal-body text-center">
              <div className="d-flex">
                <button className="btn btn-success" onClick={closeModal}>
                  CANCEL
                </button>
                <div className="col" />
                <button className="btn btn-success" form="confirm-wallet-form">
                  CONFIRM
                </button>
              </div>
            </div>
          </ModalContent>
        </Modal>
      </div>
    </RequestsProvider>
  )
})

import { useFindSweepstakeEntryHistoryQuery } from '@coin-microservices/sweepstakes-react-hooks'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import { SweepstakesSize, SweepstakesOptionsContext } from './context'
import { pluralEntries } from './options'
import times from 'lodash/times'
import { SweepstakesDetailsAvatar } from './Details'
import TablePagination from '@material-ui/core/TablePagination'
import { useOnOpenSweestakes, SweepstakesEnterButton } from './ListItem'
import TablePaginationActions from '../Table/Pagination'
import { useScrollContext } from '../Scroll'
import React from 'react'

export const EntryHisory = () => {
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(10)
  const scrollElement = useScrollContext() as any
  const { data, loading } = useFindSweepstakeEntryHistoryQuery({
    variables: {
      sort: {
        field: 'created',
        order: -1,
      },
      pagination: {
        page,
        perPage,
      },
    },
  })
  const items = data?.findSweepstakeEntryHistory?.data
  const total = data?.findSweepstakeEntryHistory?.total ?? 0
  const changePage = (p: number) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    setPage(p)
  }
  const handleChangePage = (event: any, newPage: number) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage = (event: any) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }
  const rowCount = loading ? 10 : items?.length || 0
  return (
    <>
      {times(rowCount).map((_, i) => (
        <SweepstakesOptionsContext.Provider
          key={i}
          value={items?.[i]?.options ?? null}
        >
          <EntryHisoryListItem item={items?.[i]} loading={loading} />
        </SweepstakesOptionsContext.Provider>
      ))}
      {total ? (
        <TablePagination
          component="div"
          rowsPerPageOptions={[5]}
          count={total}
          rowsPerPage={perPage}
          page={page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        ></TablePagination>
      ) : null}
    </>
  )
}

export const EntryHisoryListItem = ({
  item,
  loading,
}: {
  item: any
  loading: boolean
}) => {
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const [size] = SweepstakesSize.useContext()
  const onOpen = useOnOpenSweestakes()
  const classes = useStyles({ size })

  const entriesText = item?.freeEntries
    ? `${item?.freeEntries} free ${pluralEntries(item?.freeEntries)}`
      ? item?.paidEntries
      : `${item?.paidEntries} paid ${pluralEntries(item?.paidEntries)}`
    : item?.entries
    ? `${item?.entries} ${pluralEntries(item?.entries)}`
    : ''

  return (
    <ListItem
      button
      disabled={loading}
      className={classes.listItem}
      onClick={onOpen}
    >
      <ListItemAvatar>
        <SweepstakesDetailsAvatar size={48} loading={loading} />
      </ListItemAvatar>
      <ListItemText
        primary={loading ? <Skeleton width={180} /> : item?.options?.title}
        secondary={
          <>
            <Typography>
              {loading ? (
                <Skeleton width={160} />
              ) : item?.created ? (
                new Date(item?.created).toLocaleString()
              ) : (
                ''
              )}
            </Typography>
            <Typography>{entriesText}</Typography>
          </>
        }
      />
      {isSmUp ? (
        <ListItemSecondaryAction>
          <SweepstakesEnterButton onClick={onOpen} loading={loading} />
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    listItem: {
      paddingRight: (props: any) => (props.size === 'lg' ? 16 : 102),
    },
  }),
)

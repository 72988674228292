import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Badge from '@material-ui/core/Badge'
import Skeleton from '@material-ui/lab/Skeleton'
import Link from '@material-ui/core/Link'
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import moment from 'moment-timezone'
import { ReactElement, useMemo, useState } from 'react'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import AlarmIcon from '@material-ui/icons/Alarm'
import RedeemIcon from '@material-ui/icons/Redeem'
import { numberFormat } from '../../utils/number'
import {
  infoUri,
  pluralEntries,
  useSweepstakesOptions,
  useSweepstakesOptionsLoading,
  useSweepstakesOptionsEnded,
  getWinner,
} from './options'
import IconButton from '@material-ui/core/IconButton'
import { PlanIcon } from '../Plans'

export const SweepstakesDetailsAvatar = ({
  size,
  ...props
}: {
  size: number
  loading?: boolean
}) => {
  const options = useSweepstakesOptions()
  const loading = useSweepstakesOptionsLoading() || props.loading
  return (
    <Box>
      {loading ? (
        <DarkSkeleton variant="circle" height={size} width={size} />
      ) : (
        <Avatar
          variant="square"
          src={options?.icon ?? ''}
          alt={`${options?.title ?? ''} - ${options?.subtitle ?? ''}`}
          imgProps={{ style: { objectFit: 'contain' } }}
          style={{ height: size, width: size }}
        >
          {(options?.item ?? '').slice(0, 2).toUpperCase()}
        </Avatar>
      )}
    </Box>
  )
}

export const useSweepstakesDetailsListItemPrimaryText = () => {
  const options = useSweepstakesOptions()
  return useMemo(() => {
    const title = options?.title ?? ''
    const subtitle = options?.subtitle ?? ''
    return `${title} ${subtitle}`
  }, [options])
}

export const useSweepstakesDetailsListItemDrawingText = () => {
  const options = useSweepstakesOptions()
  const ended = useSweepstakesOptionsEnded()
  return useMemo(() => {
    if (options?.userEntry?.winner) {
      const winner = getWinner(options)
      // Make sure confirmed before showing
      if (winner?.confirmed) {
        return (
          <Typography style={{ fontSize: '1.2em', lineHeight: '1em' }}>
            You Were The Winner!!!
          </Typography>
        )
      }
    }
    const drawingDate = options?.endDate
      ? moment(options?.endDate).fromNow()
      : ''
    if (ended) {
      return `Winner selected ${drawingDate}`
    }
    const drawing = drawingDate ? `Winner drawn ${drawingDate}` : ''
    return drawing
  }, [options, ended])
}

export const useSweepstakesDetailsListItemEntriesText = (
  alwaysShow = false,
) => {
  const options = useSweepstakesOptions()
  const ended = useSweepstakesOptionsEnded()
  return useMemo(() => {
    const entryCount = options?.userEntry?.entries ?? 0
    const entries = (
      <Tooltip
        title={
          <Typography>
            This may not include any free entries that you have.
          </Typography>
        }
      >
        <span>
          You {ended ? 'had' : 'have'} {entryCount} {pluralEntries(entryCount)}
        </span>
      </Tooltip>
    )
    if (alwaysShow) return entries
    return entryCount > 0 ? entries : ''
  }, [options, ended])
}

export const SweepstakesDetailsListItemText = () => {
  const loading = useSweepstakesOptionsLoading()
  const primary = useSweepstakesDetailsListItemPrimaryText()
  const entries = useSweepstakesDetailsListItemEntriesText()

  return loading ? (
    <ListItemText
      primary={
        <Box mb={1}>
          <DarkSkeleton variant="rect" width={110} height={18} />
        </Box>
      }
      secondary={<DarkSkeleton variant="rect" width={90} height={14} />}
    />
  ) : (
    <ListItemText
      primary={primary}
      primaryTypographyProps={{
        style: { lineHeight: '1em' },
      }}
      secondary={entries}
    />
  )
}

export const SweepstakesDetailsLargeListItemText = ({
  action,
}: {
  action?: ReactElement
}) => {
  const loading = useSweepstakesOptionsLoading()
  const primary = useSweepstakesDetailsListItemPrimaryText()
  const drawing = useSweepstakesDetailsListItemDrawingText()
  const entries = useSweepstakesDetailsListItemEntriesText(true)

  return loading ? (
    <LargeListItemText
      primary={
        <Box mb={1}>
          <DarkSkeleton variant="rect" width={110} height={18} />
        </Box>
      }
      secondary={<DarkSkeleton variant="rect" width={90} height={14} />}
    />
  ) : (
    <LargeListItemText
      primary={primary}
      primaryTypographyProps={{
        style: {
          lineHeight: '1em',
          fontWeight: 'bold',
          // textAlign: 'center',
        },
      }}
      secondary={
        <>
          <Typography>{drawing}</Typography>
          <Typography color="secondary">
            <strong>{entries}</strong> {action}
          </Typography>
        </>
      }
    />
  )
}

export const SweepstakesDetailsHeader = () => {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      px={2}
      py={1}
      pr={6}
    >
      <ListItemAvatar>
        <SweepstakesDetailsAvatar size={40} />
      </ListItemAvatar>
      <SweepstakesDetailsListItemText />
      <ListItemSecondaryAction>
        <Link href={infoUri} target="_blank" rel="noreferrer nofollow">
          <InfoIcon color="action" />
        </Link>
      </ListItemSecondaryAction>
    </Box>
  )
}

export const SweepstakesPrizeDetailsLoading = () => {
  return (
    <Box mb={2}>
      <Box mt={2}>
        <DarkSkeleton variant="rect" width="100%" height={56} />
      </Box>
      <Box mt={2}>
        <DarkSkeleton variant="rect" width="100%" height={56} />
      </Box>
    </Box>
  )
}

export const SweepstakesPrizeDetails = () => {
  const classes = useStyles()
  const options = useSweepstakesOptions()
  const loading = useSweepstakesOptionsLoading()
  const [qtyStr, dateStr, dateTz] = useMemo(() => {
    const qtyStr =
      options?.quantity === 1 ? 1 : numberFormat(options?.quantity ?? 0)
    const end = moment(options?.endDate)
    const dateStr = end.format('MMMM Do YYYY, h:mm:ss a')
    const tz = moment.tz
    if (!tz) return [dateStr, '']
    const dateTz = tz
      ?.zone(tz.guess() ?? '')
      ?.abbr(new Date().getTimezoneOffset())
    return [qtyStr, dateStr, dateTz]
  }, [options?.endDate])

  if (loading) return <SweepstakesPrizeDetailsLoading />

  return (
    <>
      <TextField
        fullWidth
        disabled
        // label="Prize"
        label={
          <Box component="span" display="flex" alignItems="center">
            <RedeemIcon scale={0.5} style={{ paddingRight: 4 }} /> Prize
          </Box>
        }
        margin="normal"
        variant="outlined"
        value={qtyStr}
        InputProps={{
          className: classes.input,
          endAdornment: (
            <Typography style={{ whiteSpace: 'nowrap' }}>
              {options?.item ?? ''}
            </Typography>
          ),
        }}
      />
      <TextField
        fullWidth
        disabled
        // label="Drawing"
        label={
          <Box component="span" display="flex" alignItems="center">
            <AlarmIcon scale={0.5} style={{ paddingRight: 4 }} /> Drawing
          </Box>
        }
        margin="normal"
        variant="outlined"
        value={dateStr}
        classes={{}}
        InputProps={{
          className: classes.input,
          endAdornment: (
            <Typography style={{ whiteSpace: 'nowrap' }}>{dateTz}</Typography>
          ),
        }}
      />
    </>
  )
}

export const SweepstakesWinnerDetails = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const options = useSweepstakesOptions()
  const {
    username,
    profileImage,
    daysOld,
    videoUrl,
    plan,
    entries,
    blockhash,
    blockhashShort,
  } = useMemo(() => {
    const winner = getWinner(options)
    const blockhashShort = options?.blockhash
      ? `${options.blockhash.slice(0, 10)}...${options.blockhash.slice(-10)}`
      : ''
    return {
      username: winner?.username ?? ' ',
      profileImage: winner?.profileImage ?? ' ',
      daysOld: winner?.daysOld ?? 0,
      videoUrl: winner?.videoUrl ?? '',
      plan: winner?.plan ?? ' ',
      entries: winner?.entries ?? 0,
      blockhash: options?.blockhash ?? '',
      blockhashShort,
    }
  }, [options])
  return (
    <Box>
      <Box display="flex" flexWrap="nowrap" pr={1}>
        <Box px={1} pb={2}>
          <Badge
            color="secondary"
            badgeContent={
              <Tooltip title={<Typography>{plan}</Typography>}>
                <PlanIcon plan={plan} height={24} />
              </Tooltip>
            }
            classes={{ badge: 'winner-badge-style' }}
            // overlap="circle"
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
          >
            <Avatar
              variant="rounded"
              src={profileImage}
              style={{ height: 100, width: 100 }}
            />
          </Badge>
        </Box>
        <Grid container spacing={1}>
          <Grid xs={12} item>
            <TextField
              fullWidth
              disabled
              size="small"
              label="Username"
              variant="outlined"
              value={username}
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              fullWidth
              disabled
              size="small"
              label="Days Old"
              variant="outlined"
              value={daysOld}
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              fullWidth
              disabled
              size="small"
              label="Entries"
              variant="outlined"
              value={entries}
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box px={1}>
        <TextField
          fullWidth
          disabled
          size="small"
          label="Blockhash"
          variant="outlined"
          margin="normal"
          value={blockhashShort}
          onClick={(ev) => {
            window.open(`https://etherscan.io/block/${blockhash}`)
          }}
          InputProps={{
            className: classes.input,
            endAdornment: (
              <IconButton
                style={{ color: '#a8c7e0' }}
                onClick={(ev) => {
                  ev.stopPropagation()
                  setAnchorEl(ev.target)
                }}
              >
                <InfoIcon />
              </IconButton>
            ),
          }}
        />
        {videoUrl ? (
          <Box
            position="relative"
            width="100%"
            paddingTop="56.5%"
            overflow="hidden"
            borderRadius={4}
            mt={1}
          >
            <iframe
              width="100%"
              height="100%"
              style={{ position: 'absolute', top: 0, left: 0 }}
              src={
                videoUrl.indexOf('embed') === -1
                  ? `https://www.youtube.com/embed/${
                      (videoUrl.split('?v=')[1] || '').split('&')[0]
                    }`
                  : videoUrl
              }
              title="Winner video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Box>
        ) : null}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          style={{ whiteSpace: 'normal', maxWidth: 300 }}
          onClick={() => window.open(`https://etherscan.io/block/${blockhash}`)}
        >
          <Typography>
            The current Ethereum block hash at the time of drawing the
            sweepstakes is used as the seed for the random number generator that
            selects the winning entry.
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}

const DarkSkeleton = withStyles({
  root: {
    backgroundColor: `rgba(0, 0, 0, 0.11)`,
  },
})(Skeleton)

const LargeListItemText = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      flex: 'initial',
    },
  },
  primary: {
    [theme.breakpoints.up('md')]: {
      fontSize: '1.6em',
    },
  },
}))(ListItemText)

const useStyles = makeStyles((theme) =>
  createStyles({
    input: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
      },
      color:
        theme.palette.type === 'dark'
          ? 'rgba(255, 255, 255, 0.8)!important'
          : 'rgba(0, 0, 0, 0.8)!important',
    },
  }),
)

import Checkbox from '@material-ui/core/Checkbox'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { PasswordRequiredDialog } from '../components/Alert/PasswordRequired'
import DialogTitle from '@material-ui/core/DialogTitle'
import Link from '@material-ui/core/Link'
import Menu from '@material-ui/core/Menu'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import firebase from 'firebase/app'
import 'firebase/auth'
import { useState, useRef } from 'react'
import { swalSuccess } from '../components/Alert'
import { FadeIn } from '../components/Animate'
import { UserInfoDisplay } from '../components/User'
import { BossProvider } from '../context/bossapi'
import { useMutation } from 'react-apollo-hooks'
import { useUser, useUserInfo } from '../context/user'
import gql from 'graphql-tag'
// import { trackEvent } from '../utils/events'
import { gtagCategoricalEvent } from '../utils/gtag'

export default () => {
  return (
    <BossProvider>
      <AccountDeletePage />
    </BossProvider>
  )
}

const accountDeleteEvent = gtagCategoricalEvent('account-delete')

export const AccountDeletePage = ({}) => {
  const { user } = useUser()
  const { userInfo } = useUserInfo()
  const xyoCollected = userInfo?.xyoCollected ?? 0
  const activeSubscription = userInfo?.activeSubscription ?? 'Basic'
  const [checked, setChecked] = useState(false)
  const [confirmEmail, setConfirmEmail] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [error, setError] = useState('')
  const [deleteUser] = useMutation(DELETE_USER)
  // const disabled = !checked || activeSubscription !== 'Basic'
  const disabledReason =
    activeSubscription !== 'Basic'
      ? 'All subscriptions must be canceled before account deletion.'
      : !checked
      ? 'Must accept COIN loss terms'
      : ''
  const trackContactSupport = () => {
    accountDeleteEvent('support', activeSubscription, {
      value: xyoCollected,
    })
  }
  const trackShowReason = () => {
    accountDeleteEvent('disabled-reason', activeSubscription, {
      value: disabledReason,
    })
  }
  const openConfirmation = () => {
    setConfirmEmail(user.email)
    accountDeleteEvent('confirm', activeSubscription, {
      value: xyoCollected,
    })
  }
  const confirmDeleteUser = async (ev) => {
    if (ev) ev.preventDefault()
    try {
      setError('')
      setDeleting(true)
      await deleteUser()
      accountDeleteEvent('success', activeSubscription, {
        value: xyoCollected,
      })
      await swalSuccess('Your account was successfully deleted.')
      await firebase.auth().signOut()
    } catch (e) {
      console.log(e)
      accountDeleteEvent('error', activeSubscription, {
        value: e.message,
      })
      setError(e.message)
    }
    setDeleting(false)
  }
  return (
    <div className="container pt-5 pb-40vh">
      {/* <AlertError error={data.error} /> */}
      <FadeIn>
        <div
          className="mb-5 text-white pb-3"
          style={{ borderBottom: '2px dashed' }}
        >
          <UserInfoDisplay />
        </div>
        <div className="text-center">
          <Typography gutterBottom variant="h5">
            By deleting your account you are agreeing to the following
          </Typography>
          <Typography gutterBottom variant="h6">
            1. All rewards will be permanently lost and unrecoverable.
          </Typography>
          {activeSubscription !== 'Basic' ? (
            <>
              <Typography gutterBottom variant="h6" color="error">
                2. All subscriptions must be canceled before account deletion.
              </Typography>
              <Link
                target="_black"
                href={process.env.REACT_APP_SUPPORT_URI}
                onClick={trackContactSupport}
              >
                Contact Support
              </Link>
              <div />
            </>
          ) : null}
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(ev, value) => setChecked(value)}
                  name="checkedB"
                  color="primary"
                />
              }
              label={
                xyoCollected
                  ? `I agree to lose ${xyoCollected.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })} COIN`
                  : 'I agree to terms'
              }
            />
          </div>
          <DisabledReasonButton
            disabledReason={deleting ? 'Delete in progress' : disabledReason}
            onClick={openConfirmation}
            onEnter={trackShowReason}
          >
            Delete Account
          </DisabledReasonButton>
        </div>
      </FadeIn>
      <PasswordRequiredDialog
        title="Confirm Account Deletion"
        content="All rewards will be permanently lost and unrecoverable."
        open={Boolean(confirmEmail)}
        onClose={() => setConfirmEmail('')}
        email={confirmEmail}
        onConfirmed={() => {
          setConfirmEmail('')
          confirmDeleteUser()
        }}
      />
    </div>
  )
}

const DELETE_USER = gql`
  mutation DeleteUser {
    deleteUser {
      ownerUID
    }
  }
`

const DisabledReasonButton = ({
  disabledReason,
  onClick,
  onEnter,
  children,
  ...props
}) => {
  const buttonRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(false)
  return (
    <>
      <div
        onClick={(ev) => {
          if (disabledReason) setAnchorEl(buttonRef.current)
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={!!disabledReason}
          buttonRef={buttonRef}
          onClick={(ev) => {
            ev.stopPropagation()
            onClick()
          }}
          {...props}
        >
          {children}
        </Button>
      </div>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onEnter={onEnter}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'center',
        }}
      >
        <Box px={2}>
          <Typography>{disabledReason}</Typography>
        </Box>
      </Menu>
    </>
  )
}

export const fromNanoseconds = date => {
  if (!date) return new Date()
  if (date.seconds) return new Date((date.seconds + date.nanoseconds * 1e-9) * 1000)
  if (!isNaN(Number(date))) return new Date(Number(date))
  if (typeof date === 'string') return new Date(date)
  return new Date()
}

export const toYYYYMMDD = date => {
  return Number(
    date
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, ''),
  )
}

export const fromYYYYMMDD = date => {
  const year = String(date).substring(0, 4)
  const month = String(date).substring(4, 6)
  const day = String(date).substring(6, 8)
  return new Date(year, month - 1, day)
}

export const subTime = (date, time) => {
  return new Date(date.valueOf() - time)
}

export function getCurrentCampaign() {
  const start = getCurrentCampaignStart()
  return toYYYYMMDD(start)
}

export function getPreviousCampaign() {
  const lastWeek = addDays(new Date(), -7)
  setUTCMonday(lastWeek)
  return toYYYYMMDD(lastWeek)
}

export function getCurrentCampaignStart() {
  const today = new Date()
  return setUTCMonday(today)
}

export function getCurrentCampaignEnd() {
  const nextWeek = addDays(new Date(), 7)
  return setUTCMonday(nextWeek)
}

export function addDays(date, days = 0) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate() + days,
  )
}

export function setUTCMonday(date) {
  const day = date.getUTCDay() || 7 // Get current day number, converting Sun. to 7
  if (day !== 1) {
    // Only manipulate the date if it isn't Mon.
    date.setUTCHours(-24 * (day - 1), 0, 0, 0) // Set the hours to day number minus 1
  } else {
    date.setUTCHours(0, 0, 0, 0)
  }

  return date
}

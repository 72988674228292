import React from 'react'
import Plans from '../components/Plans'
import { Link } from 'react-router-dom'

export default () => {
  return (
    <div className='pb-40vh'>
      <Plans />
      <div className='my-5 text-center'>
        <Link to='/' className='btn btn-danger btn-lg btn-pill'>Go Home</Link>
      </div>
    </div>
  )
}
import React, {
  Suspense,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react'
import { BossProvider } from '../context/bossapi'
import { useQuery } from 'react-apollo-hooks'
import { QuadkeyMap } from '../components/Map'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import groupBy from 'lodash/groupBy'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import gql from 'graphql-tag'
import { numberFormat } from '../utils/number'
import { AWSError } from '../components/Error'
import { View } from '../components/Footer'
import {
  addDays,
  toYYYYMMDD,
  fromYYYYMMDD,
  getCurrentCampaign,
} from '../utils/date'

const GeoclaimPage = React.memo(() => {
  const { campaigns, loading, error } = useCampaigns()
  const [campaign, setCampaign] = useState('')
  const handleChange = (ev) => {
    setCampaign(ev.target.value)
  }
  useEffect(() => {
    if (campaigns) {
      setCampaign(campaigns[0])
    }
  }, [campaigns])

  return (
    <View>
      <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8 px-0">
        <div className="d-flex align-items-center justify-content-between text-white px-2">
          <h1>Geoclaims</h1>
          <SelectCampaign campaign={campaign} onChange={handleChange} />
        </div>
        <div className="position-relative list-group text-dark">
          {/* <GeoclaimLeaderboard campaign={campaign} /> */}
          {error ? (
            <AWSError error={error} />
          ) : campaign ? (
            <GeoclaimList campaign={campaign} />
          ) : null}
        </div>
      </div>
    </View>
  )
})

const useCampaigns = () => {
  const { data, loading, error } = useQuery(CLAIM_CAMPAIGN_STATS)
  const campaigns = useMemo(
    () =>
      uniq(
        [getCurrentCampaign()].concat(
          (get(data, 'claimCampaignStats') || [])
            .map(({ campaign }) => campaign)
            .sort((a, b) => b - a),
        ),
      ),
    [data],
  )

  return { campaigns, loading, error }
}

const SelectCampaign = ({ campaign, onChange }) => {
  const { campaigns, loading } = useCampaigns()

  if (loading) return <div className="spinner-border text-danger" />

  return (
    <Select value={campaign} onChange={onChange}>
      {campaigns.map((campaign) => (
        <MenuItem key={campaign} value={campaign}>
          <ClaimCampaignTitle campaign={campaign} />
        </MenuItem>
      ))}
    </Select>
  )
}

const GeoclaimLeaderboard = ({ campaign }) => {
  const { data, loading } = useQuery(CLAIMS_LEADERBOARD, {
    variables: { campaign },
  })
  const claims = get(data, 'claimsLeaderboard') || []
  return (
    <>
      {loading && (
        <div className="text-center">
          <div className="spinner-border text-danger" />
        </div>
      )}
    </>
  )
}

const GeoclaimList = ({ campaign }) => {
  const { data, loading } = useQuery(CLAIMS, {
    variables: { campaign },
  })
  const claims = get(data, 'claimsByCampaign') || []
  return (
    <>
      {loading && (
        <div className="text-center">
          <div className="spinner-border text-danger" />
        </div>
      )}
      {!loading && !claims.length ? <NoGeoClaims campaign={campaign} /> : null}
      {claims.map((claim) => (
        <ClaimQuadkeyResults
          id={claim.quadkey}
          key={claim.quadkey}
          quadkey={claim.quadkey}
          paid={claim.paid}
          earned={claim.collected}
        />
      ))}
    </>
  )
}

const ClaimQuadkeyResults = ({ id, quadkey, paid, earned, style }) => {
  return (
    <div
      className="list-group-item d-flex align-items-center flex-wrap rounded-0-sm"
      style={style}
    >
      {quadkey && (
        <div
          className="quadkey-map bg-dark rounded overflow-hidden"
          style={{ height: 140, width: 140, position: 'relative' }}
        >
          <QuadkeyMap
            id={id}
            quadkey={quadkey}
            paid={paid}
            earned={earned}
            style={{ height: '100%', width: '100%' }}
          />
        </div>
      )}
      <div className="flex-grow-1 mt-3 ml-3">
        <h4 className="mb-0 nowrap">
          Tile: <small className="address">{quadkey}</small>
        </h4>
        <p className="mb-0">
          Paid: <span className="nowrap">{numberFormat(paid)}</span>
        </p>
        <p className="mb-0">
          Earned: <span className="nowrap">{numberFormat(earned)}</span>
        </p>
        <p className={paid > earned ? 'd-none' : 'd-sm-none'}>
          Gain:{' '}
          <span
            className={
              paid > earned ? 'text-danger nowrap' : 'text-success nowrap'
            }
          >
            {numberFormat(((earned - paid) / paid) * 100)}%
          </span>
        </p>
      </div>
      <div
        className={
          paid > earned ? 'd-none text-center' : 'd-sm-block d-none text-center'
        }
      >
        <p className="mb-0">Gain</p>
        <h4
          className={paid > earned ? 'text-danger mb-0' : 'text-success mb-0'}
        >
          {numberFormat(((earned - paid) / paid) * 100)}%
        </h4>
      </div>
    </div>
  )
}

const ClaimCampaignTitle = ({ campaign }) => {
  const start = fromYYYYMMDD(campaign)
  const end = addDays(start, 6)
  return (
    <span className="d-flex-inline align-items-center">
      {end.valueOf() > Date.now() ? (
        <i className="text-danger mr-1 fa fa-circle" />
      ) : (
        ''
      )}
      {start.toLocaleDateString()}
    </span>
  )
}

const NoGeoClaims = ({ campaign }) => {
  return (
    <div className="list-group-item text-center py-5 rounded-0-sm">
      <h6>
        <ClaimCampaignTitle campaign={campaign} />
      </h6>
      <h4>No Claims Found For Campaign</h4>
      <p>
        Read more about geoclaiming{' '}
        <a
          href="https://support.coinapp.co/hc/en-us/categories/360002104613"
          target="_blank"
        >
          here
        </a>
        .
      </p>
    </div>
  )
}

export default () => (
  <BossProvider>
    <GeoclaimPage />
  </BossProvider>
)

const CLAIM_CAMPAIGN_STATS = gql`
  query ClaimCampaignStats {
    claimCampaignStats {
      campaign
      userCount
      totalAmount
    }
  }
`

const CLAIMS = gql`
  query ClaimsByCampaign($campaign: Int!) {
    claimsByCampaign(campaign: $campaign) {
      quadkey
      campaign
      paid
      collected
    }
  }
`

const CLAIMS_LEADERBOARD = gql`
  query ClaimsLeaderboard($campaign: Int!) {
    claimsLeaderboard(campaign: $campaign) {
      uid
      quadkey
      campaign
      paid
      collected
    }
  }
`

import { useEffect, useState, useContext } from 'react'
import { RequestsContext, isActiveRequest } from './'
import { useUser, useUserInfo } from '../../context/user'
import Paper from '@material-ui/core/Paper'
import CardHeader from '@material-ui/core/CardHeader'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { fromNanoseconds, addDays } from '../../utils/date'
import NoRequests from './NoRequests'
import marked from 'marked'
import cx from 'classnames'

export default ({ loading, requests = [] }) => {
  // const { loading, data = [] } = useContext(RequestsContext)
  const { user } = useUser()
  const { userInfo } = useUserInfo()
  const active = requests.filter(isActiveRequest)
  if (loading || !user || !userInfo) return null
  // if (requests.length && !active.length) return <NoRequests />
  if (requests.length > 1) return null
  const created = fromNanoseconds(userInfo.created)
  const twoWeeks = addDays(created, 14)
  const mined = userInfo.xyoCollected
  const steps = [
    {
      completed: Date.now() > twoWeeks.valueOf(),
      text: 'Account is at least 2 weeks old',
      more: `Account ${
        user.email
      }, was created on ${created.toLocaleDateString()}.`,
    },
    {
      completed: mined > 10000,
      text: 'Collected at least 10,000 COIN',
      more: `You have geomined ${Number(mined || 0).toLocaleString()}!`,
    },
    {
      completed:
        userInfo.lastKnownAddress ||
        userInfo.coinbaseEmail ||
        userInfo.shipStreetAddress,
      text: 'Provided the necessary info',
      more: (
        <Typography
          dangerouslySetInnerHTML={{ __html: marked(createWalletDetails) }}
        ></Typography>
      ),
    },
    {
      completed: !!active.length,
      text: 'Created a redeem request',
      more: () => <CreateRequestDetails steps={steps} />,
    },
  ]
  // const hasSteps = steps.some(({ completed }) => !completed)
  return (
    <div className="mb-5">
      <Paper>
        <CardHeader title="Before You Redeem" />
      </Paper>
      {steps.map(({ completed, text, more }) => (
        <ExpansionPanel key={text}>
          <ExpansionPanelSummary expandIcon={more && <ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              <i
                className={cx('fa', {
                  'fa-check text-success mr-1': completed,
                  'fa-times text-danger mr-1': !completed,
                })}
              />
              {text}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {typeof more === 'function' ? more() : more}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </div>
  )
}

export const CreateRequestDetails = ({ steps }) => {
  const { user, onLogout } = useUser()
  return (
    <ol>
      <li>
        In the app, tap the assets bar where the total collected is displayed.
      </li>
      <li>
        Tap the option you are interested in, and then tap the "Redeem" button.
      </li>
      {steps && steps[0].completed && steps[1].completed ? (
        <li>
          If you submitted one through the app and are still seeing this
          message, please make sure that you are logged into the correct
          account. The email you are logged into here is{' '}
          <strong>{user && user.email}</strong>. In the app tap the top right
          account button and check to make sure you are using the same email. If
          not click{' '}
          <a className="text-danger cursor-pointer" onClick={() => onLogout()}>
            here
          </a>{' '}
          to log out and log back in with the correct email to enter your wallet
          address and get your redeem request processed.
        </li>
      ) : null}
    </ol>
  )
}
const createWalletDetails = `
  <p>If you'll be redeeming physical products you need to add your shipping information in the app by tapping <a href="https://my.coinapp.co/appLink/account">here</a> and the "Account Details" button.</p>
  <p>If you'll be redeeming for BTC or ETH you need to add your coinbase email in the app by tapping <a href="https://my.coinapp.co/appLink/account">here</a> and the "Account Details" button.</p>
  <p>If you'll be redeeming digital assets, you need to make sure you have an external wallet that can hold your assets. XYO Tokens are an ERC-20 Token, so if you want to hold XYO Tokens, then you will need to make sure your wallet is ERC-20 Compatible. We often suggest Trust Mobile Wallet or MetaMask Chrome extension for a new wallet that can hold ERC-20 Tokens. Trust is a smartphone app, and MetaMask can be used on the computer. We have extensive PDF instructions to assist you in making a wallet! Click the links below to view them!</p><p><strong><a href="https://cdn.xy.company/documents/MetaMask_Wallet_Instructions.pdf" target="_blank" rel="noopener">MetaMask Instructions</a></strong></p><p><strong><a href="https://cdn.xy.company/documents/Trust_Wallet_Instructions.pdf" target="_blank" rel="noopener">Trust Wallet Instructions</a></strong></p>`

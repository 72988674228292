import React from 'react'
import Checkout, { BuyNowButton } from '../Checkout'
import {
  getCoinFreeTrialDetails,
  getCoinPlusPlanDetails,
  getCoinProPlanDetails,
  getCoinPlusPlanFHRDetails,
} from '../Checkout/plans'
import { SignatureVerification } from '../SignatureVerification'
import { swalSuccess, swalError, swalLoader, swalRender } from '../Alert'
import { useUpgrade } from '../../store/subscriptions'
import { CancelIconButton, DangerBtn } from '../Buttons'
import { purchaseTracker } from '../../utils/events'
import Modal, { ModalContent } from '../Modal'
import { RenderPlanModal } from '../Plans'
import Checkbox from '@material-ui/core/Checkbox'
import { FormField } from '../Form'
import { checkout, CheckoutProvider } from '../../context/checkout'
import { parseError } from '../../utils/error'
import over from 'lodash/over'
import omit from 'lodash/omit'
import get from 'lodash/get'
import { VerifyProvider } from '../../context/verify'

const stopPropagation = (fn) => (ev) => {
  if (ev) ev.stopPropagation()
  fn(ev)
}

export const StartFreeTrial = ({ popover, ...props }) => {
  const [_, { confirmProFHR }] = useUpgrade()
  const trialPlan = getCoinFreeTrialDetails()
  const trialTracker = purchaseTracker(trialPlan)
  const { label, buttonText, amount, planId } = trialPlan

  const renderPopover = (buyNow) => {
    return (
      <>
        <p className="text-dark">
          {label} (
          <RenderPlanModal>
            <span className="styled-link text-underline-hover">
              More Details
            </span>
          </RenderPlanModal>
          )
        </p>
        <button className="btn btn-primary btn-block mb-1" onClick={buyNow}>
          Buy Now
        </button>
        <button className="btn btn-block" onClick={confirmProFHR}>
          FHR Member?
        </button>
        {/* <button className='btn btn-secondary btn-block mb-1' onClick={goToCheckout}>Checkout</button> */}
      </>
    )
  }

  const buyPro = () => {
    swalRender(
      <CheckoutProvider>
        <Checkout
          onSuccess={over([handleSuccess(trialPlan, trialTracker)])}
          onDisplay={() => trialTracker.click()}
          onCustomerInfoComplete={() => trialTracker.step(1)}
          onShippingInfoComplete={() => trialTracker.step(2)}
          onPaymentInfoComplete={(options) =>
            trialTracker.step(
              3,
              'is-annual',
              get(options, 'creditCardData.isAnnual') ? 1 : 0,
            )
          }
        />
      </CheckoutProvider>,
      {
        customClass: {
          popup: 'overflow-hidden',
        },
      },
    )
  }

  return (
    <BuyNowButton
      onClick={buyPro}
      renderPopover={renderPopover}
      onRenderPopover={trialTracker.view}
    >
      {props.children ||
        (() => (
          <DangerBtn onClick={popover ? null : stopPropagation(buyPro)}>
            {buttonText}
          </DangerBtn>
        ))}
    </BuyNowButton>
  )
}

export const PurchasePlus = ({ popover, ...props }) => {
  const [_, { confirmPlusFHR }] = useUpgrade()
  const plusPlan = getCoinPlusPlanDetails()
  const plusTracker = purchaseTracker(plusPlan)
  const { label, buttonText, amount, planId } = plusPlan

  const renderPopover = () => {
    return (
      <>
        <p className="text-dark">
          {label} (
          <RenderPlanModal>
            <span className="styled-link text-underline-hover">
              More Details
            </span>
          </RenderPlanModal>
          )
        </p>
        <button className="btn btn-primary btn-block mb-1" onClick={buyPlus}>
          Buy Now
        </button>
        <button className="btn btn-block" onClick={confirmPlusFHR}>
          FHR Member?
        </button>
        {/* <button className='btn btn-secondary btn-block mb-1' onClick={goToCheckout}>Checkout</button> */}
      </>
    )
  }

  const buyPlus = () => {
    swalRender(
      <CheckoutProvider>
        <Checkout
          onSuccess={over([handleSuccess(plusPlan, plusTracker)])}
          onDisplay={() => plusTracker.click()}
          onCustomerInfoComplete={() => plusTracker.step(1)}
          onShippingInfoComplete={() => plusTracker.step(2)}
          onPaymentInfoComplete={(options) =>
            plusTracker.step(
              3,
              'is-annual',
              get(options, 'creditCardData.isAnnual') ? 1 : 0,
            )
          }
        >
          <AnnualOptIn label={plusPlan.annualText} />
        </Checkout>
      </CheckoutProvider>,
      {
        customClass: {
          popup: 'overflow-hidden',
        },
      },
    )
  }

  return (
    <BuyNowButton
      onClick={buyPlus}
      renderPopover={renderPopover}
      onRenderPopover={plusTracker.view}
    >
      {props.children ||
        (() => (
          <DangerBtn onClick={popover ? null : stopPropagation(buyPlus)}>
            {buttonText}
          </DangerBtn>
        ))}
    </BuyNowButton>
  )
}

export const PurchasePro = ({ popover, ...props }) => {
  const [_, { confirmProFHR }] = useUpgrade()
  const proPlan = getCoinProPlanDetails()
  const proTracker = purchaseTracker(proPlan)
  const { label, buttonText, amount, planId } = proPlan

  const renderPopover = (buyNow) => {
    return (
      <>
        <p className="text-dark">
          {label} (
          <RenderPlanModal>
            <span className="styled-link text-underline-hover">
              More Details
            </span>
          </RenderPlanModal>
          )
        </p>
        <button className="btn btn-primary btn-block mb-1" onClick={buyNow}>
          Buy Now
        </button>
        <button className="btn btn-block" onClick={confirmProFHR}>
          FHR Member?
        </button>
        {/* <button className='btn btn-secondary btn-block mb-1' onClick={goToCheckout}>Checkout</button> */}
      </>
    )
  }

  const buyPro = () => {
    swalRender(
      <CheckoutProvider>
        <Checkout
          onSuccess={over([handleSuccess(proPlan, proTracker)])}
          onDisplay={() => proTracker.click()}
          onCustomerInfoComplete={() => proTracker.step(1)}
          onShippingInfoComplete={() => proTracker.step(2)}
          onPaymentInfoComplete={(options) =>
            proTracker.step(
              3,
              'is-annual',
              get(options, 'creditCardData.isAnnual') ? 1 : 0,
            )
          }
        >
          <AnnualOptIn label={proPlan.annualText} />
        </Checkout>
      </CheckoutProvider>,
      {
        customClass: {
          popup: 'overflow-hidden',
        },
      },
    )
  }

  return (
    <BuyNowButton
      onClick={buyPro}
      renderPopover={renderPopover}
      onRenderPopover={proTracker.view}
    >
      {props.children ||
        (() => (
          <DangerBtn onClick={popover ? null : stopPropagation(buyPro)}>
            {buttonText}
          </DangerBtn>
        ))}
    </BuyNowButton>
  )
}

export const UpgradeModal = () => {
  const [open, { closeUpgrade }] = useUpgrade()
  return (
    <Modal open={!!open} onClose={closeUpgrade} isScrolling>
      <ModalContent>
        <div className="modal-body overflow-hidden">
          <UpgradeModalContent />
        </div>
      </ModalContent>
    </Modal>
  )
}

const handleSuccess = (planDetails, tracker) => async (
  paymentCardId,
  { infoData, billing, shipping, creditCardData },
) => {
  try {
    const planId = creditCardData.isAnnual
      ? planDetails.annualPlanId
      : planDetails.planId
    const items = [{ planId, quantity: 1 }]
    swalLoader(<p>Processing Transaction...</p>)
    const transaction = await checkout(
      planId,
      paymentCardId,
      infoData,
      omit(billing, ['email', 'firstName', 'lastName']),
      omit(shipping, ['email', 'firstName', 'lastName']),
    )
    swalSuccess(planDetails.successText || 'Successfully upgraded!')
    if (tracker && tracker.purchase)
      tracker.purchase(
        get(transaction, 'data.checkout.transaction.id'),
        get(transaction, 'data.checkout.transaction.amount'),
      )
  } catch (e) {
    swalError(parseError(e.message))
  }
}

const UpgradeModalContent = () => {
  const [
    open,
    {
      buyPlus,
      buyPro,
      confirmPlusFHR,
      confirmProFHR,
      upgradePlusFHR,
      upgradeProFHR,
      buyPlusFHR,
      closeUpgrade,
    },
  ] = useUpgrade()
  const plusPlan = getCoinPlusPlanDetails()
  const proPlan = getCoinProPlanDetails()
  const purchasePlusFHRDetails = getCoinPlusPlanFHRDetails()
  const plusTracker = purchaseTracker(plusPlan)
  const proTracker = purchaseTracker(proPlan)
  const fhrTracker = purchaseTracker(purchasePlusFHRDetails)
  switch (open) {
    case confirmPlusFHR.type:
    case confirmProFHR.type:
      return (
        <>
          <div className="swal2-content">
            <div className="swal2-icon swal2-info d-flex" />
            <p>
              FHR members are eligible for 12 months of Plus for free or 12
              months of Pro for a discounted rate of{' '}
              {purchasePlusFHRDetails.amount}/mo. To get started click continue
              below to verify your FHR wallet address.{' '}
              <a
                href="https://coinappco.zendesk.com/hc/en-us/articles/360050977274"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            </p>
          </div>
          <div className="swal2-footer text-center">
            <button className="btn btn-danger" onClick={closeUpgrade}>
              Cancel
            </button>
            <button
              className="btn btn-success ml-1"
              onClick={
                open === confirmPlusFHR.type ? upgradePlusFHR : upgradeProFHR
              }
            >
              Continue
            </button>
          </div>
        </>
      )
    case upgradePlusFHR.type:
    case upgradeProFHR.type:
      return (
        <VerifyProvider>
          <div className="text-right">
            <CancelIconButton onClick={closeUpgrade} />
          </div>
          <div className="text-dark">
            <SignatureVerification
              onSuccess={closeUpgrade}
              onError={closeUpgrade}
            />
          </div>
        </VerifyProvider>
      )
    case buyPlusFHR.type:
      return (
        <Checkout
          onCancel={closeUpgrade}
          onSuccess={over([
            closeUpgrade,
            handleSuccess(purchasePlusFHRDetails, fhrTracker),
          ])}
          onDisplay={fhrTracker.view}
          onCustomerInfoComplete={() => fhrTracker.step(1)}
          onShippingInfoComplete={() => fhrTracker.step(2)}
          onPaymentInfoComplete={(options) =>
            fhrTracker.step(
              3,
              'is-annual',
              get(options, 'creditCardData.isAnnual') ? 1 : 0,
            )
          }
        />
      )
    default:
      return <></>
  }
}

const AnnualOptIn = ({ label: labelText }) => {
  return (
    <div className="d-flex align-items-center mb-3">
      <FormField
        id="isAnnual"
        name="isAnnual"
        type="checkbox"
        component={Checkbox}
      />
      <label htmlFor="isAnnual" className="mb-0">
        {labelText}
      </label>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { useUser, useUserInfo } from '../../context/user'
import coinLogo from '../../assets/defaultProfilePic.jpg'
import get from 'lodash/get'
import cx from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress'
import { swalError, swalConfirmAppLink } from '../Alert'
import firebase from 'firebase/app'
import 'firebase/storage'

export const uploadFile = async (path, file, metadata) => {
  const storageRef = firebase.storage().ref()
  const ref = storageRef.child(path)
  await ref.put(file, metadata)
  const url = await ref.getDownloadURL()
  return url
}

const getProfileImageUrl = ({ userInfo }) => {
  if (userInfo && userInfo.profileImageExists) {
    return `https://firebasestorage.googleapis.com/v0/b/xyo-network-1522800011804.appspot.com/o/coin%2Fimages%2F${userInfo.ownerUID}%2Fprofile150.jpg?alt=media&updated${userInfo.profileImageUpdated}`
  }
  return coinLogo
}

export const ProfileImage = ({ className }) => {
  const { user } = useUser()
  const { userInfo, loading } = useUserInfo()
  const [src, setSrc] = useState('')
  const [uploading, setUploading] = useState(false)
  const uploadCoinImageFile = async (ev) => {
    try {
      setUploading(true)
      const file = ev.target.files[0]
      if (!file) return
      if (!user) throw new Error('Must be logged in')
      const fileName = get(file, 'name')
      const type = get(file, 'type')
      const path = `coin/images/${user.uid}/${fileName}`
      const photoURL = await uploadFile(path, file, { contentType: type })
      await user.updateProfile({ photoURL })
      setSrc(photoURL)
    } catch (err) {
      swalError(err.message)
    }
    setUploading(false)
  }
  useEffect(() => {
    if (userInfo !== null) {
      setSrc(getProfileImageUrl({ userInfo }))
    }
  }, [userInfo])
  return (
    <div
      className={cx(
        'profile-photo position-relative rounded-circle overflow-hidden mx-auto border border-danger text-danger cursor-pointer',
        className,
      )}
      style={{
        height: '6em',
        width: '6em',
        maxHeight: '6em',
        minHeight: '6em',
        maxWidth: '6em',
        minWidth: '6em',
      }}
    >
      {src ? (
        <DefaultImage className="img-fluid" src={src} defaultSrc={coinLogo} />
      ) : (
        ''
      )}
      <div
        onClick={() =>
          swalConfirmAppLink({
            link: '/account',
            text: 'In the app, tap the profile image to update it.',
          })
        }
        className={cx('profile-overlay', {
          'opacity-1': !src || loading || uploading,
        })}
      >
        {!src || loading || uploading ? (
          <CircularProgress
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          />
        ) : (
          <i className="fa fa-plus fa-2x" />
        )}
      </div>
      {/* <input
        type="file"
        accept="image/png"
        onChange={uploadCoinImageFile}
        className="opacity-0 position-absolute w-100 h-100 top-0 left-0 cursor-pointer"
      /> */}
    </div>
  )
}

export const DefaultImage = ({ src: initialSrc, defaultSrc, ...props }) => {
  const [src, setSrc] = useState('')
  useEffect(() => {
    const img = document.createElement('img')
    img.onload = () => setSrc(initialSrc)
    img.onerror = () => setSrc(defaultSrc)
    img.src = initialSrc
  }, [initialSrc])

  if (!src) return <span />
  return <img src={src} {...props} />
}

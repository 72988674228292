import { withRouter } from 'react-router-dom'

export const GoToWithdraw = withRouter(({ history, children }) => {
  const goToWithdraw = (item) => {
    const asset = (typeof item === 'string' ? item : item.asset || '').toLowerCase()
    if (!asset || asset === 'xyo') {
      history.push('/redeem')
    } else {
      history.push(`/redeem/${asset}`)
    }
  }
  return children(goToWithdraw)
})
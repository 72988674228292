import { useCallback, useState } from 'react'
import VerifiedUser from '@material-ui/icons/VerifiedUser'
import ClearRoundedIcon from '@material-ui/icons/ClearRounded'
import HourglassFullIcon from '@material-ui/icons/HourglassFull'
import { useDropzone } from 'react-dropzone'
import Loader from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import CheckIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Cancel'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Tooltip from '@material-ui/core/Tooltip'
import capitalize from 'lodash/capitalize'
import map from 'lodash/map'
import Typography from '@material-ui/core/Typography'
import { SlideInDown, SlideInUp } from '../Animate'
import { LoadingButton } from '../Buttons'
import Camera, { IMAGE_TYPES } from 'react-html5-camera-photo'
import Form, { FormField } from '../Form'
import cx from 'classnames'
import flow from 'lodash/flow'
import lowerCase from 'lodash/lowerCase'
import get from 'lodash/get'
import 'react-html5-camera-photo/build/css/index.css'
import './index.scss'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  CardMedia,
  Divider,
} from '@material-ui/core'
import { useKycByIdQuery } from '@xyo-network/api-checkout-apollo'
import Skeleton from '@material-ui/lab/Skeleton'

const readable = flow(lowerCase, capitalize)

export const KYCExpandList = ({ expanded, onClick }) => {
  return (
    <IconButton
      className={cx('expand', {
        ['expandOpen']: expanded,
      })}
      onClick={onClick}
      aria-expanded={expanded}
      aria-label="show more"
    >
      <ExpandMoreIcon />
    </IconButton>
  )
}

export const KYCList = ({ documents }) => {
  const [activeItem, setActiveItem] = useState(null)
  const { data } = useKycByIdQuery({
    skip: !activeItem?.id,
    variables: { id: activeItem?.id },
  })
  return (
    <>
      <List>
        {documents.map((kyc, i) => (
          <SlideInUp delay={200 * i} config={{}} key={kyc.id}>
            <ListItem button onClick={() => setActiveItem(kyc)}>
              <Tooltip title={capitalize(kyc.status)}>
                <ListItemAvatar>
                  <KycDocumentStatusIcon kyc={kyc} />
                </ListItemAvatar>
              </Tooltip>
              <ListItemText
                primary={
                  get(kyc, 'rejectionReason.message') ||
                  kyc.documentType.split('-').map(capitalize).join(' ')
                }
                secondary={new Date(
                  kyc.processedTime || kyc.createdTime,
                ).toLocaleString()}
              />
            </ListItem>
          </SlideInUp>
        ))}
      </List>
      <Dialog
        open={Boolean(activeItem)}
        onClose={() => setActiveItem(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{readable(activeItem?.documentType)}</DialogTitle>
        <Divider />
        <CardMedia
          image={data?.kycDocumentById?.download}
          style={{ paddingTop: '56.6%', position: 'relative' }}
        >
          <Skeleton
            variant="rect"
            height="100%"
            width="100%"
            style={{ position: 'absolute', top: 0, left: 0 }}
          />
        </CardMedia>
        <Divider />
        <DialogContent>
          <Typography variant="subtitle1" paragraph>
            ANALYSIS
          </Typography>
          <Typography paragraph>
            This document was <strong>{activeItem?.status}</strong>{' '}
            {activeItem?.processedTime
              ? `on ${new Date(activeItem?.processedTime).toLocaleString()}`
              : ''}
          </Typography>
          {activeItem?.rejectionReason?.type ? (
            <Typography>
              <strong>Rejection Reason:</strong>{' '}
              {readable(activeItem?.rejectionReason?.type)}
            </Typography>
          ) : null}
          {activeItem?.rejectionReason?.message ? (
            <Typography paragraph>
              <strong>Rejection Message:</strong>{' '}
              {activeItem?.rejectionReason?.message}
            </Typography>
          ) : null}
          <Typography paragraph>
            Analysis Score {activeItem?.documentMatches?.score}%
          </Typography>
          <Typography></Typography>
          <Typography>
            <StatusIcon
              value={activeItem?.documentMatches?.data?.containsImage}
            />{' '}
            This image contains one or more faces
          </Typography>
          <Typography>
            <StatusIcon
              value={!activeItem?.documentMatches?.data?.isPublishedOnline}
            />{' '}
            Document was not found online
          </Typography>
          <Typography>
            <StatusIcon
              value={activeItem?.documentMatches?.data?.isIdentityDocument}
            />{' '}
            This image looks like an ID
          </Typography>
          <Typography>
            <StatusIcon
              value={activeItem?.documentMatches?.data?.hasMinimalAge}
            />{' '}
            Subject has minimum legal age
          </Typography>
          <Typography>
            <StatusIcon
              value={activeItem?.documentMatches?.data?.dateOfBirth}
            />{' '}
            Date of birth{' '}
            <em>{activeItem?.documentMatches?.data?.dateOfBirth}</em>
          </Typography>
          <Typography>
            <StatusIcon value={activeItem?.documentMatches?.data?.expiryDate} />{' '}
            Expiry date{' '}
            <em>{activeItem?.documentMatches?.data?.dateOfBirth}</em>
          </Typography>
          <Typography>
            <StatusIcon value={activeItem?.documentMatches?.data?.issueDate} />{' '}
            Issue date <em>{activeItem?.documentMatches?.data?.issueDate}</em>
          </Typography>
          <Typography>
            <StatusIcon value={activeItem?.documentMatches?.data?.firstName} />{' '}
            First name match{' '}
            <em>{activeItem?.documentMatches?.data?.firstName}</em>
          </Typography>
          <Typography>
            <StatusIcon value={activeItem?.documentMatches?.data?.lastName} />{' '}
            Last name match{' '}
            <em>{activeItem?.documentMatches?.data?.lastName}</em>
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions onClick={() => setActiveItem(null)}>
          <Button>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const KycDocumentStatusIcon = ({ kyc }) => {
  if (kyc.status === 'accepted')
    return (
      <Avatar className="bg-success">
        <VerifiedUser className="text-white" />
      </Avatar>
    )
  if (kyc.status === 'in-progress' || kyc.status === 'pending')
    return (
      <Avatar className="bg-warning">
        <HourglassFullIcon className="text-white" />
      </Avatar>
    )
  if (kyc.status === 'rejected')
    return (
      <Avatar className="bg-danger">
        <ClearRoundedIcon className="text-white" />
      </Avatar>
    )
  return null
}

export const SuccessContent = ({ children }) => {
  return (
    <div className="text-center">
      <div className="d-inline-block bg-success mt-4 mb-2 rounded-circle">
        <VerifiedUser
          className="text-white p-2"
          style={{ height: 120, width: 120 }}
        />
      </div>
      {children}
    </div>
  )
}

const StatusIcon = ({ value }) => {
  if (value) return <CheckIcon className="text-success" />
  return <CancelIcon className="text-danger" />
}

export const ProcessingContent = ({ children }) => {
  return (
    <div className="text-center">
      <div className="d-inline-block bg-warning mt-4 mb-2 rounded-circle">
        <HourglassFullIcon
          className="p-2 spin"
          style={{ height: 120, width: 120 }}
        />
      </div>
      {children}
    </div>
  )
}

export const ErrorContent = ({ children }) => {
  return (
    <div className="text-center">
      <div className="d-inline-block bg-danger mt-4 mb-2 rounded-circle">
        <ClearRoundedIcon className="p-2" style={{ height: 120, width: 120 }} />
      </div>
      {children}
    </div>
  )
}

import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import geodrop from '../../assets/graphics/geodrop.svg'
import referral from '../../assets/graphics/referral.svg'
import nfc from '../../assets/graphics/nfc.svg'
import count from '../../assets/graphics/geomine-count.svg'
import witness from '../../assets/graphics/witness.svg'
import total from '../../assets/graphics/geomine-total.svg'
import pile from '../../assets/graphics/coin-pile.svg'
import geoclaim from '../../assets/graphics/geoclaim.svg'
import sweepstakes from '../../assets/graphics/sweepstakes.svg'
import redeem from '../../assets/graphics/redeem.svg'
import collected from '../../assets/graphics/collected-coin.svg'
import raining from '../../assets/graphics/raining-geodrops.svg'
import survey from '../../assets/graphics/survey.svg'
import React from 'react'

export const StatCard: React.FC<{
  loading?: boolean
  label?: React.ReactNode
  secondary?: React.ReactNode
  action?: React.ReactNode
  graphic?: string
}> = ({ label, children, loading, secondary, action, graphic }) => {
  return (
    <Paper
      style={{ position: 'relative', ...getStatCardGraphicStyle(graphic) }}
    >
      <LinearProgress
        variant={loading ? 'indeterminate' : 'determinate'}
        value={100}
      />
      <Box p={2}>
        <Typography variant="body1" paragraph>
          {label}
        </Typography>
        <Typography variant="h4">
          {loading ? <Skeleton variant="rect" width={180} /> : children}
        </Typography>
        {secondary ? (
          <Typography variant="body2">
            {loading ? <Skeleton variant="rect" width={160} /> : secondary}
          </Typography>
        ) : null}
        {action}
      </Box>
    </Paper>
  )
}

function getStatCardGraphicStyle(type?: string) {
  const base = {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom right',
  }
  switch (type) {
    case 'geodrop':
      return { backgroundImage: `url(${geodrop})`, ...base }
    case 'referral':
      return { backgroundImage: `url(${referral})`, ...base }
    case 'nfc':
      return { backgroundImage: `url(${nfc})`, ...base }
    case 'count':
      return { backgroundImage: `url(${count})`, ...base }
    case 'witness':
      return { backgroundImage: `url(${witness})`, ...base }
    case 'total':
      return { backgroundImage: `url(${total})`, ...base }
    case 'pile':
      return { backgroundImage: `url(${pile})`, ...base }
    case 'geoclaim':
      return { backgroundImage: `url(${geoclaim})`, ...base }
    case 'sweepstakes':
      return { backgroundImage: `url(${sweepstakes})`, ...base }
    case 'redeem':
      return { backgroundImage: `url(${redeem})`, ...base }
    case 'collected':
      return { backgroundImage: `url(${collected})`, ...base }
    case 'raining':
      return { backgroundImage: `url(${raining})`, ...base }
    case 'survey':
      return { backgroundImage: `url(${survey})`, ...base }
    default:
      return {}
  }
}

import { useState } from 'react'
import { SelectItem } from '../Select'
import ButtonBase from '@material-ui/core/ButtonBase'
import MenuItem from '@material-ui/core/MenuItem'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AddIcon from '@material-ui/icons/Add'
import { primaryMain } from '../../context/page'
import { EditInline } from '../Edit'
import Main from '../../Main'
import keyBy from 'lodash/keyBy'
import find from 'lodash/find'
import get from 'lodash/get'
import cx from 'classnames'

const toExpiration = ({ expMonth, expYear }) =>
  `${String(expMonth).padStart(2, '0')}/${expYear}`
const toCardholderName = ({ billingAddress }) =>
  billingAddress ? `${billingAddress.firstName} ${billingAddress.lastName}` : ''
const hiddenPrefix = '****-****-****-'

export const CreditCard = ({
  last4,
  expMonth,
  expYear,
  brand,
  status,
  bankName,
  onClick,
}) => (
  <div
    className={cx('card card-design hover-shadow w-100', {
      [`card-design-${status}`]: !!status,
    })}
  >
    <ButtonBase
      focusRipple
      onClick={onClick}
      className="d-block w-100 outline-0 text-white"
    >
      <div className="card-body position-relative">
        <div className="row justify-content-end mb-3">
          <div
            className="col-6 col-md-4 text-right line-height-1 overflow-hidden"
            style={{ height: '3em' }}
          >
            <small>{bankName}</small>
          </div>
        </div>
        <div className="text-center">
          <p className="mb-0">
            {status !== 'active' ? (
              <span className="text-capitalize">{status}</span>
            ) : (
              <span>&nbsp;</span>
            )}
          </p>
          <h4 className="text-shadow">
            <strong>
              {hiddenPrefix}
              {last4}
            </strong>
          </h4>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <strong>Expiration</strong>
            <p className="mb-0">{toExpiration({ expMonth, expYear })}</p>
          </div>
          <div className="text-right">
            <strong>Card Type</strong>
            <p className="mb-0 text-muted">
              <i className={`fab fa-2x fa-cc-${brand.toLowerCase()}`} />
            </p>
          </div>
        </div>
      </div>
    </ButtonBase>
  </div>
)

export const CreditCardDetails = ({
  card,
  onClose,
  loading,
  deactivating,
  onSave,
  onDeactivate,
}) => {
  return (
    <div>
      <div className="text-center text-dark py-4 bg-light">
        {/* <EditInline initial={`****-****-****-${card.last4}`} /> */}
        <h4>
          <strong>
            {hiddenPrefix}
            {card.last4}
          </strong>
        </h4>
        <p className="text-danger mb-0">
          (<span className="text-capitalize">{card.status}</span>)
        </p>
      </div>
      <div className="row py-3">
        <div className="col-6">
          {toCardholderName(card) && (
            <>
              <strong>Cardholder Name</strong>
              <p>{toCardholderName(card)}</p>
            </>
          )}
          {toExpiration(card) && (
            <>
              <strong>Expiration</strong>
              <p>{toExpiration(card)}</p>
            </>
          )}
        </div>
        <div className="col-6">
          {card.brand && (
            <>
              <strong>Type</strong>
              <p>{card.brand}</p>
            </>
          )}
          {card.bankName && (
            <>
              <strong>Bank</strong>
              <p>{card.bankName}</p>
            </>
          )}
        </div>
      </div>
      <div className="text-center">
        <button className="btn btn-success" onClick={onClose}>
          Close
        </button>
        {onDeactivate && (
          <button
            className="btn btn-danger ml-1"
            onClick={onDeactivate}
            disabled={loading || deactivating}
          >
            {deactivating ? 'Deactivating...' : 'Deactivate'}
          </button>
        )}
        {onSave && (
          <button
            className="btn btn-success ml-1"
            onClick={onSave}
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
        )}
      </div>
    </div>
  )
}

export const SelectPaymentCard = ({ onChange, onAdd, cards }) => {
  const cardMap = keyBy(cards, 'id')
  const [value, setValue] = useState(
    find(cards, ({ status }) => status === 'active')?.id ?? '',
  )
  const handleChange = (ev, { props }) => {
    onChange(cardMap[props.value])
    setValue(props.value)
  }
  const handleAdd = (ev) => {
    ev.stopPropagation()
    onAdd()
  }
  return (
    <Main type="light">
      <SelectItem onChange={handleChange} value={value} fullWidth>
        {(cards || []).map((card) => (
          <ListItem
            key={card.id}
            value={card.id}
            button
            disabled={card.status !== 'active'}
          >
            <CreditCardItem {...card} />
          </ListItem>
        ))}
        {onAdd && (
          <div>
            <ListItem button onClick={handleAdd} value={null}>
              <ListItemText primary="Add Payment Card" />
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
            </ListItem>
          </div>
        )}
      </SelectItem>
    </Main>
  )
}

export const CreditCardItem = ({
  last4,
  brand,
  expMonth,
  expYear,
  onClick,
}) => (
  <div
    className="d-flex align-items-center justify-content-between w-100"
    onClick={onClick}
  >
    <ListItemText
      primary={`${hiddenPrefix}${last4}`}
      secondary={toExpiration({ expMonth, expYear })}
    />
    <ListItemIcon>
      <i className={`fab fa-2x fa-cc-${brand.toLowerCase()}`} />
    </ListItemIcon>
  </div>
)

import React from 'react'
import { Link } from 'react-router-dom'
import { ExplorerMotionGraphic } from '../components/Graphics'
import { FadeIn, SlideInLeft, config } from '../components/Animate'

export default () => (
  <div className="container pb-40vh pt-5 flex-grow align-items-center">
    <div className="row">
      <div className="offset-md-4 col-md-4 mb-5">
        <FadeIn config={config.wobbly}>
          <div className="card overflow-hidden">
            <div className="card-body text-center">
              <div className="mb-3">
                <ExplorerMotionGraphic />
              </div>
              <h4 className="text-dark">Page not found</h4>
              <Link to="/" className="btn btn-outline-danger">
                Go Home
              </Link>
            </div>
          </div>
        </FadeIn>
      </div>
    </div>
  </div>
)

import { useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import coinVertical from '../assets/CoinLogoWithText2.png'
import { useScrollTopEffect } from '../components/Scroll'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import { CheckoutProvider } from '../context/checkout'
import {
  useKycQuery,
  KycDocument,
  useCreateKycMutation,
} from '@xyo-network/api-checkout-apollo'
import { SlideInUp } from '../components/Animate'
import { parseError } from '../utils/error'
import {
  KYCForm,
  KYCList,
  KYCExpandList,
  SuccessContent,
  ErrorContent,
  ProcessingContent,
} from '../components/KYC'
import { LoadingButton } from '../components/Buttons'
import Collapse from '@material-ui/core/Collapse'
import moment from 'moment'
import cx from 'classnames'
import { useBooleanControls } from '../utils/hooks'
import isFunction from 'lodash/isFunction'
import isNumber from 'lodash/isNumber'
import get from 'lodash/get'
import set from 'lodash/set'
import { useUserInfo } from '../context/user'
import { UserInfoDisplay } from '../components/User'
import { ButtonBase } from '@material-ui/core'

const Identity = () => {
  const { userInfo } = useUserInfo()
  const kycCompleted = userInfo?.kycComplete
  const query = useKycQuery({
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  })
  useScrollTopEffect()
  return (
    <div className="container pb-40vh">
      <div className="pt-md-5 mb-5 text-white">
        <UserInfoDisplay />
      </div>
      <div className="row">
        <div className="offset-md-3 col-md-6 col">
          <IdentityVerificationCard {...query} />
        </div>
      </div>
    </div>
  )
}

const IdentityVerificationCard = ({ error, data, refetch, networkStatus }) => {
  const [retry, retryControls] = useBooleanControls(false)
  const [expanded, expandedControls] = useBooleanControls()
  const initializing = networkStatus === 1
  const refetching = networkStatus === 4
  const networkError = networkStatus === 8
  const customerId = get(data, 'me.id')
  const kycDocuments = get(data, 'me.kycDocuments') || []
  const attempts = get(data, 'me.kycMaxAttempts.attempts')
  const attemptsToday = get(data, 'me.kycMaxAttempts.attemptsToday')
  const maxAttempts = get(data, 'me.kycMaxAttempts.maxAttempts')
  const maxAttemptsToday = get(data, 'me.kycMaxAttempts.maxAttemptsToday')
  const kycMaxAttempts = data && data.me && data.me.kycDocuments
  console.log(kycDocuments)
  const identityDocuments = kycDocuments.filter(
    ({ documentType }) => documentType === 'identity-proof',
  )
  const inProgress = identityDocuments.find(
    ({ status }) => status === 'in-progress' || status === 'pending',
  )
  const accepted = identityDocuments.find(({ status }) => status === 'accepted')
  const rejected = identityDocuments.find(({ status }) => status === 'rejected')

  useEffect(() => {
    if (!inProgress) return
    const id = setInterval(() => {
      console.log('refetch')
      refetch()
    }, 10000)
    return () => clearInterval(id)
  }, [refetch, inProgress])

  console.log(error, networkStatus)

  return (
    <Card className="text-white">
      <CardContent>
        {initializing ? (
          <div className="text-center py-5">
            <CircularProgress />
          </div>
        ) : error ? (
          <IdentityError message={parseError(error)} />
        ) : networkError ? (
          <IdentityError message="Please check your network connection and try again." />
        ) : accepted ? (
          <IdentityAccepted />
        ) : inProgress ? (
          <IdentityInProgress />
        ) : attempts >= maxAttempts ? (
          <IdentityMaxError title="" max={maxAttempts} />
        ) : attemptsToday >= maxAttemptsToday ? (
          <IdentityMaxTodayError title="" max={maxAttemptsToday} />
        ) : rejected && !retry ? (
          <IdentityRejected
            rejected={rejected}
            onRetry={retryControls.setTrue}
          />
        ) : (
          <IdentitySubmission
            customerId={customerId}
            onComplete={retryControls.setFalse}
          />
        )}
      </CardContent>
      <ButtonBase
        className="justify-content-end"
        component={CardActions}
        onClick={expandedControls.toggle}
      >
        {identityDocuments.length ? (
          <KYCExpandList
            expanded={expanded}
            onClick={(ev) => {
              ev.stopPropagation()
              expandedControls.toggle()
            }}
          />
        ) : null}
      </ButtonBase>
      <Collapse in={expanded} unmountOnExit>
        {refetching ? <LinearProgress /> : null}
        <CardContent>
          <Typography variant="h5">Verification Attempts</Typography>
          <KYCList documents={identityDocuments} />
        </CardContent>
      </Collapse>
    </Card>
  )
}

const IdentityAccepted = () => {
  return (
    <SlideInUp delay={0} config={{}}>
      <SuccessContent>
        <Typography variant="h4" className="mb-3">
          Identity Verified
        </Typography>
        <Button component={Link} to="/" color="primary">
          Go Home
        </Button>
      </SuccessContent>
    </SlideInUp>
  )
}

const IdentityRejected = ({ rejected, onRetry }) => {
  return (
    <SlideInUp delay={0} config={{}}>
      <ErrorContent>
        <h4 className="mb-4">Document Rejected</h4>
        <p>{get(rejected, 'rejectionReason.message')}</p>
        <Button onClick={onRetry} variant="contained" color="primary">
          Retry
        </Button>
      </ErrorContent>
    </SlideInUp>
  )
}

const IdentityInProgress = () => {
  return (
    <SlideInUp delay={0} config={{}}>
      <ProcessingContent>
        <Typography variant="h4" className="mb-3">
          Verifying Identity
        </Typography>
      </ProcessingContent>
    </SlideInUp>
  )
}

const IdentitySubmission = ({ customerId, onComplete }) => {
  const [createKyc, create] = useCreateKycMutation({
    update: handleCreateDocUpdate(onComplete),
  })
  return (
    <SlideInUp delay={0} config={{}}>
      <KYCForm
        onSubmit={({ file }) =>
          createKyc({
            variables: {
              input: {
                file,
                customerId,
              },
            },
          })
        }
        loading={create.loading}
        error={create.error}
      />
    </SlideInUp>
  )
}

const handleCreateDocUpdate = (callback) => {
  return (cache, { data }) => {
    try {
      const prevData = cache.readQuery({ query: KycDocument })
      const prevKycDocuments = get(prevData, 'me.kycDocuments') || []
      console.log({ prevData })
      const newData = { __typename: 'Customer', ...prevData }
      const customerId = get(data, 'createKycDocument.customerId')
      const kycDocument = get(data, 'createKycDocument.kycDocument')
      const kycMaxAttempts = get(data, 'createKycDocument.kycMaxAttempts')
      const newKycDocuments = []
        .concat(kycDocument || [])
        .concat(prevKycDocuments)
      set(newData, 'me.__typename', 'Customer')
      set(newData, 'me.id', customerId)
      set(newData, 'me.kycDocuments', newKycDocuments)
      set(newData, 'me.kycMaxAttempts', kycMaxAttempts)
      console.log({ newData })
      cache.writeQuery({
        query: KycDocument,
        data: newData,
      })
      if (isFunction(callback)) {
        callback(data.createKycDocument)
      }
    } catch (e) {
      console.warn('Create Doc Cache Update Error', e.message)
    }
  }
}

const IdentityError = ({ message }) => {
  return (
    <SlideInUp delay={0} config={{}}>
      <ErrorContent>
        <Typography variant="h4" className="mb-4">
          Error
        </Typography>
        <Typography>{message}</Typography>
      </ErrorContent>
    </SlideInUp>
  )
}

const IdentityMaxError = ({ max }) => {
  return (
    <SlideInUp delay={0} config={{}}>
      <ErrorContent>
        <Typography variant="h4" className="mb-4">
          Maximum Attempts
        </Typography>
        <Typography>
          You have reached the maximum number of allowed attempts.
        </Typography>
        <Typography variant="h4" className="text-danger mb-4">
          <em>{max}</em>
        </Typography>
        <Button
          component="a"
          href={process.env.REACT_APP_SUPPORT_URI}
          target="_blank"
          color="primary"
        >
          Contact Support
        </Button>
      </ErrorContent>
    </SlideInUp>
  )
}

const IdentityMaxTodayError = ({ max }) => {
  return (
    <SlideInUp delay={0} config={{}}>
      <ErrorContent>
        <Typography variant="h4" className="mb-4">
          Maximum Daily Attempts
        </Typography>
        <Typography>
          You have reached the maximum number of allowed attempts in one day.
        </Typography>
        <Typography>Please come back and try again tomorrow.</Typography>
        <Typography variant="h4" className="text-danger mb-4">
          <em>{max}</em>
        </Typography>
      </ErrorContent>
    </SlideInUp>
  )
}

function getNumber(obj, src, defaultValue) {
  const n = get(obj, src)
  if (isNumber(n)) return n
  return defaultValue
}

export default () => {
  return (
    <CheckoutProvider>
      <Identity />
    </CheckoutProvider>
  )
}

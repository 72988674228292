import { Suspense, useMemo, useState, useEffect } from 'react'
import { render } from 'react-dom'
import { numberFormat } from '../../utils/number'
import { SelectPaymentCard } from '../PaymentCards'
import { SlideInRight } from '../Animate'
import Checkout from '../Checkout'
import AlertError from '../Error'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useBooleanControls } from '../../utils/hooks'
import { trackWarning } from '../../utils/events'
import { swalConfirm, swalRender, swalError } from '../Alert'
import swal from 'sweetalert2'
import get from 'lodash/get'
import { parseError } from '../../utils/error'

const getConfirmationProvider = (dataPromise, cardsPromise) => {
  let result
  let error
  return (props) => {
    if (error) return <AlertError error={error} />
    if (result)
      return (
        <ConfirmationProvider data={result[0]} cards={result[1]} {...props} />
      )
    throw Promise.all([dataPromise, cardsPromise])
      .then((d) => (result = d))
      .catch((e) => (error = e))
  }
}

const UpgradeTable = ({ data }) => {
  const amount = numberFormat(
    get(data, 'lineItemSubtotal') || get(data, 'amount') || 0,
  )
  const currency =
    get(data, 'lineItemSubtotal.currency') || get(data, 'currency') || 'USD'
  return (
    <table className="table table-sm w-100">
      <thead>
        <tr>
          <th>Description</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {(data.lineItems || data.items || []).map(
          ({ description, type, unitPriceAmount, unitPrice }, i) => (
            <tr key={i}>
              <td>
                <small>{description}</small>
              </td>
              <td>
                <small>
                  {type === 'debit'
                    ? numberFormat(unitPriceAmount || unitPrice)
                    : `(${numberFormat(unitPriceAmount || unitPrice)})`}
                </small>
              </td>
            </tr>
          ),
        )}
        <tr>
          <td>
            <small>
              Subtotal{' '}
              <strong>{amount > 0 ? '(Due Now)' : '(Billing Credit)'}</strong>
            </small>
          </td>
          <td>
            <small>
              {amount} {currency}
            </small>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const ConfirmationProvider = ({
  data,
  cards,
  title,
  onConfirmed,
  onCancel,
}) => {
  const initial = useMemo(() => ({ method: 'payment-card' }), [])
  const [selectedCard, setSelectedCard] = useState(cards && cards[0])
  const [error, setError] = useState('')
  const [add, addControls] = useBooleanControls(false)
  const onSubmit = async (paymentCardId) => {
    try {
      onConfirmed({
        paymentCardId,
        method: 'payment-card',
      })
    } catch (e) {
      setError(e.message)
    }
  }
  if (add)
    return (
      <div className="position-relative overflow-hidden">
        <AlertError error={error} />
        <Checkout onSuccess={onSubmit} onCancel={addControls.setFalse} />
      </div>
    )
  return (
    <div className="position-relative overflow-hidden">
      <SlideInRight>
        <div className="text-center">
          <div className="swal2-icon swal2-warning d-flex" />
          <h2 className="swal2-title">{title || 'Confirm Plan Change'}</h2>
        </div>
        <SelectPaymentCard
          cards={cards}
          onChange={setSelectedCard}
          onAdd={addControls.setTrue}
        />
        <div className="my-3">
          <UpgradeTable data={data} />
        </div>
        <div className="text-center">
          <button className="btn btn-danger" onClick={onCancel}>
            Cancel
          </button>
          <button
            className="btn btn-success ml-1"
            onClick={() =>
              onConfirmed(
                selectedCard && {
                  method: 'payment-card',
                  paymentCardId: selectedCard.id,
                },
              )
            }
          >
            Confirm
          </button>
        </div>
      </SlideInRight>
    </div>
  )
}

export const confirmPaymentPrompt = async (
  dataPromise,
  cardsPromise,
  title,
) => {
  return new Promise((res) => {
    const ConfirmUpgrade = getConfirmationProvider(dataPromise, cardsPromise)

    dataPromise.catch(async (e) => {
      trackWarning(e.message)
      await swalError(parseError(e.message))
      done()
      return null
    })

    const done = (v) => {
      res(v)
      swal.close()
    }
    swalRender(
      <Suspense
        fallback={
          <div className="text-center text-primary">
            <CircularProgress color="inherit" />
          </div>
        }
      >
        <ConfirmUpgrade
          title={title}
          onConfirmed={done}
          onCancel={() => done(null)}
        />
      </Suspense>,
      {
        onClose: () => res(null),
      },
    )
  })
}

import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import { MuiThemeProvider } from '@material-ui/core/styles'
import times from 'lodash/times'
import React from 'react'
import { InvoiceListItem } from './ListItem'
import { usePayInvoice } from '../Subscriptions/PayInvoice'
import { lightTheme } from '../../context/page'
import { trackPayEvent } from '../../utils/events'

const shouldPay = {
  'past-due': true,
}

export const InvoiceList = ({
  perPage,
  loading,
  cards,
  invoices,
  onClick,
  onInvoicePaid,
  onAllInvoicesPaid,
}) => {
  const payInvoice = usePayInvoice(cards)
  const paymentTransactionHandler = (invoice, transaction) => {
    if (transaction) {
      trackPayEvent('past-due-paid', {})
    }
    if (transaction && onInvoicePaid) {
      onInvoicePaid(invoice)
    }
    if (transaction && invoices.length === 1) {
      trackPayEvent('past-due-all-paid', {})
    }
    if (transaction && onAllInvoicesPaid && invoices.length === 1) {
      onAllInvoicesPaid()
    }
  }
  return (
    <MuiThemeProvider theme={lightTheme}>
      <Paper>
        <List disablePadding>
          {loading ? (
            times(perPage).map((_, i) => <InvoiceListItem key={i} />)
          ) : invoices?.length ? (
            invoices.map?.(invoice => (
              <InvoiceListItem
                key={invoice.id}
                invoice={invoice}
                onClick={onClick ? () => onClick(invoice) : null}
                action={
                  shouldPay[invoice?.status] ? (
                    <button
                      className="btn btn-danger nowrap"
                      onClick={() =>
                        payInvoice(invoice).then(transaction =>
                          paymentTransactionHandler(invoice, transaction),
                        )
                      }
                    >
                      Pay Invoice
                    </button>
                  ) : null
                }
              />
            ))
          ) : (
            <NoInvoicesFound />
          )}
        </List>
      </Paper>
    </MuiThemeProvider>
  )
}

const NoInvoicesFound = () => {
  return (
    <div className="list-group-item text-center py-5 rounded-0-sm">
      <h4>No Invoices Found</h4>
    </div>
  )
}

export default InvoiceList

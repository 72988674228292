import orderBy from 'lodash/orderBy'
import { useAsyncEffect, useAsyncMethod } from '../utils/hooks'
import fetch from 'isomorphic-fetch'
import keyBy from 'lodash/keyBy'

const zenApi = 'https://coinappco.zendesk.com/api/v2'

export async function findSupportCategories() {
  const res = await fetch(`${zenApi}/help_center/en-us/categories.json`)
  const data = await res.json()
  return orderBy(data.categories, 'position', 'asc')
    .filter(({ id }) => supportCategoryIconName(id))
    .map(category => ({
      ...category,
      icon: supportCategoryIconName(category.id),
    }))
}

export async function findAllArticles() {
  async function articlesPage(articles, page) {
    const res = await fetch(
      `${zenApi}/help_center/en-us/articles.json?per_page=100&page=${page}`,
    )
    const data = await res.json()
    if (data.next_page) {
      return articlesPage(articles.concat(data.articles), data.page + 1)
    }
    return articles.concat(data.articles)
  }

  const sections = await findSupportSections()
  const sectionsById = keyBy(sections, 'id')
  return (await articlesPage([], 1)).map(article => {
    if (article.section_id && sectionsById[article.section_id]) {
    }
    const category_id =
      article.section_id && sectionsById[article.section_id]
        ? sectionsById[article.section_id].category_id
        : null
    return {
      question: article.title,
      answer: article.body,
      category_id,
    }
  })
}

export async function findSupportArticles() {
  const res = await fetch(`${zenApi}/help_center/en-us/articles.json`)
  const data = await res.json()
  return data
}

export async function findSupportSections() {
  async function sectionPage(sections, page) {
    const res = await fetch(
      `${zenApi}/help_center/en-us/sections.json?per_page=100&page=${page}`,
    )
    const data = await res.json()
    if (data.next_page)
      return findAllArticles(sections.concat(data.sections), data.page + 1)

    return sections.concat(data.sections)
  }
  return sectionPage([], 1)
}

export async function findSupportCategoryArticles(id) {
  const res = await fetch(
    `${zenApi}/help_center/en-us/categories/${id}/articles.json`,
  )
  const data = await res.json()
  return data
}

export async function helpCenterSearch(query) {
  const res = await fetch(
    `${zenApi}/help_center/articles/search.json?query=${query}`,
  )
  const data = await res.json()
  return data
}

export const useHelpCenterSearch = () => {
  return useAsyncMethod(helpCenterSearch)
}

export const useSupportCategoryArticles = () => {
  return useAsyncMethod(findSupportCategoryArticles)
}

export const useAllArticles = () => {
  return useAsyncEffect(findAllArticles)
}

export const useSupportCategories = () => {
  return useAsyncEffect(findSupportCategories)
}

const supportCategoryIconName = id => {
  switch (id) {
    case 360002099393:
      return 'fas fa-question'
    case 360002083674:
      return 'fas fa-book-open'
    case 360002083734:
      return 'fas fa-user-circle'
    case 360002099453:
      return 'fas fa-crown'
    case 360002104613:
      return 'fas fa-map-marked-alt'
    case 360002099613:
      return 'fas fa-user-plus'
    case 360002099633:
      return 'fas fa-parachute-box'
    case 360002083694:
      return 'fas fa-cogs'
    case 360002099433:
      return 'fas fa-wallet'
    case 360002083774:
      return 'fas fa-exclamation-triangle'
    case 360002211173:
      return 'fas fa-users'
    default:
      return ''
  }
}

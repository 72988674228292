import { createContext, useContext, Children, useEffect, useRef } from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { FadeIn, SlideInRight } from '../../components/Animate'

export default ({ children, prev, step }) => {
  const steps = Children.toArray(children)
  return (
    <div>
      <Stepper activeStep={step} className="px-0 pt-0 pb-3">
        {steps.map((step) => (
          <Step key={step.props.label}>
            <StepLabel>{step.props.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {steps.reduce((activeStep, stepCmp, i) => {
        if (activeStep) return activeStep
        if (step === i && typeof prev !== 'number')
          return <FadeIn key={i}>{stepCmp}</FadeIn>
        if (step === i && step > prev) return <FadeIn key={i}>{stepCmp}</FadeIn>
        if (step === i && step <= prev)
          return <FadeIn key={i}>{stepCmp}</FadeIn>
        return null
      }, null) || <div />}
    </div>
  )
}

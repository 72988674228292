import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import { useRef } from 'react'
import { View } from '../components/Footer'
import {
  SweepstakesEntryForm,
  SweepstakesHistoryDrawer,
  SweepstakesHistoryFab,
  SweepstakesProvider,
  SweepstakesSize,
  CurrentSweepstakesListItems,
} from '../components/Sweepstakes'

const Sweepstakes = () => {
  const form = useRef(null)
  return (
    <>
      {/* <SweepstakesHeader
          onEnterToWin={() => {
            console.log(form.current)
            if (form.current)
            form.current.scrollIntoView({ behavior: 'smooth' })
          }}
          />
        <SweepstakesHowToEnter /> */}
      <SweepstakesProvider>
        <SweepstakesSize.Provider initial="lg">
          <View>
            <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8 px-0">
              <Paper>
                <Box px={2} py={1}>
                  <Typography variant="h6">Sweepstakes</Typography>
                </Box>
                <Divider />
                <List>
                  <CurrentSweepstakesListItems />
                </List>
              </Paper>
            </div>
          </View>
        </SweepstakesSize.Provider>
        {/* <div ref={form}>
            <Box py={4}>
              <Container maxWidth="md">
                <Paper elevation={0}>
                  <SweepstakesEntryForm />
                </Paper>
              </Container>
            </Box>
          </div> */}
        <SweepstakesHistoryDrawer />
        <SweepstakesHistoryFab
          style={{ position: 'fixed', top: '50%', right: 0 }}
        />
      </SweepstakesProvider>
    </>
  )
}

const SweepstakesHeader = ({ onEnterToWin }) => {
  return (
    <Paper elevation={0} square>
      <Box py={4} component={Container}>
        <Grid container>
          <Grid item xs={12} md={7}>
            <Typography variant="h4" paragraph>
              We're giving away a million bucks for the holidays. Want some?
            </Typography>
            <Typography paragraph>
              This holiday season, Honey is dropping $1 MILLION to help pay for
              gifts for our members. Just add gifts to your list and we might
              help pay for one of them. Don’t miss out. Get Honey now.
            </Typography>
            <Button variant="contained" color="primary" onClick={onEnterToWin}>
              Enter To Win
            </Button>
          </Grid>
          <Grid item xs={12} md={5}></Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

const SweepstakesHowToEnter = () => {
  return (
    <Box py={4} component={Container}>
      <Box mb={2}>
        <Typography variant="h4" align="center">
          Here's How to Enter
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <SweepstakesHowToEnterStep
          title="1. Earn Some COIN"
          imageUri="//placebeard.it/300x301"
        >
          <Typography paragraph variant="body1">
            Install Honey on your computer with two clicks. Create an account
            with your first and last name and email address.
          </Typography>
        </SweepstakesHowToEnterStep>
        <SweepstakesHowToEnterStep
          title="2. Deposit COIN"
          imageUri="//placebeard.it/301x300"
        >
          <Typography variant="body1">
            Add up to 50 Eligible Items to your Droplist. Select the{' '}
            <strong>HoneyHolidays</strong> Collection or Tag from the menu, or
            create your own HoneyHolidays Tag or Collection.
          </Typography>
        </SweepstakesHowToEnterStep>
        <SweepstakesHowToEnterStep
          title="3. Be Lucky!"
          imageUri="//placebeard.it/301x301"
        >
          <Typography paragraph variant="body1">
            There are thousands of winners every week, so cross your fingers and
            rub a rabbit's foot.
          </Typography>
          <Typography paragraph variant="caption">
            You will receive an email confirming your entry in to the
            sweepstakes. If you are a US resident and do not receive this email,
            check your spam folder, or contact support.
          </Typography>
        </SweepstakesHowToEnterStep>
      </Grid>
    </Box>
  )
}

const SweepstakesHowToEnterStep = ({ title, subtitle, imageUri, children }) => {
  return (
    <Grid item xs={12} md={4}>
      {imageUri ? (
        <Box mb={1}>
          <Paper elevation={0} style={{ height: 200, overflow: 'hidden' }}>
            <img
              src={imageUri}
              alt={title}
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            />
          </Paper>
        </Box>
      ) : null}
      <Typography variant="h4" paragraph>
        {title}
      </Typography>
      {subtitle ? (
        <Typography paragraph variant="body1">
          {subtitle}
        </Typography>
      ) : (
        children
      )}
    </Grid>
  )
}

export default Sweepstakes

import {
  createContext,
  useContext,
  cloneElement,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { lightTheme } from '../../context/page'
import createQuestionsIndex from './search'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import DirectionsIcon from '@material-ui/icons/Directions'
import Tooltip from '@material-ui/core/Tooltip'
import {
  useHelpCenterSearch,
  findSupportCategoryArticles,
  useAllArticles,
} from '../../context/support'
import { BuyNowButton } from '../Checkout'
import { HelpLinks } from '../Sidebar'

export const Context = createContext({
  questions: null,
  filter: '',
})

export const useQuestions = () => {
  return useContext(Context)
}

export const QuestionsProvider = ({ children }) => {
  const [filter, setFilter] = useState('')
  const [categoryId, setCategoryId] = useState(null)
  const [active, setActive] = useState(null)
  const [allQuestions, setAllQuestions] = useState(null)
  const articles = useAllArticles()
  const activeQuestions = useMemo(() => {
    return categoryId
      ? (articles.data || []).filter(
          ({ category_id }) => category_id === categoryId,
        )
      : allQuestions
      ? allQuestions.concat(articles.data || [])
      : []
  }, [allQuestions, articles, categoryId])
  const questionsIndex = useMemo(() => createQuestionsIndex(activeQuestions), [
    activeQuestions,
  ])

  const questions = filter
    ? questionsIndex.search(filter).map((v) => v.item)
    : activeQuestions

  useEffect(() => {
    import('./questions.json')
      .then((questions) => {
        setAllQuestions(questions.default)
      })
      .catch(() => {})
  }, [])

  return (
    <Context.Provider
      value={{
        filter,
        active,
        categoryId,
        setFilter,
        setActive,
        questions,
        setCategoryId,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const QuestionsList = ({ children, className }) => {
  const { questions, active, setActive } = useQuestions()
  const handleClick = (q) => () => {
    setActive(q.question === active ? null : q.question)
  }
  return (
    <div className={className}>
      {questions ? (
        questions.map((q, index) =>
          cloneElement(children, {
            ...q,
            key: index,
            index,
            active: q.question === active,
            onClick: handleClick(q),
          }),
        )
      ) : (
        <div className="text-center text-danger pb-40vh">
          <div className="spinner-border" />
        </div>
      )}
    </div>
  )
}

export const QuestionsFilter = (props) => {
  const { filter, setFilter, categoryId, setCategoryId } = useQuestions()
  const clear = () => {
    setFilter('')
    setCategoryId(null)
  }
  return (
    <Paper className="d-flex align-items-center mb-4">
      <BuyNowButton
        renderPopover={() => (
          <MuiThemeProvider theme={lightTheme}>
            <HelpLinks
              categoryId={categoryId}
              onClick={(id) => setCategoryId(categoryId === id ? null : id)}
            />
          </MuiThemeProvider>
        )}
      >
        {() => (
          <IconButton aria-label="menu">
            <MenuIcon />
          </IconButton>
        )}
      </BuyNowButton>
      <InputBase
        value={filter}
        onChange={(ev) => setFilter(ev.target.value)}
        placeholder="Type your question here"
        style={{ flexGrow: 1, marginLeft: 12 }}
      />
      {(filter || categoryId) && (
        <IconButton aria-label="clear" onClick={clear}>
          <i className="fa fa-times-circle" />
        </IconButton>
      )}
      <Divider orientation="vertical" />
      <Tooltip title="Contact Support">
        <a href={process.env.REACT_APP_SUPPORT_URI}>
          <IconButton color="primary" aria-label="support">
            <DirectionsIcon />
          </IconButton>
        </a>
      </Tooltip>
    </Paper>
  )
}

import React from 'react'
import { View } from '../Footer'

export default ({ title, action, children }) => {
  return (
    <View>
      <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8 px-0">
        <div className="d-flex align-items-center text-white px-2">
          <div className="mb-1">{title}</div>
          <div className="flex-grow-1" />
          <div className="mb-1">{action}</div>
        </div>
        {children}
      </div>
    </View>
  )
}

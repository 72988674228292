import { FC, useEffect, useRef } from 'react'
import { trackInfo } from '../utils/events'
import { swalConfirm } from '../components/Alert'
import { localJSONStore } from '../utils/localStore'
import firebase from 'firebase/app'
import 'firebase/firestore'

const appVersion = process.env.REACT_APP_GIT_SHA
const promptSeen = localJSONStore('reload-prompt')

const promptReload = async () => {
  const last = promptSeen.from()
  const now = Date.now()
  if (last && last.time > now - 2 * 60 * 60 * 1000) {
    return // Only show at most once per 2 hours
  }
  trackInfo('Version diff reload prompt')
  promptSeen.to({ time: now })
  const { value } = await swalConfirm(
    'You are using a stale app version. Update now?',
    'New Version Found',
  )
  if (value) window.location.reload(true)
}

export const VersionProvider: FC = ({ children }) => {
  const versionRef = useRef()

  useEffect(() => {
    firebase
      .firestore()
      .collection('coin_app_versions')
      .doc('mycoinapp')
      .onSnapshot((doc) => {
        const liveVersion = doc.get('version')
        if (appVersion !== liveVersion) {
          trackInfo('Version diff')
          promptReload()
        }
        if (versionRef.current && versionRef.current !== liveVersion) {
          trackInfo('Version changed')
        }
        versionRef.current = liveVersion
      })
  }, [])

  return <>{children}</>
}

import { FC, SVGProps } from 'react'
import scansCircle from './scansCircle.png'
import scansFull from './scansFull.png'
import scansGreen from './scansGreen.png'
import surveysCircle from './surveyCircle.png'
import surveysFull from './surveyFull.png'
import surveysGreen from './surveyGreen.png'
import visitsCircle from './visitsCircle.png'
import visitsFull from './visitsFull.png'
import visitsGreen from './visitsGreen.png'

function getIconSrc(type: string, variant: string) {
  if (variant === 'circle') {
    switch (type) {
      case 'scan':
      case 'scans':
        return scansCircle
      case 'visit':
      case 'visits':
        return visitsCircle
      case 'survey':
      case 'surveys':
        return surveysCircle
      default:
        return ''
    }
  }
  if (variant === 'green') {
    switch (type) {
      case 'scan':
      case 'scans':
        return scansGreen
      case 'visit':
      case 'visits':
        return visitsGreen
      case 'survey':
      case 'surveys':
        return surveysGreen
      default:
        return ''
    }
  }
  switch (type) {
    case 'scan':
    case 'scans':
      return scansFull
    case 'visit':
    case 'visits':
      return visitsFull
    case 'survey':
    case 'surveys':
      return surveysFull
    default:
      return ''
  }
}

export const RewardIcon: FC<
  { type: string; variant: string } & SVGProps<any>
> = ({ type, variant, ...props }) => {
  const src = getIconSrc(type, variant)
  if (!src) return null
  return <img src={src} {...props} />
}

import { useCallback } from 'react'
import Box from '@material-ui/core/Box'
import DialogContentText from '@material-ui/core/DialogContentText'
import { SuccessButton } from './Buttons'
import { useSnackbar } from '../Snackables'
import {
  useSweepstakesOptionsLoading,
  SweepstakesEntryDialogOpen,
  SweepstakesEntryStep,
  sweepstakesEvent,
} from './context'
import { useAgreedToSweepstakesRequirementsMutation } from '@coin-microservices/sweepstakes-react-hooks'

const RESIDENT_EVENT = 'is-resident-of-us-uk'
const residentEvent = sweepstakesEvent(RESIDENT_EVENT)

export const SweepstakesRequiredCountryDetails = () => {
  return (
    <Box p={2}>
      <DialogContentText>
        Are you a resident of the US/UK and at least the age of 18?
      </DialogContentText>
    </Box>
  )
}

export const useResidentsOfUsMessage = () => {
  const { setSnackbar } = useSnackbar()[1] as any
  return useCallback(() => {
    setSnackbar({
      message: 'For US/UK residents only',
      severity: 'error',
      autoHideDuration: 6000,
    })
  }, [])
}

export const EnterRequireCountryActions = () => {
  const loading = useSweepstakesOptionsLoading()
  const [step, setStep] = SweepstakesEntryStep.useContext()
  const setDialogOpen = SweepstakesEntryDialogOpen.useContext()[1]
  const displayResidentsOfUsMessage = useResidentsOfUsMessage()
  const [
    agreedToSweepstakesRequirements,
  ] = useAgreedToSweepstakesRequirementsMutation()
  const onNo = async () => {
    try {
      setDialogOpen(false)
      residentEvent('no', {})
      residentStorage.set('no')
      displayResidentsOfUsMessage()
      await agreedToSweepstakesRequirements({ variables: { agreed: false } })
    } catch (e) {
      console.warn(e)
    }
  }
  const onYes = async () => {
    try {
      setStep(step + 1)
      residentEvent('yes', {})
      residentStorage.set('yes')
      await agreedToSweepstakesRequirements({ variables: { agreed: true } })
    } catch (e) {
      console.warn(e)
    }
  }
  return (
    <Box px={2} pb={1} display="flex" justifyContent="flex-end">
      <SuccessButton
        disabled={loading}
        variant="contained"
        color="primary"
        onClick={onNo}
        disableElevation
      >
        No
      </SuccessButton>
      <Box ml={1}>
        <SuccessButton
          disabled={loading}
          variant="contained"
          color="secondary"
          onClick={onYes}
          disableElevation
        >
          Yes
        </SuccessButton>
      </Box>
    </Box>
  )
}

type IResult = 'yes' | 'no' | ''
export const residentStorage = {
  result: '' as IResult,
  set: (status: IResult) => {
    try {
      residentStorage.result = status
      localStorage.setItem(RESIDENT_EVENT, status)
    } catch (e) {}
  },
  get: (): IResult => {
    try {
      return (localStorage.getItem(RESIDENT_EVENT) ?? '') as IResult
    } catch (e) {
      return residentStorage.result
    }
  },
}

import {
  Component,
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react'
import invoke from 'lodash/invoke'
import { trackError, trackInfo } from '../utils/events'

const jquerySrc = '//ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js'
const coinTracker = '//t.coinapp.co/events.js'
const pollFishSrc =
  '//storage.googleapis.com/pollfish_production/sdk/webplugin/pollfish.min.js'

const PollFishContext = createContext({})
export const usePollFish = () => {
  const context = useContext(PollFishContext)
  // useEffect(() => {
  //   if (context.initialize) {
  //     context.initialize()
  //   }
  // }, [context.initialize])
  return context
}

// PHP http://www.writephponline.com/
// $reward_conversion_hash = base64_encode(hash_hmac("sha1" , "50", "6ed8671b-ee86-4280-8c02-7c28eedb948a", true));
// print($reward_conversion_hash);

export const fullReward = {
  rewardConversion: '2000',
  rewardConversionHash: 'cYGh/mA56xOBLb7cY6QAy1RuaHU=',
}

export const halfReward = {
  rewardConversion: '1001',
  rewardConversionHash: 'M937adsxA2xWye5Nw1T2PNTHIOs=',
}

export const quarterReward = {
  rewardConversion: '500',
  rewardConversionHash: 'sgPA7nReb5hjd+aCQC78HsuTk28=',
}

export class PollFishProvider extends Component {
  state = {
    nonce: 0,
    error: '',
    survey: null,
    initializing: false,
    isReady: false,
    isSurveyAvailable: false,
    isSurveyNotAvailable: false,
    isSurveyComplete: false,
    isUserNotEligible: false,
  }
  unsubscribe = []
  componentDidMount() {}
  componentWillUnmount() {
    this.unsubscribe.forEach((unsub) => {
      unsub()
    })
    this.unsubscribe = []
  }
  log = (...v) => {} //console.log(...v)
  initialize = (config) => {
    if (this.initializing) return this.initializing
    let clearTimer
    const onReady = (v) => {
      this.onReady(v)
      if (clearTimer) clearTimer()
    }
    window.pollfishConfig = {
      api_key: '9db6acda-5484-4c96-8213-aedb389ef87f',
      indicator_position: 'BOTTOM_RIGHT',
      rewardName: 'COIN',
      debug: process.env.NODE_ENV === 'development',
      offerwall: true,
      ready: onReady,
      surveyAvailable: this.onSurveyAvailable,
      surveyNotAvailable: this.onSurveyNotAvailable,
      surveyCompletedCallback: this.onSurveyCompletedCallback,
      userNotEligibleCallback: this.onUserNotEligibleCallback,
      ...this.props.config,
      ...config,
    }
    this.setState({ initializing: true })
    this.initializing = Promise.resolve()
      .then(() => {
        if (!window.pollfishConfig.uuid) throw new Error('User not initialized')
      })
      .then(() =>
        Promise.all([appendScript(coinTracker), appendScript(jquerySrc)]),
      )
      .then(() => appendScript(pollFishSrc))
      .then(() => {
        const id = setTimeout(() => {
          this.onError(
            new Error(
              'Surveys are not currently available, please try again later.',
            ),
          )
        }, 10000)
        clearTimer = () => clearTimeout(id)
        this.unsubscribe.push(clearTimer)
      })
      .catch((e) => {
        this.log(e)
        this.setState({ initializing: false })
        this.initializing = null
      })

    return this.initializing
  }
  onReady = (v) => {
    this.log('onReady')
    this.setState({ isReady: true, initializing: false, error: '' }, () =>
      invoke(this.props, 'onReady', this.getProviderValue()),
    )
  }
  onError = (e) => {
    this.log('onError', e)
    this.setState({ error: e.message }, () =>
      invoke(this.props, 'onError', this.getProviderValue(), e),
    )
    // trackError('Pollfish error', e)
  }
  onSurveyAvailable = (survey) => {
    this.log('onSurveyAvailable', survey)
    this.setState({ isSurveyAvailable: true, survey }, () =>
      invoke(this.props, 'onSurveyAvailable', this.getProviderValue(), survey),
    )
  }
  onSurveyNotAvailable = (v) => {
    this.log('onSurveyNotAvailable', v)
    this.setState({ isSurveyNotAvailable: true }, () =>
      invoke(this.props, 'onSurveyNotAvailable', this.getProviderValue(), v),
    )
    // trackInfo('Pollfish not available', v)
  }
  onSurveyCompletedCallback = (v) => {
    this.log('onSurveyCompleted', v)
    this.setState({ isSurveyComplete: true }, () =>
      invoke(this.props, 'onSurveyCompleted', this.getProviderValue(), v),
    )
    const id = setTimeout(() => {
      const nonce = this.state.nonce + 1
      this.setState({ nonce })
    }, 1000)
    this.unsubscribe.push(() => clearTimeout(id))
    window.coinTracker = window.coinTracker || []
    window.coinTracker.push({ event: 'convert' })
    // trackInfo('Pollfish survey complete', v)
  }
  onUserNotEligibleCallback = (v) => {
    this.log('onUserNotEligible', v)
    this.setState({ isUserNotEligible: true }, () =>
      invoke(this.props, 'onUserNotEligible', this.getProviderValue(), v),
    )
    // trackInfo('Pollfish not eligible', v)
  }
  getProviderValue = () => {
    return { ...this.state, ...this.actions }
  }
  actions = {
    initialize: this.initialize,
    showFullSurvey: () => window.Pollfish.showFullSurvey(),
    hide: () => window.Pollfish.hide(),
  }
  render() {
    const { children } = this.props
    return (
      <PollFishContext.Provider value={this.getProviderValue()}>
        {children}
      </PollFishContext.Provider>
    )
  }
}

function appendScript(src) {
  return new Promise((res, rej) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => res()
    script.onerror = (e) => rej(e)
    document.body.appendChild(script)
  })
}

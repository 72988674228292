import React from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import marked from 'marked'

const FaqAccordion = ({ index, question, answer, active, onClick }) => {
  return (
    <Accordion expanded={active} onChange={onClick}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">{question}</Typography>
      </AccordionSummary>
      {active && (
        <AccordionDetails>
          <Typography
            dangerouslySetInnerHTML={{ __html: answer ? marked(answer) : '' }}
          ></Typography>
        </AccordionDetails>
      )}
    </Accordion>
  )
}

export default FaqAccordion

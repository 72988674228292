import Box from '@material-ui/core/Box'
import CardHeader from '@material-ui/core/CardHeader'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { useMemo } from 'react'
import { Link as ReactLink, useLocation } from 'react-router-dom'
import { makeContext } from './makeContext'
import appStore from '../../assets/app-store-badge.svg'
import playStore from '../../assets/get-it-on-google-play.svg'
import { SweepstakesFreeEntryDialogOpen } from './FreeEntry'
import { sweepstakesEvent } from './event'

export const SweepstakesDownloadDialogOpen = makeContext(
  false,
  'download_dialog_open',
)

const appUris = {
  normal: 'https://coin.onelink.me/ePJg/9ff19d08',
  fromAd: 'https://coin.onelink.me/ePJg/9eafc196',
}
const useAppUri = () => {
  const location = useLocation()
  return useMemo(() => {
    const isFromAd = location.search.indexOf('utm') !== -1
    return isFromAd ? appUris.fromAd : appUris.normal
  }, [location.search])
}

export const SweepstakesDownloadDialog = () => {
  const appUri = useAppUri()
  const [open, setOpen] = SweepstakesDownloadDialogOpen.useContext()
  const setFreeEntryOpen = SweepstakesFreeEntryDialogOpen.useContext()[1]
  const handleAlreadyHaveAccount = () => {
    setOpen(false)
    sweepstakesEvent('download', 'have-account', {})
  }
  const handleAlternative = () => {
    setOpen(false)
    setFreeEntryOpen(true)
    sweepstakesEvent('download', 'alternative-entries', {})
  }
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs">
        <CardHeader title="Entering is Simple" />
        <Box
          px={2}
          style={{ overflowY: 'auto', WebkitOverflowScrolling: 'touch' }}
        >
          <Typography>
            Step 1) Download COIN (a free app that allows you to easily earn
            rewards)
          </Typography>
          <Box display="flex">
            <Box py={2} pr={1}>
              <a href={appUri}>
                <img
                  style={{ height: 40 }}
                  alt="Available on the AppStore"
                  src={appStore}
                />
              </a>
            </Box>
            <Box py={2}>
              <a href={appUri}>
                <img
                  style={{ height: 40 }}
                  alt="Get it on Google Play"
                  src={playStore}
                />
              </a>
            </Box>
          </Box>
          <Typography>
            Step 2) Earn in-app rewards and use them to enter Sweepstakes
          </Typography>
        </Box>
        <Box p={2} display="flex">
          <Link
            color="textSecondary"
            style={{
              lineHeight: '1em',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={handleAlternative}
          >
            Alternative ways to enter
          </Link>
          <Box flexGrow={1} />
          <Link
            color="secondary"
            style={{ lineHeight: '1em', textAlign: 'right' }}
            to={`/auth?redirect=/sweepstakes`}
            onClick={handleAlreadyHaveAccount}
            component={ReactLink}
          >
            Already have an account?
          </Link>
        </Box>
      </Dialog>
    </>
  )
}

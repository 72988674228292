
import firebase from 'firebase/app'
import 'firebase/auth'

export default async () => {
  const user = firebase.auth().currentUser
  if (!user) return {}
  return { 
    headers: {
      'X-Auth-Token': await user.getIdToken()
    } 
  }
}
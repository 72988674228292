import { Children, cloneElement } from 'react'
import { numberFormat } from '../../utils/number'
import get from 'lodash/get'
import cx from 'classnames'

export default ({
  children,
  headerClassName,
  data,
  className,
  onClick,
  isActive,
  activeColumn,
  activeColumnClassName,
  activeColumnHeaderClassName,
}) => {
  return (
    <table className={cx(className, { 'table-hover cursor-pointer': onClick })}>
      <thead className={headerClassName}>
        <tr>
          {Children.map(children, (child, i) => (
            <th
              key={i}
              className={cx({
                [activeColumnHeaderClassName]: activeColumn === i,
              })}
            >
              {child.props.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {(data || []).map((d, i) => (
          <tr
            key={i}
            onClick={() => onClick && onClick(d)}
            className={cx({ 'table-light text-dark': isActive && isActive(d) })}
          >
            {Children.map(children, (child, i) => (
              <td
                key={i}
                className={cx({ [activeColumnClassName]: activeColumn === i })}
              >
                {cloneElement(child, { ...d, ...child.props })}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export const AssetTitleIcon = ({ className, icon, title }) => (
  <span className={cx('d-flex align-items-center', className)}>
    {icon ? (
      <img
        src={icon}
        style={{
          height: '1em',
          width: '1em',
          marginRight: 4,
          objectFit: 'contain',
        }}
      />
    ) : (
      <></>
    )}
    {title}
  </span>
)

export const LocaleNumber = ({ source, className, ...props }) => {
  const num = get(props, source) || 0
  const value = numberFormat(num)
  return <span className={className}>{value}</span>
}

import { useFindInmarketRewardsQuery } from '@coin-microservices/coin-inmarket-react-hooks/dist/client.generated'
import IconButton from '@material-ui/core/IconButton'
import Fab from '@material-ui/core/Fab'
import TablePagination from '@material-ui/core/TablePagination'
import RefreshIcon from '@material-ui/icons/Refresh'
import Skeleton from '@material-ui/lab/Skeleton'
import times from 'lodash/times'
import { useEffect, useMemo, useState } from 'react'
import { RewardIcon } from '../components/Rewards/Icon'
import { useScrollContext } from '../components/Scroll'
import { AWSError } from '../components/Error'
import { View } from '../components/Footer'
import { TableCursorActions } from '../components/Table/Pagination'
import { InmarketProvider } from '../context/inmarket'
import { localJSONStore } from '../utils/localStore'
import { numberFormat } from '../utils/number'

const inmarketDisclaimer = localJSONStore('inmarket-disclaimer')

const InmarketHistory = ({ type }) => {
  const scrollElement = useScrollContext()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const variables = useMemo(() => {
    const filter = {}
    if (type !== 'all') filter.type = { EQ: type }
    return {
      filter,
      pagination: { page, perPage },
      sort: { field: 'created', order: -1 },
    }
  }, [page, perPage, type])
  const { data, loading, error, refetch } = useFindInmarketRewardsQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  })
  const { remoteRowCount, rowData } = useMemo(() => {
    const remoteRowCount = data?.findInmarketRewards?.total ?? 0
    const rowData = data?.findInmarketRewards?.data ?? []
    return { remoteRowCount, rowData }
  }, [data])

  const changePage = (p) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    setPage(p)
  }
  const handleChangePage = (event, newPage) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }
  const showDisclaimer = async () => {
    // inmarketDisclaimer.to({ time: Date.now() })
    // await swalWarning(
    //   'Warning',
    //   'Be sure to answer the surveys truthfully. If the Pollfish system detects you are randomly answering the questions, you may not get rewarded for your time or may even be rejected from their system and not be allowed to take any further surveys.',
    // )
  }

  useEffect(() => {
    const seen = inmarketDisclaimer.from()
    if (seen && seen.time) {
      // pollFish.initialize()
      return
    }
    showDisclaimer()
      // .then(() => pollFish.initialize())
      .catch((e) => console.log(e))
  }, [])

  return (
    <View>
      <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8 px-0">
        <div className="d-flex align-items-center text-white px-2 px-md-0">
          <h1>{type === 'visit' ? 'Rewarded Visits' : 'Rewarded Scans'}</h1>
          <div className="flex-grow-1" />
          <IconButton
            disabled={loading}
            onClick={() => refetch({ variables })}
            className="ml-1"
          >
            <RefreshIcon />
          </IconButton>
        </div>
        <div className="position-relative list-group text-dark">
          {loading ? (
            times(perPage).map((a, i) => (
              <InmarketRewardListItem key={i} loading={true} />
            ))
          ) : (rowData || []).length ? (
            (rowData || []).map((reward, i) => (
              <InmarketRewardListItem key={reward.id} reward={reward} />
            ))
          ) : type === 'visit' ? (
            <NoRewardedVisits />
          ) : type === 'scan' ? (
            <NoRewardedScans />
          ) : null}
          {remoteRowCount ? (
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25]}
              count={remoteRowCount}
              rowsPerPage={perPage}
              page={page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TableCursorActions}
            ></TablePagination>
          ) : null}
        </div>
      </div>
    </View>
  )
}

const InmarketRewardListItem = ({ reward, loading }) => {
  return (
    <div className="list-group-item d-flex align-items-center justify-content-between rounded-0-sm">
      {loading ? (
        <Skeleton
          variant="rect"
          width={80}
          height={80}
          className="skeleton"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
        />
      ) : (
        <div
          className="border border-info rounded overflow-hidden text-center text-white d-flex align-items-center flex-column"
          style={{ height: 80, width: 80, position: 'relative' }}
        >
          <div className="d-flex flex-grow-1 align-items-center justify-content-center">
            <RewardIcon type={reward?.type ?? ''} style={{ height: 28 }} />
          </div>
          <p className="mb-0 w-100 text-uppercase bg-info">
            {reward?.type ?? ''}&nbsp;
          </p>
        </div>
      )}
      <div />
      <div className="ml-4 text-right">
        {loading ? (
          <>
            <Skeleton
              variant="text"
              width={80}
              className="skeleton"
              classes={{ root: 'ml-auto' }}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
            />
            <Skeleton
              variant="text"
              width={60}
              className="skeleton"
              classes={{ root: 'ml-auto' }}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
          </>
        ) : (
          <>
            <h4 className="mb-0">
              Earned:{' '}
              {reward?.points ? numberFormat(reward?.points) : numberFormat(0)}
            </h4>
            <p className="mb-0 text-muted">
              <small>{new Date(reward?.created).toLocaleDateString()}</small>
            </p>
          </>
        )}
      </div>
    </div>
  )
}

const NoRewardedVisits = () => {
  return (
    <div className="list-group-item text-center text-dark py-5 rounded-0-sm">
      <h4 className="mb-2">No Previous Rewarded Visits Found</h4>
      <p>
        Read more about rewarded visits{' '}
        <a
          href="https://support.coinapp.co/hc/en-us/articles/360058812333-Rewarded-Scans"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>
    </div>
  )
}

const NoRewardedScans = () => {
  return (
    <div className="list-group-item text-center text-dark py-5 rounded-0-sm">
      <h4 className="mb-2">No Previous Rewarded Scans Found</h4>
      <p>
        Read more about rewarded scans{' '}
        <a
          href="https://support.coinapp.co/hc/en-us/articles/360058812333-Rewarded-Scans"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>
    </div>
  )
}

export const InmarketVisits = () => {
  return (
    <InmarketProvider>
      <InmarketHistory type="visit" />
    </InmarketProvider>
  )
}

export const InmarketScans = () => {
  return (
    <InmarketProvider>
      <InmarketHistory type="scan" />
    </InmarketProvider>
  )
}

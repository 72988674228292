import { ReactNode, useMemo } from 'react'
import { FadeIn } from '../Animate'
import { RebillySubscription } from '@xyo-network/api-checkout-apollo'
import {
  getUpgradeToProDetails,
  getUpgradeToMasterDetails,
} from '../Checkout/plans'
import { swalConfirm } from '../Alert'
import { PurchasePlus, PurchasePro } from '../Upgrade'
import { useUpgrade } from '../../store/subscriptions'
import Cancel from './Cancel'
import Change from './Change'
import Reactivate from './Reactivate'
import PayInvoice from './PayInvoice'
import get from 'lodash/get'
import PotentialEarnings from '../PotentialEarnings'
import { DangerBtn } from '../Buttons'
// import CircularProgress from '@material-ui/core/CircularProgress'
// import Tooltip from '@material-ui/core/Tooltip'
import { useMySubscription, useMySubscriptionMaster } from './mySubscription'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { lightTheme } from '../../context/page'
import { numberFormat } from '../../utils/number'
import coinLogo from '../../assets/CoinNoText.svg'
import { BuyNowButton } from '../Checkout'

export const UpgradePlanToPro = () => {
  const { data, subscription } = useMySubscription()
  const upgradePlan = getUpgradeToProDetails()
  const planId =
    subscription && subscription.planId === 'COIN-PLUS-ANNUAL-249-50'
      ? upgradePlan.annualPlanId
      : upgradePlan.planId
  return data?.account ? (
    <Change
      subscription={subscription}
      cards={data?.account?.customer?.paymentCards}
      planId={planId}
    >
      {upgradePlan.buttonText}
    </Change>
  ) : (
    <></>
  )
}

export const UpgradePlanToMaster = () => {
  const {
    data,
    isMasterRoute,
    isImpossibleReason,
    isUnavailableReason,
    subscription,
    userInfo,
  } = useMySubscriptionMaster()
  const account = data?.account
  const upgradePlan = getUpgradeToMasterDetails()
  if (!account) return null
  if (!userInfo) return null
  if (!isMasterRoute) return null
  const error = (
    <BuyNowButton
      onClick={() => { }}
      onRenderPopover={() => { }}
      renderPopover={() => (
        <MuiThemeProvider theme={lightTheme}>
          <div className="d-flex flex-wrap" style={{ maxWidth: 400 }}>
            <div className="col-12 text-center mb-2">
              <img style={{ height: 80 }} alt="COIN Logo" src={coinLogo} />
            </div>
            <div className="col-12 text-center text-dark">
              <p>{isImpossibleReason || isUnavailableReason}</p>
            </div>
          </div>
        </MuiThemeProvider>
      )}
    >
      {() => <DangerBtn>{upgradePlan.buttonText}</DangerBtn>}
    </BuyNowButton>
  )
  if (isImpossibleReason) return error
  if (isUnavailableReason && !get(userInfo, 'masterOk')) {
    return error
  }
  return (
    <Change
      subscription={subscription}
      cards={account.customer?.paymentCards}
      planId={upgradePlan.planId}
    >
      {upgradePlan.buttonText}
    </Change>
  )
}

const UpgradePlanToProFHR = () => {
  const upgrade = useUpgrade()
  const upgradePlan = getUpgradeToProDetails()
  const { buyPlusFHR, closeUpgrade } = upgrade[1]
  const upgradeFhrPlusToPro = async () => {
    closeUpgrade()
    const { value } = await swalConfirm(
      undefined,
      'Congratulations!',
      'success',
      {
        confirmButtonText: 'Continue',
        html:
          'As a verified FHR member you are eligible for a discounted COIN Pro upgrade for only $12.95 per month for up to 12 months (based on when you started using FHR Subscription benefits) <a href="https://coinappco.zendesk.com/hc/en-us/articles/360050977274" target="_blank" rel="noopener noreferrer">Learn More</a>',
      },
    )
    if (value) buyPlusFHR()
  }

  return (
    <DangerBtn onClick={upgradeFhrPlusToPro}>
      {upgradePlan.buttonText}
    </DangerBtn>
  )
}

export const MySubscriptionDisplay = () => {
  const {
    data,
    loading,
    userInfo,
    subscription,
    activeSubscription,
    tokenLogin,
    isFHR,
  } = useMySubscription()
  const customer = data?.account?.customer

  // if (loading)
  //   return (
  //     <div className="text-center my-3">
  //       <CircularProgress />
  //     </div>
  //   )
  if (loading) return <div style={{ height: 40 }} />

  return (
    <FadeIn delay config>
      <SubscriptionActions
        isFHR={isFHR}
        activeSubscription={activeSubscription}
        subscriptionInfo={userInfo && userInfo.subscriptionInfo}
        tokenLogin={tokenLogin}
        subscription={subscription}
        upgradeToPro={<UpgradePlanToPro />}
        upgradeToMaster={<UpgradePlanToMaster />}
        upgradeFhrPlusToPro={<UpgradePlanToProFHR />}
        purchasePlus={<PurchasePlus popover={true} />}
        purchasePro={<PurchasePro popover={true} />}
        payRecentInvoice={
          userInfo &&
          !userInfo.subActive &&
          customer &&
          subscription &&
          subscription.recentInvoice && (
            <PayInvoice
              invoiceId={subscription.recentInvoice.id}
              cards={customer.paymentCards}
            >
              Pay Invoice
            </PayInvoice>
          )
        }
        cancelSubscription={
          subscription && !isCanceledOrChurned(subscription) ? (
            <Cancel subscription={subscription} />
          ) : userInfo && userInfo.subActive ? (
            <BuyNowButton
              renderPopover={() => (
                <div className="d-flex flex-wrap" style={{ maxWidth: 400 }}>
                  <div className="col-12 text-center mb-2">
                    <img
                      style={{ height: 80 }}
                      alt="COIN Logo"
                      src={coinLogo}
                    />
                  </div>
                  <div className="col-12 text-center text-dark">
                    <p>
                      It appears you have purchased your subscription through
                      the app. To upgrade or downgrade open the app, go to the
                      account screen and tap the info icon in the Plan section.
                      To cancel your subscription you must do so through the
                      Playstore or iTunes &amp; App Store settings.
                    </p>
                  </div>
                  {subscription && !isCanceledOrChurned(subscription) ? (
                    <div className="col-12 text-center text-dark">
                      <Cancel subscription={subscription} />
                    </div>
                  ) : null}
                </div>
              )}
              onClick={() => { }}
              onRenderPopover={() => { }}
            >
              {() => <DangerBtn>Cancel Subscription</DangerBtn>}
            </BuyNowButton>
          ) : null
        }
        reactivateSubscription={
          subscription && <Reactivate subscription={subscription} />
        }
      />
    </FadeIn>
  )
}

function isCanceledOrChurned(subscription: RebillySubscription) {
  const status = subscription?.status
  return status === 'canceled' || status === 'churned'
}

export const SubscriptionPotential = ({ data }: any) => {
  const { loading } = useMySubscription()
  if (loading) return <></>

  return (
    <FadeIn delay config>
      <div
        className="offset-md-2 col-md-8"
        style={{ borderBottom: '2px dashed' }}
      />
      <div className="offset-md-2 col-md-8 my-3">
        <PotentialEarnings
          value={null}
          headerClassName=""
          bodyClassName=""
          title={(collected: number, userInfo: any) => (
            <span>
              You have Geomined{' '}
              {/* <Tooltip
                enterDelay={200}
                leaveDelay={2000}
                title={
                  <table className="table table-borderless table-sm text-white mb-0">
                    <tbody>
                      <tr>
                        <td>Collected</td>
                        <td className="text-right">
                          <strong>
                            {numberFormat(get(userInfo, 'xyoCollected') || 0)}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Geoclaimed</td>
                        <td className="text-right">
                          <strong>
                            {numberFormat(get(userInfo, 'xyoGeoclaimed') || 0)}
                          </strong>
                        </td>
                      </tr>
                      {get(userInfo, 'xyoRedeemPending') ? (
                        <tr>
                          <td>Pending</td>
                          <td className="text-right">
                            <strong>
                              {numberFormat(
                                get(userInfo, 'xyoRedeemPending') || 0,
                              )}
                            </strong>
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <td>Redeemed</td>
                        <td className="text-right">
                          <strong>
                            {numberFormat(get(userInfo, 'xyoRedeemed') || 0)}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Dropped</td>
                        <td className="text-right">
                          <strong>
                            {numberFormat(get(userInfo, 'xyoDropped') || 0)}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Expired</td>
                        <td className="text-right">
                          <strong>
                            {numberFormat(get(userInfo, 'xyoExpired') || 0)}
                          </strong>
                        </td>
                      </tr>
                      {get(userInfo, 'xyoSweepstakes') ? (
                        <tr>
                          <td>Sweepstakes</td>
                          <td className="text-right">
                            <strong>
                              {numberFormat(
                                get(userInfo, 'xyoSweepstakes') || 0,
                              )}
                            </strong>
                          </td>
                        </tr>
                      ) : null}
                      <tr className="border-bottom">
                        <td>Picked Up</td>
                        <td className="text-right text-danger">
                          <strong>
                            ({numberFormat(get(userInfo, 'xyoPickedUp') || 0)})
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td className="text-right">
                          <strong>{numberFormat(collected)}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              > */}
              <span>
                {numberFormat(collected)} COIN
                {/* <i
                    className="fa fa-info-circle mb-2"
                    style={{ fontSize: 12, verticalAlign: 'top' }}
                  /> */}
              </span>
              {/* </Tooltip> */}
            </span>
          )}
          subtitle={'Here is what that would look like with each plan*'}
          upgradeToPro={<UpgradePlanToPro />}
          upgradeToMaster={<UpgradePlanToMaster />}
          upgradeFhrPlusToPro={<UpgradePlanToProFHR />}
        />
      </div>
    </FadeIn>
  )
}

export const RedeemUpsale = ({ value }: { value: number }) => {
  return (
    <PotentialEarnings
      value={value}
      headerClassName="bg-white text-dark p-2"
      bodyClassName="p-4"
      title={'With a premium plan you could redeem even more XYO!'}
      subtitle={(collected: number) =>
        `Here is what your redemption for ${numberFormat(
          collected,
        )} XYO would look like under each plan*`
      }
      upgradeToPro={<UpgradePlanToPro />}
      upgradeToMaster={<UpgradePlanToMaster />}
      upgradeFhrPlusToPro={<UpgradePlanToProFHR />}
    />
  )
}

export const SubscriptionInfo = () => {
  const { subscription } = useMySubscription()
  const status = subscription?.status
  const billingStatus = subscription?.billingStatus
  return (
    <div>
      {subscription && (
        <>
          {status === 'active' && subscription.startTime && (
            <p className="mb-0">
              <strong>Start Date:</strong>{' '}
              {new Date(subscription.startTime).toLocaleString()}
            </p>
          )}
          {status === 'active' &&
            subscription.upcomingInvoice &&
            subscription.renewalTime && (
              <p className="mb-0">
                <strong>Next Billing Date:</strong>{' '}
                {new Date(subscription.renewalTime).toLocaleString()}
              </p>
            )}
          {/* {status === 'active' && subscription.upcomingInvoice && subscription.renewalTime && <p className='mb-0'><strong>Next Billing Amount:</strong> ${subscription.upcomingInvoice.amount || 0}</p>} */}
          {status !== 'active' &&
            !subscription.upcomingInvoice &&
            subscription.renewalTime && (
              <p className="mb-0">
                <strong>Deactivation Date:</strong>{' '}
                {new Date(subscription.renewalTime).toLocaleString()}
              </p>
            )}
          {status !== 'canceled' &&
            (billingStatus === 'unpaid' || billingStatus === 'past-due') &&
            subscription.recentInvoice && (
              <p className="mb-0 text-danger">
                <strong>Recent Invoice Unpaid</strong>
              </p>
            )}
        </>
      )}
    </div>
  )
}

export const SubscriptionActions = ({
  isFHR,
  tokenLogin,
  activeSubscription,
  subscription,
  purchasePlus,
  purchasePro,
  reactivateSubscription,
  cancelSubscription,
  downgradeToPlus,
  subscriptionInfo,
  upgradeFhrPlusToPro,
  payRecentInvoice,
  upgradeToPro,
  upgradeToMaster,
}: {
  isFHR?: boolean
  tokenLogin?: boolean
  activeSubscription?: string
  subscriptionInfo?: any
  subscription?: RebillySubscription | null
  payRecentInvoice?: ReactNode
  purchasePlus?: ReactNode
  purchasePro?: ReactNode
  upgradeToPro?: ReactNode
  upgradeToMaster?: ReactNode
  downgradeToPlus?: ReactNode
  upgradeFhrPlusToPro?: ReactNode
  reactivateSubscription?: ReactNode
  cancelSubscription?: ReactNode
}) => {
  const actions = useMemo(() => {
    const status = subscription && subscription.status
    const billingStatus = subscription && subscription.billingStatus
    if (status === 'canceled' || status === 'churned') {
      return (
        <>
          {reactivateSubscription}
          {cancelSubscription}
        </>
      )
    }
    if (billingStatus === 'unpaid' || billingStatus === 'past-due') {
      return (
        <>
          {cancelSubscription}
          {payRecentInvoice}
        </>
      )
    }
    if (activeSubscription === 'Plus' && isFHR) return upgradeFhrPlusToPro
    if (activeSubscription && subscription) {
      if (activeSubscription === 'Pro') {
        return (
          <>
            {cancelSubscription}
            {upgradeToMaster}
          </>
        )
      } else if (activeSubscription === 'Plus') {
        return (
          <>
            {cancelSubscription}
            {upgradeToPro}
          </>
        )
      } else return cancelSubscription
    }
    if (tokenLogin) return <div style={{ height: 40 }}></div>
    if (activeSubscription && activeSubscription.toLowerCase() !== 'basic') {
      return <></>
    }
    return (
      <>
        {purchasePlus}
        {purchasePro}
      </>
    )
  }, [activeSubscription, isFHR, subscription])
  return <div className="text-center">{actions}</div>
}

import { useMemo } from 'react'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import MaskedInput, { conformToMask } from 'react-text-mask'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import CreditCardType, { isValidCreditCard } from './CreditCardType'
import Form, { FormField } from '../Form'
import times from 'lodash/times'

const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth()
const years = times(2050).slice(currentYear)
const months = times(12)
const cardNotExpired = (vs) => {
  if (!vs.ccExpYear) return ''
  if (vs.ccExpYear < currentYear) return 'Expired'
  if (vs.ccExpYear === currentYear && vs.ccExpMonth <= currentMonth + 1)
    return 'Expired'
  return ''
}
// const cardNotExpired = (v) => {
//   const [month, year] = v.split('/').map(n => Number(n))
//   if (year < currentYear - 2000) return 'Expired'
//   if (year === currentYear - 2000 && month <= currentMonth + 1) return 'Expired'
//   return ''
// }

const cardInfoValidator = {
  method: (v) => (v ? '' : 'Required'),
  ccNumber: (v) => (v ? (isValidCreditCard(v) ? '' : 'Invalid') : 'Required'),
  ccCvv: (v) => (v ? '' : 'Required'),
  // ccExp: v => v ? cardNotExpired(v) : 'Required',
  ccExpMonth: (v, vs) => (v ? cardNotExpired(vs) : 'Required'),
  ccExpYear: (v, vs) => (v ? cardNotExpired(vs) : 'Required'),
}

export default ({ id, onSubmit, initial, children }) => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      initial={initial}
      validator={cardInfoValidator}
    >
      {({ values }) => (
        <>
          <FormField
            id="method"
            name="method"
            component={SelectPaymentMethod}
            label="Method"
          />
          <CreditCardType ccNumber={values.ccNumber} />
          <FormField
            id="cardNumber"
            name="ccNumber"
            component={CreditCardMaskField}
            autoFocus
            autoComplete="cc-number"
            label="Credit card number"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <div className="row">
            <div className="col pr-1">
              <FormField
                id="cardExpirationMonth"
                name="ccExpMonth"
                component={SelectMonth}
                autoComplete="cc-exp cc-exp-month"
                label="Exp Month"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
            <div className="col px-1">
              <FormField
                id="cardExpirationYear"
                name="ccExpYear"
                autoComplete="cc-exp cc-exp-year"
                component={SelectYear}
                label="Exp Year"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
            <div className="col pl-1">
              <FormField
                id="cardCsc"
                name="ccCvv"
                component={TextField}
                autoComplete="cc-csc"
                label="CVV"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </div>
          </div>
          {children}
          <small>
            <small>
              Charges on your statement will appear as xycompany442037460957
            </small>
          </small>
        </>
      )}
    </Form>
  )
}

const cardMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d?/,
]
const expMask = [/\d/, /\d/, '/', /\d/, /\d/]

const CreditCardMaskField = ({ onChange, ...props }) => {
  const value = useMemo(
    () => conformToMask(props.value, cardMask, { guide: true }).conformedValue,
    [props.value],
  )
  const handleChange = (ev) => {
    const value = ev.target.value.replace(/\D/g, '')
    onChange(ev, value)
  }
  return (
    <TextField
      {...props}
      value={value}
      onChange={handleChange}
      InputProps={{ inputComponent: CreditCardMask }}
    />
  )
}

const CreditCardMask = ({ inputRef, ...props }) => {
  return (
    <MaskedInput
      {...props}
      ref={(ref) => inputRef(ref ? ref.inputElement : null)}
      mask={cardMask}
      showMask
    />
  )
}

const CreditCardExpMaskField = ({ onChange, ...props }) => {
  const value = useMemo(
    () => conformToMask(props.value, expMask, { guide: true }).conformedValue,
    [props.value],
  )
  const handleChange = (ev) => {
    // const value = ev.target.value.replace(/\D/g, '')
    onChange(ev, value)
  }
  return (
    <TextField
      {...props}
      value={value}
      onChange={handleChange}
      InputProps={{ inputComponent: CreditCardExpMask }}
    />
  )
}

const CreditCardExpMask = ({ inputRef, ...props }) => {
  return (
    <MaskedInput
      {...props}
      ref={(ref) => inputRef(ref ? ref.inputElement : null)}
      mask={expMask}
      showMask
    />
  )
}

const SelectPaymentMethod = ({ label, error, helperText, ...props }) => {
  return (
    <FormControl component="fieldset">
      <RadioGroup {...props} row>
        <FormControlLabel
          value="payment-card"
          control={<Radio />}
          label="Credit / Debit card"
        />
        {/* <FormControlLabel
          value="paypal"
          control={<Radio />}
          label="PayPal"
          disabled
        />
        <FormControlLabel
          value="direct-deposit"
          control={<Radio />}
          label="Direct Deposit"
          disabled
        /> */}
      </RadioGroup>
    </FormControl>
  )
}

const SelectMonth = (props) => {
  const handleChange = (ev, element) => {
    props.onChange(ev, element.props.value)
  }
  return (
    <TextField select {...props} onChange={handleChange}>
      <MenuItem value="">Choose...</MenuItem>
      {months.map((i) => (
        <MenuItem value={i + 1} key={i}>
          {String(i + 1).padStart(2, '0')}
        </MenuItem>
      ))}
    </TextField>
  )
}

const SelectYear = (props) => {
  const handleChange = (ev, element) => {
    props.onChange(ev, element.props.value)
  }
  return (
    <TextField select {...props} onChange={handleChange}>
      <MenuItem value="">Choose...</MenuItem>
      {years.map((i) => (
        <MenuItem value={i} key={i}>
          {i}
        </MenuItem>
      ))}
    </TextField>
  )
}

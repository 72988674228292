import { useContext, createContext, useEffect, useState, useMemo } from 'react'
import { AddressForm, AddressInput, AddressButton } from '../Address'
import { useAsyncControls, defaultState } from '../../utils/hooks'
import { useUser } from '../../context/user'
import AddressHelp from '../Address/Help'
import Request, { ActiveRequest } from '../Request'
import Redeem, { ActiveRedeem } from '../Redeem'
import negate from 'lodash/negate'
import firebase from 'firebase/app'
import sum from 'lodash/sum'
import cx from 'classnames'
import 'firebase/firestore'

const loadUserRequests = (db, uid) => {
  const col = db.collection('coin_withdrawal')

  return col.where('ownerUID', '==', uid).orderBy('created', 'desc')
}

const toArray = (snapshot) => {
  const results = []
  snapshot.forEach((doc) => {
    results.push({ id: doc.id, ...doc.data() })
  })
  return results
}

export const RequestsContext = createContext(defaultState)
export const RequestContext = createContext()

export const RequestsProvider = ({ children }) => {
  const { user } = useUser()
  const [state, { setLoading, setError, setData }] = useAsyncControls(
    defaultState,
  )
  useEffect(() => {
    if (user) {
      setLoading(true)
      return loadUserRequests(firebase.firestore(), user.uid).onSnapshot(
        (snapshot) => {
          setData(toArray(snapshot))
        },
      )
    }
  }, [user])
  return (
    <RequestsContext.Provider value={state}>
      {children}
    </RequestsContext.Provider>
  )
}

export const RequestProvider = ({ request: req, children }) => {
  const [state, setState] = useState(req)
  useEffect(() => {
    return firebase
      .firestore()
      .collection('coin_withdrawal')
      .doc(req.id)
      .onSnapshot((doc) => {
        setState(doc.data())
      })
  }, [req.id])
  return (
    <RequestContext.Provider value={state}>{children}</RequestContext.Provider>
  )
}

export const TotalRedeemed = ({ className }) => {
  const { loading, data = [] } = useContext(RequestsContext)
  const total = sum(
    data
      .filter(isCompletedRequest)
      .map(({ sent }) => Number(sent))
      .filter((v) => !isNaN(v)),
  )
  if (loading || !total) return null
  return <p className={className}>Total: {total.toLocaleString()}</p>
}

export const isCompletedRequest = (request) =>
  request && request.status === 'completed'

export const isActiveRequest = (request) =>
  request && (request.status === 'requested' || request.status === 'authorized')

export const useActiveRequests = () => {
  const { loading, data = [] } = useContext(RequestsContext)
  const active = useMemo(() => data.filter(isActiveRequest), [data])
  return { loading, active }
}

const hasWallet = (request) => request && request.wallet

export const ActiveRequests = ({ onSubmit }) => {
  const { loading, active = [] } = useActiveRequests()
  const isActionRequired = !active.every(hasWallet)
  return (
    <>
      {active.length ? (
        <div className="d-flex mb-4">
          <div className="flex-grow-1">
            <p className="mb-0">
              <b>Pending</b>
            </p>
            {isActionRequired && (
              <p className="mb-0">
                <em>
                  <i className="fa fa-info" /> Please provide wallet address
                </em>
              </p>
            )}
          </div>
          {isActionRequired && (
            <button className="btn btn-success btn-sm" onClick={onSubmit}>
              Add Wallet
            </button>
          )}
        </div>
      ) : null}
      {active.map((request) => (
        <ActiveRedeem key={request.id} request={request} onSubmit={onSubmit} />
      ))}
    </>
  )
}

export const HistoricalRequests = () => {
  const { data = [], loading } = useContext(RequestsContext)
  const historical = data.filter(negate(isActiveRequest))
  return (
    <div className="mb-2">
      {historical.length ? (
        <p>
          <b>Redeem History</b>
        </p>
      ) : (
        ''
      )}
      {loading ? (
        <div className="text-center text-danger pb-40vh">
          <div className="spinner-border" />
        </div>
      ) : (
        historical.map((request) => (
          <Redeem key={request.id} request={request} />
        ))
      )}
    </div>
  )
}

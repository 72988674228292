import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from './components/Error'
import RedirectScreen from './components/Logo/RedirectScreen'
import SplashScreen from './components/Logo/SplashScreen'
// import * as serviceWorker from './serviceWorker'
import { getNfcRefistrationInviteUrl } from './context/bossapi'
import CssBaseline from '@material-ui/core/CssBaseline'
import Main from './Main'
import App from './App'
import { parse } from 'querystring'
import './index.scss'

try {
  if (
    // window.location.host === 'localhost:3000' ||
    window.location.host === 'x.coinapp.co'
  ) {
    getNfcRefistrationInviteUrl()
      .then((inviteUrl) => {
        ReactDOM.render(
          <RedirectScreen
            error={new Error(`Not redirected automatically?`)}
            message={`Redirecting to ${inviteUrl}`}
            onClick={() => window.open(inviteUrl)}
          />,
          document.getElementById('web-root'),
        )
        window.location.href = inviteUrl
      })
      .catch((err) => {
        console.log(err)
        ReactDOM.render(
          <RedirectScreen
            error={new Error(`Not redirected automatically?`)}
            message={`Redirecting to https://coinapp.co`}
            onClick={() => window.open('https://coinapp.co')}
          />,
          document.getElementById('web-root'),
        )
        window.location.href = 'https://coinapp.co'
      })
  } else if (
    window.location.search.indexOf('ug') !== -1 &&
    window.location.search.indexOf('provider') !== -1
  ) {
    try {
      window.opener.displaySurvey(
        parse(window.location.search.replace('?', '')),
      )
    } catch (e) {
      // console.log(e)
    }
    window.close()
  } else {
    ReactDOM.render(
      <Suspense fallback={<SplashScreen />}>
        <ErrorBoundary fallback={(error) => <SplashScreen error={error} />}>
          <BrowserRouter>
            <Main>
              <CssBaseline />
              <App />
            </Main>
          </BrowserRouter>
        </ErrorBoundary>
      </Suspense>,
      document.getElementById('web-root'),
    )
  }
} catch (e) {}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()

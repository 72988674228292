import { Suspense, useState, useMemo, useEffect, useRef } from 'react'
import { BossProvider } from '../context/bossapi'
import { useQuery } from 'react-apollo-hooks'
import get from 'lodash/get'
import Map from '../components/Map'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from '../components/Table/Pagination'
import { PlanIcon } from '../components/Plans'
import Avatar from '@material-ui/core/Avatar'
import Skeleton from '@material-ui/lab/Skeleton'
import { QuadkeyMap, GlobalMap } from '../components/Map'
import { useScrollContext } from '../components/Scroll'
import { numberFormat } from '../utils/number'
import { AWSError } from '../components/Error'
import { View } from '../components/Footer'
import gql from 'graphql-tag'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'
import identity from 'lodash/identity'
import capitalize from 'lodash/capitalize'
import isFunction from 'lodash/isFunction'
import negate from 'lodash/negate'
import times from 'lodash/times'
import flow from 'lodash/flow'
import cx from 'classnames'
import moment from 'moment'

export default () => (
  <BossProvider>
    <BackgroundRewardsPage />
  </BossProvider>
)

function BackgroundRewardsPage() {
  return (
    <View>
      <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8 px-0">
        <div className="d-flex align-items-center justify-content-between text-white px-2">
          <h1>Background Rewards</h1>
        </div>
        <div className="position-relative list-group text-dark">
          <BackgroundRewardsList />
        </div>
      </div>
    </View>
  )
}

function BackgroundRewardsList() {
  const scrollElement = useScrollContext()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const variables = useMemo(
    () => ({
      page,
      perPage,
    }),
    [perPage, page],
  )
  const { data, loading, error } = useQuery(BACKGROUND_REWARDS, { variables })
  const backgroundRewards = get(data, 'backgroundRewards.data') || []
  const remoteRowCount = get(data, 'backgroundRewards.total') || 0
  const handleChangePage = (event, newPage) => {
    changePage(newPage + 1)
  }
  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    changePage(1)
  }
  const changePage = (p) => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
    setPage(p)
  }
  return (
    <>
      {loading ? (
        times(perPage).map((a, i) => (
          <BackgroundRewards key={i} loading={true} />
        ))
      ) : backgroundRewards.length ? (
        backgroundRewards.map((backgroundReward) => (
          <BackgroundRewards
            key={backgroundReward._id}
            backgroundReward={backgroundReward}
          />
        ))
      ) : error ? (
        <AWSError error={error} />
      ) : (
        <NoBackgroundRewards />
      )}
      {remoteRowCount && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          count={remoteRowCount}
          rowsPerPage={variables.perPage}
          page={variables.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        ></TablePagination>
      )}
    </>
  )
}

function BackgroundRewards({ loading, backgroundReward, style }) {
  const plan = get(backgroundReward, 'plan')
  const amount = get(backgroundReward, 'amount')
  const secondsElapsed = get(backgroundReward, 'secondsElapsed')
  const secondsRewarded = get(backgroundReward, 'secondsRewarded')
  const xyoCollected = get(backgroundReward, 'xyoCollected')
  const date = toMoment(get(backgroundReward, 'date'))
  const epoch = get(backgroundReward, 'epoch')
  const yyyymmdd = get(backgroundReward, 'yyyymmdd')
  return (
    <div
      className="list-group-item d-flex align-items-center flex-nowrap rounded-0-sm"
      style={style}
    >
      {loading ? (
        <Skeleton
          variant="rect"
          width={80}
          height={80}
          className="skeleton"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
        />
      ) : plan ? (
        <div
          className="bg-info rounded overflow-hidden text-center text-white d-flex align-items-center flex-column"
          style={{ height: 80, width: 80, position: 'relative' }}
        >
          <div className="d-flex flex-grow-1 align-items-center justify-content-center">
            <PlanIcon plan={plan} height={28} />
          </div>
          <p
            className="mb-0 w-100 text-uppercase"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}
          >
            {plan}
          </p>
        </div>
      ) : null}
      <div className="flex-grow-1 mt-0 ml-3">
        {loading ? (
          <div>
            <Skeleton
              variant="text"
              width={180}
              className="skeleton"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
            />
            <Skeleton
              variant="text"
              width={160}
              className="skeleton"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
            <Skeleton
              variant="text"
              width={160}
              className="skeleton"
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            />
          </div>
        ) : (
          <div>
            <h4 className="mb-0 nowrap">{toLocaleString(amount)} COIN</h4>
            {/* {secondsElapsed ? (
              <p className="mb-0">
                <span className="nowrap">
                  Time Elapsed: {toFixed(secondsElapsed)} secs
                </span>
              </p>
            ) : null} */}
            {secondsRewarded ? (
              <p className="mb-0">
                <span className="nowrap">
                  Time Rewarded: {humanize(secondsRewarded)}
                </span>
              </p>
            ) : null}
            {xyoCollected ? (
              <p className="mb-0">
                <span className="">Running Total:</span>{' '}
                <span className="nowrap">{toLocaleString(xyoCollected)}</span>
              </p>
            ) : null}
            {date ? (
              <p className="mb-0 text-muted">
                <small>
                  <span className="d-none d-md-inline">Date:</span>{' '}
                  <span className="nowrap">
                    {date.toDate().toLocaleString()}
                  </span>
                </small>
              </p>
            ) : null}
          </div>
        )}
      </div>
      <div>
        <BackgroundRewardPercent
          loading={loading}
          percent={toFixed(
            ((secondsRewarded || 0) / secondsElapsed || 1) * 100,
          )}
        />
      </div>
    </div>
  )
}

function BackgroundRewardPercent({ loading, percent }) {
  if (loading)
    return (
      <Skeleton
        variant="circle"
        width={60}
        height={60}
        className="skeleton"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      />
    )
  if (!percent) return null
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#4a90e3',
        textAlign: 'center',
        color: '#fff',
        lineHeight: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontSize: getFontSize(percent),
        borderRadius: 60,
        height: 60,
        width: 60,
      }}
    >
      <strong>{percent}%</strong>
    </div>
  )
}

function NoBackgroundRewards() {
  return (
    <div className="list-group-item text-center py-5 rounded-0-sm">
      <h4>No Background Rewards Found</h4>
      <p>
        Read more about background rewards{' '}
        <a
          href="https://support.coinapp.co/hc/en-us/categories/360002104613"
          target="_blank"
        >
          here
        </a>
        .
      </p>
    </div>
  )
}

const log = (n) => (v) => {
  console.log(n, v)
  return v
}

const getFontSize = (secs) => {
  if (secs.length > 7) return 8
  if (secs.length === 7) return 10
  if (secs.length === 6) return 12
  if (secs.length === 5) return 14
  if (secs.length === 4) return 14
  if (secs.length === 3) return 14
  return 16
}
const toLocaleString = flow(Number, (v) =>
  v.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }),
)
const toMoment = fork(identity, flow(Number, moment), () => null)
const isFloored = (n) => Math.floor(n) === n
const toFixed = fork(
  isFloored,
  identity,
  flow(Number, (n) => n.toFixed(2)),
)
// const humanize = identity
const humanize = (secs) => {
  let amount = secs
  let unit = 'second'
  if (secs > 60 * 60 * 24) {
    amount = secs / 60 / 60 / 24
    unit = 'day'
  } else if (secs > 60 * 60) {
    amount = secs / 60 / 60
    unit = 'hour'
  } else if (secs > 60) {
    amount = secs / 60
    unit = 'minute'
  }
  return `${toFixed(amount)} ${unit}${amount === 1 ? '' : 's'}`
}

function fork(condition, truthy, falsy) {
  return function (...args) {
    if (condition(...args)) {
      return truthy(...args)
    } else {
      return falsy(...args)
    }
  }
}

const BACKGROUND_REWARDS = gql`
  query BackgroundRewards($page: Int, $perPage: Int) {
    backgroundRewards(
      pagination: { page: $page, perPage: $perPage }
      sort: { field: "date", order: -1 }
    ) {
      total
      data {
        _id
        uid
        plan
        amount
        secondsElapsed
        secondsRewarded
        xyoCollected
        date
        epoch
        yyyymmdd
      }
    }
  }
`

import { useCompleteEmailResetMutation } from '@coin-microservices/auth-change-email-react-hooks'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import AlertTitle from '@material-ui/lab/AlertTitle'
import firebase from 'firebase/app'
import 'firebase/auth'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { LoadingButton } from '../components/Buttons'
import { useSnackbar } from '../components/Snackables'
import { ChangeEmailProvider, verifyRequest } from '../context/changeEmail'
import { parseError } from '../utils/error'

const ChangeEmailView = () => {
  const params = useParams()
  const history = useHistory()
  const [request, setRequest] = useState(null)
  const [errors, setErrors] = useState({})
  const [snack, { setSnackbar }] = useSnackbar()
  const [completeEmailReset, complete] = useCompleteEmailResetMutation()

  const code = request?.id

  const onSubmit = (ev) => {
    ev.preventDefault()
    if (!code) return
    const email = ev.target.email.value
    const emailConfirm = ev.target.emailConfirm.value
    if (!email) return setErrors({ email: 'Email is required' })
    if (!emailConfirm)
      return setErrors({ emailConfirm: 'Email confirmation is required' })
    if (email !== emailConfirm)
      return setErrors({ emailConfirm: 'Email confirmation is invalid' })

    firebase
      .auth()
      .currentUser.updateEmail(email)
      .then(() =>
        completeEmailReset({
          variables: { id: code, email },
        }),
      )
      .then(() => {
        setSnackbar({
          severity: 'success',
          message: (
            <>
              <AlertTitle>Email Updated</AlertTitle>
              Your email was successfully changed to <strong>{email}</strong>
            </>
          ),
          autoHideDuration: 10000,
        })
      })
      .catch((e) => {
        setErrors({ email: parseError(e) })
      })
  }

  useEffect(() => {
    setSnackbar({
      loading: true,
      severity: 'info',
      message: 'Verifying request',
    })

    verifyRequest(params.code)
      .then((request) => {
        setSnackbar({
          severity: 'success',
          message: (
            <>
              <AlertTitle>Verified</AlertTitle>
              This is a one time link if you refresh or navigate away you will
              need a new link.
            </>
          ),
          autoHideDuration: 10000,
        })
        setRequest(request)
      })
      .catch((err) => {
        console.log(err)
        setSnackbar({
          severity: 'error',
          message: parseError(err),
          autoHideDuration: 6000,
        })
        history.push('/')
      })
  }, [params.code])

  return (
    <div className="text-white pt-5 pb-40vh">
      <Container maxWidth="sm">
        <Paper>
          <Box p={2}>
            <form onSubmit={onSubmit} disabled={!code}>
              <h1 style={{ fontWeight: 100 }}>Update Email</h1>
              <TextField
                name="email"
                label="New email"
                helperText={errors.email ?? ''}
                error={Boolean(errors.email)}
                variant="outlined"
                margin="normal"
                disabled={!code}
                fullWidth
              />
              <TextField
                name="emailConfirm"
                label="Confirm email"
                helperText={errors.emailConfirm ?? ''}
                error={Boolean(errors.emailConfirm)}
                variant="outlined"
                margin="normal"
                disabled={!code}
                fullWidth
              />
              <Box pt={2}>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={!code}
                  loading={complete.loading}
                  fullWidth
                >
                  Update
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Paper>
      </Container>
    </div>
  )
}

export default () => {
  return (
    <ChangeEmailProvider>
      <ChangeEmailView />
    </ChangeEmailProvider>
  )
}

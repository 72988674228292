import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import gql from 'graphql-tag'
import keyBy from 'lodash/keyBy'
import sum from 'lodash/sum'
import times from 'lodash/times'
import React, { useEffect, useState } from 'react'
import { Query } from 'react-apollo'
import { swalConfirmAppLink } from '../components/Alert'
import { FadeIn } from '../components/Animate'
import { FooterLinks } from '../components/Footer'
import Redeem from '../components/Redeem'
import { RequestsProvider } from '../components/Requests'
import BeforeYouWithdraw from '../components/Requests/BeforeYouWithdraw'
import { UpgradeModal } from '../components/Upgrade'
import { BossProvider, getVerificationCode } from '../context/bossapi'
import { useUser } from '../context/user'
import {
  CurrentSweepstakesListItems,
  SweepstakesProvider,
} from '../components/Sweepstakes'
import { useSnackbar } from '../components/Snackables'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { verifyAddressHistory } from '../context/bossapi'
import { parseError } from '../utils/error'
import lowerCase from 'lodash/lowerCase'
import { useHistory } from 'react-router-dom'

const Withdraw = () => {
  const { user } = useUser()
  useVerifyAddressHistory()
  return (
    <RequestsProvider>
      <Container className="text-white pt-5 pb-40vh" maxWidth="md">
        <BossProvider>
          <div className="row">
            <div className="col-12 mb-5">
              <RedeemOptions />
            </div>
            {user ? (
              <div className="col-12 mb-5">
                <Query
                  query={redeemHistoryQuery}
                  variables={{ sort: { field: 'date', order: -1 } }}
                >
                  {({ loading, data }) => {
                    const requests = loading
                      ? []
                      : (data &&
                          data.myRedeemHistory &&
                          data.myRedeemHistory.data) ||
                        []
                    const options = (data && data.redeemOptions) || []
                    const optionsById = keyBy(options, 'id')
                    const total = sum(
                      requests
                        .filter(
                          (request) =>
                            request && request.status === 'completed',
                        )
                        .map(
                          ({ sent, xyoCost }) =>
                            Number(xyoCost) || Number(xyoCost) || Number(sent),
                        )
                        .filter((v) => !isNaN(v)),
                    )
                    return (
                      // <div className="toc-md">
                      <div>
                        {/* <FadeIn>
                              <div className="mb-5">
                                <UserInfoDisplay />
                              </div>
                            </FadeIn> */}
                        <FadeIn timer={400} style={{ zIndex: 2 }}>
                          <BeforeYouWithdraw
                            requests={requests}
                            loading={loading}
                          />
                        </FadeIn>
                        <div>
                          <div className="mb-2">
                            {loading ? (
                              <div className="text-center text-danger pb-40vh">
                                <div className="spinner-border" />
                              </div>
                            ) : requests.length ? (
                              <>
                                <Paper>
                                  <CardHeader title="Redeem History" />
                                </Paper>
                                {requests.map((request) => (
                                  <Redeem
                                    key={request.id}
                                    request={request}
                                    option={optionsById[request.itemId]}
                                  />
                                ))}
                              </>
                            ) : null}
                          </div>
                          {total ? (
                            <p>Total: {total.toLocaleString()}</p>
                          ) : null}
                        </div>
                      </div>
                    )
                  }}
                </Query>
              </div>
            ) : null}
          </div>
        </BossProvider>
        <div className="mb-5">
          <FooterLinks />
        </div>
        {/* <FadeIn timer={800}>
          <BeforeYouWithdraw />
          <HistoricalRequests />
          <TotalRedeemed />
          <div className="mt-5">
            <FooterLinks />
          </div>
        </FadeIn> */}
      </Container>
      <UpgradeModal />
    </RequestsProvider>
  )
}

export const RedeemOptions = () => {
  const [activeTab, setActiveTab] = useState('direct')
  const openPurchaseInAppDialog = async () => {
    await swalConfirmAppLink({
      link: '/redeem',
      text: '',
      type: 'warning',
      html: `<ol>
      <li>
        In the app, tap the assets bar where the total collected is displayed.
      </li>
      <li>
        Tap the option you are interested in, and then tap the "Redeem" button.
      </li>
      </ol>`,
    })
  }
  return (
    <Query query={redeemOptionsQuery} errorPolicy="all">
      {({ data, loading }) => (
        <Card>
          <Box display="flex" alignItems="center" p={2}>
            <Typography variant="h6">Redeem Options</Typography>
            <Box flexGrow={1}></Box>
            <Button
              onClick={() => setActiveTab('direct')}
              // color={activeTab === 'direct' ? 'secondary' : ''}
              variant={activeTab === 'direct' ? 'contained' : 'initial'}
            >
              Direct
            </Button>
            <Box ml={1}>
              <Button
                onClick={() => setActiveTab('sweepstakes')}
                // color={activeTab === 'sweepstakes' ? 'secondary' : ''}
                variant={activeTab === 'sweepstakes' ? 'contained' : 'initial'}
              >
                Sweepstakes
              </Button>
            </Box>
          </Box>
          <Divider />
          <SweepstakesProvider>
            <List>
              {activeTab === 'sweepstakes' ? (
                <CurrentSweepstakesListItems />
              ) : activeTab === 'direct' ? (
                loading ? (
                  times(8).map((i) => (
                    <ListItem key={i}>
                      <ListItemAvatar>
                        <Skeleton variant="circle" height={60} width={60} />
                      </ListItemAvatar>
                      <ListItemText
                        className="ml-1"
                        primary={
                          <div className="mb-1">
                            <Skeleton variant="rect" height={24} width={100} />
                          </div>
                        }
                        secondary={
                          <Skeleton variant="rect" height={18} width={60} />
                        }
                      />
                      <ListItemSecondaryAction />
                    </ListItem>
                  ))
                ) : (
                  data?.redeemOptions
                    ?.filter(
                      (option) =>
                        option.active !== false && !option.activeWhenNoCrypto,
                    )
                    .filter((option) => option.coinAmount)
                    .filter(
                      (option) =>
                        !option.redeemLimit ||
                        option.redeemLimit > option.redeemCount,
                    )
                    .map((option) => (
                      <ListItem
                        key={option.id}
                        button
                        onClick={openPurchaseInAppDialog}
                        disabled={
                          option.redeemLimit &&
                          option.redeemLimit <= option.redeemCount
                        }
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={option.imageUrl}
                            style={{ borderRadius: 0 }}
                            imgProps={{
                              style: { objectFit: 'contain' },
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={option.title}
                          // secondary={
                          //   !option.redeemLimit
                          //     ? ''
                          //     : option.redeemLimit <= option.redeemCount
                          //     ? 'Sold Out'
                          //     : `${(
                          //         option.redeemLimit - option.redeemCount
                          //       ).toLocaleString(undefined, {
                          //         maximumFractionDigits: 0,
                          //         minimumFractionDigits: 0,
                          //       })} Remaining`
                          // }
                        />
                        {option.coinAmount ? (
                          <Box ml={1}>
                            <Typography noWrap>
                              {option.coinAmount.toLocaleString(undefined, {
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                              })}{' '}
                              COIN{option.variable ? '+' : ''}
                            </Typography>
                          </Box>
                        ) : null}
                      </ListItem>
                    ))
                )
              ) : null}
            </List>
          </SweepstakesProvider>
        </Card>
      )}
    </Query>
  )
}

export const redeemOptionFragment = gql`
  fragment RedeemOption on RedeemOption {
    id
    active
    sortOrder
    variable
    currency
    currencyAmount
    coinAmount
    title
    imageUrl
    description
    processingFeeInDollars
    requiresShippingAddress
    requiresWalletAddress
    activeWhenNoCrypto
    redeemCount
    redeemLimit
  }
`

export const redeemFragment = gql`
  fragment Redeem on RedeemRequest {
    id
    wallet
    updated
    reference
    ownerUID
    created
    confirmation
    shipFullName
    shipStreetAddress
    shipStreetAddress2
    shipCity
    shipState
    shipZipcode
    shipCountry
    shipPhoneNumber
    notes
    sent
    coinType
    status
    contact
    type
    fromLegacy
    imported
    itemId
    quantity
    uid
    state
    xyoCost
    xyoERC20Requested
    email
    timestamp
    ethAddress
    date
    currentTime
    yyyymmdd
    transactionHash
    confirmation
  }
`

export const redeemOptionsQuery = gql`
  query RedeemOptions {
    redeemOptions {
      ...RedeemOption
    }
  }

  ${redeemOptionFragment}
`

export const redeemHistoryQuery = gql`
  query RedeemHistory(
    $pagination: Pagination
    $sort: Sort # $filter: RedeemFilter
  ) {
    redeemOptions {
      ...RedeemOption
    }
    myRedeemHistory(pagination: $pagination, sort: $sort) {
      total
      page
      data {
        ...Redeem
      }
    }
  }

  ${redeemFragment}
  ${redeemOptionFragment}
`

export const redeemRetryMutation = gql`
  mutation RetryRedeemRequest($reference: String!) {
    retryRedeemRequest(reference: $reference) {
      ...Redeem
    }
  }
  ${redeemFragment}
`

const useVerifyAddressHistory = () => {
  const { setSnackbar } = useSnackbar()[1]
  const history = useHistory()

  useEffect(() => {
    const code = getVerificationCode()
    if (!code) return
    
    setSnackbar({
      loading: true,
      severity: 'info',
      message: 'Verifying redeem details request',
    })
    
    return verifyAddressHistory(code)
      .then((verified) => {
        if (!verified) return
        setSnackbar({
          severity: 'success',
          message: (
            <>
              <AlertTitle>Verified</AlertTitle>You have successfully
              verified your {verifiedAddressChangeType(verified.type)}.
            </>
          ),
          autoHideDuration: 10000,
        })
        history.push('/redeem')
      })
      .catch((err) => {
        setSnackbar({
          severity: 'error',
          message: parseError(err),
          autoHideDuration: 6000,
        })
        history.push('/redeem')
      })
  }, [])
}

function verifiedAddressChangeType (verified) {
  switch (verified.type) {
    case 'eth_address_update': return 'Ethereum wallet address'
    case 'coinbase_email_update': return 'shipping address'
    case 'shipping_address_update': return 'Coinbase email address'
    default:
      return ''
  }
}

export default Withdraw

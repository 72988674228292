import React from 'react'
import Downshift from 'downshift'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      position: 'relative',
    },
    paper: {
      position: 'absolute',
      backgroundColor: '#fff',
      maxHeight: 160,
      overflow: 'scroll',
      zIndex: 2,
      marginTop: 4,
      left: 0,
      right: 0,
    },
    inputInput: {
      width: 'auto',
      flexGrow: 1,
    },
    divider: {
      height: 4,
    },
    inputRoot: {
      flexWrap: 'wrap',
      color: 'currentColor',
      '&$cssFocused $notchedOutline': {
        borderWidth: 1,
        borderColor: `rgba(0, 0, 0, 0.23) !important`,
      },
      '&$cssFocused $cssError': {
        borderWidth: 1,
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
    cssFocused: {},

    notchedOutline: {},

    cssError: {},
  }),
)

function renderInput(inputProps) {
  const { InputProps, classes, ref, invalid, error, ...other } = inputProps

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
          focused: classes.cssFocused,
          notchedOutline: error ? classes.cssError : classes.notchedOutline,
        },
        ...InputProps,
      }}
      error={error}
      {...other}
    />
  )
}

function renderSuggestion(suggestionProps) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
  } = suggestionProps
  const isHighlighted = highlightedIndex === index
  const isSelected = (selectedItem || '').indexOf(suggestion) > -1
  return (
    <MenuItem
      {...itemProps}
      onClick={() => itemProps.onClick(suggestion)}
      key={suggestion}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion}
    </MenuItem>
  )
}

export const SelectItem = ({ children, ...props }) => {
  const classes = useStyles()
  console.log(classes)
  return (
    <Select
      input={
        <OutlinedInput labelWidth={0} classes={{ input: classes.inputRoot }} />
      }
      {...props}
    >
      {children}
    </Select>
  )
}

export const SelectInput = ({
  labelStyle,
  value,
  label,
  name,
  error,
  helperText,
  onChange: handleChange,
  getSuggestions,
  ...props
}) => {
  const classes = useStyles()
  return (
    <Downshift id="downshift-options">
      {({
        clearSelection,
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        openMenu,
        selectedItem,
      }) => {
        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
          name,
          value,
          onChange: (event) => {
            if (event.target.value === '') {
              clearSelection()
            }
            handleChange(event, event.target.value)
          },
          onFocus: openMenu,
          onClick: openMenu,
        })
        return (
          <div className={classes.container}>
            {renderInput({
              classes,
              fullWidth: true,
              error: !!error,
              label,
              helperText,
              variant: 'outlined',
              InputLabelProps: getLabelProps({
                // shrink: true,
                style: labelStyle,
                classes: {
                  focused: classes.cssFocused,
                },
              }),
              InputProps: { onChange, onFocus, onBlur },
              ...inputProps,
              ...props,
            })}

            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {getSuggestions(inputValue).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({
                        item: suggestion,
                        onClick: (v) => {
                          handleChange({ target: { name } }, v)
                        },
                      }),
                      highlightedIndex,
                      selectedItem,
                    }),
                  )}
                </Paper>
              ) : null}
            </div>
          </div>
        )
      }}
    </Downshift>
  )
}

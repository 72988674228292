import React from 'react'
import cx from 'classnames'

export const IconButton = ({ onClick, className, icon, sm }) => {
  return (
      <button className={cx('btn', { 'btn-sm': !!sm }, className)} onClick={onClick}>
          <i className={cx('fa', { [`fa-${icon}`]: icon })} />
      </button>
  )
}

export const EditIconButton = (props) => <IconButton icon='pencil-alt' {...props} />
export const SaveIconButton = (props) => <IconButton icon='check' {...props} />
export const CancelIconButton = (props) => <IconButton icon='times' {...props} />
export const BackIconButton = (props) => <IconButton icon='arrow-left' {...props} />
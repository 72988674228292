// import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
// import CardHeader from '@material-ui/core/CardHeader'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import { formatName, formatSymbol, getLogo } from './utils'
import { Position, Token } from './types'

export const UniswapV3PositionItem = ({
  position,
  onClick,
}: {
  position?: Position
  onClick: any
}) => {
  return (
    <ButtonBase
      onClick={onClick}
      style={{ width: '100%', textAlign: 'initial' }}
    >
      <Card style={{ width: '100%' }}>
        <Box p={1}>
          <TokenBalanceRow
            amount={position?.amount0}
            token={position?.token0}
          />
          <TokenBalanceRow
            amount={position?.amount1}
            token={position?.token1}
          />
        </Box>
      </Card>
    </ButtonBase>
  )
}

export const TokenBalanceRow = ({
  amount,
  token,
}: {
  amount?: string
  token?: Token
}) => {
  return (
    <Box display="flex" alignItems="center">
      <Box p={1}>
        {token ? (
          <img
            style={{ height: '1em' }}
            alt={formatName(token)}
            src={getLogo(token)}
          />
        ) : (
          <Skeleton variant="circle" height={12} width={12} />
        )}
      </Box>
      <Typography>
        {token ? formatName(token) : <Skeleton width={120} />}
      </Typography>
      <Box flexGrow={1} />
      {token ? (
        <Typography>
          <strong>{amount}</strong> {formatSymbol(token)}
        </Typography>
      ) : (
        <Skeleton width={120} />
      )}
    </Box>
  )
}

import React from 'react'
import {
  useTagAddressMutation,
  useRemoveAddressMutation,
  // useMyVerifiedAddressesWithTokenAndPairBalancesQuery,
  // MyVerifiedAddressesWithTokenAndPairBalancesDocument,
} from '@xyo-network/api-address-verifier-apollo/bindings'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { Mutation } from 'react-apollo'
import { SignatureVerification } from '../components/SignatureVerification'
import { SlideInUp } from '../components/Animate'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import Info from '@material-ui/icons/Info'
import size from 'lodash/size'
import { EditInline } from '../components/Edit'
import { numberFormat } from '../utils/number'
import { swalConfirm } from '../components/Alert'
import { UniswapV3PositionList } from '../components/UniswapV3'
import { View } from '../components/Footer'
// import positions from '../components/UniswapV3/mock'
import Container from '@material-ui/core/Container'
import { useUserInfo } from '../context/user'
import { verifyClient, VerifyProvider } from '../context/verify'
import { bossClient, BossProvider } from '../context/bossapi'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import cx from 'classnames'
import uni from '../assets/uniswap.png'

const MyVerifiedAddresses = (props) => {
  const { data, loading, error } = useQuery(
    MyVerifiedAddressesWithTokenAndPairBalancesDocument,
    {
      notifyOnNetworkStatusChange: true,
      client: verifyClient,
    },
  )
  const [uniDetails, setUniDetails] = React.useState(null)
  const { userInfo } = useUserInfo()
  const positions =
    (data?.verifiedAddresses ?? data?.userVerifiedAddresses)?.reduce(
      (acc, v) => acc.concat(v.poolPositions),
      [],
    ) ?? []
  return (
    <div className="my-5">
      {!loading &&
      size(data?.verifiedAddresses ?? data?.userVerifiedAddresses ?? []) ? (
        <>
          <h4 className="text-center mb-0">Verified Addresses</h4>
          <p className="text-center">
            Please note: it may take up to 6 hours for balances to update.
          </p>
        </>
      ) : (
        ''
      )}
      <div className="text-center">{loading ? <CircularProgress /> : ''}</div>
      {error ? <p className="text-danger">{error.message}</p> : ''}
      <ul className="list-group d-inline-flex w-100 mb-5">
        {(data?.verifiedAddresses ?? []).map((address, i) => (
          <AddressRow
            key={address.id}
            i={i}
            {...address}
            userInfo={userInfo}
            setUniDetails={setUniDetails}
          />
        ))}
      </ul>
      <UniswapV3PositionList positions={positions} />
      <PairInfoDialog uniDetails={uniDetails} setUniDetails={setUniDetails} />
    </div>
  )
}

const AddressRow = ({
  i,
  id,
  address,
  name,
  fhr,
  ethBalance,
  xyoBalance,
  xyoLpBalance,
  userInfo,
  setUniDetails,
}) => {
  const [remove, removing] = useRemoveAddressMutation({
    variables: { id },
    client: verifyClient,
    refetchQueries: [
      {
        query: MyVerifiedAddressesWithTokenAndPairBalancesDocument,
      },
    ],
  })
  const [tag, tagging] = useTagAddressMutation({
    client: verifyClient,
  })
  const isPrimary =
    userInfo &&
    (userInfo.ethAddress || userInfo.lastKnownAddress || '').toLowerCase() ===
      address.toLowerCase()
  return (
    <li
      className={cx(
        'list-group-item bg-transparent border-light overflow-hidden',
        { active: removing.loading, 'd-none': removing.data },
      )}
    >
      <div className="row align-items-center">
        <div className="col-md-5 mb-3 mb-md-0">
          <SlideInUp timer={200 * i}>
            <p className="d-flex align-items-center justify-content-center mb-0 text-white">
              <strong>
                <EditInline
                  initial={name || ''}
                  className="text-white"
                  placeholder={'Tag this address'}
                  onSave={(name) => tag({ variables: { id, name } })}
                />
              </strong>
              {isPrimary && (
                <span className="badge badge-success ml-2">Primary</span>
              )}
              {fhr && !fhr.hasSold && (
                <span className="badge badge-secondary ml-2">FHR</span>
              )}
            </p>
            <span className="d-flex align-items-center">
              {isPrimary && <i className="fa fa-check text-success" />}{' '}
              <span className="ellipsis px-1">{address}</span>
            </span>
          </SlideInUp>
        </div>
        <div className="col-3 col-md-2">
          <div className="border-left-md pl-2">
            <MaybeSmall text={numberFormat(ethBalance)} max={8} /> ETH
          </div>
        </div>
        <div className="col-3 col-md-2">
          <div className="border-left pl-2">
            <MaybeSmall text={numberFormat(xyoBalance)} max={8} /> XYO
          </div>
        </div>
        <div className="col-3 col-md-2">
          <div className="border-left pl-2 d-flex align-items-center">
            <MaybeSmall text={numberFormat(xyoLpBalance)} max={8} /> XYO/ETH{' '}
            <Info
              onClick={() => setUniDetails({ xyoLpBalance, address })}
              className="ml-1"
            />
          </div>
        </div>
        <div className="col-3 col-md-1">
          {removing.loading || removing.data ? null : (
            <Tooltip title="Set as primary">
              <Mutation mutation={updateEthAddress} client={bossClient}>
                {(setAsPrimary, primary) =>
                  primary.loading ? (
                    <div className="spinner-border spinner-border-sm text-white" />
                  ) : (
                    <IconButton
                      onClick={async () => {
                        await setAsPrimary({
                          variables: {
                            address,
                          },
                        })
                      }}
                    >
                      <i
                        className="fa fa-check text-success"
                        style={{ fontSize: 14 }}
                      />
                    </IconButton>
                  )
                }
              </Mutation>
            </Tooltip>
          )}
          {removing.loading ? (
            <div className="spinner-border spinner-border-sm text-danger" />
          ) : removing.data ? null : (
            <Tooltip title="Remove address">
              <IconButton
                onClick={async () => {
                  const { value } = await swalConfirm(
                    '',
                    'Are you sure you would like to remove this address?',
                  )
                  if (value) {
                    await remove()
                  }
                }}
              >
                <i
                  className="fa fa-trash text-danger"
                  style={{ fontSize: 14 }}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </li>
  )
}

const pairInfoQuery = gql`
  query PaieInfo {
    pair(address: "0xa986f2a12d85c44429f574ba50c0e21052b18ba1") {
      totalSupply
      getReserves {
        reserve0(unit: ether)
        reserve1(unit: ether)
      }
    }
  }
`

const PairInfoDialog = ({ uniDetails, setUniDetails }) => {
  const tokens = uniDetails?.xyoLpBalance || 0
  const { data } = useQuery(pairInfoQuery, { client: verifyClient })
  const total = data?.pair?.totalSupply / 1e18
  const percent = tokens / total
  const reserve0 = data?.pair?.getReserves?.reserve0 ?? 0
  const reserve1 = data?.pair?.getReserves?.reserve1 ?? 0
  return (
    <Dialog
      open={Boolean(uniDetails)}
      onClose={() => setUniDetails(null)}
      fullWidth
      maxWidth="xs"
    >
      <Box display="flex" alignItems="center" p={2}>
        <img src={uni} alt="Uniswap" className="mr-1" />
        <Typography variant="h6">Uniswap Pair</Typography>
        <Box flexGrow={1} />
        <Box mx={1}>
          <Button
            component="a"
            href="https://uniswap.exchange/add/0x55296f69f40ea6d20e478533c15a6b08b654e758/ETH"
            target="_blank"
            rel="noopener noreferrer"
          >
            + Liquidity
          </Button>
        </Box>
        <Button
          component="a"
          href="https://uniswap.exchange/swap?inputCurrency=0x55296f69f40ea6d20e478533c15a6b08b654e758&outputCurrency=ETH"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trade
        </Button>
      </Box>
      <Divider />
      <DialogContent>
        <Box display="flex" alignItems="center">
          <Typography>Your position</Typography>
          <Box flexGrow={1} />
          <Typography>
            <string>{numberFormat(tokens, 4)}</string>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography>Your pool share</Typography>
          <Box flexGrow={1} />
          <Typography>
            <strong>{numberFormat(percent * 100, 2)}%</strong>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography>XYO:</Typography>
          <Box flexGrow={1} />
          <Typography>
            <strong>{numberFormat(percent * reserve0, 0)}</strong>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography>ETH:</Typography>
          <Box flexGrow={1} />
          <Typography>
            <strong>{numberFormat(percent * reserve1, 4)}</strong>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const MaybeSmall = ({ text, max }) => {
  if (text.length > max) return <span className="font-small-md">{text}</span>
  return <>{text}</>
}

const updateEthAddress = gql`
  mutation UpdateEthAddress($address: String!) {
    updateEthAddress(address: $address) {
      id
      uid
      type
      ethAddress
    }
  }
`

const VerifiedAddressFragment = gql`
  fragment VerifiedAddress on VerifiedAddress {
    id
    uid
    type
    address
    signature
    name
    notes
    fhr {
      uid
      total
      balance
      hasSold
    }
  }
`
const MyVerifiedAddressesWithTokenAndPairBalancesDocument = gql`
  query MyVerifiedAddressesWithTokenAndPairBalances {
    # userVerifiedAddresses(uid: "A6eKRWEsvcaYHL0FABuS4DoPoIJ3") {
    verifiedAddresses {
      ...VerifiedAddress
      ethBalance(unit: ether)
      xyoBalance: contractBalance(
        unit: ether
        contractAddress: "0x55296f69f40ea6d20e478533c15a6b08b654e758"
      )
      xyoLpBalance: contractBalance(
        unit: ether
        contractAddress: "0xa986f2a12d85c44429f574ba50c0e21052b18ba1"
      )
      poolPositions(
        # contractAddress: "0xa986f2a12d85c44429f574ba50c0e21052b18ba1"
        contractAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
      ) {
        tokenId
        token0 {
          name
          symbol
        }
        token1 {
          name
          symbol
        }
        price0 {
          upper
          current
          lower
        }
        price1 {
          upper
          current
          lower
        }
        amount0
        amount1
        ratio0
        ratio1
        tokenURI
      }
    }
  }

  ${VerifiedAddressFragment}
`

export default () => {
  return (
    <VerifyProvider>
      <View>
        <Container maxWidth="md">
          <SignatureVerification title="Verify New Address" safeFHR={true} />
          <MyVerifiedAddresses />
        </Container>
      </View>
    </VerifyProvider>
  )
}

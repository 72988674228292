import AlertTitle from '@material-ui/lab/AlertTitle'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from '../components/Snackables'
import { getVerificationCode, verifyDevice } from '../context/bossapi'
import { parseError } from '../utils/error'

const VerifyDevice = () => {
  useVerifyDevice()
  return null
}

const useVerifyDevice = () => {
  const { setSnackbar } = useSnackbar()[1]
  const history = useHistory()

  useEffect(() => {
    const code = getVerificationCode()
    if (!code) return
    
    setSnackbar({
      loading: true,
      severity: 'info',
      message: 'Verifying device request',
    })
    
    return verifyDevice(code)
      .then((verified) => {
        if (!verified) return
        setSnackbar({
          severity: 'success',
          message: (
            <>
              <AlertTitle>Verified</AlertTitle>You have successfully
              verified your device.
            </>
          ),
          autoHideDuration: 10000,
        })
        history.push('/')
      })
      .catch((err) => {
        setSnackbar({
          severity: 'error',
          message: parseError(err),
          autoHideDuration: 6000,
        })
        history.push('/')
      })
  }, [])
}

export default VerifyDevice

import { createContext, useContext, useRef, useEffect } from 'react'

const ScrollContext = createContext(null)

export const useScrollContext = () => useContext(ScrollContext)

export const useScrollTopEffect = () => {
  const scrollElement = useScrollContext()
  useEffect(() => {
    if (scrollElement) {
      scrollElement.scrollTop = 0
    }
  }, [])
}

export const ScrollY = ({ children, style }) => {
  const ref = useRef(null)
  return (
    <ScrollContext.Provider value={ref.current}>
      <div className="scroll-y" style={style} ref={ref}>
        {children}
      </div>
    </ScrollContext.Provider>
  )
}

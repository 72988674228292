import { useEffect, useState } from 'react'
import { FadeIn } from '../components/Animate'
import coinLogo from '../assets/CoinNoText.svg'
import appStore from '../assets/app-store-badge.svg'
import playStore from '../assets/get-it-on-google-play.svg'

export default () => {
  const [isDesktop, setIsDesktop] = useState(false)
  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent,
      )
    ) {
      window.location.href = 'coinapp://premium'
    } else {
      setIsDesktop(true)
    }
  }, [])
  return (
    <div className="container-fluid text-white pt-2 pb-40vh">
      <div className="row">
        <div className="col-12 text-center mb-5">
          <img style={{ height: 120 }} alt="COIN Logo" src={coinLogo} />
        </div>
        <div className="col-12 text-center mt-4">
          <h1 style={{ fontWeight: 100 }}>GET PAID TO EXPLORE THE WORLD!</h1>
          {isDesktop ? (
            <p>Please open this link on your mobile device.</p>
          ) : null}
        </div>
        <div className="col-12 offset-lg-4 offset-md-3 offset-sm-2 col-md-3 col-sm-4 col-lg-2 text-center mt-4">
          <a href="https://itunes.apple.com/app/id1450443351">
            <img
              style={{ height: 50 }}
              alt="Available on the AppStore"
              src={appStore}
            />
          </a>
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-2 text-center mt-4">
          <a href="https://play.google.com/store/apps/details?id=network.xyo.coin">
            <img
              style={{ height: 50 }}
              alt="Get it on Google Play"
              src={playStore}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

import { useMemo } from 'react'
import get from 'lodash/fp/get'
import mapValues from 'lodash/mapValues'
import isArray from 'lodash/isArray'
import pickBy from 'lodash/fp/pickBy'
import { parse, stringify } from 'querystring'
import isNumber from 'lodash/isNumber'
import isBoolean from 'lodash/isBoolean'
import isString from 'lodash/isString'
import first from 'lodash/first'

const getLocationSearch = get('location.search')
const isUtmParam = key => (key || '').indexOf('utm_') === 0
const pickUtmParams = pickBy((_, key) => isUtmParam(key))

export const matchParam = (history, name) => {
  const search = getLocationSearch(history) || ''
  const match = search.match(new RegExp(`${name}=([^\&|\?]*)`))
  return match && match[1]
}

export const fromSearch = history => {
  const search = getLocationSearch(history) || ''
  return parse(search.replace(/^\?/, ''))
}

export const toSearch = params => {
  return stringify(params)
}

export const initialSearchParams = fromSearch(window)

export const utmParams = pickUtmParams(initialSearchParams)

if (initialSearchParams.userGroup) {
  // Does not follow utm_X add it anyway for appsflyer
  utmParams.userGroup = initialSearchParams.userGroup
  utmParams.media_source = initialSearchParams.utm_source || ''
  utmParams.campaign = initialSearchParams.utm_campaign || ''
  utmParams.ad_id = initialSearchParams.utm_content || ''
}

export function useQueryString(options, history) {
  const search = history?.location?.search?.slice?.(1) ?? ''
  const query = useMemo(() => {
    const values = parse(search)
    return mapValues(options, (opt, key) => {
      if (typeof values[key] === 'undefined') return opt.default
      if (opt.parse) return opt.parse(values[key])
      return values[key]
    })
  }, [search, options])
  const setQuery = values => {
    const search = stringify(values)
    const pathname = history.location.pathname
    history.push({ pathname, search })
  }
  return [query, setQuery]
}

export function queryValue(defaultValue) {
  const parse = pickParser(defaultValue)
  return {
    parse,
    default: defaultValue,
  }
}

function pickParser(defaultValue) {
  if (isArray(defaultValue)) {
    const fn = pickParser(first(defaultValue))
    return value => [].concat(value).map(fn)
  }
  if (isString(defaultValue)) {
    return v => v
  }
  if (isBoolean(defaultValue)) {
    return value => Boolean(value)
  }
  if (isNumber(defaultValue)) {
    return value => Number(value)
  }
  return v => v
}

import React from 'react'
import Downshift from 'downshift'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useMyVerifiedAddressesQuery } from '@xyo-network/api-address-verifier-apollo/bindings'
import { useAddress } from '../../context/address'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      position: 'relative',
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: 4,
      left: 0,
      right: 0,
    },
    inputInput: {
      width: 'auto',
      flexGrow: 1,
    },
    divider: {
      height: 4,
    },
    inputRoot: {
      flexWrap: 'wrap',
      color: 'currentColor',
      '&$cssFocused $notchedOutline': {
        borderWidth: 1,
        borderColor: `${theme.palette.text.primary} !important`,
      },
      '&$cssFocused $cssError': {
        borderWidth: 1,
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
    cssFocused: {},

    notchedOutline: {},

    cssError: {},
  }),
)

function renderInput(inputProps) {
  const { InputProps, classes, ref, invalid, error, ...other } = inputProps

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
          focused: classes.cssFocused,
          notchedOutline: error ? classes.cssError : classes.notchedOutline,
        },
        ...InputProps,
      }}
      error={error}
      {...other}
    />
  )
}

function getSuggestions(values, value) {
  return values || []
}

function renderSuggestion(suggestionProps) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
  } = suggestionProps
  const isHighlighted = highlightedIndex === index
  const isSelected = (selectedItem || '').indexOf(suggestion.address) > -1
  return (
    <MenuItem
      {...itemProps}
      onClick={() => itemProps.onClick(suggestion)}
      key={suggestion.address}
      selected={isHighlighted}
      component="div"
      style={{
        height: 36,
        flexDirection: 'column',
        fontWeight: isSelected ? 500 : 400,
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '1px solid',
        overflow: 'hidden',
      }}
    >
      <p className="mb-0 line-height-1">{suggestion.name}</p>
      <p className="mb-0 line-height-1 ellipsis w-100 text-center">
        {suggestion.address}
      </p>
    </MenuItem>
  )
}

export const AddressInput = ({ labelStyle }) => {
  const { data } = useMyVerifiedAddressesQuery()
  const classes = useStyles()
  const { wallet, invalid, setWallet, setInvalid } = useAddress()
  return (
    <Downshift id="downshift-options">
      {({
        clearSelection,
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        openMenu,
        selectedItem,
      }) => {
        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
          value: wallet,
          autoComplete: 'off',
          autoCorrect: 'off',
          autoCapitalize: 'off',
          spellCheck: false,
          placeholder: 'Enter your wallet address',
          onChange: (event) => {
            if (event.target.value === '') {
              clearSelection()
            }
            setWallet(event.target.value)
            setInvalid(false)
          },
          onFocus: openMenu,
          onClick: openMenu,
        })

        return (
          <div className={classes.container}>
            {renderInput({
              classes,
              fullWidth: true,
              error: !!invalid,
              label: invalid
                ? typeof invalid === 'string'
                  ? invalid
                  : 'The wallet address is not valid'
                : '',
              variant: 'outlined',
              InputLabelProps: getLabelProps({
                shrink: true,
                style: labelStyle,
                classes: {
                  focused: classes.cssFocused,
                },
              }),
              InputProps: { onBlur, onChange, onFocus },
              inputProps,
            })}

            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {getSuggestions(data.verifiedAddresses, inputValue).map(
                    (suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({
                          item: suggestion.address,
                          onClick: (v) => {
                            setWallet(v.address, true)
                            setInvalid(false)
                          },
                        }),
                        highlightedIndex,
                        selectedItem,
                      }),
                  )}
                </Paper>
              ) : null}
            </div>
          </div>
        )
      }}
    </Downshift>
  )
}

import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Camera, { IMAGE_TYPES } from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import { LoadingButton } from '../Buttons'
import Form, { FormField } from '../Form'
import './index.scss'
import { useBooleanControls } from '../../utils/hooks'
import { swalError } from '../Alert'
import { parseError } from '../../utils/error'
import idPng from '../../assets/ID-good-01.png'

const initialValues = { method: 'webcam', file: '' }
const idealResolution = { width: 640, height: 360 }
const kycFormValidator = {
  file: (v) => (v ? '' : 'Image is required'),
  agree: (v) => !v,
}

export const KYCForm = ({ onSubmit, loading, error }) => {
  const [example, exampleControls] = useBooleanControls(true)
  const [cameraError, cameraErrorControls] = useBooleanControls(false)
  const onCameraStartError = () => {
    cameraErrorControls.setTrue()
  }
  const handleSubmit = (values) => {
    if (onSubmit) {
      const file = values.file.split('base64,')[1]
      onSubmit({ file })
    }
  }
  return (
    <Form
      initial={initialValues}
      validator={kycFormValidator}
      onSubmit={handleSubmit}
    >
      {({ values, errors, handleChange }) => (
        <>
          <Typography variant="h4" paragraph>
            Verify your identification
          </Typography>
          <Typography paragraph>
            Upload or take a webcam photo of a valid government-issued ID (e.g.
            driver license, identification card, or passport).
          </Typography>
          <Typography variant="error" color="error">
            You do not have to be in the photo.
          </Typography>
          {example ? (
            <>
              <img
                src={idPng}
                alt="Example Identification"
                className="w-100 my-3"
              />
              <LoadingButton
                fullWidth
                variant="contained"
                color="primary"
                onClick={exampleControls.setFalse}
              >
                Continue
              </LoadingButton>
            </>
          ) : (
            <>
              {cameraError ? (
                <div className="mb-5" />
              ) : (
                <FormField name="method" component={UploadMethod} />
              )}
              <div className="position-relative">
                {cameraError || values.method === 'upload' ? (
                  <FormField
                    name="file"
                    component={FileUpload}
                    onError={(e) => swalError(e.message)}
                  />
                ) : (
                  <FormField
                    name="file"
                    component={WebcamUpload}
                    onCameraStartError={onCameraStartError}
                  />
                )}
                {values.file ? (
                  <>
                    <img
                      src={values.file}
                      className="position-absolute w-100 h-100 top-0 left-0"
                      style={{ objectFit: 'cover', pointerEvents: 'none' }}
                    />
                    <FileRemoveButton
                      onClick={() => handleChange('file', '')}
                    />
                  </>
                ) : null}
              </div>
              <FormField
                name="agree"
                type="checkbox"
                component={TokenIssuanceAgreement}
              />
              {errors && errors.agree && (
                <p className="text-danger">
                  You must read and accept the XYO Token Issuance Agreement
                </p>
              )}
              {error ? (
                <p className="text-danger">{parseError(error)}</p>
              ) : null}
              <LoadingButton
                fullWidth
                type="submit"
                loading={loading}
                disabled={!values.file}
                variant="contained"
                color="primary"
              >
                Submit
              </LoadingButton>
            </>
          )}
        </>
      )}
    </Form>
  )
}

const UploadMethod = ({ name, value, onChange }) => {
  return (
    <FormControl component="fieldset" className="w-100">
      <RadioGroup
        className="justify-content-center"
        aria-label="method"
        onChange={onChange}
        name={name}
        value={value}
        row
      >
        <FormControlLabel
          value="webcam"
          control={<Radio />}
          label="Use webcam"
        />
        <FormControlLabel
          value="upload"
          control={<Radio />}
          label="Upload photo"
        />
      </RadioGroup>
    </FormControl>
  )
}

const TokenIssuanceAgreement = ({ checked, name, onChange }) => {
  return (
    <>
      <div>
        <p>
          Please read and agree to the{' '}
          <a
            target="_blank"
            href="https://cdn.xy.company/documents/Token_Purchase_Agreement_March_2019.pdf"
          >
            XYO Token Issuance Agreement
          </a>
        </p>
      </div>
      <div className="d-flex align-items-center mb-3">
        <Checkbox
          id="token-issuance-agreement"
          name={name}
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        <label htmlFor="token-issuance-agreement" className="mb-0">
          I have read and agree to the terms of the XYO Token Issuance Agreement
        </label>
      </div>
    </>
  )
}

const WebcamUpload = ({
  name,
  value,
  onChange,
  onCameraStartError,
  onError,
}) => {
  const setValue = useCallback(
    (base86Image) => {
      onChange(name, base86Image)
    },
    [onChange],
  )

  const { getRootProps, getInputProps } = useDrop(setValue, onError)

  return (
    <>
      <Camera
        value={value}
        // onTakePhoto={setValue}
        onCameraError={onCameraStartError}
        onTakePhotoAnimationDone={setValue}
        isImageMirror={false}
        idealResolution={idealResolution}
        imageCompression={1}
        imageType={IMAGE_TYPES.JPG}
      />
      <div
        {...getRootProps()}
        className="position-absolute w-100 h-100 top-0 left-0"
      >
        <input {...getInputProps()} />
      </div>
    </>
  )
}

const FileUpload = ({ name, value, onChange, onError }) => {
  const setValue = useCallback(
    (base86Image) => {
      onChange(name, base86Image)
    },
    [onChange],
  )

  const { getRootProps, getInputProps } = useDrop(setValue, onError)

  return (
    <div className="input-group my-2">
      <section className="kyc-container">
        <div {...getRootProps()} className="dropzone w-100">
          <input id="upload-identity" {...getInputProps()} />
          <label
            htmlFor="upload-identity"
            className="kyc-container-label"
            onClick={(ev) => ev.stopPropagation()}
          >
            Drag and drop an image from your computer, or click to select an
            image from your device. Max filesize 6MB
          </label>
        </div>
      </section>
    </div>
  )
}

const FileRemoveButton = ({ onClick }) => {
  return (
    <div id="container-circles" style={{ zIndex: 1 }}>
      <div id="outer-circle" onClick={onClick}>
        <div
          id="inner-circle"
          className="d-flex align-items-center justify-content-center text-danger"
        >
          <i className="fa fa-times" />
        </div>
      </div>
    </div>
  )
}

function useDrop(onSuccess, onError) {
  const onDrop = useCallback(
    async ([file]) => {
      try {
        const image = await readFile(file)
        onSuccess(image)
      } catch (e) {
        onError(e)
      }
    },
    [onSuccess, onError],
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.png, .jpg, .jpeg',
  })

  return { getRootProps, getInputProps }
}

function readFile(file) {
  return new Promise((res, rej) => {
    if (!file) rej(new Error('No file supplied'))
    const reader = new FileReader()
    reader.addEventListener('load', () => res(reader.result), false)
    reader.addEventListener('error', (e) => rej(e))
    const { size } = file
    if (size < 4.8e7) {
      reader.readAsDataURL(file)
    } else {
      rej(new Error('File too large'))
    }
  })
}

import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { fromSearch } from '../utils/search'
import firebase from 'firebase/app'
import 'firebase/auth'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'
import Container from '@material-ui/core/Container'
import CardHeader from '@material-ui/core/CardHeader'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { swalError } from '../components/Alert'
import { useSnackbar } from '../components/Snackables'
import { parseError } from '../utils/error'

const AuthActions = () => {
  const history = useHistory()
  const { mode, oobCode, continueUrl, lang = 'en' } = useMemo(
    () => fromSearch(history),
    [history],
  )

  // Handle the user management action.
  switch (mode) {
    case 'resetPassword':
      return (
        <ResetPassword
          oobCode={oobCode}
          continueUrl={continueUrl}
          lang={lang}
        />
      )
    case 'recoverEmail':
      return (
        <RecoverEmail oobCode={oobCode} continueUrl={continueUrl} lang={lang} />
      )
    case 'verifyEmail':
      return (
        <VerifyEmail oobCode={oobCode} continueUrl={continueUrl} lang={lang} />
      )
    default:
      return null
  }
}

const ResetPassword = ({ oobCode, continueUrl, lang }) => {
  const history = useHistory()
  const [initialized, setInitialized] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({ password: '', confirm: '' })
  const [email, setEmail] = useState('rph')
  const [complete, setComplete] = useState(false)

  const confirmPasswordReset = async (ev) => {
    ev.preventDefault()
    const password = ev.target.password.value
    const confirm = ev.target.confirm.value
    const passwordError = !password ? 'Password is required' : ''
    const confirmError = !confirm
      ? 'Confirmation is required'
      : confirm !== password
      ? 'Confirmation does not match'
      : ''
    if (passwordError || confirmError) {
      setErrors({
        password: passwordError,
        confirm: confirmError,
      })
      return
    }

    setLoading(true)
    try {
      await firebase.auth().confirmPasswordReset(oobCode, password)
      setLoading(false)
      if (continueUrl) {
        setComplete(true)
      } else {
        await firebase.auth().signInWithEmailAndPassword(email, password)
        history.push('/')
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
      swalError(err.message)
    }
  }

  useEffect(() => {
    setInitialized(false)
    firebase
      .auth()
      .verifyPasswordResetCode(oobCode)
      .then((email) => {
        setInitialized(true)
        setEmail(email)
      })
      .catch((err) => {
        console.log(err)
        setInitialized(true)
        swalError(err.message)
        history.push('/')
      })
  }, [])

  return (
    <>
      {!initialized ? <LinearProgress /> : null}
      <div className="flex-grow-1" />
      <Container maxWidth="xs">
        <Slide direction="up" in={complete}>
          <Button onClick={() => window.open(continueUrl)} fullWidth>
            Continue
          </Button>
        </Slide>
        <Slide direction="up" in={Boolean(email && !complete)}>
          <Card component="form" onSubmit={confirmPasswordReset}>
            <CardHeader title="Reset Password" />
            <Box px={2}>
              <TextField
                label="New Password"
                name="password"
                type="password"
                margin="normal"
                variant="outlined"
                onChange={() => setErrors((e) => ({ ...e, password: '' }))}
                helperText={errors.password}
                error={!!errors.password}
                fullWidth
              />
              <TextField
                label="Confirm Password"
                name="confirm"
                type="password"
                margin="normal"
                variant="outlined"
                onChange={() => setErrors((e) => ({ ...e, confirm: '' }))}
                helperText={errors.confirm}
                error={!!errors.confirm}
                fullWidth
              />
            </Box>
            <Box p={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ position: 'relative' }}
                disabled={loading}
                fullWidth
              >
                {loading ? (
                  <LinearProgress
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                    }}
                  />
                ) : null}
                Submit
              </Button>
            </Box>
          </Card>
        </Slide>
      </Container>
    </>
  )
}

const RecoverEmail = ({ oobCode, continueUrl, lang }) => {
  const history = useHistory()
  const [initialized, setInitialized] = useState(false)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [complete, setComplete] = useState(false)

  const confirmResetPassword = async (ev) => {
    try {
      setLoading(true)
      await firebase.auth().sendPasswordResetEmail()
      setComplete(true)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    setInitialized(false)
    const auth = firebase.auth()
    auth
      .checkActionCode(oobCode)
      .then(async (info) => {
        await auth.applyActionCode(oobCode)
        setInitialized(true)
        setEmail(info?.data?.email)
      })
      .catch((err) => {
        console.log(err)
        setInitialized(true)
        swalError(err.message)
        history.push('/')
      })
  }, [])

  return (
    <>
      {!initialized ? <LinearProgress /> : null}
      <div className="flex-grow-1" />
      <Container maxWidth="xs">
        <Slide direction="up" in={complete}>
          <Card>
            <CardHeader
              style={{ alignItems: 'center' }}
              title="Check your email"
            />
            <Box px={2}>
              <Typography>
                Follow the instructions sent to{' '}
                <strong>{email || 'email'}</strong> to recover your password.
              </Typography>
            </Box>
            <Box p={2} pt={3} display="flex">
              <Box flex={1} />
              <Button
                variant="contained"
                color="primary"
                style={{ position: 'relative' }}
                onClick={() => history.push('/')}
              >
                Done
              </Button>
            </Box>
          </Card>
        </Slide>
        <Slide direction="up" in={Boolean(email && !complete)}>
          <Card>
            <CardHeader title="Updated email address" />
            <Box px={2} pb={2}>
              <Typography paragraph>
                Your sign-in email address has been changed back to{' '}
                <Link>{email}</Link>.
              </Typography>
              <Typography>
                If you didn’t ask to change your sign-in email, it’s possible
                someone is trying to access your account and you should{' '}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ position: 'relative' }}
                  onClick={confirmResetPassword}
                  disabled={loading}
                >
                  Change password right away.
                </Button>
              </Typography>
            </Box>
          </Card>
        </Slide>
      </Container>
    </>
  )
}

const VerifyEmail = ({ oobCode, continueUrl, lang }) => {
  const history = useHistory()
  const { setSnackbar } = useSnackbar()[1]

  useEffect(() => {
    const auth = firebase.auth()
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    Promise.resolve()
      .then(() => auth.applyActionCode(oobCode))
      .then((resp) => {
        // Email address has been verified.
        setSnackbar({
          severity: 'success',
          message: (
            <>
              <AlertTitle>Email Verified</AlertTitle> You have successfully
              verified your email.
            </>
          ),
          autoHideDuration: 10000,
        })
        history.push('/')
      })
      .catch((err) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        console.log(err)
        setSnackbar({
          severity: 'error',
          message: parseError(err),
          autoHideDuration: 6000,
        })
        history.push('/')
      })
  }, [])

  return null
}

export default AuthActions

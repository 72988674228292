import React from 'react'
import Box from '@material-ui/core/Box'
import {
  SweepstakesBackButton,
  SweepstakesEntryButtons,
  SuccessButton,
} from './Buttons'
import { SweepstakesFreeEntryButton } from './FreeEntry'
import { SweepstakesTermsAgreement, SweepstakesDisclaimer } from './Terms'
import { SweepstakesConfirmDetails, SweepstakesConfirmButton } from './Confirm'
import {
  useSweepstakesOptionsLoading,
  SweepstakesEntryTermsAgreed,
  SweepstakesEntryStep,
  sweepstakesEvent,
} from './context'

export const EnterStepActions = () => {
  return (
    <Box px={2} pb={1}>
      <SweepstakesEnterActions />
    </Box>
  )
}

export const EntryCountStepActions = () => {
  return (
    <Box pb={1}>
      <Box display="flex" pb={1} px={1}>
        <Box p={1}>
          <SweepstakesBackButton />
        </Box>
        <Box flexGrow={1}>
          <SweepstakesEntryButtons />
        </Box>
      </Box>
      <Box px={2}>
        <SweepstakesFreeEntryButton />
      </Box>
    </Box>
  )
}

export const EntryConfirmStepActions = () => {
  return (
    <Box pb={1}>
      <Box display="flex" pb={1} px={1}>
        <Box p={1}>
          <SweepstakesBackButton />
        </Box>
        <Box flexGrow={1}>
          <Box display="flex" flexGrow={1} alignItems="center">
            <SweepstakesConfirmDetails />
            <SweepstakesConfirmButton />
          </Box>
        </Box>
      </Box>
      <Box px={2} pt={2} textAlign="center">
        <SweepstakesDisclaimer />
      </Box>
    </Box>
  )
}

const SweepstakesEnterActions = () => {
  const loading = useSweepstakesOptionsLoading()
  const [agreed] = SweepstakesEntryTermsAgreed.useContext()
  const [step, setStep] = SweepstakesEntryStep.useContext()
  const onEnter = () => {
    sweepstakesEvent('enter', 'pressed', {})
    setStep(step + 1)
  }

  return (
    <Box display="flex" justifyContent="space-between">
      <SweepstakesTermsAgreement />
      <SuccessButton
        disabled={!agreed || loading}
        variant="contained"
        color="secondary"
        onClick={onEnter}
        disableElevation
      >
        Enter
      </SuccessButton>
    </Box>
  )
}

import React, {
  Component,
  useContext,
  useState,
  createContext,
  useEffect,
} from 'react'
import { parseError } from '../../utils/error'
import { trackWarning } from '../../utils/events'
import { useCustomTokenContext } from '../../context/customToken'
import { useUser } from '../../context/user'
import Typography from '@material-ui/core/Typography'
import cx from 'classnames'

export default React.memo(({ className, error }) => {
  const message = parseError(error)
  return message ? (
    <div
      className={cx(
        'alert bg-danger text-white',
        { 'd-none': !message },
        className,
      )}
    >
      {message}
    </div>
  ) : null
})

export const ErrorAlertAction = React.memo(({ className, error, action }) => {
  const message = parseError(error)
  return message ? (
    <div
      className={cx(
        'bg-danger text-white d-flex align-items-center justify-content-between p-3',
        { 'd-none': !message },
        className,
      )}
    >
      {message}
      {action}
    </div>
  ) : null
})

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    // trackWarning('Error Boundary', error, info)
    if (/Loading chunk (.*) failed./.test(error.message)) {
      this.setState({
        error: {
          message: 'New version found, please reload.',
        },
      })
    } else {
      this.setState({ error })
    }
  }

  render() {
    const { fallback, children } = this.props
    const { error } = this.state
    if (error) return fallback(error)
    return children
  }
}

const ErrorBannerContext = createContext({})

export const ErrorBannerProvider = ({ children }) => {
  const [error, setError] = useState(null)
  const removeError = () => setError(null)
  const setOnline = () => setError(null)
  const setOffline = () => setError('Network connection not found')
  useEffect(() => {
    window.addEventListener('online', setOnline)
    window.addEventListener('offline', setOffline)
    return () => {
      window.removeEventListener('online', setOnline)
      window.removeEventListener('offline', setOffline)
    }
  }, [])

  return (
    <ErrorBannerContext.Provider value={{ error, setError, removeError }}>
      {children}
    </ErrorBannerContext.Provider>
  )
}

export const useErrorBanner = () => {
  return useContext(ErrorBannerContext)
}

export const ErrorBanner = () => {
  const { error, removeError } = useErrorBanner()
  return (
    <ErrorAlertAction
      error={error}
      action={
        <button className="btn rounded-circle text-white" onClick={removeError}>
          <i className="fa fa-times" />
        </button>
      }
      className="mb-0"
    />
  )
}

export const AWSError = ({ error }) => (
  <div className="list-group-item py-5 rounded-0-sm">
    <Typography>
      <strong>FYI</strong>: Functionality is currently limited due to some
      Amazon Web Services issues. This will not affect the COIN Application, and
      should be resolved once Amazon addresses their outages!
    </Typography>
  </div>
)

export const CustomTokenBanner = () => {
  const { user, onLogout } = useUser()
  const [{ isUsingCustomToken }] = useCustomTokenContext()
  if (!isUsingCustomToken) return null
  return (
    <ErrorAlertAction
      error={`Currently logged in as ${user?.email ?? ''}`}
      action={
        <button
          className="btn btn-sm btn-outline-light ml-2"
          onClick={onLogout}
        >
          Logout
        </button>
      }
    />
  )
}

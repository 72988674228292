import { Suspense, useState } from 'react'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { Switch, Route, Redirect } from 'react-router-dom'
import Navbar, { LoginNavbar } from './components/Navbar'
import Sidebar from './components/Sidebar'
import Footer from './components/Footer'
import Private, {
  ExternalRedirect,
  RequireReauthentication,
  PrivateEmail,
  InvitedAdminRoute,
} from './components/Private'
import BottomNav from './components/BottomNav'
import SideNav from './components/SideNav'
import SurveyNav, { SurveyProvider } from './components/SurveyNav'
import { UserProvider, useUser } from './context/user'
import { VersionProvider } from './context/version'
import { VerifyProvider } from './context/verify'
import { AddressProvider } from './context/address'
import { useBooleanControls } from './utils/hooks'
import Particles from './components/Particles'
import { CustomTokenProvider } from './context/customToken'
import {
  Auth,
  AuthActions,
  Faq,
  AccountV2,
  AccountV3,
  AccountV3b,
  Badges,
  Terms,
  Privacy,
  Account,
  Verify,
  Withdraw,
  VerifyDevice,
  WithdrawAsset,
  VerifyRedeem,
  Success,
  Settings,
  Plans,
  FourOhFour,
  InmarketVisits,
  InmarketScans,
  ChangeEmail,
  // History,
  Invoices,
  InvoicesPastDue,
  Master,
  AppLink,
  Empty,
  Invite,
  Devices,
  Geoclaims,
  Geodrops,
  Geomines,
  Referrals,
  Referrer,
  VideoAds,
  Identity,
  FreeTrial,
  FreeTrialTest,
  Refund,
  BigWinsAcknowledged,
  BackgroundMining,
  CreateDrops,
  Surveys,
  Sweepstakes,
  SweepstakesHistory,
  SweepstakesTerms,
  AccountDelete,
  ThankYou,
  Validations,
  Opinion,
} from './routes'
import {
  PollFishProvider,
  fullReward,
  halfReward,
  quarterReward,
} from './context/pollfish'
import SplashScreen from './components/Logo/SplashScreen'
import {
  AlertProvider,
  useSetAlertError,
} from '@xyo-network/coin-forms/build/lib/components/Alert'
import {
  ErrorBannerProvider,
  CustomTokenBanner,
  ErrorBanner,
  useErrorBanner,
} from './components/Error'
import Snackables, { SnackbarProvider } from './components/Snackables'
import { ScrollY } from './components/Scroll'
import { useTracking } from './utils/gtag'

export default () => {
  return (
    <AlertProvider>
      <CustomTokenProvider>
        <UserProvider>
          <VerifyProvider>
            <AddressProvider>
              <ErrorBannerProvider>
                <SnackbarProvider>
                  <CustomTokenBanner />
                  <Router />
                </SnackbarProvider>
              </ErrorBannerProvider>
            </AddressProvider>
          </VerifyProvider>
        </UserProvider>
      </CustomTokenProvider>
    </AlertProvider>
  )
}

const Router = () => {
  useTracking()
  const setError = useSetAlertError()
  const [open, { setTrue, setFalse, toggle }] = useBooleanControls(false)
  const { initialized, user } = useUser()
  if (!initialized) return <SplashScreen message="Initializing..." />
  return (
    <PollFishProvider
      onError={(_, e) => setError(e)}
      onReady={({ showFullSurvey }) => showFullSurvey()}
      config={{
        uuid: user && user.uid,
        // ...quarterReward,
        ...halfReward,
        // ...fullReward,
      }}
    >
      <SurveyProvider>
        <div className="d-flex flex-column vh-100">
          <ScrollY>
            <ErrorBanner />
            <Switch>
              <Route path="/sweepstakes">
                {user ? (
                  <Navbar toggleDrawer={toggle} />
                ) : (
                  <LoginNavbar redirect="/sweepstakes" />
                )}
              </Route>
              <Route path="/redeem">
                {user ? (
                  <Navbar toggleDrawer={toggle} />
                ) : (
                  <LoginNavbar redirect="/redeem" />
                )}
              </Route>
              <Route path="/redeem*">
                {user ? (
                  <Navbar toggleDrawer={toggle} />
                ) : (
                  <LoginNavbar redirect="/redeem" />
                )}
              </Route>
              <Route path="/">
                <Navbar toggleDrawer={toggle} />
              </Route>
            </Switch>

            <Suspense fallback={<div />}>
              <Switch>
                <Route path="/faq" exact>
                  <Faq />
                </Route>
                <ExternalRedirect
                  path="/terms"
                  to="https://coinapp.co/legal/terms"
                  exact
                />
                <ExternalRedirect
                  path="/privacy"
                  to="https://coinapp.co/legal/privacy"
                  exact
                />
                <ExternalRedirect
                  path="/sweepstakes-terms"
                  to="https://coinapp.co/legal/sweepstakes-terms"
                  exact
                />
                <Route path="/plans" exact>
                  <Plans />
                </Route>
                <Route path="/freetrial" exact>
                  <FreeTrial />
                </Route>
                {process.env.NODE_ENV === 'development' ? (
                  <Route path="/freetrial/test" exact>
                    <FreeTrialTest />
                  </Route>
                ) : null}
                <Route path="/devices" exact>
                  <Devices />
                </Route>
                <Route path="/applink/:deeplink" component={AppLink} />

                <Route path="/success" exact>
                  <Success />
                </Route>
                <Route path="/auth" exact>
                  <>
                    <Auth />
                    <div className="flex-grow-1 pb-40vh" />
                  </>
                </Route>
                <Route path="/invite/:id" exact>
                  <Invite />
                </Route>
                <Route path="/__/auth/action" exact>
                  <AuthActions />
                </Route>
                <Route path="/auth/action" exact>
                  <AuthActions />
                </Route>
                <Private path="/" to="/auth" exact component={AccountV3b} />
                <Private
                  path="/account"
                  to="/auth"
                  exact
                  component={AccountV3b}
                />
                <Private
                  path="/account_v2"
                  to="/auth"
                  exact
                  component={AccountV2}
                />
                <Private
                  path="/account_v3"
                  to="/auth"
                  exact
                  component={AccountV3}
                />
                <Private
                  path="/account_v3b"
                  to="/auth"
                  exact
                  component={AccountV3b}
                />
                {/* <Private
                path="/account_v4"
                to="/auth"
                exact
                component={AccountV4}
              /> */}
                <Private
                  path="/invoices"
                  to="/auth"
                  exact
                  component={Invoices}
                />
                <Private path="/badges" to="/auth" exact component={Badges} />
                <Private
                  path="/invoices-past-due"
                  to="/auth"
                  exact
                  component={InvoicesPastDue}
                />
                <Private
                  path="/subscriptions/:subscriptionId/invoices"
                  to="/auth"
                  exact
                  component={Invoices}
                />
                <Private
                  path="/account/delete"
                  to="/auth"
                  exact
                  component={AccountDelete}
                />
                <Private path="/verify" to="/auth" exact component={Verify} />
                <Route path="/device-verification/verify" exact component={VerifyDevice} />
                <Route path="/redeem/verify" exact component={VerifyRedeem} />
                <Route path="/sweepstakes" exact component={Sweepstakes} />
                <Route
                  path="/sweepstakes/history"
                  exact
                  component={SweepstakesHistory}
                />
                <Private
                  path="/withdraw"
                  to="/auth"
                  exact
                  component={Withdraw}
                />
                <PrivateEmail path="/redeem" to="/auth" component={Withdraw} />
                <PrivateEmail path="/redeem*" to="/auth" component={Withdraw} />
                <PrivateEmail path="/VerifyDevice" to="/auth" component={VerifyDevice} />
                <RequireReauthentication
                  path="/change/email/:code"
                  to="/auth"
                  component={ChangeEmail}
                />
                <Private
                  path="/refund/:id"
                  to="/auth"
                  exact
                  component={Refund}
                />
                <Private
                  path="/identity"
                  to="/auth"
                  exact
                  component={Identity}
                />
                <Private path="/master" to="/auth" exact component={Master} />
                {/* <Private
                path="/redeem/:asset"
                to="/auth"
                exact
                component={WithdrawAsset}
              /> */}
                {/* <Private path="/history" to="/auth" exact component={History} /> */}
                <Private
                  path="/geoclaims"
                  to="/auth"
                  exact
                  component={Geoclaims}
                />
                <Private
                  path="/geomines"
                  to="/auth"
                  exact
                  component={Geomines}
                />
                <Private
                  path="/geodrops"
                  to="/auth"
                  exact
                  component={Geodrops}
                />
                <Private
                  path="/videoads"
                  to="/auth"
                  exact
                  component={VideoAds}
                />
                <Private
                  path="/bigwins"
                  to="/auth"
                  component={BigWinsAcknowledged}
                  exact
                />
                <Private
                  path="/background"
                  to="/auth"
                  component={BackgroundMining}
                  exact
                />
                <Private
                  path="/referrals"
                  to="/auth"
                  exact
                  component={Referrals}
                />
                <Private
                  path="/referrer"
                  to="/auth"
                  exact
                  component={Referrer}
                />
                <Private
                  path="/settings"
                  to="/auth"
                  exact
                  component={Settings}
                />
                <Private path="/surveys" to="/auth" component={Surveys} />
                <Private path="/opinion" to="/auth" exact component={Opinion} />
                <Private
                  path="/rewarded/visits"
                  to="/auth"
                  exact
                  component={InmarketVisits}
                />
                <Private
                  path="/rewarded/scans"
                  to="/auth"
                  exact
                  component={InmarketScans}
                />
                <Private
                  path="/rewarded/validations"
                  to="/auth"
                  exact
                  component={Validations}
                />
                <Private
                  path="/rewarded/verifications"
                  to="/auth"
                  exact
                  component={Validations}
                />
                <InvitedAdminRoute
                  path="/geodrops/create"
                  property="webDropsAllowed"
                  exact
                  component={CreateDrops}
                />
                <Route path="/premium" exact>
                  <Redirect to="/account?utm_source=app_promo" />
                </Route>
                <Route path="/thank-you">
                  <ThankYou />
                </Route>
                <Route path="/">
                  <FourOhFour />
                </Route>
              </Switch>
            </Suspense>
          </ScrollY>
          {/* <Switch>
        <Route path="/invite/:id" exact />
        <Route path="/">
          <VersionProvider />
        </Route>
      </Switch> */}
          <Switch>
            <Route path="/create-drops" exact component={BottomNav} />
            <Route path="/geoclaims" exact component={BottomNav} />
            <Route path="/geomines" exact component={BottomNav} />
            <Route path="/geodrops" exact component={BottomNav} />
            <Route path="/videoads" exact component={BottomNav} />
            <Route path="/bigwins" exact component={BottomNav} />
            <Route path="/background" exact component={BottomNav} />
            <Route path="/account_v3" exact component={BottomNav} />
            {/* <Route path="/account_v3b" exact component={BottomNav} /> */}
            <Route path="/surveys" component={SurveyNav} />
          </Switch>
          <Switch>
            <Route path="/geoclaims" exact component={SideNav} />
            <Route path="/geomines" exact component={SideNav} />
            <Route path="/geodrops" exact component={SideNav} />
            <Route path="/videoads" exact component={SideNav} />
            <Route path="/bigwins" exact component={SideNav} />
            <Route path="/background" exact component={SideNav} />
            <Route path="/surveys" component={SideNav} />
            <Route path="/account_v3" exact component={SideNav} />
            {/* <Route path="/account_v3b" exact component={SideNav} /> */}
            <Route path="/rewarded/visits" exact component={SideNav} />
            <Route path="/rewarded/scans" exact component={SideNav} />
            <Route path="/rewarded/validations" exact component={SideNav} />
            <Route path="/rewarded/verifications" exact component={SideNav} />
            <Route path="/sweepstakes/history" exact component={SideNav} />
          </Switch>
          <Switch>
            {/* <Route path="/account_v3b" exact component={Empty} /> */}
            <Route path="/account_v4" exact component={Empty} />
            <Route path="/verify" exact component={Empty} />
            <Route path="/opinion" exact component={Empty} />
            <Route path="/identity" exact component={Empty} />
            <Route path="/redeem/verify" exact>
              <Footer hiker={false} />
            </Route>
            <Route path="/redeem/:asset" exact component={Empty} />
            {/* <Route path="/identity" exact component={Empty} /> */}
            <Route path="/">
              <Footer hiker={false} />
            </Route>
          </Switch>
          <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={setFalse}
            onOpen={setTrue}
          >
            <div style={{ width: 250 }} onClick={setFalse} onKeyDown={setFalse}>
              <Sidebar />
            </div>
          </SwipeableDrawer>
          <Snackables />
          <Particles />
        </div>
      </SurveyProvider>
    </PollFishProvider>
  )
}
